import React, { useEffect } from 'react'
import './styles/customStyles.css'
import './styles/swalStyles.css'

import AlertProvider from './context/alert/alertContext'
import AppRouter from './routes/AppRouter'
import AuthProvider from './context/auth/authContext'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './styles/theme'
import HeaderProvider from './context/header/headerContext'
import RestaurantProvider from './context/restaurants/restaurantContext'
import BookingProvider from './context/booking/bookingContext'
import { getToken, onMessage } from 'firebase/messaging'
// import ErrorBoundary from './components/ErrorBoundary'
import firebase from './firebase/firebase'

export default function App(): JSX.Element {
  useEffect(() => {
    getToken(firebase.messaging, {
      vapidKey: 'BPMHfp_Sl-CCdVpvfIvoOG0OR4LL4mwaGE291OiBloIRcLlqHKfD1_YCV4yD7VS-1HzPCGjvfA3m807rm3mt_eM'
    }).catch((err) => {
      console.error('An error occurred while retrieving token. ', err)
      // ...
    })

    onMessage(firebase.messaging, (message) => {
      // previo a mostrar notificación
      const notificationTitle = message?.notification?.title as string
      const notificationOptions = {
        body: message?.notification?.body,
        icon: '/img/Avatar.png',
        data: message?.data
      }
      try {
        // eslint-disable-next-line no-new
        const notification = new Notification(notificationTitle, notificationOptions)

        notification.onclick = (event: any) => {
          event.preventDefault()
          const origin = window.location.origin
          if (event.target?.data.type === 'PLANNING_NOT_EDITED_RES') {
            window.location.replace(`${origin}/planificar`)
          } else {
            window.location.replace(`${origin}/pedidos`)
          }
          notification.close()
        }
      } catch (err) {
        console.error('error', err)
      }
    })
  }, [])
  return (
    // <ErrorBoundary>
    <AlertProvider>
      <AuthProvider>
        <HeaderProvider>
          <RestaurantProvider>
            <BookingProvider>
              <ThemeProvider theme={theme}>
                <AppRouter />
              </ThemeProvider>
            </BookingProvider>
          </RestaurantProvider>
        </HeaderProvider>
      </AuthProvider>
    </AlertProvider>
    // </ErrorBoundary>
  )
}
