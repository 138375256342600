import React, { useState, ChangeEvent, useEffect, useContext } from 'react'
import FormTitle from 'src/components/Form/FormTitle'
import ButtonSelector from 'src/components/Form/ButtonSelector'
import TextInput from 'src/components/Form/TextInput'
import Box from '@mui/material/Box'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps, DiscountTypes } from 'src/interfaces/pages.types'
import { ReactComponent as User } from 'src/assets/icons/general/User.svg'
import { ReactComponent as Users } from 'src/assets/icons/general/Users.svg'
import SwitchForm from 'src/components/Form/SwitchForm'
import ChipSelector from 'src/components/Form/ChipSelector'
import { labels } from 'src/labels/main_labels'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { createDiscount, updateDiscount } from 'src/services/discounts.services'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { useTranslation } from 'react-i18next'

const INITIAL_VALUES = { name: '', discount: '', expiration: '', totalUses: '', usesPerUser: '', obv: '' }

export default function CodigosAddEdit({ dataToEdit, closeModal, openModal, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState<DiscountTypes['type']>('NORMAL')
  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [users, setUsers] = useState<string[] | never[]>([])
  const [status, setStatus] = useState<DiscountTypes['status']>('ACTIVE')

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setType('NORMAL')
    setUsers([])
    setStatus('ACTIVE')
  }

  useEffect(() => {
    if (dataToEdit) {
      setType(dataToEdit.type)
      setUsers(dataToEdit.users || [])
      if (dataToEdit.status) setStatus(dataToEdit.status)
    }
    return () => resetComponent()
  }, [dataToEdit])

  const onSave = async () => {
    setLoading(true)
    if (dataToEdit) {
      const data: { obv: string; status: DiscountTypes['status']; users?: string[] } = {
        status: status,
        obv: values.obv
      }
      if (type === 'PARTICULAR') data.users = users?.map((user: any) => user._id)

      try {
        await updateDiscount(dataToEdit._id, data)
        showSnackbar('success', labels[lng].discountUpdated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    } else {
      const data: DiscountTypes = {
        name: values.name,
        discount: values.discount,
        type: type,
        status: status
      }
      if (values.expiration) data.expiration = new Date(values.expiration).toISOString()
      if (values.totalUsers) data.totalUsers = values.totalUsers
      if (values.usesPerUser) data.usesPerUser = values.usesPerUser
      if (values.obv) data.obv = values.obv

      if (type === 'PARTICULAR') data.users = users?.map((user: any) => user._id)
      try {
        await createDiscount(data)
        showSnackbar('success', labels[lng].discountCreated)
        if (fetchData) fetchData()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setStatus('ACTIVE')
    else setStatus('DISABLED')
  }

  const handleClose = () => {
    resetComponent()
    closeModal()
  }

  return (
    <Modal
      title={editing ? dataToEdit.name : labels[lng].addDiscount}
      open={openModal}
      handleClose={handleClose}
      footer={
        <FooterForm
          text={labels[lng].discount}
          closeModal={handleClose}
          onSave={onSave}
          editing={editing}
          loading={loading}
          disabled={!values.discount || !values.name || !type}
        />
      }
    >
      <Box id="form-codigos" component="form" noValidate>
        <FormTitle text={labels[lng].information} />
        <ButtonSelector
          noEditable={editing}
          text={labels[lng].type}
          valueSelected={type}
          setValue={setType}
          options={[
            { value: 'NORMAL', text: labels[lng].general, Icon: Users },
            { value: 'PARTICULAR', text: labels[lng].particular, Icon: User }
          ]}
        />
        <TextInput
          disabled={!!dataToEdit}
          value={values.name}
          handleChange={(e) => {
            e.target.value = e.target.value.toUpperCase()
            handleChange(e)
          }}
          name="name"
          id="name"
          text={labels[lng].name}
          placeholder={labels[lng].nameDiscount}
        />
        <TextInput
          disabled={!!dataToEdit}
          value={values.discount}
          handleChange={(e) => handleChange(e)}
          name="discount"
          id="discount"
          text={labels[lng].percentageDiscount}
          placeholder={labels[lng].percentageDiscountPlaceholder}
          type="number"
          percentage
        />

        <TextInput
          disabled={!!dataToEdit}
          value={values.expiration}
          handleChange={(e) => {
            handleChange(e)
          }}
          name="expiration"
          id="expiration"
          text={labels[lng].expirationDiscount + ' ' + labels[lng].optionalVoucher}
          placeholder={!editing ? labels[lng].expirationDiscountPlaceholder : '-'}
          type="date"
        />
        <TextInput
          disabled={!!dataToEdit}
          value={values.totalUses || ''}
          handleChange={(e) => handleChange(e)}
          name="totalUses"
          id="totalUses"
          text={labels[lng].totalUsesTitle + ' ' + labels[lng].optionalVoucher}
          placeholder={!editing ? labels[lng].totalUsesPlaceholder : '-'}
          type="number"
        />
        <TextInput
          disabled={!!dataToEdit}
          value={values.usesPerUser}
          handleChange={(e) => handleChange(e)}
          name="usesPerUser"
          id="usesPerUser"
          text={labels[lng].usesPerUser + ' ' + labels[lng].optionalVoucher}
          placeholder={!editing ? labels[lng].usesPerUserPlaceholder : '-'}
          type="number"
        />
        <TextInput
          value={values.obv}
          handleChange={(e) => handleChange(e)}
          name="obv"
          id="obv"
          text={labels[lng].observations + ' ' + labels[lng].optionalVoucher}
          placeholder={!editing ? labels[lng].observations : '-'}
          multiline
        />
        <SwitchForm text={labels[lng].state} handleChange={(e) => handleSwitch(e)} checked={status === 'ACTIVE'} />
        {editing && (
          <TextInput
            disabled
            value={dateTimeConvert(values.createdAt)}
            name="createdAt"
            id="createdAt"
            text={labels[lng].createdAt}
            placeholder=""
          />
        )}
        {type === 'PARTICULAR' && (
          <>
            <FormTitle text={labels[lng].adjudication} />
            <ChipSelector
              searchBy="users"
              data={users}
              setData={setUsers}
              options={[]}
              text={labels[lng].users}
              searchText={labels[lng].user}
              importUsers
            />
          </>
        )}
      </Box>
    </Modal>
  )
}
