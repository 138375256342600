import { PlateTypes, PlateReviewTypes, StatiticsTypes } from 'src/interfaces/pages.types'
import axiosInstance from './axiosInstance'
import axiosInstanceImages from './axiosInstanceImages'
import transformInToFormObject from 'src/utils/transformToFormData'

export const getPlates = async (): Promise<PlateTypes[]> => {
  return axiosInstance
    .get('/plate')
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de platos')
    })
}

export const getPlateById = async (id: string): Promise<PlateTypes> => {
  return axiosInstance
    .get(`/plate/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar el plato')
    })
}

// PDTE VER EQUIPO BACK
export const searchPlates = async (searchString: string): Promise<any> => {
  return axiosInstance
    .get(`/plate/${searchString}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar el plato')
    })
}

export const createPlate = async (newPlate: Record<string, any>): Promise<void> => {
  const formatted = transformInToFormObject(newPlate)

  return axiosInstanceImages
    .post('/plate/create', formatted)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba crear el plato')
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updatePlatePhoto = async (images: any, id: string): Promise<void> => {
  function dataURLtoFile(dataurl: any, filename: any) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const formData = new FormData()

  images.deletedImages.forEach(async (obj: any, index: any) => {
    if (obj !== '' && typeof obj === 'string') formData.append(`imagesDelete[${index}]`, obj)
    else formData.append(`imagesDelete[${index}]`, '')
  })

  images?.images.forEach(async (obj: any, index: any) => {
    if (obj !== '') {
      if (obj.substring(0, 4) === 'data') {
        const file = dataURLtoFile(obj, `images${index}.png`)

        formData.append(`images`, file)
      }
    }
  })

  return axiosInstanceImages
    .put(`/plate/${id}/photo`, formData)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar las fotos del plato')
    })
}

export const updatePlate = async (updatedPlate: Record<string, unknown>, id: string): Promise<void> => {
  delete updatedPlate._id
  delete updatedPlate.parameters
  delete updatedPlate.images

  const formatted = transformInToFormObject(updatedPlate)

  return axiosInstanceImages
    .put(`/plate/${id}`, formatted)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el plato')
    })
}

export const deletePlate = async (id: string): Promise<void> => {
  return axiosInstance
    .delete(`/plate/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar el plato')
    })
}

export const getPlateStatitics = async (
  type: string,
  plate: string,
  dateFrom?: Date | null,
  dateTo?: Date | null
): Promise<StatiticsTypes> => {
  const obj: { type: string; to?: Date | null; from?: Date | null; plate: string } = {
    type,
    to: (dateTo && new Date(Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 0, 0, 0))) || null,
    from: (dateFrom && new Date(Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0))) || null,
    plate
  }
  return axiosInstance
    .post('/plate_statics/plate_statics', obj)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar las estadisticas del plato')
    })
}

export const getReviewByPlate = async (id: string): Promise<PlateReviewTypes[]> => {
  return axiosInstance
    .get(`/review/plate/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaban cargar las valoraciones del plato')
    })
}

export const getReviewByRestaurant = async (
  id: string,
  limit?: number,
  offset?: number,
  searchString?: string,
  language?: string
): Promise<any> => {
  const pagination = limit ? `limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`
  return axiosInstance
    .get(`/review/record/${id}${query}`, {
      headers: {
        'Accept-Language': language || 'es'
      }
    })
    .then((response: any) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaban cargar el historial del restaurante')
    })
}
