import React, { createContext, useReducer, FC, useState, useContext, useEffect } from 'react'
import { RestaurantTypes } from 'src/interfaces/pages.types'
import { restaurantReducer } from './restaurantReducer'
import { AlertContext } from '../alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { getRestaurantData } from 'src/services/admin.services'
import { getRestaurantById, updateFcmRestaurant } from 'src/services/restaurants.services'
import { NeighborhoodTypes } from '../../interfaces/pages.types'
import { getParameters } from 'src/services/parameters.services'

export interface RestaurantState {
  restaurant: RestaurantTypes | null
  neighborhood: NeighborhoodTypes[] | null
  parameters: any | null
}

export interface RestaurantContextProps {
  fetchRestaurant: () => void
  fetchParameters: () => void
  fetchRestaurantById: (id: string) => void
  restaurant: RestaurantTypes | null
  loading: boolean | null
  parameters: any
}

// Initial State
export const restaurantInitialState: RestaurantState = {
  restaurant: null,
  parameters: null,
  neighborhood: null
}

// Create context
export const RestaurantContext = createContext({} as RestaurantContextProps)

const RestaurantProvider: FC = ({ children }) => {
  const { showSnackbar } = useContext(AlertContext)
  const { userData, firebase } = useContext(AuthContext)

  const [restaurantState, dispatch] = useReducer(restaurantReducer, restaurantInitialState)
  const [loading, setLoading] = useState(false)

  const fetchRestaurant = async () => {
    setLoading(true)
    try {
      const restaurant = await getRestaurantData()
      dispatch({ type: 'FETCH_RESTAURANT', payload: { restaurant } })
      if (restaurant._id) {
        const fcm = await firebase.verify()
        if (fcm) await updateFcmRestaurant(restaurant._id, fcm).catch(console.error)
      }
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchRestaurantById = async (id: string) => {
    // Only for restaurant platform
    if (userData && userData.role === 'restaurant') localStorage.setItem('rest_sel', id)
    setLoading(true)
    try {
      const restaurant = await getRestaurantById(id)
      dispatch({ type: 'FETCH_RESTAURANT', payload: { restaurant } })
      if (restaurant._id) {
        const fcm = await firebase.verify()
        if (fcm) await updateFcmRestaurant(restaurant._id, fcm).catch(console.error)
      }
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchParameters = async () => {
    // Only for admin platform
    setLoading(true)
    try {
      const parameters = await getParameters()
      dispatch({ type: 'FETCH_PARAMETERS', payload: { parameters } })
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (userData && userData.role === 'restaurant') {
      if (!userData.group || userData.isNewRestaurant) fetchRestaurant()
      else {
        const idFromStorage = localStorage.getItem('rest_sel')
        if (idFromStorage) fetchRestaurantById(idFromStorage)
      }
    }
    if (userData && userData.role === 'admin') fetchParameters()
    return () => dispatch({ type: 'REMOVE_RESTAURANT' })
  }, [userData])

  return (
    <RestaurantContext.Provider
      value={{
        restaurant: restaurantState.restaurant,
        parameters: restaurantState.parameters,
        fetchRestaurant,
        fetchRestaurantById,
        fetchParameters,
        loading
      }}
    >
      {children}
    </RestaurantContext.Provider>
  )
}

export default RestaurantProvider
