import React from 'react'
import { useTranslation } from 'react-i18next'
import FormTitle from 'src/components/Form/FormTitle'
import HistoryRow from 'src/components/HistoryRow'
import { labels } from 'src/labels/main_labels'

interface HistoricRestaurantProp {
  parameter: any
  parameters: any
}

export default function HistoricRestaurant({ parameter }: HistoricRestaurantProp): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  let historicArray: any = []

  if (parameter.MPOD.lastUpdate && parameter.MPOD.lastUpdate.length > 0) {
    historicArray = [...parameter.MPOD.lastUpdate.map((e: any) => ({ ...e, type: 'MPOD' }))]
  }

  if (parameter.EMHIRR.lastUpdate && parameter.EMHIRR.lastUpdate.length > 0) {
    historicArray = [...historicArray, ...parameter.EMHIRR.lastUpdate.map((e: any) => ({ ...e, type: 'EMHIRR' }))]
  }

  if (parameter.FRR.lastUpdate && parameter.FRR.lastUpdate.length > 0) {
    historicArray = [...historicArray, ...parameter.FRR.lastUpdate.map((e: any) => ({ ...e, type: 'FRR' }))]
  }
  historicArray = historicArray.sort((p1: any, p2: any) => new Date(p2.createdAt).getTime() - new Date(p1.createdAt).getTime())

  const displayHistoric = () => {
    return historicArray?.map((parameter: any, idx: number) => {
      if (parameter.type === 'MPOD') {
        return (
          <HistoryRow
            key={`restaurant-parameter-${idx}`}
            // name={parameter.MPOD?.admin?.name + '  ' + parameter.MPOD?.admin?.surname}
            date={parameter.updatedAt}
            lastValue={parameter.lastValue}
            fieldChanged={'MPOD'}
            byDefaultText={parameter.default}
            complex
          />
        )
      } else if (parameter.type === 'EMHIRR') {
        return (
          <HistoryRow
            key={`restaurant-parameter-${idx}`}
            // name={parameter.EMHIRR?.admin?.name + '  ' + parameter.EMHIRR?.admin?.surname}
            date={parameter.updatedAt}
            fieldChanged={'EMHIRR'}
            lastValue={
              parameter.lastValueHour && parameter.lastValueMinutes >= 0
                ? parameter.lastValueHour + ':' + String(parameter.lastValueMinutes).padStart(2, '0')
                : ''
            }
            complex
            byDefaultText={parameter.default}
          />
        )
      } else {
        return (
          <HistoryRow
            key={`restaurant-parameter-${idx}`}
            // name={parameter.FRR?.admin?.name + '  ' + parameter.FRR?.admin?.surname}
            date={parameter.updatedAt}
            fieldChanged={'FRR'}
            lastValue={
              parameter.lastValueHour && parameter.lastValueMinutes >= 0
                ? parameter.lastValueHour + ':' + String(parameter.lastValueMinutes).padStart(2, '0')
                : ''
            }
            complex
            byDefaultText={parameter.default}
          />
        )
      }
    })
  }

  return (
    <>
      <FormTitle text={labels[lng].history} />
      <div style={{ maxHeight: '192px', overflowY: 'scroll' }}>{historicArray?.length ? displayHistoric() : null}</div>
    </>
  )
}
