import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Box } from '@mui/material'
import { useForm } from 'src/hooks/useForm'

import ChipSelector from 'src/components/Form/ChipSelector'
import FormTitle from 'src/components/Form/FormTitle'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import ButtonSelector from 'src/components/Form/ButtonSelector'
import SwitchForm from 'src/components/Form/SwitchForm'

import { AddEditProps, VoucherTypes } from 'src/interfaces/pages.types'
import { AlertContext } from 'src/context/alert/alertContext'
import { addVoucherToUsers, createVoucher, updateVoucher } from 'src/services/voucher.services'

import { ReactComponent as Especial } from 'src/assets/icons/general/Especial.svg'
import { ReactComponent as Normal } from 'src/assets/icons/general/Normal.svg'
import { ReactComponent as Enterprise } from 'src/assets/icons/general/Briefcase.svg'

import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { SimpleDialog } from 'src/components/Modals/Dialog'
import { COUPON_TYPES } from 'src/constants/const'
import { getCouponType, getParenthesizedText, parseEnterpriseCoupon } from 'src/utils/helpers'

const INITIAL_VALUES = { name: '', quantity: '', expiration: '', giftQuantity: '', totalPrice: '', obv: '' }

export default function BonosAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData, disabled }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const [users, setUsers] = useState<string[] | never[]>([])
  const [enterprises, setEnpterprises] = useState<string[]>([])
  const { showSnackbar } = useContext(AlertContext)
  const [loading, setLoading] = useState(false)
  const { setState, handleChange, values, translations, resetForm } = useForm(
    (dataToEdit?.company ? parseEnterpriseCoupon(dataToEdit) : dataToEdit) || INITIAL_VALUES,
    dataToEdit,
    ['name']
  )
  const [openSended, setOpenSended] = useState(false)
  const [special, setSpecial] = useState(false)
  const [couponType, setCouponType] = useState<'normal' | 'special' | 'enterprise'>('normal')
  const [position, setPosition] = useState('0')
  const [usersAdd, setUsersAdd] = useState<string[]>([])
  const [usersDelete, setUsersDelete] = useState<string[]>([])
  const [status, setStatus] = useState<'ACTIVE' | 'DISABLED'>('DISABLED')
  const ENTERPRISE_RANGE_DATA = [
    { range: '1', order: 'first', name: 'firstPriceRange', validity: 'firstRangeValidity' },
    { range: '2', order: 'second', name: 'secondPriceRange', validity: 'secondRangeValidity' },
    { range: '3', order: 'third', name: 'thirdPriceRange', validity: 'thirdRangeValidity' }
  ]

  const resetComponent = () => {
    setLoading(false)
    resetForm(INITIAL_VALUES)
    setPosition('0')
    setSpecial(false)
    setCouponType('normal')
    setUsers([])
    setEnpterprises([])
    setUsersDelete([])
    setUsersAdd([])
    setStatus('DISABLED')
  }

  useEffect(() => {
    if (dataToEdit) {
      setSpecial(dataToEdit.special || dataToEdit.company)
      if (!dataToEdit.company) setUsers(dataToEdit.users || [])
      setCouponType(getCouponType(dataToEdit))
      if (dataToEdit.company) {
        setEnpterprises(dataToEdit.companyConfig?.emails || [])
        // Habría que popular estas ids...
        setUsers(dataToEdit.companyConfig?.users || [])
      }
      if (dataToEdit.status) setStatus(dataToEdit.status)
      if (dataToEdit.position) setPosition(dataToEdit.position)
    }
    return () => resetComponent()
  }, [dataToEdit])

  const handleClose = () => {
    resetComponent()
    closeModal()
  }

  const onSave = async () => {
    setLoading(true)
    let data: VoucherTypes

    if (special) {
      data = {
        name: values.name,
        special: special,
        totalPrice: values.totalPrice,
        status,
        users: users?.map((user: any) => user._id),
        translations
      }
    }
    if (couponType === 'enterprise') {
      data = {
        name: values.name,
        special: false,
        status,
        translations,
        company: true,
        companyConfig: {
          code: values.enterpriseCode,
          minQuantity: +values.minimumAmountPlates,
          maxGiftQuantity: +values.giftQuantity,
          range1: {
            min: +values['firstDishRange-min'],
            max: +values['firstDishRange-max'],
            price: +values.firstRangePrice,
            expiration: +values.firstRangeValidity
          },
          range2: {
            min: +values['secondDishRange-min'],
            max: +values['secondDishRange-max'],
            price: +values.secondRangePrice,
            expiration: +values.secondRangeValidity
          },
          range3: {
            active: !!values.thirdDishRange,
            min: +values.thirdDishRange,
            price: +values.thirdRangePrice,
            expiration: +values.thirdRangeValidity
          },
          emails: enterprises,
          users: users?.map((user: any) => user._id)
        }
      }
      if (data.companyConfig && !data.companyConfig?.range3?.active) {
        delete data.companyConfig.range3
      }
    } else {
      data = {
        name: values.name,
        special: special,
        totalPrice: +values.totalPrice,
        position: position,
        quantity: Math.round(+values.quantity),
        giftQuantity: Math.round(+values.giftQuantity),
        users: users?.map((user: any) => user._id),
        translations,
        expiration: Math.round(+values.expiration)
      }
    }
    if (values.obv) data.obv = values.obv
    if (dataToEdit) {
      try {
        const editedData: {
          obv?: string
          position?: string
          status?: 'ACTIVE' | 'DISABLED'
          companyConfig?: {
            emails?: string[]
            users?: string[]
          }
          name?: string
          translations?: { language: string; name: string }[]
        } = {}

        if (dataToEdit.special) {
          editedData.status = status
          editedData.obv = values.obv
        } else if (dataToEdit.company) {
          editedData.status = status
          editedData.companyConfig = {}
          editedData.companyConfig.emails = enterprises
          editedData.companyConfig.users = users
          editedData.name = values.name
          editedData.translations = translations
        } else {
          editedData.obv = values.obv
          editedData.position = position
        }
        await updateVoucher(dataToEdit?._id, editedData)
        if (usersAdd.length > 0 && !dataToEdit.company) {
          await addVoucherToUsers(dataToEdit?._id, usersAdd)
        }
        // if (usersDelete.length > 0) {
        //   await removeVoucherToUsers(dataToEdit?._id, usersDelete)
        // }
        if (usersDelete.length > 0 || usersAdd.length > 0 && !dataToEdit.company) {
          setOpenSended(true)
        } else {
          showSnackbar('success', labels[lng].voucherUpdated)
        }
        if (fetchData) fetchData()
        handleClose()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      }
    } else {
      try {
        await createVoucher(data)
        if (data?.users?.length && data.users.length > 0 && !dataToEdit.company) {
          setOpenSended(true)
        } else {
          showSnackbar('success', labels[lng].voucherCreated)
        }
        if (fetchData) fetchData()
        handleClose()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
        setLoading(false)
      }
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit._id)
  }

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setStatus('ACTIVE')
    else setStatus('DISABLED')
  }

  return (
    <>
      <Modal
        title={editing ? values.name : labels[lng].addVoucher}
        open={openModal}
        handleClose={handleClose}
        footer={
          <FooterForm
            text={labels[lng].voucher}
            closeModal={handleClose}
            onSave={onSave}
            onDelete={onDelete ? handleDelete : undefined}
            editing={editing}
            loading={loading}
            disabled={
              disabled
                ? true
                : couponType === 'enterprise'
                ? !editing
                  ? !values?.enterpriseCode ||
                    !values?.minimumAmountPlates ||
                    !values?.['firstDishRange-min'] ||
                    !values?.['firstDishRange-max'] ||
                    !values?.firstRangePrice ||
                    !values?.firstRangeValidity ||
                    !values?.['secondDishRange-min'] ||
                    !values?.['secondDishRange-max'] ||
                    !values?.secondRangePrice ||
                    !values?.secondRangeValidity ||
                    !values?.giftQuantity
                  : !values?.name || !values.status
                : special
                ? !values?.totalPrice || !values?.name || !translations?.[0].name
                : !values?.name || !translations?.[0].name || values?.giftQuantity === undefined || !values?.quantity || !values?.totalPrice
            }
          />
        }
      >
        <Box id="form-bonos" component="form" noValidate>
          <FormTitle text={labels[lng].information} />

          <ButtonSelector
            resetForm={() => {
              setEnpterprises([])
              resetForm()
            }}
            text={labels[lng].type}
            valueSelected={couponType}
            setSpecial={setSpecial}
            setCouponType={setCouponType}
            noEditable={editing}
            options={[
              { value: COUPON_TYPES[0], text: labels[lng].normal, Icon: Normal },
              { value: COUPON_TYPES[1], text: labels[lng].special, Icon: Especial },
              { value: COUPON_TYPES[2], text: labels[lng].enterprise, Icon: Enterprise }
            ]}
          />

          {special && <SwitchForm text={labels[lng].state} handleChange={(e) => handleSwitch(e)} checked={status === 'ACTIVE'} />}

          {!special && (
            <ButtonSelector
              text={labels[lng].position}
              valueSelected={position}
              setValue={setPosition}
              options={[
                { value: '0', text: labels[lng].none },
                { value: '1', text: '1' },
                { value: '2', text: '2' },
                { value: '3', text: '3' }
              ]}
            />
          )}
          <TextInput
            required
            disabled={!!dataToEdit && couponType !== 'enterprise'}
            value={values.name}
            handleChange={(e) => handleChange(e)}
            name="name"
            id="name"
            text={`${labels[lng].nameVoucher} (ES)`}
            placeholder={`${labels[lng].nameVoucher} (ES)`}
          />
          {translations.map((opt: { language: string; name: string | number }, idx: number) => (
            <TextInput
              required
              disabled={!!dataToEdit && couponType !== 'enterprise'}
              key={`${opt.language}-${idx}`}
              value={opt.name}
              handleChange={(e) => handleChange(e, opt)}
              name="name"
              text={`${labels[lng].nameVoucher} (${opt.language.toUpperCase()})`}
              placeholder={`${labels[lng].nameVoucher} (${opt.language.toUpperCase()})`}
            />
          ))}
          {!special && (
            <TextInput
              required
              disabled={!!dataToEdit}
              value={values.quantity}
              handleChange={(e) => handleChange(e)}
              name="quantity"
              id="quantity"
              text={labels[lng].numberVoucher}
              placeholder={labels[lng].availableVoucher}
              type="number"
            />
          )}
          {!special && (
            <TextInput
              required
              disabled={!!dataToEdit}
              value={values.expiration}
              handleChange={(e) => handleChange(e)}
              name="expiration"
              id="expiration"
              text={labels[lng].validateUntil}
              placeholder={labels[lng].validatePeriod}
              type="number"
            />
          )}
          {!special && (
            <TextInput
              required
              disabled={!!dataToEdit}
              value={values.giftQuantity}
              handleChange={(e) => handleChange(e)}
              name="giftQuantity"
              id="giftQuantity"
              text={labels[lng].maxPlatesGift}
              placeholder={labels[lng].maxPlatesGiftText}
              type="number"
              inputProps={{ min: 0, max: 10 }}
            />
          )}
          {couponType !== 'enterprise' && (
            <TextInput
              disabled={!!dataToEdit}
              value={values.totalPrice}
              handleChange={(e) => handleChange(e)}
              name="totalPrice"
              id="totalPrice"
              text={labels[lng].price}
              placeholder={labels[lng].voucherPrice}
              type="number"
              price
            />
          )}

          {couponType === 'enterprise' && (
            <>
              <TextInput
                required
                disabled={!!dataToEdit}
                value={values.enterpriseCode}
                handleChange={(e) => {
                  e.target.value = e.target.value.toUpperCase()
                  handleChange(e)
                }}
                name="enterpriseCode"
                id="enterpriseCode"
                text={labels[lng].enterpriseCode}
                placeholder={labels[lng].enterpriseCode}
              />
              <TextInput
                required
                disabled={!!dataToEdit}
                value={values.minimumAmountPlates}
                handleChange={(e) => handleChange(e)}
                name="minimumAmountPlates"
                id="minimumAmountPlates"
                text={labels[lng].minimumAmountPlates}
                placeholder={labels[lng].minimumAmountPlates}
                type="number"
              />

              {ENTERPRISE_RANGE_DATA.map(({ name, range, order, validity }, idx) => {
                return (
                  <Fragment key={name}>
                    <TextInput
                      required
                      disabled={!!dataToEdit}
                      value={idx !== 2 ? [values[`${order}DishRange-min`], values[`${order}DishRange-max`]] : values[`${order}DishRange`]}
                      handleChange={(e) => handleChange(e)}
                      name={`${order}DishRange`}
                      id={validity}
                      text={`Rango ${range} nº de platos ${
                        +range === 3 ? getParenthesizedText(labels[lng].optional.toLocaleLowerCase()) : ''
                      }`}
                      placeholder={''}
                      type={idx === 2 ? 'number' : 'range'}
                    />

                    <TextInput
                      required
                      disabled={!!dataToEdit}
                      value={values[`${order}RangePrice`]}
                      handleChange={(e) => handleChange(e)}
                      name={`${order}RangePrice`}
                      id={name}
                      text={`${labels[lng].priceRange} ${range} ${
                        +range === 3 ? getParenthesizedText(labels[lng].optional.toLocaleLowerCase()) : ''
                      }`}
                      placeholder={labels[lng].priceRangePlaceholder}
                      type="number"
                      price
                    />

                    <TextInput
                      required
                      disabled={!!dataToEdit}
                      name={`${order}RangeValidity`}
                      value={values[`${order}RangeValidity`]}
                      handleChange={(e) => handleChange(e)}
                      id={validity}
                      text={`${labels[lng].rangeValidity} ${range}, días ${
                        +range === 3 ? getParenthesizedText(labels[lng].optional.toLocaleLowerCase()) : ''
                      }`}
                      placeholder={`${labels[lng].rangeValidityPlaceholder} ${range}`}
                      type="number"
                    />
                  </Fragment>
                )
              })}
              <TextInput
                required
                disabled={!!dataToEdit}
                value={values.giftQuantity}
                handleChange={(e) => handleChange(e)}
                name="giftQuantity"
                id="giftQuantity"
                text={labels[lng].maxPlatesGift}
                placeholder={labels[lng].maxPlatesGiftPercentage}
                type="number"
                inputProps={{ min: 0, max: 100 }}
                percentage
              />
            </>
          )}

          <TextInput
            disabled={editing && couponType === 'enterprise'}
            value={values.obv}
            handleChange={(e) => handleChange(e)}
            name="obv"
            id="obv"
            text={`${labels[lng].observations} - ${labels[lng].optional.toLowerCase()}`}
            placeholder={labels[lng].voucherObservations}
            multiline
          />

          <FormTitle text={labels[lng].award} />
          {couponType === 'enterprise' ? (
            <>
              <TextInput
                value={values.enterprise}
                handleChange={handleChange}
                name="enterprise"
                id="enterprise"
                data={enterprises || []}
                type="enterprises"
                text={labels[lng].enterprises}
                placeholder={'@enterprise.com'}
                multiline
                onClick={() => {
                  if (!values.enterprise) return
                  setEnpterprises((previousEnterprises) => [...previousEnterprises, values.enterprise])
                  setState({ ...values, enterprise: '' })
                }}
                onChipDelete={(name) => {
                  const filteredEnterprises = enterprises.filter((enterprise) => enterprise !== name)
                  setEnpterprises(filteredEnterprises)
                }}
              />
              <ChipSelector
                searchBy="users"
                data={users}
                setData={setUsers}
                setUsersDelete={setUsersDelete}
                setUsersAdd={setUsersAdd}
                options={[]}
                text={labels[lng].additionalEmails}
                searchText={labels[lng].user}
                miltiple
                isEnterprise
              />
            </>
          ) : (
            <ChipSelector
              searchBy="users"
              data={users}
              setData={setUsers}
              setUsersDelete={setUsersDelete}
              setUsersAdd={setUsersAdd}
              options={[]}
              text={labels[lng].users}
              searchText={labels[lng].user}
              miltiple
              importUsers
            />
          )}
        </Box>
      </Modal>
      <SimpleDialog
        category="successRed"
        open={openSended}
        handleClose={() => {
          setOpenSended(false)
          handleClose()
        }}
        title={labels[lng].voucherCreateTitle}
        text={labels[lng].voucherCreateDescription}
      />
    </>
  )
}
