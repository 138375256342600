import React, { useState, useContext, useEffect } from 'react'
import { FirebaseError } from '@firebase/util'
import { Stack, Button, Typography, Checkbox, Box } from '@mui/material'

import Modal from 'src/components/Modals/Modal'
import { FooterFormBox } from 'src/components/Form/form.styles'
import TextInput from 'src/components/Form/TextInput'
import FormTitle from 'src/components/Form/FormTitle'

import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import { reauthenticate } from 'src/utils/reauthenticate'
import { useTheme } from '@mui/system'
import { privacyPolicy, termsAndConditionsUrl } from 'src/constants/const'
import { changeIsNewRestaurant } from 'src/services/restaurants.services'
import { UserDataTypes } from 'src/interfaces/app.types'
import { changeRecoveryAccountPassword } from 'src/services/auth.services'
import { SimpleDialog } from 'src/components/Modals/Dialog'

interface EditEmailProps {
  open: boolean
  lng: string
  userData?: UserDataTypes
  handleClose?: () => void
  handleCancel?: () => void
  mandatory?: boolean
  isNewRestaurant?: boolean
  recovery?: boolean
  optionsRecovery?: {
    email: string
    tokenRef: string
  }
}

export const UpdatePassword = ({
  open,
  lng,
  handleClose,
  mandatory,
  handleCancel,
  userData,
  isNewRestaurant,
  recovery,
  optionsRecovery
}: EditEmailProps): JSX.Element => {
  const { showSnackbar } = useContext(AlertContext)
  const { firebase, user } = useContext(AuthContext)
  const theme = useTheme()

  const [state, setState] = useState({ password: '', newPassword: '', repeatNewPassword: '' })
  const [check, setCheck] = useState(userData?.isNewRestaurant !== undefined || userData?.isNewRestaurant === false)
  const [error1, setError1] = useState('')
  const [error2, setError2] = useState('')
  const [validPassword, setValidPassword] = useState('')
  const [openErrorDialog, setOpenErrorDialog] = useState(false)

  useEffect(() => {
    const _lng = lng === 'es' ? 'en' : 'es'
    const findKeyByPhrase = (message: string) => {
      const phrase = Object.entries(labels[_lng]).find(([, phrase]) => phrase === message)
      if (!phrase) {
        throw new Error('Not found phrase')
      }
      return phrase[0]
    }

    const updateErrors = (updateError: React.Dispatch<React.SetStateAction<string>>, error: string) => {
      try {
        const lngKey = findKeyByPhrase(error)
        updateError(labels[lng][lngKey])
      } catch (errr) {
        console.error('Error to change language.')
      }
    }
    ;[
      { error: error1, setError: setError1 },
      { error: error2, setError: setError2 },
      { error: validPassword, setError: setValidPassword }
    ].forEach(({ error, setError }) => {
      error && updateErrors(setError, error)
    })
  }, [lng])

  const closeModal = () => {
    setState({ password: '', newPassword: '', repeatNewPassword: '' })
    setCheck(userData?.isNewRestaurant !== undefined || userData?.isNewRestaurant === false)
    setError1('')
    setError2('')
    setValidPassword('')
    if (handleCancel) handleCancel()
    else if (handleClose) handleClose()
  }

  const updatePassword = async () => {
    setError1('')
    setError2('')
    if (!check || !state.password || !state.newPassword || !state.repeatNewPassword) return

    if (state.newPassword !== state.repeatNewPassword) {
      setError2(labels[lng].passwordsNotEqual)
      return
    }
    const currentCustomer = firebase.auth.currentUser
    if (!currentCustomer) {
      return
    }
    try {
      const auth = await reauthenticate(user?.user.email, state.password)
      if (auth instanceof FirebaseError) {
        throw auth
      }
      await currentCustomer.updatePassword(state?.newPassword)
      showSnackbar('success', labels[lng].passwordChanged)
      await changeIsNewRestaurant()

      if (isNewRestaurant) {
        await firebase
          .login(user?.user.email, state?.newPassword, true)
          .then(() => {
            setOpenErrorDialog(true)
            // if (handleClose) closeModal()
          })
          .catch((error: unknown) => {
            console.error(error)
          })
      } else {
        setOpenErrorDialog(true)
      }
      // if (handleClose) closeModal()
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/wrong-password') {
          setError1(labels[lng].INVALID_PASSWORD)
          return
        }
        if (error.code === 'auth/requires-recent-login') {
          reauthenticate(user?.user.email, state.password)
            .then(async () => {
              await currentCustomer.updatePassword(state?.newPassword)
              showSnackbar('success', labels[lng].passwordChanged)
              await changeIsNewRestaurant()
              if (handleClose) closeModal()
            })
            .catch((error) => {
              if (error.code === 'auth/requires-recent-login') {
                setError1(labels[lng].INVALID_PASSWORD)
              } else showSnackbar('error', labels[lng].passwordChangingError)
            })
        } else {
          showSnackbar('error', labels[lng].passwordChangingError)
        }
      } else {
        showSnackbar('error', labels[lng].passwordChangingError)
      }
    }
  }

  const recoveryPassword = async () => {
    setError1('')
    setError2('')
    if (!state.newPassword || !state.repeatNewPassword) return
    if (state.newPassword !== state.repeatNewPassword) {
      setError2(labels[lng].passwordsNotEqual)
      return
    }
    try {
      if (optionsRecovery) {
        await changeRecoveryAccountPassword(encodeURIComponent(optionsRecovery.tokenRef), state.newPassword)
        // const auth = await reauthenticate(optionsRecovery.email, state.password)
        // if (auth instanceof FirebaseError) {
        //   throw auth
        // }
        // await firebase
        //   .login(optionsRecovery.email, state?.newPassword, false)
        //   .then(() => {
        //     // if (handleClose) closeModal()
        //     // setOpenErrorDialog(true)
        //   })
        //   .catch((error: unknown) => {
        //     console.error(error)
        //   })
        // if (handleClose) closeModal()
        setOpenErrorDialog(true)
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/wrong-password') {
          setError1(labels[lng].INVALID_PASSWORD)
        }
      } else {
        showSnackbar('error', labels[lng].INVALID_TOKEN)
      }
    }
  }

  const validPasswordFunction = (password) => {
    return password.length >= 8 && password.length <= 14
  }

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState({ ...state, [ev.target.name]: ev.target.value })
    if (ev.target.name === 'newPassword') {
      if (validPasswordFunction(ev.target.value)) setValidPassword(labels[lng].passwordSecure)
      else if (ev.target.value.length < 8) {
        setValidPassword(labels[lng].passwordSecureMin)
      } else {
        setValidPassword('')
      }
    }
  }

  const navLink = (type: string) => {
    let url = '/'
    if (type === 'terms') url = termsAndConditionsUrl
    if (type === 'privacy') url = privacyPolicy
    window.open(url)
  }

  const disabledSaveButton = () => {
    if (!recovery) if (!check || !state.password || !state.newPassword || !state.repeatNewPassword) return true
    if (!validPasswordFunction(state.newPassword)) {
      return true
    }

    // if (state.repeatNewPassword !== state.newPassword) return true
    return false
  }

  return (
    <>
      <Modal
        size={'sm'}
        title={mandatory ? labels[lng].changePasswordToContinue : labels[lng].changePassword}
        open={open}
        handleClose={closeModal}
        fullScreen={window?.innerWidth < 890}
        noCloseIcon={window?.innerWidth < 890}
        recovery={recovery}
        // dialogStyles={{
        //   borderTop: window?.innerWidth < 890 ? 'none' : '1px solid'
        // }}
        footer={
          <FooterFormBox sx={{ justifyContent: mandatory ? 'space-between' : 'flex-end' }}>
            {mandatory && (
              <Stack direction="row" alignItems="center">
                <Checkbox
                  sx={{ pl: 0, '&:hover': { backgroundColor: 'transparent' } }}
                  value={check}
                  onChange={(e) => setCheck(e.target.checked)}
                  color="primary"
                />
                <Typography variant="body2" sx={{ color: '#374151', fontWeight: 400 }}>
                  {labels[lng].readAndAcceptPolicies1}{' '}
                  <span onClick={() => navLink('terms')} style={{ color: theme.palette.primary.main, cursor: 'pointer' }}>
                    {labels[lng].termsConditions}
                  </span>
                  {labels[lng].readAndAcceptPolicies2}{' '}
                  <span onClick={() => navLink('privacy')} style={{ color: theme.palette.primary.main, cursor: 'pointer' }}>
                    {labels[lng].privacyPolicy}
                  </span>
                </Typography>
              </Stack>
            )}
            <Stack
              spacing={2}
              sx={{
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                  padding: 0
                }
              }}
              direction="row"
            >
              {!mandatory && (
                <Button
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      width: '50%'
                    }
                  }}
                  variant="outlined"
                  color="info"
                  onClick={closeModal}
                >
                  {labels[lng].cancel}
                </Button>
              )}
              {mandatory && (
                <Button
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      width: '50%'
                    }
                  }}
                  variant="outlined"
                  color="info"
                  onClick={closeModal}
                >
                  {labels[lng].cancel}
                </Button>
              )}
              <Button
                sx={{
                  ml: 2,
                  [theme.breakpoints.down('md')]: {
                    width: '50%'
                  }
                }}
                onClick={async () => {
                  recovery ? recoveryPassword() : updatePassword()
                }}
                variant="contained"
                disabled={disabledSaveButton()}
              >
                {labels[lng].save}
              </Button>
            </Stack>
          </FooterFormBox>
        }
      >
        {window.innerWidth > 890 ? <FormTitle text={labels[lng].information} /> : null}
        {!recovery && (
          <TextInput
            value={state.password}
            handleChange={handleChange}
            name="password"
            text={labels[lng].password}
            placeholder={labels[lng].password}
            type="password"
            errorBadge={window.innerWidth < 890 ? undefined : error1}
          />
        )}

        {window.innerWidth < 890 && error1 ? (
          <>
            {' '}
            <Box
              sx={{
                height: '1px',
                backgroundColor: '#E5E7EB',
                width: '100%'
              }}
            />
            <Typography
              sx={{
                mt: '8px',
                mb: '16px',
                lineHeight: '16px',
                fontSize: '12px',
                color: '#F15B70',
                fontWeight: 500,
                pl: '16px'
              }}
            >
              {error1}
            </Typography>
          </>
        ) : null}
        <TextInput
          value={state.newPassword}
          handleChange={handleChange}
          name="newPassword"
          text={labels[lng].newPassword}
          placeholder={labels[lng].newPassword}
          type="password"
          valid={window.innerWidth < 890 ? undefined : validPassword}
        />
        {window.innerWidth < 890 && validPassword ? (
          <>
            {' '}
            <Box
              sx={{
                height: '1px',
                backgroundColor: '#E5E7EB',
                width: '100%'
              }}
            />
            <Typography
              sx={{
                mt: '8px',
                mb: '16px',
                lineHeight: '16px',
                fontSize: '12px',
                color: '#9CA3AF',
                fontWeight: 500,
                pl: '16px'
              }}
            >
              {validPassword}
            </Typography>
          </>
        ) : null}
        <TextInput
          value={state.repeatNewPassword}
          handleChange={handleChange}
          name="repeatNewPassword"
          text={labels[lng].repeatNewPassword}
          placeholder={labels[lng].repeatNewPassword}
          type="password"
          noBottomBorder
          errorBadge={window.innerWidth < 890 ? undefined : error2}
        />
        <Box
          sx={{
            height: '1px',
            backgroundColor: '#E5E7EB',
            width: '100%',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        />
        {window.innerWidth < 890 && error2 ? (
          <>
            <Typography
              sx={{
                mt: '8px',
                mb: '0px',
                lineHeight: '16px',
                fontSize: '12px',
                color: '#F15B70',
                fontWeight: 500,
                pl: '16px'
              }}
            >
              {error2}
            </Typography>
          </>
        ) : null}
      </Modal>
      <SimpleDialog
        centerTitle
        category="successRed"
        open={openErrorDialog}
        handleClose={async () => {
          if (recovery) {
            if (optionsRecovery) {
              const auth = await reauthenticate(optionsRecovery.email, state.password)
              if (auth instanceof FirebaseError) {
                throw auth
              }
              await firebase
                .login(optionsRecovery.email, state?.newPassword, true)
                .then(() => {
                  setOpenErrorDialog(false)
                  if (handleClose) closeModal()
                  // setOpenErrorDialog(true)
                })
                .catch((error: unknown) => {
                  console.error(error)
                })
            }
          } else {
            if (handleClose) closeModal()
          }
        }}
        title={labels[lng].passwordRecoveryTitle}
        text={labels[lng].passwordRecoveryText}
      />
    </>
  )
}
