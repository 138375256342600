import React from 'react'
// import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as Logo } from 'src/assets/icons/general/LogoFudclub.svg'
// import { ReactComponent as Collapse } from 'src/assets/icons/general/Collapse.svg'
import { DrawerHeader } from '../layout.styles'

interface LogoZoneProps {
  open: boolean
  handleDrawer: () => void
}

export default function LogoZone({ open }: LogoZoneProps): JSX.Element {
  const theme = useTheme()

  return (
    <DrawerHeader
      sx={{
        justifyContent: open ? 'space-between' : 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[100],
        ml: open ? 1 : 0
      }}
    >
      <Logo />
      {/* {open && (
        <IconButton onClick={handleDrawer}>
          <Collapse />
        </IconButton>
      )} */}
    </DrawerHeader>
  )
}
