import React, { useState, useContext, useEffect } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { CardSummaryContainer, BoxCardRow, BoxCardIconDefault, BoxCardPostionRefresh } from './cards.styles'
import { ReactComponent as Checkmark } from 'src/assets/icons/general/Checkmark.svg'
import { labels } from 'src/labels/main_labels'
import { getUserCards } from '../../services/users.services'
import { AlertContext } from 'src/context/alert/alertContext'
import { UserCardTypes } from '../../interfaces/app.types'
import { useTranslation } from 'react-i18next'

interface CardPaymentMethodProps {
  userId: string
  refreshBono: string | void
}

export default function CardPaymentMethod({ userId, refreshBono }: CardPaymentMethodProps): JSX.Element {
  const { showSnackbar } = useContext(AlertContext)
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const [userCards, setUserCards] = useState<UserCardTypes[] | null>(null)

  const fetchCards = async () => {
    try {
      const cardsData = await getUserCards(userId)
      setUserCards(cardsData)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }
  useEffect(() => {
    fetchCards()
    return () => {
      setUserCards(null)
    }
  }, [])

  const getLastFourCharacters = (cardWord: string) => {
    if (cardWord.length > 4) return cardWord.slice(-4)
    else return cardWord
  }

  return (
    <CardSummaryContainer sx={{ flexDirection: 'column', minHeight: '72px', alignItems: 'flex-start' }}>
      {userCards?.length
        ? userCards.map((card: UserCardTypes, idx: number) => (
            <div key={card._id} style={{ width: '100%' }}>
              {userCards && userCards.length > 1 && idx !== 0 && <Divider sx={{ width: '100%' }} />}
              <BoxCardRow sx={{ width: '100% ' }}>
                <Box>
                  <Typography sx={{ color: '#111827' }} variant="body1" component="strong">
                    {labels[lng].finishedCard} {getLastFourCharacters(card.numberCard)}
                  </Typography>
                </Box>
                {card.prefer && (
                  <>
                    <BoxCardIconDefault>
                      <Checkmark />
                      <Typography sx={{ ml: '10px', color: '#6B7280' }} variant="body2">
                        {labels[lng].byDefaultCard}
                      </Typography>
                    </BoxCardIconDefault>
                    {refreshBono && (
                      <BoxCardPostionRefresh>
                        <Typography sx={{ ml: '0', color: '#6B7280' }} variant="body2">
                          {refreshBono}
                        </Typography>
                      </BoxCardPostionRefresh>
                    )}
                  </>
                )}
              </BoxCardRow>
            </div>
          ))
        : null}
    </CardSummaryContainer>
  )
}
