import React, { useState, useContext, useEffect, ChangeEvent } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { FirebaseError } from '@firebase/util'
import CssBaseline from '@mui/material/CssBaseline'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControlLabel from '@mui/material/FormControlLabel'

import { AuthContext } from 'src/context/auth/authContext'
import { Checkbox, Link, Grid, Box, Typography, FormHelperText } from '@mui/material'

import { Main, Input, WelcomeBox } from './welcome.styles'
import { ReactComponent as Logo } from 'src/assets/icons/general/LogoFudclub-big.svg'
import { RecoverPasswordDialog, SimpleDialog } from 'src/components/Modals/Dialog'
import { recoverAccountPassword } from 'src/services/auth.services'
import { labels } from 'src/labels/main_labels'
import { validateEmail } from 'src/utils/validateForm'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'

export default function Welcome(): JSX.Element {
  const router = useRouteMatch()
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const history = useHistory()
  const [error, setError] = useState<string | null>(null)
  const [errorModal, setErrorModal] = useState<string | null>(null)
  const [openRecoverPass, setOpenRecoverPass] = useState<boolean>(false)
  const [openRecoverPassError, setOpenRecoverPassError] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [persistence, setPersistence] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [loadingRecover, setLoadingRecover] = useState(false)
  const [recoverPasswordDialog, setRecoverPasswordDialog] = useState(false)

  const { firebase } = useContext(AuthContext)

  useEffect(() => {
    return () => setLoading(false)
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setError(null)
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    if (!data.get('email') || !data.get('password')) return
    if (!validateEmail(data.get('email') as string)) {
      setError(labels[lng].emailErrorWelcome)
      return
    }
    setLoading(true)
    firebase
      .login(data.get('email') as string, data.get('password') as string, true)
      .then(() => history.push('/'))
      .catch((error: unknown) => {
        setLoading(false)
        if (error instanceof FirebaseError) {
          if (error.code === 'auth/invalid-email') {
            setError(labels[lng].ERR_INVALID_EMAIL)
          } else if (error.code === 'auth/wrong-password') {
            setError(labels[lng].ERR_WRONG_EMAIL_PASS)
          } else {
            setError(labels[lng].ERR_NO_EMAIL)
          }
        }
      })
  }
  const [values, setValues] = useState({ email: '', password: '' })
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const recoverPassword = (email: string) => {
    setLoadingRecover(true)
    recoverAccountPassword(email)
      .then(() => {
        setRecoverPasswordDialog(false)
        setOpenRecoverPass(true)
        setLoadingRecover(false)
      })
      .catch(() => {
        // setOpenRecoverPassError(true)
        setErrorModal(labels[lng].ERR_NO_EMAIL)
        setLoadingRecover(false)
      })
  }

  useEffect(() => {
    const { url } = router
    if (url.includes('/en')) i18n.changeLanguage('en')
    else i18n.changeLanguage('es')
  }, [])

  return (
    <Main>
      <CssBaseline />
      <WelcomeBox>
        <Logo />
        <Typography sx={{ my: 3, textAlign: { xs: 'center' } }} component="h1" variant="h2">
          {labels[lng].loginTitle}
        </Typography>
        <Box id="form-login" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, maxWidth: '100%', width: { md: 500 } }}>
          <Input
            sx={{ '& .MuiInputBase-input': { borderEndStartRadius: 0, borderEndEndRadius: 0 } }}
            placeholder={labels[lng].email}
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => handleChange(e)}
          />
          <Input
            sx={{ '& .MuiInputBase-input': { borderTop: 0, borderStartStartRadius: 0, borderStartEndRadius: 0 } }}
            placeholder={labels[lng].password}
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => handleChange(e)}
          />

          {error ? (
            <FormHelperText sx={{ mt: '5px' }} error id="form-login">
              {error}
            </FormHelperText>
          ) : (
            <Box
              sx={{
                mt: 3,
                [theme.breakpoints.down('md')]: {
                  mt: 0
                }
              }}
            />
          )}
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              [theme.breakpoints.down('md')]: {
                marginTop: '14px',
                marginBottom: '10px'
              }
            }}
          >
            <Grid item>
              <FormControlLabel
                sx={{
                  '& .MuiTypography-root': { color: '#111827' },
                  [theme.breakpoints.down('md')]: {
                    '& .MuiCheckbox-root': {
                      padding: '0px 8px 0px'
                    }
                  }
                }}
                control={<Checkbox value="remember" onChange={(e) => setPersistence(e.target.checked)} color="primary" />}
                label={labels[lng].maintainSession}
              />
            </Grid>
            <Grid
              item
              sx={{
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              }}
            >
              <Link
                sx={{ textDecoration: 'none', cursor: 'pointer', fontWeight: 500 }}
                onClick={() => setRecoverPasswordDialog(true)}
                color="primary"
              >
                {labels[lng].forgotPass}
              </Link>
            </Grid>
          </Grid>
          <LoadingButton
            disabled={!values.email || !values.password}
            type="submit"
            loading={loading}
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            className="button-mobile"
          >
            {labels[lng].login}
          </LoadingButton>
          <Grid
            item
            sx={{
              marginTop: '12px',
              [theme.breakpoints.up('md')]: {
                display: 'none'
              }
            }}
          >
            <Link
              sx={{ textDecoration: 'none', cursor: 'pointer', fontWeight: 500 }}
              onClick={() => setRecoverPasswordDialog(true)}
              color="primary"
            >
              {labels[lng].forgotPass}
            </Link>
          </Grid>
          <SimpleDialog
            open={openRecoverPass}
            handleClose={() => setOpenRecoverPass(false)}
            title={labels[lng].weSendEmail}
            text={labels[lng].recoverYourPassword}
            category="successRed"
          />
          <SimpleDialog
            category="error"
            open={openRecoverPassError}
            handleClose={() => setOpenRecoverPassError(false)}
            title={labels[lng].errorWhenSendingEmail}
            text={labels[lng].errorWhenSendingEmail2}
          />
          <RecoverPasswordDialog
            open={recoverPasswordDialog}
            handleClose={() => setRecoverPasswordDialog(false)}
            handleAction={recoverPassword}
            loading={loadingRecover}
            errorModal={errorModal}
          />
        </Box>
      </WelcomeBox>
    </Main>
  )
}
