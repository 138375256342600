import axiosInstance from './axiosInstance'

export const getParameters = async (): Promise<any> => {
  return axiosInstance
    .get('/parameter')
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargaban los parametros')
    })
}

export const updateParameter = async (value: Record<string, any>): Promise<void> => {
  let parameter = Object.keys(value)[0]
  let slug = ''
  let timelineTemp

  if (['CDI', 'PCDI', 'PCDPR', 'PCDR', 'MCCDR'].includes(parameter)) slug = 'user'
  if (['MPOD'].includes(parameter)) slug = 'restaurant'
  if (['PP'].includes(parameter)) slug = 'plate'
  if (['TPFR', 'TPVC'].includes(parameter)) {
    slug = 'timeline2'
    parameter = parameter.toLocaleLowerCase()
  }
  if (parameter === 'timeline1') {
    parameter = ''
    slug = 'timeline'
    timelineTemp = JSON.parse(JSON.stringify(value.timeline1))
    for (const key in timelineTemp) {
      const parameterTime = timelineTemp[key]
      delete parameterTime.lastUpdate
      delete parameterTime.admin
      delete parameterTime.history

      //  return parameter
    }
  }

  return axiosInstance
    .put(`/parameter/${slug}${parameter && '/'}${parameter}`, parameter ? value : timelineTemp)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => {
      if (error.response.status) {
        throw error
      } else throw new Error('Hubo un error mientras se intentaba actualizar el parámetro')
    })
}
