import React, { createContext, useReducer, FC } from 'react'
import { bookingReducer } from './bookingReducer'

export interface BookingState {
  bookingsNumber: string | number
}

export interface BookingContextProps {
  bookingsNumber: number | string
  setTotalBookings: (qty: number | string) => void
}

// Initial State
export const bookingInitialState: BookingState = {
  bookingsNumber: 0
}

// Create context
export const BookingContext = createContext({} as BookingContextProps)

const BookingProvider: FC = ({ children }) => {
  const [bookingState, dispatch] = useReducer(bookingReducer, bookingInitialState)

  const setTotalBookings = (totalBookings: number | string) => {
    dispatch({ type: 'BOOKING_NUMBER', payload: { bookingsNumber: totalBookings } })
  }

  return (
    <BookingContext.Provider
      value={{
        bookingsNumber: bookingState.bookingsNumber,
        setTotalBookings
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}

export default BookingProvider
