import React from 'react'
import { Avatar, Typography, Chip, Stack, Box, styled, Button } from '@mui/material'
import { ChipElement } from 'src/components/Form/form.styles'
import { dateTimeConvert, dateTimeConvertUTC, parameterToTime, transformDate } from 'src/utils/time-helpers'
import { labels } from 'src/labels/main_labels'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { ReactComponent as EnterpriseDetail } from 'src/assets/icons/general/EnterpriseDetail.svg'
import { ReactComponent as LikeTable } from 'src/assets/icons/general/LikeTable.svg'
import { ReactComponent as DislikeTable } from 'src/assets/icons/general/DislikeTable.svg'
import { ReactComponent as SpecialVouchers } from 'src/assets/icons/general/SpecialVouchers.svg'
import { ReactComponent as UserOrange } from 'src/assets/icons/smalls/UserOrange.svg'
import { ReactComponent as RecommendStar } from 'src/assets/icons/general/RecommendStar.svg'
import { ReactComponent as Medal } from 'src/assets/icons/card-icons/Card-Medal.svg'
import { ReactComponent as Position } from 'src/assets/icons/card-icons/Card-Position.svg'
import { ReactComponent as SeeBtn } from 'src/assets/icons/general/SeeBtn.svg'

import { styleFoodLabels } from 'src/labels/styleFood_labels'
import { dataToCurrency, stringAvatar } from 'src/utils/helpers'
import { onlyDate } from '../../utils/time-helpers'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'

interface DisplayFieldsProps {
  col: any
  row: any
}

export const FudclubTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#1F2937'
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#1F2937',
      color: '#D1D5DB',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '20px',
      padding: '2px 12px',
      borderRadiut: '4px'
    }
  })
)

export default function DisplayFields({ col, row }: DisplayFieldsProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { idField, fieldtype, width } = col
  const data = row[idField]

  switch (fieldtype) {
    case 'string':
      return (
        <Typography
          variant="body1"
          sx={{
            color: '#6B7280',
            minWidth: width,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
        >
          {data}
        </Typography>
      )
    case 'number':
      return (
        <Typography
          sx={{
            minWidth: width,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 85
            }
          }}
          variant="body1"
        >
          {data || 0}
        </Typography>
      )
    case 'percentage':
      return (
        <Typography
          sx={{
            minWidth: width,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {data || 0} %
        </Typography>
      )
    case 'bonosNumber': {
      let _data = data
      if (row.company) {
        _data = '-'
      }
      return (
        <Typography
          sx={{
            minWidth: width,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {_data || (row.special && idField === 'quantity' ? 1 : '-')}
        </Typography>
      )
    }
    case 'reservedPlates':
      return (
        <Typography
          sx={{
            minWidth: width,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {data?.totalPlates || 0}
        </Typography>
      )
    case 'spending':
      return (
        <Typography
          sx={{
            minWidth: width,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {data?.totalSpent ? Number(data?.totalSpent).toFixed(2).replace('.', ',') : '0,00'} €
        </Typography>
      )
    case 'platesSoldTable':
      return (
        <Typography
          sx={{
            width: 92,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {data?.platesSolds || 0}
        </Typography>
      )
    case 'incomeTotal':
      return (
        <Typography
          sx={{
            width: 92,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {data?.total ? Number(data.total).toFixed(2).replace('.', ',') : '0,00'} €
        </Typography>
      )
    case 'name':
      return (
        <Typography variant="subtitle2" sx={{ color: '#111827' }}>
          {data}
        </Typography>
      )

    case 'name-valoration':
      return (
        <Stack sx={{ minWidth: 250 }} direction="row" alignItems="center">
          <Typography variant="subtitle2" sx={{ color: '#111827' }}>
            {data}
          </Typography>
          <Typography variant="body1" sx={{ ml: '8px' }}>
            x{row.booking.quantity}
          </Typography>
        </Stack>
      )

    case 'name-avatar':
      return (
        <Box
          sx={{
            minWidth: 250,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
        >
          {/* <Avatar variant="rounded" sx={{ width: 40, height: 40 }} src={row.photo} /> */}
          {!row.photo || row.photo === 'null' ? (
            <Avatar variant="rounded" sx={{ width: 40, height: 40 }} alt={row.name} {...stringAvatar(row.name)} />
          ) : (
            <Avatar variant="rounded" sx={{ width: 40, height: 40 }} alt={row.name} src={row.photo} />
          )}
          <Typography variant="subtitle2" sx={{ ml: 2, color: '#111827' }}>
            {data}
          </Typography>
          {(row.status === 'INACTIVE' || row.status === 'DISABLED') && (
            <Chip sx={{ ml: 1 }} color="secondary" size="small" label={labels[lng].deactivated} />
          )}
          {row.isNewRestaurant && (
            <Chip sx={{ ml: 1, backgroundColor: '#DBEAFE', color: '#1E40AF' }} size="small" label={labels[lng].preRestaurant} />
          )}
          {row.status === 'PRE_USER' && (
            <Chip sx={{ ml: 1, backgroundColor: '#DBEAFE', color: '#1E40AF' }} size="small" label={labels[lng].preUser} />
          )}
          {row.status === 'DELETED' && (
            <Chip
              sx={{ ml: 1 }}
              size="small"
              color="warning"
              label={`${labels[lng].deleted} ${dateTimeConvert(row.deletionDate || row.updatedAt)}`}
            />
          )}
        </Box>
      )
    case 'user-avatar':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.photo ? (
            <Avatar variant="circular" sx={{ width: 40, height: 40 }} alt={row.name} src={row.photo} />
          ) : (
            <Avatar variant="circular" sx={{ width: 40, height: 40 }} alt={row.name} {...stringAvatar(row.name, row.surname)} />
          )}
          <Stack>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Typography variant="subtitle2" sx={{ ml: 2, color: '#111827', fontWeight: 500 }}>
                {data} {row.surname || ''}
              </Typography>
              {row.status === 'INACTIVE' && <Chip sx={{ ml: 1 }} color="secondary" size="small" label={labels[lng].deactivated} />}
              {row.status === 'PRE_USER' && (
                <Chip sx={{ ml: 1, backgroundColor: '#DBEAFE', color: '#1E40AF' }} size="small" label={labels[lng].preUser} />
              )}
              {row.status === 'DELETED' && (
                <Chip sx={{ ml: 1 }} size="small" color="warning" label={`${labels[lng].deleted} ${dateTimeConvert(row.updatedAt)}`} />
              )}
            </Stack>
            <Typography variant="subtitle2" sx={{ ml: 2, color: '#6B7280', fontWeight: 400 }}>
              {row.email || ''}
            </Typography>
          </Stack>
        </div>
      )
    case 'position':
      return (
        <>
          {row.position && !row.attached && (
            <Chip sx={{ ml: 1, backgroundColor: '#E0E7FF', color: '#3730A3' }} size="small" label={row.position} />
          )}
          {row.id_bono.special && !row.attached && (
            <Box sx={{ ml: 1 }}>
              <SpecialVouchers />
            </Box>
          )}
          {row.attached && (
            <Chip
              sx={{
                alignItems: 'center',
                width: '24px',
                '& .MuiChip-label': { pr: '1px' },
                ml: 1,
                backgroundColor: '#FFEDD5',
                color: '#9A3412'
              }}
              size="small"
              icon={<UserOrange />}
            />
          )}
          {row.id_bono.company && (
            <Chip
              sx={{
                alignItems: 'center',
                width: '24px',
                '& .MuiChip-label': { pr: '1px' },
                ml: 1,
                backgroundColor: '#FFEDD5',
                color: '#9A3412'
              }}
              size="small"
              icon={<EnterpriseDetail />}
            />
          )}
        </>
      )

    case 'discount-name':
      return (
        <Stack direction="row" sx={{ alignItems: 'center', minWidth: 190 }}>
          <Typography variant="subtitle2" sx={{ color: '#111827' }}>
            {data}
          </Typography>
          {row.special && row.status === 'ACTIVE' && (
            <Box sx={{ ml: 1 }}>
              <SpecialVouchers />
            </Box>
          )}
          {row.status === 'DISABLED' && <Chip sx={{ ml: 1 }} color="secondary" size="small" label={labels[lng].deactivated} />}

          {row.expiration && Date.parse(row.expiration) < Date.now() && (
            <Chip sx={{ ml: 1, backgroundColor: '#FEF3C7', color: '#92400E' }} size="small" label={labels[lng].expired} />
          )}
          {row.users && row.users.length > 0 && (
            <Chip
              sx={{
                flexDirection: 'row-reverse',
                pr: 1.5,
                '& .MuiChip-label': { pr: '1px' },
                ml: 1,
                backgroundColor: '#FFEDD5',
                color: '#9A3412'
              }}
              size="small"
              label={row.users.length}
              icon={<UserOrange />}
            />
          )}
        </Stack>
      )
    case 'chips':
    case 'chips-estilos':
      return (
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {data.length > 0 &&
            data.map((elm: string, idx: number) => (
              <ChipElement key={idx}>
                <Chip size="small" label={fieldtype === 'chips-estilos' ? styleFoodLabels[lng][elm] : elm} variant="outlined" />
              </ChipElement>
            ))}
        </div>
      )
    case 'location':
      return (
        <div>
          {data && data.street && (
            <Typography variant="body1" sx={{ color: '#111827', minWidth: 200 }}>
              {data.street} {data.number || ''}
            </Typography>
          )}
          {data && (
            <Typography variant="body1">
              {data.cp} {data.locality}
            </Typography>
          )}
        </div>
      )
    case 'money':
      return (
        <Typography sx={{ minWidth: 100 }} variant="body1">
          {row?.company ? '-' : dataToCurrency(data)}
        </Typography>
      )
    case 'money-group':
      return (
        <Typography
          sx={{
            minWidth: 100,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {dataToCurrency(data.total)}
        </Typography>
      )
    case 'orders':
      return (
        <Typography
          sx={{
            minWidth: 100,
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {data.platesSolds}
        </Typography>
      )
    case 'date-time':
      return (
        <Typography
          sx={{
            minWidth: width || 150,
            mx: 'auto',
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {data ? dateTimeConvert(data) : ''}
        </Typography>
      )

    case 'date-time-expired':
      return (
        <Typography
          sx={{
            minWidth: width || 150,
            mx: 'auto',
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 100
            }
          }}
          variant="body1"
        >
          {data ? onlyDate(data) : ''}
        </Typography>
      )
    case 'date':
      return (
        <Typography
          variant="body1"
          sx={{
            width: 72,
            margin: '0 auto',
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 85
            }
          }}
        >
          {data ? onlyDate(data, true) : ''}
        </Typography>
      )

    case 'plate-pickup':
      return (
        <Typography
          variant="body1"
          sx={{
            width: 123,
            mx: 'auto',
            [theme.breakpoints.down('md')]: {
              width: 'auto',
              minWidth: 90
            }
          }}
        >
          {data.IRr?.hour +
            ':' +
            String(data.IRr?.minutes).padStart(2, '0') +
            ' - ' +
            data?.FRr?.hour +
            ':' +
            String(data.FRr?.minutes).padStart(2, '0')}
        </Typography>
      )
    case 'rating':
      return <>{data ? <LikeTable /> : <DislikeTable />}</>
    case 'rating-historial':
      if (row.quality === 0 && row.amount === 0) {
        return (
          <Typography
            variant="body1"
            component="span"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
              color: '#9CA3AF',
              margin: '0 auto',
              [theme.breakpoints.down('md')]: {
                width: 'auto'
              }
            }}
          >
            {labels[lng].noRatings}
          </Typography>
        )
      } else
        return (
          <Typography
            variant="body1"
            component="span"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
              ml: 0.5,
              mb: 0.5,
              [theme.breakpoints.down('md')]: {
                width: 'auto'
              }
            }}
          >
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <FudclubTooltip title={labels[lng].quality} placement="right">
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Medal />
                  <span>&nbsp;</span>
                  <span>{row.quality || '0'}</span>
                  <span>&nbsp;</span>
                </Stack>
              </FudclubTooltip>
              <span>({row.qualityPercentage || '0%'})&nbsp;&nbsp;</span>
            </Stack>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <FudclubTooltip title={labels[lng].quantity} placement="right">
                <Stack direction="row" sx={{ alignItems: 'center' }} alignItems={'center'}>
                  <Position />
                  <span>&nbsp;</span>
                  <span>{row.amount || '0'}</span>
                  <span>&nbsp;</span>
                </Stack>
              </FudclubTooltip>
              <span>({row.amountPercentage || '0%'})&nbsp;&nbsp;</span>
            </Stack>
          </Typography>
        )
    case 'parameter':
      if (row.type === 'chip') {
        if (row.value && row.value.value)
          return <Chip size="small" label={row.value.value} sx={{ backgroundColor: '#D1FAE5', color: '#065F46' }} />
      }
      if (row.type === 'string') {
        if (row.code === 'PP') {
          return (
            <>
              <Typography variant="subtitle2" sx={{ color: '#111827' }} align="center">
                {row.value.value.toFixed(2).replace('.', ',')} €
              </Typography>
            </>
          )
        }
        return (
          <Typography variant="subtitle2" sx={{ color: '#111827' }} align="center">
            {row.value.value} {row.code === 'PP' && '€'} {(row.code === 'TPFR' || row.code === 'TPVC') && labels[lng].minutes}
          </Typography>
        )
      }
      if (row.type === 'stringPer') {
        return (
          <Typography variant="subtitle2" sx={{ color: '#111827' }} align="center">
            {row.value.value} %
          </Typography>
        )
      }
      if (row.type === 'timeline1') {
        const values = Object.entries(row.value)
        return (
          <>
            {values.map((line: any, idx: number) => (
              <Typography key={idx} sx={{ color: '#111827' }} variant="subtitle2" align="center">
                {line[0]} ({parameterToTime(line[1])})
              </Typography>
            ))}
          </>
        )
      }
      if (row.type === 'timeline2') {
        const values = Object.entries(row.value)
        return (
          <>
            {values.map((line: any, idx: number) => (
              <Typography key={idx} variant="subtitle2" align="center">
                {line[0]} ({line[1].value}minutes)
              </Typography>
            ))}
          </>
        )
      }
      return <></>
    case 'numberRestaurants':
      return <Typography variant="body1">{row.group ? row.group.length || 0 : 0}</Typography>
    case 'status':
      if (row.status === 'ACTIVE')
        return <Chip size="small" label={labels[lng].active} sx={{ backgroundColor: '#D1FAE5', color: '#065F46' }} />
      if (row.status === 'DISABLED')
        return <Chip size="small" label={labels[lng].disabled} sx={{ backgroundColor: '#FEF3C7', color: '#92400E' }} />
      return <></>
    case 'plate-name':
      return (
        <div style={{ display: 'flex', alignItems: 'center', minWidth: 500, width: '100%' }}>
          <Avatar variant="rounded" sx={{ width: 40, height: 40 }} src={data.images ? data.images[0] : ''} />
          <Typography className="ellipsis" variant="subtitle2" sx={{ ml: 2, color: '#111827' }}>
            {lng === 'es' ? data.name : Array.isArray(data.translations) ? data.translations[0].name : data.name}
          </Typography>
          {row.today && <Chip sx={{ ml: 1, backgroundColor: '#DBEAFE', color: '#1E40AF' }} size="small" label={labels[lng].todayPlate} />}
          {row.tomorrow && (
            <Chip sx={{ ml: 1, backgroundColor: '#DBEAFE', color: '#1E40AF' }} size="small" label={labels[lng].tomorrowPlate} />
          )}
          {row.parameter.RFC?.value && <Chip sx={{ ml: 1, backgroundColor: '#FEF3C7' }} size="small" label={<RecommendStar />} />}
        </div>
      )
    case 'voucher-name':
      return (
        <Stack direction="row" sx={{ alignItems: 'center', minWidth: 190 }}>
          <Typography variant="subtitle2" sx={{ color: '#111827' }}>
            {data}
          </Typography>
          {row.special && row.status === 'ACTIVE' && (
            <Box sx={{ ml: 1 }}>
              <SpecialVouchers />
            </Box>
          )}
          {row.company && (
            <Chip
              sx={{
                flexDirection: 'row-reverse',
                pr: 1.5,
                '& .MuiChip-label': { pr: '1px' },
                ml: 1,
                backgroundColor: '#FEF3C7',
                color: '#92400E'
              }}
              size="small"
              label={row.users.length}
              icon={<EnterpriseDetail />}
            />
          )}
          {row.position && row.position !== '0' && (
            <Chip sx={{ ml: 1, backgroundColor: '#E0E7FF', color: '#3730A3' }} size="small" label={row.position} />
          )}
          {row.users && row.users.length > 0 && !row.company && (
            <Chip
              sx={{
                flexDirection: 'row-reverse',
                pr: 1.5,
                '& .MuiChip-label': { pr: '1px' },
                ml: 1,
                backgroundColor: '#FFEDD5',
                color: '#9A3412'
              }}
              size="small"
              label={row.users.length}
              icon={<UserOrange />}
            />
          )}
        </Stack>
      )
    case 'user-voucher-name':
      return (
        <Stack sx={{ width: 192 }}>
          <Typography variant="body1" sx={{ color: '#111827' }}>
            {row.name} {row.id_bono.quantity && `(${row.id_bono.quantity})`}
          </Typography>
          <Typography variant="body1">
            {row.vouchers} {labels[lng].availables}
          </Typography>
        </Stack>
      )
    case 'voucher-gifts':
      return (
        <Typography variant="body1" sx={{ color: '#6B7280', minWidth: width }}>
          {row?.id_bono?.giftQuantity ? (
            <>
              {row.gifts.length || '0'} {row.id_bono.giftQuantity && `(${row.id_bono.giftQuantity})`}
            </>
          ) : (
            '-'
          )}
        </Typography>
      )
    case 'voucher-date':
      return (
        <Typography variant="body1" sx={{ width: 153 }}>
          {row.expiredBono && row.id_bono.expiration ? (
            onlyDate(transformDate(new Date(row.expiredBono), -1), true)
          ) : row.id_bono.expiration ? (
            <>
              {row.id_bono.expiration} {labels[lng].days}
            </>
          ) : (
            '-'
          )}
        </Typography>
      )
    case 'voucher-price':
      return (
        <Stack sx={{ minWidth: 100 }}>
          <Typography sx={{ color: '#111827' }} variant="body1">
            {data ? dataToCurrency(data) : dataToCurrency(row.id_bono.totalPrice)}
          </Typography>
          <Typography sx={{ fontSize: '12px', textDecoration: 'line-through' }} variant="body1">
            {row.discount ? dataToCurrency(row.id_bono.totalPrice) : ''}
          </Typography>
        </Stack>
      )
    case 'voucher-gift-price':
      // eslint-disable-next-line no-case-declarations
      const gift = row?.gifts?.vouchersGifts || row?.gifts?.[0]?.vouchersGifts
      // eslint-disable-next-line no-case-declarations
      const price = row.price || row.id_bono.totalPrice
      return (
        <Stack sx={{ minWidth: 100 }}>
          <Typography sx={{ color: '#111827' }} variant="body1">
            {row.id_bono.price ? dataToCurrency((+price / +row.id_bono.quantity) * +gift) : '-'}
          </Typography>
          <Typography sx={{ fontSize: '12px', textDecoration: 'line-through' }} variant="body1">
            {row.discount ? dataToCurrency((+row.id_bono.totalPrice / +row.id_bono.quantity) * +gift) : ''}
          </Typography>
        </Stack>
      )
    case 'voucher-expired-price':
      // eslint-disable-next-line no-case-declarations
      const vouchers = row?.vouchers
      // eslint-disable-next-line no-case-declarations
      const priceExpired = row.price || row.id_bono.totalPrice
      return (
        <Stack sx={{ minWidth: 100 }}>
          <Typography sx={{ color: '#111827' }} variant="body1">
            {row.id_bono.price ? dataToCurrency((priceExpired / +row.id_bono.quantity) * +vouchers) : '-'}
          </Typography>
          <Typography sx={{ fontSize: '12px', textDecoration: 'line-through' }} variant="body1">
            {row.discount ? dataToCurrency((+row.id_bono.totalPrice / +row.id_bono.quantity) * +vouchers) : ''}
          </Typography>
        </Stack>
      )
    case 'quantity-vouchers':
      // eslint-disable-next-line no-case-declarations

      return (
        <Stack sx={{ minWidth: 100 }}>
          <Typography sx={{ color: '#111827' }} variant="body1">
            x{data}
          </Typography>
        </Stack>
      )
    case 'user-order-plate':
      return (
        <Stack>
          <Stack sx={{ minWidth: 250 }} direction="row" alignItems="center">
            <Typography variant="body1" sx={{ color: '#111827' }}>
              {data.name}
            </Typography>
            {data.parameter.RFC.value && <Chip sx={{ ml: 1, backgroundColor: '#FEF3C7' }} size="small" label={<RecommendStar />} />}
            <Typography variant="body1" sx={{ ml: '8px' }}>
              x{row.quantity}
            </Typography>
          </Stack>
          {row.review && (
            <Stack direction="row" sx={{ alignItems: 'center', mt: 0.5 }}>
              <Typography variant="body1" sx={{ mr: 0.5 }}>
                {labels[lng].quantity}
              </Typography>
              {row.review?.amount ? <LikeTable /> : <DislikeTable />}
              <Typography variant="body1" sx={{ mr: 0.5, ml: 1 }}>
                {labels[lng].quality}
              </Typography>
              {row.review?.quality ? <LikeTable /> : <DislikeTable />}
            </Stack>
          )}
        </Stack>
      )
    case 'user-order-status':
      return (
        <Box sx={{ minWidth: 180 }}>
          {['WAITING', 'WAITING-CONFIRM'].includes(data) && (
            <Chip
              label={`${data === 'WAITING' ? labels[lng].waiting : labels[lng].waitingConfirm} (${
                (row.pickupWaiting && dateTimeConvertUTC(row.pickupWaiting)) || dateTimeConvert(row.updatedAt)
              })`}
              sx={{ backgroundColor: '#E0E7FF', color: '#3730A3' }}
              size="small"
            />
          )}
          {['PENDING', 'COMPLETED'].includes(data) && (
            <Chip
              label={
                data === 'PENDING'
                  ? labels[lng].pending
                  : `${labels[lng].collectedOne} (${
                      (row.pickupWaiting && dateTimeConvertUTC(row.pickupWaiting)) || dateTimeConvert(row.updatedAt)
                    })`
              }
              sx={{ backgroundColor: '#D1FAE5', color: '#065F46' }}
              size="small"
            />
          )}
          {['SOFT-CANCELED', 'HARD-CANCELED'].includes(data) && (
            <Chip
              label={`${data === 'SOFT-CANCELED' ? labels[lng].cancelled : labels[lng].hardCancelled} (${dateTimeConvertUTC(row.pickup)})`}
              sx={{ backgroundColor: '#FEF3C7', color: '#92400E' }}
              size="small"
            />
          )}
          {data === 'UNCOLLECTED' && (
            <Chip label={labels[lng].noCollectedOne} sx={{ backgroundColor: '#F3F4F6', color: '#1F2937' }} size="small" />
          )}
        </Box>
      )
    case 'user-order-pickup':
      if (row.pickupRange.length === 2) {
        const date = row.pickupRange[0]
        const from = row.pickupRange[0].split('T')[1] ? row.pickupRange[0].split('T')[1].substring(0, 5) : '-'
        const to = row.pickupRange[1].split('T')[1] ? row.pickupRange[1].split('T')[1].substring(0, 5) : '-'
        return (
          <Stack sx={{ minWidth: 150 }}>
            <Typography variant="body1" sx={{ color: '#111827' }}>
              {date ? onlyDate(date, true) : ''}
            </Typography>
            <Typography variant="body1">{from + ' - ' + to}</Typography>
          </Stack>
        )
      }
      return <></>
    case 'user-order-location':
      return (
        <div>
          {data && (
            <Typography variant="body1" sx={{ color: '#111827', minWidth: 220 }}>
              {data.restaurant.name}
            </Typography>
          )}
          {data.restaurant?.location && (
            <Typography variant="body1">
              {data.restaurant?.location.street}, {data.restaurant?.location.locality}
            </Typography>
          )}
        </div>
      )
    case 'user-gift-type':
      return (
        <>
          {data === 'send' && (
            <Stack direction="row" alignItems={'center'}>
              <Chip label={labels[lng].sent} sx={{ backgroundColor: '#D1FAE5', color: '#065F46' }} size="small" />
              <Typography marginLeft={'8px'} variant="body1">
                {row?.gifts?.vouchersGifts ? `x${row?.gifts?.vouchersGifts}` : ''}
              </Typography>
            </Stack>
          )}
          {data === 'received' && (
            <Stack direction="row" alignItems={'center'}>
              <Chip label={labels[lng].received} sx={{ backgroundColor: '#F3F4F6', color: '#1F2937' }} size="small" />
              <Typography marginLeft={'8px'} variant="body1">
                {row?.gifts?.vouchersGifts ? `x${row?.gifts?.vouchersGifts}` : ''}
              </Typography>
            </Stack>
          )}
        </>
      )
    case 'voucher-code-discount':
      if (row.id_bono?.company) {
        return (
          <Stack sx={{ minWidth: 150 }}>
            <>
              <Typography variant="body1" sx={{ color: '#111827' }}>
                {row.id_bono.companyConfig.code}
              </Typography>
            </>
          </Stack>
        )
      }
      return (
        <Stack sx={{ minWidth: 150 }}>
          {data && (
            <>
              <Typography variant="body1" sx={{ color: '#111827' }}>
                {data.name}
              </Typography>
              <Typography variant="body1">{data.discount} %</Typography>
            </>
          )}
        </Stack>
      )
    case 'user-gift-voucher':
      return (
        <Stack sx={{ minWidth: 200, maxWidth: 290, m: 'auto' }} direction="row" alignItems="center" justifyContent="space-between">
          <Stack alignItems="flex-start">
            <Typography variant="body1" sx={{ color: '#111827' }}>
              {row.id_bono?.name && row.id_bono.name}
            </Typography>
            <Typography variant="body1">{row.createdAt ? dateTimeConvert(row.createdAt) : ''}</Typography>
          </Stack>
          <Stack alignItems="flex-end">
            <Button
              sx={{ minWidth: 59, height: 28, p: 0, fontWeight: 400, ml: 3, borderRadius: '8px' }}
              variant="outlined"
              color="info"
              size="small"
              onClick={() => col.open(row.id_bono)}
            >
              <SeeBtn /> &nbsp; {labels[lng].see}
            </Button>
          </Stack>
        </Stack>
      )
    default:
      return <></>
  }
}
