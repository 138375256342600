import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box'

import { AlertContext } from 'src/context/alert/alertContext'
import { createPreUser, updateUserAdmin } from 'src/services/users.services'

import FormTitle from 'src/components/Form/FormTitle'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { SelectGender } from 'src/components/Form/SelectGender'

import { AddEditProps } from 'src/interfaces/pages.types'
import { useForm } from 'src/hooks/useForm'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

const INITIAL_VALUES = {
  name: '',
  surname: '',
  email: ''
}

export default function UsuariosAddEdit({ dataToEdit, closeModal, openModal, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const { showAlert, showSnackbar } = useContext(AlertContext)
  const [gender, setGender] = useState('')
  const [loading, setLoading] = useState(false)
  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [errorEmail, setErrorEmail] = useState<string | undefined>(undefined)

  const resetComponent = () => {
    setLoading(false)
    resetForm()
  }

  useEffect(() => {
    if (dataToEdit && dataToEdit.gender) setGender(dataToEdit.gender)
  }, [dataToEdit])

  const onSave = async () => {
    setLoading(true)
    const newUser = { ...values }
    if (!dataToEdit) {
      delete newUser.translations

      try {
        await createPreUser(newUser)
        showAlert(labels[lng].userCreated, `${labels[lng].emailSentDialogText1} ${labels[lng].emailSentDialogText2}`, 'successRed')
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error)
          if (error.message === 'EmailAlreadyInUse') {
            showAlert(labels[lng].errorDialogTitle, labels[lng].errorDialogUser, 'error')
          } else if (error.message === 'EmailValidation') {
            // showAlert(labels[lng].errorDialogTitle, labels[lng].errorDialogValidateEmail, 'error')
            setErrorEmail(labels[lng].errorValidateEmail)
            return
          } else showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    } else {
      delete newUser.createdAt
      delete newUser.updatedAt
      delete newUser.translations

      try {
        await updateUserAdmin(gender, dataToEdit._id)
        showSnackbar('success', labels[lng].userUpdated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
        showAlert(labels[lng].errorDialogTitle, labels[lng].errorDialogUser, 'error')
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      title={editing ? dataToEdit.name : labels[lng].addUser}
      open={openModal}
      handleClose={() => {
        resetForm()
        closeModal()
      }}
      footer={
        <FooterForm
          text={labels[lng].user}
          closeModal={() => {
            resetForm()
            closeModal()
          }}
          onSave={onSave}
          editing={editing}
          loading={loading}
          disabled={!values?.name || !values?.surname || !values?.email}
        />
      }
    >
      <Box id="form-usuarios" component="form" noValidate>
        <FormTitle text={labels[lng].information} />
        <TextInput
          disabled={editing}
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          id="name"
          text={labels[lng].name}
          placeholder={labels[lng].userName}
        />
        <TextInput
          disabled={editing}
          required
          value={values.surname}
          handleChange={(e) => handleChange(e)}
          name="surname"
          text={labels[lng].surname}
          placeholder={labels[lng].surname}
        />
        <TextInput
          disabled={editing}
          required
          value={values.email}
          handleChange={(e) => handleChange(e)}
          name="email"
          text={labels[lng].email}
          placeholder={labels[lng].email}
          errorBadge={errorEmail}
        />
        {editing && <SelectGender text={labels[lng].gender} setData={setGender} data={gender} />}
      </Box>
    </Modal>
  )
}
