import React, { useState, useContext } from 'react'
import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  LinearProgress,
  Popover
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { HeaderContext } from 'src/context/header/headerContext'

import TableSnackbar from './TableSnackbar'
import { StyledTableCell, CheckBoxCell, StyledTableRow } from './table.styles'
import DisplayFields from './DisplayFields'
import PopoverTableData from './PopoverTableData'

import { DRAWER_WIDTH_CLOSE, DRAWER_WIDTH_OPEN, FOOTER_BAR_HEIGHT } from 'src/constants/const'

import { DynamicApiTableProps, Order } from 'src/interfaces/pages.types'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  headCells: any[]
  noActions?: boolean
  noCheckbox?: boolean
  textDelete?: string
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead sx={{ height: 40, '& .MuiTableCell-head': { backgroundColor: '#F9F9F9 !important' } }}>
      <TableRow>
        {!props.noCheckbox && (
          <CheckBoxCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </CheckBoxCell>
        )}
        {headCells.map((headCell, idx) => (
          <StyledTableCell
            key={`${headCell.idField}-${idx}`}
            align={headCell.align}
            sx={{
              px: headCell.disablePadding ? 0 : '20px !important',
              height: 40,
              py: 0,
              '& .MuiTableSortLabel-root': { ml: headCell.idField === 'createdAt' ? 3 : 0 }
            }}
            sortDirection={orderBy === headCell.idField ? order : false}
          >
            {headCell.order ? (
              <TableSortLabel
                active={orderBy === headCell.idField}
                direction={orderBy === headCell.idField ? order : 'asc'}
                onClick={createSortHandler(headCell.idField)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </StyledTableCell>
        ))}
        {!props.noActions && <StyledTableCell align="right"></StyledTableCell>}
      </TableRow>
    </TableHead>
  )
}

export default function TableApi({
  snackBar,
  headCells,
  rows,
  onDelete,
  onEdit,
  onDeactivate,
  filters,
  loading,
  onRowClick,
  noActions,
  noCheckbox,
  totalCount,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  page,
  onExport,
  selected,
  setSelected,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  onDeactivateAll,
  onActivateAll,
  textDelete,
  checkRestaurant
}: DynamicApiTableProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { openLayout } = useContext(HeaderContext)
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const _rows = checkRestaurant ? rows?.filter((row) => row.status !== 'DELETED') : rows
    if (rows && event && event.target.checked) {
      const newSelecteds = (_rows || rows).map((n) => n._id)
      setSelected(newSelecteds)
      return
    }
    if (rows && event && !event.target.checked && (_rows || rows)?.length !== selected.length) {
      const newSelecteds = rows.map((n) => n._id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: string[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleRowClick = (row: any) => {
    if (onRowClick) onRowClick(row)
    // if (onRowClick && (row.status === 'ACTIVE' || !row.status)) onRowClick(row)
  }

  const onDeleteSelected = () => {
    if (onDelete) onDelete(selected)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  // POPOVER
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [rowPopover, setRowPopover] = useState<Record<string, unknown> | null>(null)
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>, index: number, row: Record<string, any>) => {
    setRowPopover(row)
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
    setRowPopover(null)
  }
  const openPopover = Boolean(anchorEl)
  const idPop = openPopover ? 'simple-popover' : undefined

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cursor = (row: any) => {
    return false
    // if (onRowClick && (row.status === 'ACTIVE' || !row.status) && !noActions) return false
    // else return true
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1 }}>
          {loading && <LinearProgress />}
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={(checkRestaurant ? rows?.filter((row) => row.status !== 'DELETED') : rows)?.length || 0}
                headCells={headCells}
                noActions={noActions}
                noCheckbox={noCheckbox}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody
                sx={{
                  [theme.breakpoints.down('md')]: {
                    '& .MuiTableCell-root': {
                      padding: '16px 8px'
                    }
                  }
                }}
              >
                {rows &&
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row._id)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row._id}-${index}`}
                        selected={isItemSelected}
                      >
                        {!noCheckbox && (
                          <CheckBoxCell padding="checkbox">
                            {checkRestaurant ? (
                              row.status !== 'DELETED' && (
                                <Checkbox
                                  sx={{ outlineWidth: '1px' }}
                                  color="primary"
                                  onClick={(event) => handleClick(event, row._id)}
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId
                                  }}
                                />
                              )
                            ) : (
                              <Checkbox
                                sx={{ outlineWidth: '1px' }}
                                color="primary"
                                onClick={(event) => handleClick(event, row._id)}
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId
                                }}
                              />
                            )}
                          </CheckBoxCell>
                        )}
                        {headCells.map((col, colIdx) => (
                          <StyledTableCell
                            key={`${col}-${colIdx}`}
                            align={col.align}
                            style={col.margin ? { margin: col.margin } : {}}
                            sx={{ cursor: cursor(row) ? 'inherit' : 'pointer' }}
                            onClick={() => handleRowClick(row)}
                          >
                            <DisplayFields col={col} row={row} />
                          </StyledTableCell>
                        ))}
                        {!noActions && (
                          <StyledTableCell align="right">
                            <Button
                              size="small"
                              type="submit"
                              color="info"
                              variant="outlined"
                              aria-describedby={`${idPop}-${index}`}
                              onClick={(e) => handlePopover(e, index, row)}
                              disabled={row.status === 'DELETED'}
                            >
                              <MoreHorizIcon />
                            </Button>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              position: 'fixed',
              bottom: 0,
              backgroundColor: '#ffffff',
              height: 'calc(52px + env(safe-area-inset-bottom))',
              width: '100%'
            }
          }}
        >
          <Box sx={{ height: { xs: filters ? '104px' : FOOTER_BAR_HEIGHT, md: FOOTER_BAR_HEIGHT } }} />
          <Box
            sx={{
              height: { xs: filters ? '104px' : FOOTER_BAR_HEIGHT, md: FOOTER_BAR_HEIGHT },
              position: 'fixed',
              bottom: 0,
              width: { xs: '100%', sm: `calc(100% - ${openLayout ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE}px)` },
              overflowY: 'hidden',
              backgroundColor: '#ffffff',
              zIndex: 999,
              [theme.breakpoints.down('md')]: {
                marginBottom: 'env(safe-area-inset-bottom)'
              }
            }}
          >
            <Divider />
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: filters ? 'space-between' : 'flex-end',
                alignItems: 'center',
                width: '100%'
              }}
            >
              {filters && filters}
              <TablePagination
                sx={{ '& .MuiBox-root': { pl: 0 } }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={labels[lng].perPage}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} ${labels[lng].of} ${count !== -1 ? count : `${labels[lng].moreThan} ${to}`}`
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {snackBar && rows && (
        <TableSnackbar
          selected={selected}
          totalLength={rows.length}
          onSelectAllClick={handleSelectAllClick}
          onExport={onExport}
          onDelete={onDelete ? () => onDeleteSelected() : undefined}
          onDeactivateAll={onDeactivateAll}
          onActivateAll={onActivateAll}
          textDelete={textDelete}
        />
      )}
      {rowPopover && (
        <Popover
          id={idPop}
          elevation={1}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <PopoverTableData
            onEdit={onEdit}
            onDeactivate={onDeactivate}
            onDelete={onDelete}
            rowPopover={rowPopover}
            handleClosePopover={handleClosePopover}
            textDelete={textDelete}
          />
        </Popover>
      )}
    </>
  )
}
