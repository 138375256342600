import React, { Dispatch, SetStateAction, useRef } from 'react'
import { Stack, Typography } from '@mui/material'
import { OrderColumnTitle, OrderColumnBox, OrderEmptyContent } from './pedidos.styles'
import PickUpOrdersItem from './PickUpOrdersItem'
import EmptyHead from '../EmptyPage/EmptyHead'

import { BookingTypes, PlanningTypes, RestaurantTypes } from 'src/interfaces/pages.types'
import { getSubsMinutesFromDateFRrAddDays, subsMinutesFromDateFRr } from 'src/utils/time-helpers'

import { ReactComponent as Sun } from 'src/assets/icons/smalls/Sun.svg'
import { ReactComponent as PedidosInto } from 'src/assets/icons/Empty/PedidosInto.svg'

import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { subHours } from 'date-fns'
import { theme } from 'src/styles/theme'

interface PickUpOrdersProps {
  plateUses?: number
  bookings?: BookingTypes[]
  planning?: PlanningTypes
  EMHIRR: { hour: number; minutes: number } | null
  restaurantInfo: RestaurantTypes | null
  setOpenErrorDialog: Dispatch<SetStateAction<boolean>>
  setTextError: Dispatch<SetStateAction<{ title: string; text: string }>>
  fetchData: (noLoading?: boolean) => Promise<void>
}

export default function PickUpOrders({
  bookings,
  plateUses,
  planning,
  setOpenErrorDialog,
  setTextError,
  EMHIRR,
  restaurantInfo,
  fetchData
}: PickUpOrdersProps): JSX.Element {
  const slidersRefs = useRef<any[] | never[]>([])
  slidersRefs.current = []
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const addToRef = () => {
    // if (elm && !slidersRefs.current.includes(elm)) {
    //   slidersRefs.current.push(elm)
    // }
  }
  let day = 0
  let forPickUp = labels[lng].pickUpOrdersText1
  if (EMHIRR && planning) {
    const now = new Date()
    const planningDate = subHours(new Date(planning.date as string), Math.abs(new Date().getTimezoneOffset() / 60))
    if (now < planningDate) {
      forPickUp = labels[lng].pickUpOrdersText2
      day = 1
    }
  }
  const conditionTodayOrTomorrow = day
    ? new Date() < getSubsMinutesFromDateFRrAddDays(planning?.parameter?.FRr, restaurantInfo?.parameter?.FRR, day)
    : new Date() > getSubsMinutesFromDateFRrAddDays(planning?.parameter?.FRr, restaurantInfo?.parameter?.FRR, day)
  return (
    <>
      <OrderColumnBox
        sx={{
          [theme.breakpoints.down('md')]: {
            minHeight: bookings && bookings.length > 0 ? 'auto' : '183px',
            paddingBottom: 0,
            backgroundColor: '#fff',
            borderRadius: '8px'
          }
        }}
      >
        <OrderColumnTitle>
          <div className="dflex">
            <Typography sx={{ mr: 0.5, color: '#6B7280' }} variant="subtitle2" component="h6">
              {labels[lng].waitingOrders} •
            </Typography>
            <Typography sx={{ color: '#a3a8b4' }} variant="subtitle2">
              {plateUses && plateUses} {plateUses === 1 ? labels[lng].plate.toLowerCase() : labels[lng].plates.toLowerCase()}
            </Typography>
          </div>
        </OrderColumnTitle>
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
        >
          <Sun />
          {conditionTodayOrTomorrow ? (
            <Typography sx={{ textAlign: 'center', ml: 2 }} variant="subtitle2" component="h6">
              {labels[lng].cantLongerReceiveOrdersForToday}
            </Typography>
          ) : (
            <Typography sx={{ textAlign: 'center', ml: 2 }} variant="subtitle2" component="h6">
              {forPickUp} {planning && restaurantInfo ? subsMinutesFromDateFRr(planning.parameter?.FRr, restaurantInfo.parameter?.FRR) : ''}
            </Typography>
          )}
        </Stack>

        {bookings && bookings.length > 0 ? (
          <Stack
            direction="column"
            sx={{
              mt: { xs: '0', md: '16px' },
              px: { xs: '0px', md: '23px' },
              gap: { xs: '0', md: '16px' }
            }}
          >
            {bookings?.map((item) => (
              <PickUpOrdersItem
                key={item._id}
                item={item}
                addToRefsArr={addToRef}
                setOpenErrorDialog={setOpenErrorDialog}
                setTextError={setTextError}
                fetchData={fetchData}
              />
            ))}
          </Stack>
        ) : (
          <OrderEmptyContent
            sx={{
              [theme.breakpoints.down('md')]: {
                py: '24px'
              }
            }}
          >
            <EmptyHead Icon={PedidosInto} title={labels[lng].noOrders} text={labels[lng].ordersCollectedEmpty1} />
          </OrderEmptyContent>
        )}
      </OrderColumnBox>
    </>
  )
}
