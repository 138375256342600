/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react'
import './SwipeableButtonRight.css'
import { ReactComponent as Arrow } from 'src/assets/icons/swipe-icons/swipeRight.svg'

// const slider = React.createRef()
// const container = React.createRef()
const isTouchDevice = 'ontouchstart' in document.documentElement

export default class SwipeableButtonRight extends Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.slider = React.createRef()
  }

  state = {}

  componentDidMount() {
    if (isTouchDevice) {
      document.addEventListener('touchmove', this.onDrag)
      document.addEventListener('touchend', this.stopDrag)
    } else {
      document.addEventListener('mousemove', this.onDrag)
      document.addEventListener('mouseup', this.stopDrag)
    }
    this.containerWidth = this.container.current.clientWidth - 50
  }

  onDrag = (e) => {
    if (this.unmounted || this.state.unlocked) return
    if (this.isDragging) {
      if (isTouchDevice) {
        this.sliderLeft = Math.min(Math.max(0, e.touches[0].clientX - this.startX), this.containerWidth)
      } else {
        this.sliderLeft = Math.min(Math.max(0, e.clientX - this.startX), this.containerWidth)
      }
      this.updateSliderStyle()
    }
  }

  updateSliderStyle = () => {
    if (this.unmounted || this.state.unlocked) return
    this.slider.current.style.left = this.sliderLeft + 50 + 'px'
  }

  stopDrag = () => {
    if (this.unmounted || this.state.unlocked) return
    if (this.isDragging) {
      this.isDragging = false
      if (this.sliderLeft > this.containerWidth * 0.9) {
        this.sliderLeft = this.containerWidth
        if (this.props.onSuccess) {
          setTimeout(() => {
            this.sliderLeft = 0
            this.updateSliderStyle()
          }, 1000)
          this.props.onSuccess(this.props.id)
          this.onSuccess()
        }
      } else {
        this.sliderLeft = 0
        if (this.props.onFailure) {
          this.props.onFailure()
        }
      }
      this.updateSliderStyle()
    }
  }

  startDrag = (e) => {
    if (this.unmounted || this.state.unlocked) return
    this.isDragging = true
    if (isTouchDevice) {
      this.startX = e.touches[0].clientX
    } else {
      this.startX = e.clientX
    }
  }

  onSuccess = () => {
    this.container.current.style.width = this.container.current.clientWidth + 'px'
    // this.setState({
    //   unlocked: true
    // })
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  render() {
    return (
      <div className="ReactSwipeButton">
        <div id={this.props.id} className={'rsbContainer ' + (this.state.unlocked ? 'rsbContainerUnlocked' : '')} ref={this.container}>
          <div
            className="rsbcSlider"
            ref={this.slider}
            onMouseDown={this.startDrag}
            style={{ background: this.props.color }}
            onTouchStart={this.startDrag}
          >
            <span className="rsbcSliderArrow">
              <Arrow />
            </span>
            <span className="rsbcSliderCircle" style={{ background: this.props.color }}></span>
          </div>
          <div className="rsbcText">{this.props.text}</div>
        </div>
      </div>
    )
  }
}
