import axios from 'axios'
import firebase from 'src/firebase/firebase'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_URLAPI}`,
  withCredentials: true
})

axiosInstance.interceptors.request.use(async (config) => {
  const token = await firebase?.auth?.currentUser?.getIdToken()
  if (config && config.headers) {
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    config.headers.noCache = new Date().getTime().toString()
  }

  return config
})

export default axiosInstance
