import React, { ChangeEvent } from 'react'
import { Typography } from '@mui/material'
import { CustomSwitch, SwitchFitted } from './form.styles'
import FormControlLabel from '@mui/material/FormControlLabel'

interface SwitchSmallProps {
  ariaLabel?: string
  text: string
  handleChange: (ev: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  checked: boolean
  disabled?: boolean
}

export default function SwitchSmall({ text, ariaLabel, handleChange, checked, disabled = false }: SwitchSmallProps): JSX.Element {
  return (
    <SwitchFitted>
      <Typography variant="subtitle2" component="h6" sx={{ width: '85%' }}>
        {text}
      </Typography>
      <FormControlLabel
        sx={{ mr: 0 }}
        control={
          <CustomSwitch disabled={disabled} checked={checked} onChange={handleChange} inputProps={{ 'aria-label': ariaLabel || text }} />
        }
        label={''}
      />
    </SwitchFitted>
  )
}
