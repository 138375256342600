import React, { ChangeEvent } from 'react'
import { Grid, Typography } from '@mui/material'
import { GridFlexBetween, MainBox, CustomSwitch } from './form.styles'
import FormControlLabel from '@mui/material/FormControlLabel'

interface SwitchFormProps {
  ariaLabel?: string
  text: string
  handleChange: (ev: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  checked: boolean
  disabled?: boolean
  trueLabel?: string
  falseLabel?: string
  required?: boolean
}

export default function SwitchForm({
  text,
  ariaLabel,
  handleChange,
  checked,
  disabled = false,
  trueLabel,
  falseLabel,
  required
}: SwitchFormProps): JSX.Element {
  const labelTrue = trueLabel || 'Activado'
  const labelFalse = falseLabel || 'Desactivado'

  return (
    <MainBox sx={{ height: 60, py: 1 }}>
      <GridFlexBetween container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="h6">
            {text} {required ? '*' : ''}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControlLabel
            control={
              <CustomSwitch
                disabled={disabled}
                checked={checked}
                onChange={handleChange}
                sx={{ m: 1 }}
                inputProps={{ 'aria-label': ariaLabel || text }}
              />
            }
            label={checked ? labelTrue : labelFalse}
          />
        </Grid>
      </GridFlexBetween>
    </MainBox>
  )
}
