import React from 'react'
import { Chip, Stack, Typography } from '@mui/material'
import { OrderColumnTitle, OrderColumnBox, OrderCollectedItem, OrderEmptyContent } from './pedidos.styles'
import EmptyHead from '../EmptyPage/EmptyHead'

import { BookingTypes } from 'src/interfaces/pages.types'

import { ReactComponent as PedidosInto } from 'src/assets/icons/Empty/PedidosInto.svg'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { subHours } from 'date-fns'

interface OrdersCollectedItemProps {
  item: any
}

const getTimeFromDate = (time: string): string => {
  const date = time
  const hour = String(subHours(new Date(date), Math.abs(new Date().getTimezoneOffset() / 60)).getHours()).padStart(2, '0')
  const minutes = String(new Date(date).getMinutes()).padStart(2, '0')
  return `${hour}:${minutes}`
}

const OrdersCollectedItem = ({ item }: OrdersCollectedItemProps) => {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { user, pickupRange, pickup, pickupWaiting, quantity, status } = item
  return (
    <OrderCollectedItem
      sx={{
        [theme.breakpoints.down('md')]: {
          ':last-child': {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            borderBottom: 'none'
          }
        }
      }}
    >
      <Stack>
        <Typography sx={{ mr: 1 }} variant="subtitle2" component="h6" color="grey.900" className="ellipsis">
          {user.name || ''} {user.surname || ''}
        </Typography>
        <Typography variant="subtitle2" component="span">
          x{quantity}
        </Typography>
      </Stack>
      <Stack direction="row" sx={{ alignItems: 'center', px: '8px' }}>
        {status === 'COMPLETED' && <Chip label={labels[lng].delivered} size="small" />}
        {status === 'UNCOLLECTED' && <Chip label={labels[lng].noCollectedOne} size="small" />}
        {['SOFT-CANCELED', 'HARD-CANCELED'].includes(status) && (
          <Chip label={labels[lng].cancelled} size="small" sx={{ backgroundColor: '#FEE2E2', color: '#991B1B' }} />
        )}

        <Typography sx={{ fontSize: '12px' }} variant="subtitle2" component="span">
          {status === 'COMPLETED'
            ? getTimeFromDate(pickupWaiting || pickup)
            : `${getTimeFromDate(pickupRange[0])} - ${getTimeFromDate(pickupRange[1])}`}
        </Typography>
      </Stack>
    </OrderCollectedItem>
  )
}

interface OrdersCollectedProps {
  plateUses?: number
  bookings?: BookingTypes[]
}

export default function OrdersCollected({ bookings, plateUses }: OrdersCollectedProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <OrderColumnBox
      sx={{
        [theme.breakpoints.down('md')]: {
          minHeight: bookings && bookings.length > 0 ? 'auto' : '183px',
          paddingBottom: 0
        }
      }}
    >
      <OrderColumnTitle>
        <div className="dflex">
          <Typography sx={{ mr: 0.5, color: '#6B7280' }} variant="subtitle2" component="h6">
            {labels[lng].collected} •
          </Typography>
          <Typography sx={{ color: '#a3a8b4' }} variant="subtitle2">
            {plateUses && plateUses} {plateUses === 1 ? labels[lng].plate.toLowerCase() : labels[lng].plates.toLowerCase()}
          </Typography>
        </div>
      </OrderColumnTitle>
      {bookings && bookings.length > 0 ? (
        bookings?.map((item) => <OrdersCollectedItem key={item._id} item={item} />)
      ) : (
        <OrderEmptyContent
          sx={{
            pt: '30px',
            [theme.breakpoints.down('md')]: {
              py: '24px',
              backgroundColor: '#fff',
              borderRadius: '8px'
            }
          }}
        >
          <EmptyHead Icon={PedidosInto} title={labels[lng].noOrders} text={labels[lng].ordersCollectedEmpty} />
        </OrderEmptyContent>
      )}
    </OrderColumnBox>
  )
}
