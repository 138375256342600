import { styled, Card, Box, Button, Grid, CardActionArea } from '@mui/material'
import { grey } from '@mui/material/colors'
import { CARD_PLANNING_HEIGHT, CARD_DISH_HEIGHT } from 'src/constants/const'
import { theme } from 'src/styles/theme'

export const CardContainer = styled(Card)(() => ({
  height: CARD_DISH_HEIGHT,
  maxWidth: '100%',
  border: '1px solid #E5E7EB',
  boxSizing: 'border-box',
  borderRadius: '8px !important',
  margin: 'auto',
  position: 'relative'
}))

export const CardSummaryContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #E5E7EB',
  boxSizing: 'border-box',
  borderRadius: '8px',
  minHeight: '96px',
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  overflowWrap: 'anywhere',
  [theme.breakpoints.down('md')]: {
    padding: '10px',
    minHeight: '58px',
    '& svg': {
      width: '38px',
      height: '38px'
    },
    '& .likeRed': {
      width: '14px',
      height: '14px'
    }
  }
}))

export const CardSummaryBigContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #E5E7EB',
  boxSizing: 'border-box',
  borderRadius: '8px',
  height: '100%',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  overflowWrap: 'anywhere'
}))

export const CardSummaryGrid = styled(Grid)(() => ({
  minHeight: '142px',
  overflowWrap: 'anywhere',
  [theme.breakpoints.down('md')]: {
    minHeight: '100px'
  }
}))

export const ChipCard = styled(Box)(() => ({
  backgroundColor: '#EDE9FE',
  borderRadius: '10px',
  height: '20px',
  position: 'absolute',
  left: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '60px',
  paddingLeft: '10px',
  paddingRight: '10px',
  zIndex: 10
}))

export const ChipRecommend = styled(Box)(() => ({
  boxShadow: '0px 1.46326px 3.65815px rgba(0, 0, 0, 0.1)',
  '& .MuiTypography-root': { fontSize: '10px', fontWeight: 600, color: '#111827' },
  backgroundColor: 'white',
  borderRadius: '8px',
  minWidth: '102px',
  height: '24px',
  position: 'absolute',
  left: '16px',
  top: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  px: 0.5,
  // backgroundColor: '#EDE9FE',
  padding: '4px 8px',
  zIndex: 10
}))

export const ChipHeart = styled(Box)(() => ({
  backgroundColor: 'white',
  borderRadius: '16px',
  width: '50px',
  height: '24px',
  position: 'absolute',
  right: '16px',
  top: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
  boxShadow: '0px 1.46326px 3.65815px rgba(0, 0, 0, 0.1)',
  '& .MuiTypography-root': { fontSize: '12px', marginLeft: 3.33 }
}))

export const CardActionsBox = styled(Box)(({ theme }) => ({
  borderTop: '1px solid #E5E7EB',
  width: '100%',
  padding: '8px',
  height: '50px',
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .Mui-checked': {
    color: `${theme.palette.primary.main} !important`
  },
  '& .MuiCheckbox-root': {
    color: grey[300],
    padding: theme.spacing(0.5)
  }
}))

export const CardActionButton = styled(Button)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(1),
  minWidth: '41px',
  height: '24px',
  borderColor: grey[300],
  borderRadius: '6px',
  color: grey[700]
}))

export const CardHeader = styled(Box)(() => ({
  borderBottom: '1px solid #E5E7EB',
  height: '44px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const EditButton = styled(Button)(() => ({
  position: 'absolute',
  paddingLeft: 0,
  paddingRight: 0,
  color: grey[500],
  bottom: '15px',
  right: '15px',
  minWidth: '32px',
  height: '32px',
  backgroundColor: '#fff',
  borderColor: grey[300],
  '& .MuiSvgIcon-root': {
    fontSize: '18px'
  },
  zIndex: 80
}))

export const CardWithAction = styled(CardActionArea)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: CARD_PLANNING_HEIGHT - 60,
  backgroundColor: '#F9FAFB',
  '& .MuiTypography-root': {
    fontWeight: 500,
    color: '#9CA3AF'
  }
}))
export const CardNoAction = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: CARD_PLANNING_HEIGHT - 44,
  backgroundColor: '#F9FAFB',
  '& .MuiTypography-root': {
    fontWeight: 500,
    color: '#9CA3AF'
  }
}))

export const BoxCardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  flexWrap: 'wrap',
  minHeight: '72px',
  width: '100%'
}))

export const BoxCardIconDefault = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F3F4F6',
  borderRadius: '6px',
  width: 128,
  height: 32,
  marginLeft: '48px'
}))

export const BoxCardPostionRefresh = styled(BoxCardIconDefault)(() => ({
  margin: '0px',
  width: 32
}))
