import { BookingState } from './bookingContext'
import { BOOKING_NUMBER } from '../actions'

type bookingAction = {
  type: 'BOOKING_NUMBER'
  payload: { bookingsNumber: string | number }
}

export const bookingReducer = (state: BookingState, action: bookingAction): BookingState => {
  switch (action.type) {
    case BOOKING_NUMBER:
      return {
        ...state,
        bookingsNumber: action.payload.bookingsNumber
      }
    default:
      return state
  }
}
