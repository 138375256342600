import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { HEADER_BAR_HEIGHT } from '../constants/const'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#EB435A'
    },
    secondary: { main: grey[200], contrastText: grey[800] },
    warning: {
      main: '#FEF3C7',
      contrastText: '#92400E'
    },
    error: {
      main: '#F15B70'
    },
    info: {
      main: '#374151'
    }
  },
  typography: {
    fontFamily: ['Inter', 'Noto Sans', 'Arial'].join(','),
    h2: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '32px',
      color: '#111827'
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '17px',
      color: '#6B7280'
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '36px',
      letterSpacing: '0em',
      textAlign: 'center'
    },
    h5: {
      fontFamily: 'Noto Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      color: grey[300]
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '28px',
      textAlign: 'center'
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      color: '#111827'
    },
    subtitle2: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#6B7280'
    },
    body1: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0em',
      color: '#6B7280'
    },
    body2: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0em'
    }
  },
  mixins: { toolbar: { minHeight: HEADER_BAR_HEIGHT } },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '12px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          fontSize: '14px',
          boxShadow: 'none',
          borderColor: '#D1D5DB',
          '& :hover': { boxShadow: 'none' },
          outline: 'none',
          '& :selected': {
            outline: 'none'
          },
          '& :focus': {
            outline: 'none'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#D1D5DB'
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: '8px'
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 748,
      md: 820,
      lg: 1200,
      xl: 1536
    }
  }
})
