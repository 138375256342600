import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'

import { Dialog, DialogContent, Box, List, ListItem, ListItemText, Typography } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import SearchInput from '../SearchInput'
import { labels as labelsT, LabelType } from 'src/labels/main_labels'

import { getNeighborhood, getNeighborhoods } from 'src/services/restaurants.services'
import { fetchData, getLabel } from './Dialog.utils'
import Spinner from '../Spinner'
import { useTranslation } from 'react-i18next'

interface SearchDialogProps {
  onClose: () => void
  open: boolean
  optionsData: any[]
  setter: React.Dispatch<any>
  selected?: any[]
  text: string
  single?: boolean
  labels?: LabelType
  restaurantEmail?: boolean
  required?: boolean
  searchBy?: 'users' | 'restaurant' | 'neighborhood' | 'discount'
  searchText?: string
}
export const SearchDialog = ({
  open,
  onClose,
  optionsData,
  setter,
  selected,
  text,
  single,
  labels,
  searchBy,
  restaurantEmail,
  searchText
}: SearchDialogProps): JSX.Element => {
  const [options, setOptions] = useState<any[] | null>(null)
  const [page, setPage] = useState(0)
  const [maxPage, setMaxPage] = useState(0)
  const wordSearch = useRef('')

  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const fetchNeigh = async () => {
    const neighborhood = await getNeighborhoods()
    setOptions(neighborhood)
  }

  const handleSearchBy = async (init?: boolean) => {
    const word = wordSearch.current || ''
    if (searchBy === 'neighborhood' && word.length < 2) fetchNeigh()
    if (word.length > 2 || init) {
      let resp
      if (['restaurant', 'users', 'discount'].includes(searchBy || '')) {
        resp = await fetchData(word, setMaxPage, page, searchBy)
      }
      if (optionsData.length > 0 && resp) resp = [...optionsData, ...resp]
      if (searchBy === 'neighborhood' && !init) resp = await getNeighborhood(word)
      if (searchBy === 'neighborhood' && init) resp = await fetchNeigh()
      if (resp) setOptions(resp)
    } else setOptions([])
  }

  useEffect(() => {
    const noOptions = !options || options.length === 0
    if (noOptions) setOptions(optionsData)
    else setOptions([...optionsData, ...options])
    if (searchBy && noOptions) handleSearchBy(true)
  }, [optionsData])

  const loadMore = async () => {
    const resp = await fetchData(wordSearch.current, setMaxPage, page, searchBy)
    if (resp) setOptions([...(options || []), ...resp])
  }

  useEffect(() => {
    return () => {
      setPage(0)
      setMaxPage(0)
    }
  }, [])

  useEffect(() => {
    loadMore()
  }, [page])

  const handleSearch = (word: string) => {
    setPage(0)
    if (searchBy) {
      wordSearch.current = word
      setOptions(null)
      if (!word) handleSearchBy(true)
      else handleSearchBy()
      return
    }
    if (labels) {
      const values = Object.values(labels.es)
      let filtered = values.filter((opt) => opt.toLowerCase().includes(word.toLowerCase()))
      filtered = Object.keys(labels.es).filter((key) => filtered.includes(labels.es[key]))
      setOptions(filtered)
      return
    }
    if (options) {
      const filtered = optionsData.filter((opt) => {
        if (opt.name) return opt.name.toLowerCase().includes(word.toLowerCase())
        else return opt.toLowerCase().includes(word.toLowerCase())
      })
      setOptions(filtered)
    }
  }

  const action = (option: any) => {
    setter(option)
    if (single) onClose()
  }

  const isSelected = (option: any) => {
    if (selected && selected.length > 0) {
      if (selected[0] && selected[0]._id) {
        return selected.map((elm) => elm._id).includes(option._id ? option._id : option)
      }
      return selected.includes(option._id ? option._id : option)
    }
    return false
  }

  return (
    <Dialog sx={{ '& .MuiPaper-root': { width: '350px !important', height: '500px' } }} onClose={onClose} open={open}>
      <Box sx={{ p: 3, pb: 1 }}>
        <SearchInput
          fullwidth
          placeholder={`${labelsT[lng].search} ${searchText ? searchText.toLowerCase() : text.toLowerCase()}`}
          name={'search'}
          handleSubmit={handleSearch}
          clean
        />
      </Box>
      <DialogContent sx={{ pt: 0 }}>
        {!options && (
          <Box sx={{ mt: 5 }}>
            <Spinner />
          </Box>
        )}
        {options && options.length > 0 && (
          <List>
            {options.map((option, idx) => (
              <ListItem
                sx={{ justifyContent: 'space-between', display: 'flex', px: 1 }}
                button
                onClick={() => action(option)}
                key={`${option}+${idx}`}
              >
                <ListItemText
                  primary={getLabel(option, restaurantEmail ? 'group' : searchBy, labels)}
                  sx={{ '& .MuiTypography-root': { color: isSelected(option) ? '#EB435A' : 'inherit' } }}
                />
                {isSelected(option) && <CheckIcon color="primary" />}
              </ListItem>
            ))}
            {maxPage - 1 > page && (
              <Button variant="outlined" color="info" fullWidth onClick={() => setPage((prev) => prev + 1)}>
                {labelsT[lng].loadMore}
              </Button>
            )}
          </List>
        )}
        {options && options.length === 0 && (
          <Box sx={{ p: 2 }}>
            <Typography sx={{ textAlign: 'center', pb: 1, maxWidth: 270 }} variant="subtitle1">
              {labelsT[lng].noResults}
            </Typography>
            {searchBy && (
              <Typography sx={{ textAlign: 'center', maxWidth: 270 }} variant="subtitle2">
                {labelsT[lng].moreThanThreeCharacters}
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
