import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { TableRow } from '@mui/material'

export const StyledTableCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'align' })(({ theme, align }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[100],
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.05em',
    textAlign: align || 'left',
    textTransform: 'uppercase',
    paddingLeft: align !== 'left' ? '0px' : '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.05em',
    textAlign: align || 'left'
    // paddingLeft: align !== 'left' ? '0px' : '20px'
  }
}))

export const CheckBoxCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  },
  [`&.${tableCellClasses.body}`]: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: '#ffffff'
  }
}))

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(2n)': {
    backgroundColor: '#F9F9F9'
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent'
  }
}))
