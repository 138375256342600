// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'

import FormTitle from 'src/components/Form/FormTitle'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from 'src/interfaces/pages.types'
import ImagePicker from 'src/components/ImagePicker'
import { useForm } from 'src/hooks/useForm'
import SwitchForm from 'src/components/Form/SwitchForm'

import SelectDialog from 'src/components/Form/SelectDialog'
import TimeInputSelect from 'src/components/Form/TimeInputSelect'

import { AlertContext } from 'src/context/alert/alertContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'

import { createRestaurant, updateRestaurant } from 'src/services/restaurants.services'
import { labels } from 'src/labels/main_labels'
import { compareInfoToEdit } from '../../../utils/validateForm'
import { RestaurantTypes } from '../../../interfaces/pages.types'
import { validateEmail } from 'src/utils/validateForm'
import HistoricRestaurant from './HistoricRestaurant'
import AddEditMap from 'src/components/Maps/AddEditMap'
// import { getTimeFromError } from 'src/utils/time-helpers'
import { useTranslation } from 'react-i18next'

const INITIAL_VALUES = {
  name: '',
  email: '',
  message: '',
  contact: { name: '', phone: '' },
  bank: { holder: '', bic: '', nif: '', iban: '' },
  photo: ''
  //
}

const INITIAL_PARAMETERS = {
  EMHIRR: { hour: 0, minutes: 0 },
  FRR: { hour: 0, minutes: 0 },
  MPOD: { value: '' }
}

const INITIAL_IS_OPEN = {
  EMHIRR: false,
  FRR: false,
  MPOD: false
}

export default function RestaurantesAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const { showSnackbar, showAlert } = useContext(AlertContext)
  const { parameters } = useContext(RestaurantContext)
  const history = useHistory()

  const { handleChange, values, setPhoto, photo, resetForm, translations } = useForm(INITIAL_VALUES, dataToEdit, ['message'])
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(true)
  const [address, setAddress] = useState<any | null>(null)
  const [neighborhood, setNeighborhood] = useState<any | never>('')
  const [state, setState] = useState<any | null>(INITIAL_PARAMETERS)
  const [isOpen, setIsOpen] = useState<any | null>(INITIAL_IS_OPEN)
  const [errorEmail, setErrorEmail] = useState<string | undefined>(undefined)

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setErrorEmail(undefined)
    setAddress(null)
    setPhoto(null)
    setNeighborhood('')
    setStatus(true)
    setState(INITIAL_PARAMETERS)
    setIsOpen(INITIAL_IS_OPEN)
    if (!dataToEdit) {
      const parameterTemp: any = state
      parameterTemp.MPOD.value = parameters?.restaurant?.MPOD.value
      parameterTemp.EMHIRR.hour = parameters?.timeline1?.EMHIRR.hour
      parameterTemp.EMHIRR.minutes = parameters?.timeline1?.EMHIRR.minutes
      parameterTemp.FRR.hour = parameters?.timeline1?.FRR.hour
      parameterTemp.FRR.minutes = parameters?.timeline1?.FRR.minutes
      setIsOpen({
        EMHIRR: true,
        FRR: true,
        MPOD: true
      })
      setState(parameterTemp)
    }
  }

  useEffect(() => {
    if (!dataToEdit) {
      const parameterTemp: any = state
      parameterTemp.MPOD.value = parameters?.restaurant?.MPOD.value
      parameterTemp.EMHIRR.hour = parameters?.timeline1?.EMHIRR.hour
      parameterTemp.EMHIRR.minutes = parameters?.timeline1?.EMHIRR.minutes
      parameterTemp.FRR.hour = parameters?.timeline1?.FRR.hour
      parameterTemp.FRR.minutes = parameters?.timeline1?.FRR.minutes
      setIsOpen({
        EMHIRR: true,
        FRR: true,
        MPOD: true
      })
      setState(parameterTemp)
    }
  }, [parameters])

  useEffect(() => {
    if (dataToEdit) {
      if (dataToEdit.status === 'ACTIVE') setStatus(true)
      if (dataToEdit.status === 'INACTIVE') setStatus(false)
      if (dataToEdit.photo) setPhoto(dataToEdit.photo)

      if (dataToEdit.location.neighborhood) setNeighborhood(dataToEdit.location.neighborhood)
      if (dataToEdit.location) setAddress(dataToEdit.location)

      if (dataToEdit.parameter) {
        const parameterTemp = dataToEdit.parameter
        setState(parameterTemp)
      }
    }
    return () => resetComponent()
  }, [dataToEdit])

  const handleAddress = (newAddress: any) => {
    setAddress(newAddress)
  }

  const onSave = async () => {
    setErrorEmail(undefined)
    if (!validateEmail(values.email)) {
      setErrorEmail(labels[lng].errorValidateEmail)
      return
    }
    setLoading(true)
    const newRestaurant = JSON.parse(JSON.stringify(values))
    delete newRestaurant.plates
    delete newRestaurant.notifications
    delete newRestaurant.privacity
    delete newRestaurant.income
    delete newRestaurant.privacity
    delete newRestaurant.parameter
    delete newRestaurant.isNewRestaurant
    newRestaurant.translations = translations
    newRestaurant.photo = photo
    if (neighborhood) newRestaurant.location = { ...address, neighborhood: neighborhood._id }
    else newRestaurant.location = JSON.parse(JSON.stringify(address))
    if (newRestaurant.location) {
      delete newRestaurant.location.position
      delete newRestaurant.location.locality
    }
    newRestaurant.parameter = { ...state }
    newRestaurant.status = status ? 'ACTIVE' : 'INACTIVE'
    try {
      if (!dataToEdit) {
        const idNewRestaurant = await createRestaurant(newRestaurant)
        if (idNewRestaurant) history.push(`/restaurantes/${idNewRestaurant}`)
        showSnackbar('success', labels[lng].restaurantCreated)
        showAlert(
          labels[lng].successOnSave1,
          `${labels[lng].successOnSaveText1}`,
          'successRed'
        )
      } else {
        const payload: any = compareInfoToEdit(dataToEdit, newRestaurant)
        if (state?.MPOD.value === dataToEdit.parameter.MPOD.value) {
          delete payload.parameter.MPOD
        }

        if (state?.EMHIRR.hour === dataToEdit.parameter.EMHIRR.hour && state?.EMHIRR.minutes === dataToEdit.parameter.EMHIRR.minutes) {
          delete payload.parameter.EMHIRR
        }

        if (state?.FRR.hour === dataToEdit.parameter.FRR.hour && state?.FRR.minutes === dataToEdit.parameter.FRR.minutes) {
          delete payload.parameter.FRR
        }
        await updateRestaurant(payload as RestaurantTypes, dataToEdit._id)
        showSnackbar('success', labels[lng].restaurantUpdated)
      }
      resetComponent()
      if (fetchData) fetchData()
      closeModal()
    } catch (error: any) {
      if (error.message === 'RestaurantMailError') {
        showAlert(labels[lng].errorDialogTitle, labels[lng].errorDialogRestaurant, 'error')
      } else if (error.response?.data.code) {
        const code = error.response?.data.code
        if (code.error === 'EMHIRR' || code.error === 'FRR') {
          showAlert(labels[lng].errorDialogTitle, labels[lng].errorDialogText, 'error')
        }
        // if (code.error === 'EMHIRR') errText = labels[lng].errorEMHIRR + getTimeFromError(code.value)
        // if (code.error === 'FRR')
        //   errText = labels[lng].errorFRR1 + getTimeFromError(code.value[0]) + labels[lng].errorFRR2 + getTimeFromError(code.value[1])
        // if (errText) showAlert(labels[lng].errorDialogTitle, errText, 'error')
      } else showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }
  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked)
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit._id)
  }
  // const debouncedValue = useDebouncedValue(state?.MPOD?.value, 700)

  // useEffect(() => {
  //   const mpod = +parameters?.restaurant?.MPOD.value || 0
  //   if (state.MPOD.value < mpod) setState({ ...state, MPOD: { value: mpod } })
  // }, [debouncedValue])

  const handleChangeMPOD = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState({ ...state, MPOD: { value: event.target.value } })
  }

  return (
    <Modal
      title={editing ? dataToEdit.name : labels[lng].addRestaurant}
      open={openModal}
      handleClose={() => {
        resetComponent()
        closeModal()
      }}
      footer={
        <FooterForm
          text={labels[lng].restaurant}
          closeModal={() => {
            resetComponent()
            closeModal()
          }}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={
            !values.name ||
            !values.email ||
            !values.contact.name ||
            !values.contact.phone ||
            !values.bank.holder ||
            !values.bank.bic ||
            !values.bank.nif ||
            !values.bank.holder ||
            !values.bank.iban ||
            !address ||
            !neighborhood ||
            (address && !address.number)
          }
        />
      }
    >
      <Box id="form-restaurantes" component="form" noValidate>
        <ImagePicker setImgData={setPhoto} imgData={values.photo} actualImage={editing ? values.photo : null} />

        <FormTitle text={labels[lng].information} />
        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          id="name"
          text={labels[lng].nameRestaurant}
          placeholder={labels[lng].nameRestaurant}
          noTopBorder
        />
        <TextInput
          required
          value={values.contact.name}
          handleChange={(e) => handleChange(e)}
          name="contact.name"
          id="contact.name"
          text={labels[lng].contactPerson}
          placeholder={labels[lng].nameSurname}
        />
        <TextInput
          required
          value={values.email}
          handleChange={(e) => handleChange(e)}
          name="email"
          id="email"
          text={labels[lng].email}
          placeholder={labels[lng].emailRestaurant}
          disabled={editing}
          errorBadge={errorEmail}
        />
        <TextInput
          required
          value={values.contact.phone}
          handleChange={(e) => handleChange(e)}
          name="contact.phone"
          id="contact.phone"
          text={labels[lng].phone}
          placeholder={labels[lng].restaurantPhone}
        />

        <TextInput
          value={values.message}
          handleChange={(e) => handleChange(e)}
          name="message"
          id="message"
          text={`${labels[lng].message} - ${labels[lng].optional.toLowerCase()} (ES)`}
          placeholder={`${labels[lng].message} - ${labels[lng].optional.toLowerCase()} (ES)`}
        />

        {translations.map((opt: { language: string; message: string | number }, idx: number) => (
          <TextInput
            key={`${opt.language}-${idx}`}
            value={opt.message}
            handleChange={(e) => handleChange(e, opt)}
            name="message"
            text={`${labels[lng].message} - ${labels[lng].optional.toLowerCase()} (${opt.language.toUpperCase()})`}
            placeholder={`${labels[lng].message} - ${labels[lng].optional.toLowerCase()} (${opt.language.toUpperCase()})`}
          />
        ))}

        <SwitchForm text={labels[lng].state} handleChange={(e) => handleSwitch(e)} checked={status} />
        <FormTitle text={labels[lng].address} />
        {openModal && <AddEditMap address={values?.location} setAddress={handleAddress} />}

        <SelectDialog text={labels[lng].neightborhood} setData={setNeighborhood} data={neighborhood} options={[]} neighborhood />

        <FormTitle text={labels[lng].payments} />
        <TextInput
          required
          value={values.bank.holder}
          handleChange={(e) => handleChange(e)}
          name="bank.holder"
          id="bank.holder"
          text={labels[lng].beneficiary}
          placeholder={labels[lng].name}
        />
        <TextInput
          required
          value={values.bank.nif}
          handleChange={(e) => handleChange(e)}
          name="bank.nif"
          id="bank.nif"
          text={labels[lng].nationalId}
          placeholder={labels[lng].nationalIdLabel}
        />
        <TextInput
          required
          value={values.bank.bic}
          handleChange={(e) => handleChange(e)}
          name="bank.bic"
          id="bank.bic"
          text="BIC"
          placeholder={labels[lng].bic}
        />
        <TextInput
          required
          value={values.bank.iban}
          handleChange={(e) => handleChange(e)}
          name="bank.iban"
          id="bank.iban"
          text="IBAN"
          placeholder={labels[lng].iban}
        />

        <FormTitle text={labels[lng].parameters} />
        <TextInput
          value={state?.MPOD.value}
          handleChange={(e) => {
            setIsOpen({ ...isOpen, MPOD: true })
            handleChangeMPOD(e)
          }}
          name="mpod"
          id="state.mpod"
          text="MPOD"
          placeholder={'Mpod'}
          type="number"
          inputProps={{
            min: 0,
            step: 1
          }}
          noTopBorder
          byDefaultText={isOpen.MPOD ? +state?.MPOD.value === +parameters?.restaurant?.MPOD.value : state?.MPOD.default}
        />
        <TimeInputSelect
          required
          value={state?.EMHIRR}
          state={state}
          setState={setState}
          setIsOpen={() => setIsOpen({ ...isOpen, EMHIRR: true })}
          name="EMHIRR"
          text="EMHIRR"
          byDefaultText={
            isOpen.EMHIRR
              ? parameters?.timeline1?.EMHIRR.hour === state?.EMHIRR.hour && parameters?.timeline1?.EMHIRR.minutes === state?.EMHIRR.minutes
              : state?.EMHIRR.default
          }
        />
        <TimeInputSelect
          required
          value={state?.FRR}
          state={state}
          setState={setState}
          setIsOpen={() => setIsOpen({ ...isOpen, FRR: true })}
          name="FRR"
          text="FRR"
          byDefaultText={
            isOpen.FRR
              ? parameters?.timeline1?.FRR.hour === state?.FRR.hour && parameters?.timeline1?.FRR.minutes === state?.FRR.minutes
              : state?.FRR.default
          }
        />
        {editing && dataToEdit && <HistoricRestaurant parameter={dataToEdit.parameter} parameters={parameters} />}
      </Box>
    </Modal>
  )
}
