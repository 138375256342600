import React, { FC } from 'react'
import { Chip, Typography } from '@mui/material'
import { CardSummaryBigContainer, EditButton } from './cards.styles'
import EditIcon from '@mui/icons-material/Edit'
import { theme } from 'src/styles/theme'

interface CardBigIconTitleTextProps {
  Icon: FC
  title: string
  text?: string | Date
  ellipsis?: boolean
  handleEdit?: () => void
  chip?: boolean
  finalText?: string
  specialText?: boolean
}

export default function CardBigIconTitleText({
  title,
  Icon,
  text,
  ellipsis,
  handleEdit,
  chip,
  finalText,
  specialText = false
}: CardBigIconTitleTextProps): JSX.Element {
  return (
    <CardSummaryBigContainer
      sx={{
        px: 2,
        textAlign: 'center',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          p: '16px',
          height: 'auto'
        }
      }}
    >
      <Icon />
      <Typography
        sx={{
          mt: 0.5,
          color: '#111827',
          [theme.breakpoints.down('md')]: {
            marginBottom: '4px'
          }
        }}
        variant="subtitle2"
        component="strong"
      >
        {title}
      </Typography>
      {chip ? (
        <Chip size="small" label={text} sx={{ backgroundColor: '#D1FAE5', color: '#065F46' }} />
      ) : (
        <Typography variant="body1" component="p" color={specialText ? '#9CA3AF' : 'grey.500'} className={ellipsis ? 'ellipsis' : ''}>
          {text} {finalText}
        </Typography>
      )}
      {handleEdit && (
        <EditButton size="small" sx={{ color: '#9CA3AF' }} variant="outlined" onClick={handleEdit}>
          <EditIcon />
        </EditButton>
      )}
    </CardSummaryBigContainer>
  )
}
