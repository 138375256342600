import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, LinearProgress, Typography } from '@mui/material'

import { HeaderContext } from 'src/context/header/headerContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { AuthContext } from 'src/context/auth/authContext'
import { AlertContext } from 'src/context/alert/alertContext'

import { getReviewByRestaurant } from 'src/services/plates.services'

import EmptyPage from 'src/components/EmptyPage'
import SearchInput from 'src/components/SearchInput'
import TableApi from 'src/components/Table/TableApi'

import { labels } from 'src/labels/main_labels'
import { ReactComponent as HistorialEmpty } from 'src/assets/icons/Empty/HistorialEmpty.svg'
import { Order } from 'src/interfaces/pages.types'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { ChevronLeft } from '@mui/icons-material'

export default function Historial({
  setHistoryDBFilter,
  historyDB,
  fetchHistorial,
  isEmpty,
  setIsEmpty,
  totalCount,
  setTotalCount,
  rowsPerPage,
  setRowsPerPage,
  loadingTable,
  pageRef,
  wordSearch
}: {
  setHistoryDBFilter: React.Dispatch<React.SetStateAction<{ plate: { name: string } }[]>>
  historyDB: { plate: { name: string } }[]
  fetchHistorial: () => void
  isEmpty: boolean
  setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>
  totalCount: number
  setTotalCount: React.Dispatch<React.SetStateAction<number>>
  rowsPerPage: number
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
  loadingTable: boolean
  pageRef: React.MutableRefObject<number>
  wordSearch: React.MutableRefObject<string>
}): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const { restaurant } = useContext(RestaurantContext)

  const [selected, setSelected] = useState<string[]>([])
  // TABLE CONTROL
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')

  const params: any = useParams()

  const headCells: any[] = [
    {
      idField: 'plate',
      align: 'center',
      disablePadding: false,
      label: labels[lng].plate,
      order: true,
      fieldtype: 'plate-name'
    },
    {
      idField: 'date',
      align: 'center',
      disablePadding: true,
      label: labels[lng].date,
      fieldtype: 'date',
      margin: '0 auto'
    },
    {
      idField: 'parameter',
      align: 'center',
      disablePadding: true,
      label: labels[lng].schedule,
      fieldtype: 'plate-pickup',
      margin: '0 auto'
    },
    {
      idField: 'quantity',
      align: 'center',
      disablePadding: false,
      label: window.innerWidth < 890 ? labels[lng].dispPlatesMob : labels[lng].dispPlates,
      fieldtype: 'number',
      margin: '0 auto'
    },
    {
      idField: 'maxQuantity',
      align: 'center',
      disablePadding: true,
      label: labels[lng].platesSoldTable,
      fieldtype: 'number',
      width: 100,
      margin: '0 auto'
    },
    {
      idField: 'quality',
      align: 'center',
      disablePadding: true,
      label: labels[lng].ratings,
      fieldtype: 'rating-historial',
      margin: '0 auto'
    }
  ]

  // const getPlateName = (plate) => {
  //   return lng === 'en' ? plate?.translations && plate?.translations[0] ? plate?.translations[0].name : plate.name : plate.name;
  // }

  useEffect(() => {
    return () => setIsEmpty(false)
  }, [])

  useEffect(() => {
    fetchHistorial()
  }, [params.idRestaurant])

  const handleSearch = async (word: string, lng?: string) => {
    wordSearch.current = word
    if (!word) {
      fetchHistorial()
      return
    }
    try {
      const resp = await getReviewByRestaurant(params.idRestaurant ? params.idRestaurant : restaurant!._id, rowsPerPage, 0, word, lng)
      pageRef.current = 0
      setTotalCount(resp.total)
      setHistoryDBFilter(resp.plannings)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
      setHistoryDBFilter([])
    }
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    const history = useHistory()

    return (
      <div style={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: '-2px',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.push('/mas')} />
        </Box>
        <Typography
          variant="h3"
          component="h1"
          textAlign={'center'}
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          {labels[lng].history}
        </Typography>
        <Box
          sx={{
            width: { sm: 230, md: 330 },
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
        >
          <SearchInput placeholder={labels[lng].searchHistorialPlate} name="search" handleSubmit={handleSearch} clean />
        </Box>
      </div>
    )
  }
  useEffect(() => {
    if (userData.role !== 'admin') showHeader(Header)
  }, [])

  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchHistorial()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(() => parseInt(event.target.value, 10))
    pageRef.current = 0
  }

  return (
    <>
      {loadingTable ? (
        <LinearProgress />
      ) : (
        <>
          {isEmpty ? (
            <EmptyPage small title={labels[lng].emptyHistorialTitle} text={labels[lng].emptyHistorialText} Icon={HistorialEmpty} />
          ) : (
            <>
              <Box
                sx={{
                  px: '16px',
                  pt: '16px',
                  mb: '16px',
                  [theme.breakpoints.up('md')]: {
                    display: 'none'
                  }
                }}
              >
                <SearchInput
                  fullwidth
                  placeholder={labels[lng].searchHistorialPlate}
                  name="search"
                  handleSubmit={handleSearch}
                  clean
                  initial={false}
                />
              </Box>
              {historyDB && historyDB.length === 0 ? (
                <Typography
                  sx={{
                    p: 2,
                    [theme.breakpoints.down('md')]: {
                      pt: 0
                    }
                  }}
                  variant="body1"
                >
                  {labels[lng].noResultsHistorial}
                </Typography>
              ) : (
                <>
                  <TableApi
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    rows={historyDB}
                    headCells={headCells}
                    snackBar
                    totalCount={totalCount}
                    handleChangePage={handleChangePage}
                    page={pageRef.current}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    selected={selected}
                    setSelected={setSelected}
                    noCheckbox
                    noActions
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
