import React, { useEffect, useContext, useState, ChangeEvent } from 'react'
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material'

import ContactWithFudclub from 'src/components/ContactWithFudclub'
import { getRestaurantById, toggleNotificationsById } from 'src/services/restaurants.services'
import NotificationsPrivacityCards from 'src/components/NotificationsPrivacityCards'
import RestaurantBasicInformation from 'src/components/RestaurantBasicInformation'
import { QrDialog } from 'src/components/Modals/Dialog'

import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { BoxGrey, AvatarResumen, ContainerFud } from 'src/styles/general-styles'
import { labels } from 'src/labels/main_labels'
import { ReactComponent as QRcode } from 'src/assets/icons/general/QRcode.svg'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'src/context/auth/authContext'
import { theme } from 'src/styles/theme'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useHistory } from 'react-router-dom'
import { RestaurantTypes } from 'src/interfaces/pages.types'

const INITIAL_NOTIFICATIONS = {
  dish_of_the_day_reminder: true,
  orders_and_cancellations: true,
  order_picking: true,
  news: true
}

const INITIAL_PRIVACITY = { new_orders_and_cancellations: true, special_offers_and_promotions: true }

export default function Informacion(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showHeader } = useContext(HeaderContext)
  const { restaurant } = useContext(RestaurantContext)
  const { showSnackbar } = useContext(AlertContext)
  const { userData } = useContext(AuthContext)
  const [switchNotifications, setSwitchNotifications] = useState(INITIAL_NOTIFICATIONS)
  const [switchPrivacity, setSwitchPrivacity] = useState(INITIAL_PRIVACITY)
  const [loadingSwitchs, setLoadingSwitchs] = useState(false)
  const [openQrDialog, setOpenQrDialog] = useState(false)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const updateNotificationAndPrivacity = async (restaurant: RestaurantTypes) => {
    if (restaurant._id) {
      const newRestaurant = await getRestaurantById(restaurant._id)
      if (newRestaurant) {
        if (newRestaurant.privacity) setSwitchPrivacity(newRestaurant.privacity)
        if (newRestaurant.notifications) setSwitchNotifications(newRestaurant.notifications)
      }
    }
  }

  useEffect(() => {
    if (restaurant) {
      updateNotificationAndPrivacity(restaurant)
    }
  }, [restaurant])

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <Grid container alignItems={'center'}>
        <Grid item xs={0} sm={4}></Grid>
        <Grid
          item
          xs={6}
          sm={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { sm: 'flex-start', md: 'center' },
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
        >
          <Typography variant="h3" component="h1">
            {restaurant && restaurant.name}
            {restaurant?.status === 'INACTIVE' && userData?.role === 'admin' && `(${labels[lng].deactivated})`}
          </Typography>
        </Grid>

        <Grid
          item
          xs={2}
          sm={4}
          sx={{
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.goBack()} />
        </Grid>
        <Grid
          item
          xs={8}
          sm={4}
          sx={{
            flex: 1,
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <Typography variant="h3" component="h1" textAlign={'center'}>
            {labels[lng].information}
          </Typography>
        </Grid>

        <Grid item xs={2} sm={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            sx={{
              backgroundColor: '#fff',
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            }}
            size="small"
            type="submit"
            color="info"
            variant="outlined"
            onClick={() => setOpenQrDialog(true)}
            startIcon={<QRcode />}
            disabled={!restaurant?.qrCode}
          >
            {labels[lng].seeQrCode}
          </Button>
          {restaurant?.qrCode ? (
            <Typography
              sx={{
                backgroundColor: '#fff',
                [theme.breakpoints.up('md')]: {
                  display: 'none'
                }
              }}
              variant="h3"
              fontSize="14px"
              lineHeight={'24px'}
              color="#374151"
              onClick={() => setOpenQrDialog(true)}
            >
              {labels[lng].seeQrCodeSmall}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    if (restaurant) {
      showHeader(Header)
      if (restaurant.privacity) setSwitchPrivacity(restaurant.privacity)
      if (restaurant.notifications) setSwitchNotifications(restaurant.notifications)
    }
    return () => setLoadingSwitchs(false)
  }, [restaurant])

  const handleSwitch = async (event: ChangeEvent<HTMLInputElement>, name: string, type: string) => {
    const newValue = event.target.checked
    setLoadingSwitchs(true)
    if (!restaurant || !restaurant._id) return
    try {
      if (type === 'privacity') setSwitchPrivacity({ ...switchPrivacity, [name]: newValue })
      else if (type === 'notification') setSwitchNotifications({ ...switchNotifications, [name]: newValue })
      await toggleNotificationsById(type, name, restaurant._id)
    } catch (error) {
      if (type === 'privacity') setSwitchPrivacity({ ...switchPrivacity, [name]: !newValue })
      else if (type === 'notification') setSwitchNotifications({ ...switchNotifications, [name]: !newValue })
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingSwitchs(false)
    }
  }

  if (!restaurant) return <LinearProgress />

  return (
    <>
      <BoxGrey className="flex-between" sx={{ py: 1.5, px: 2 }}>
        <div className="flex-center">
          <AvatarResumen
            sx={{
              ml: 1,
              [theme.breakpoints.down('md')]: {
                ml: 0
              }
            }}
            src={restaurant.photo}
            alt={restaurant.name}
          />

          <Typography
            sx={{
              ml: 2,
              [theme.breakpoints.down('md')]: {
                fontSize: '20px',
                lineHeight: '28px'
              }
            }}
            variant="h2"
            component="h2"
          >
            {restaurant.name}
          </Typography>
        </div>
      </BoxGrey>
      <ContainerFud>
        <RestaurantBasicInformation restaurant={restaurant} editEmail={userData._id === restaurant._id} />
        <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
          {labels[lng].privacity}
        </Typography>
        <NotificationsPrivacityCards
          handleSwitch={handleSwitch}
          switchNotifications={switchNotifications}
          switchPrivacity={switchPrivacity}
          loading={loadingSwitchs}
        />
        <Box
          sx={{
            mt: 2,
            [theme.breakpoints.down('md')]: {
              mt: 3
            }
          }}
        />
        <ContactWithFudclub text1={labels[lng].contactFudClubInformation} />
      </ContainerFud>
      <QrDialog
        open={openQrDialog}
        handleClose={() => setOpenQrDialog(false)}
        title={labels[lng].qrDialogTitle}
        text={labels[lng].qrDialogText}
        name={restaurant?.name}
        qrCode={restaurant?.qrCode}
      />
    </>
  )
}
