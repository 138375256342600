// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import { Box, Grid, Hidden, IconButton, LinearProgress, Skeleton, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'

import { QrDialog } from 'src/components/Modals/Dialog'
import { SearchDialog } from 'src/components/Modals/SearchDialog'
import { RestaurantTypes, PlateTypes } from 'src/interfaces/pages.types'
import { getRestaurantById } from 'src/services/restaurants.services'
import SearchInput from 'src/components/SearchInput'
import { OptionButton } from 'src/components/Form/buttonSelector.styles'
import StatiticsSelect from 'src/components/Form/StatiticsSelect'

import RestaurantesDetailPlatos from './Platos'
import RestaurantesDetailResumen from './Resumen'
import Pedidos from 'src/pages/Common/Pedidos'
import Planificar from 'src/pages/Common/Planificar'
import Ingresos from 'src/pages/Common/Ingresos'
import Historial from 'src/pages/Common/Historial'
import RestauranteEstadisticas from 'src/pages/Common/Estadisticas/RestaurantStatitics'

import { labels } from 'src/labels/main_labels'
import { ReactComponent as Selector } from 'src/assets/icons/general/Selector.svg'
import { ReactComponent as QRcode } from 'src/assets/icons/general/QRcode.svg'
import { statiticsOptionsSelect } from 'src/constants/const'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import NotFound from 'src/components/NotFound'
import { StickyMenuStack } from 'src/styles/general-styles'
import { useTranslation } from 'react-i18next'
import { getReviewByRestaurant } from 'src/services/plates.services'
import { dateTimeConvert } from 'src/utils/time-helpers'

export default function RestaurantesDetail(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)

  const MENU_RESTAURANTES = [
    { value: 'resumen', text: labels[lng].summary },
    { value: 'platos', text: labels[lng].plates },
    { value: 'pedidos', text: labels[lng].orders },
    { value: 'planificar', text: labels[lng].toPlan },
    { value: 'historial', text: labels[lng].history },
    { value: 'ingresos', text: labels[lng].incomes },
    { value: 'estadisticas', text: labels[lng].statistics }
  ]

  const params: any = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [restaurant, setRestaurant] = useState<RestaurantTypes | null>(null)
  const [plates, setPlates] = useState<PlateTypes[]>([])

  const [photo, setPhoto] = useState<string | null>(null)

  const [error404, setError404] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [menuNav, setMenuNav] = useState<string | null>(null)
  const [openQrDialog, setOpenQrDialog] = useState(false)
  const [historyDBFilter, setHistoryDBFilter] = useState<{ plate: { name: string } }[] | []>([])

  const [type, setType] = useState('days')

  useEffect(() => {
    return () => {
      setError404(false)
      setOpenDialog(false)
      setPhoto(null)
      setLoading(false)
      setRestaurant(null)
      setPlates([])
    }
  }, [])

  const navigationLinking = (value: string) => {
    history.push(`/restaurantes/${params.idRestaurant}/${value}`)
  }
  useEffect(() => {
    if (params.navRestaurant && MENU_RESTAURANTES.map((elm) => elm.value)) setMenuNav(params.navRestaurant)
    else setMenuNav('resumen')
  }, [params.navRestaurant])

  const fetchRestaurant = async () => {
    setError404(false)
    setLoading(true)
    try {
      const restaurantDetails = await getRestaurantById(params.idRestaurant)
      if (restaurantDetails.photo) setPhoto(restaurantDetails.photo)
      setRestaurant(restaurantDetails)
      if (restaurantDetails.plates) setPlates(restaurantDetails.plates)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
      setError404(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (params.idRestaurant) {
      fetchRestaurant()
    }
  }, [params.idRestaurant])

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    const status: string[] = []
    if (restaurant?.status === 'INACTIVE') status.push(labels[lng].deactivated)
    if (restaurant?.isNewRestaurant) status.push(labels[lng].preRestaurant)
    if (restaurant?.status === 'DELETED') status.push(`${labels[lng].deleted} ${dateTimeConvert(restaurant.updatedAt)}`)

    return (
      <Grid container>
        <Hidden smDown>
          <Grid item xs={0} sm={3} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Link to="/restaurantes">
              <IconButton sx={{ ml: '-14px' }}>
                <ChevronLeftIcon sx={{ color: grey[500] }} />
              </IconButton>
            </Link>
            <Typography variant="subtitle2">{labels[lng].restaurants}</Typography>
          </Grid>
        </Hidden>
        <Grid
          gap="2px"
          item
          xs={6}
          sm={6}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'flex-start', sm: 'center' } }}
        >
          <Typography variant="h3" component="h1">
            {restaurant ? restaurant.name : <Skeleton sx={{ width: 200 }} animation="wave" />}
          </Typography>
          {status.length > 0 && (
            <Typography variant="h3" component="h1" sx={{ color: '#9CA3AF' }}>
              {' '}
              {`(${status.join(' ')})`}
            </Typography>
          )}
          <IconButton onClick={() => setOpenDialog(true)}>
            <Selector />
          </IconButton>
        </Grid>
        <Grid item xs={6} sm={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            sx={{ backgroundColor: '#fff' }}
            size="small"
            type="submit"
            color="info"
            variant="outlined"
            onClick={() => setOpenQrDialog(true)}
            startIcon={<QRcode />}
            disabled={!restaurant?.qrCode}
          >
            {labels[lng].seeQrCode}
          </Button>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [restaurant])

  // DIALOG
  const handleClose = () => {
    setOpenDialog(false)
  }

  const changeRestaurant = (restaurant: any) => {
    handleClose()
    history.push(`/restaurantes/${restaurant._id}`)
  }

  // const onDelete = async (data: any) => {
  //   Swal.fire(deleteSwalConfig(labels[lng].restaurants, lng)).then((result) => {
  //     if (result.isConfirmed) {
  //       deleteElements(data, deleteRestaurant)
  //         .then(() => {
  //           showSnackbar('success', labels[lng].restaurantDeleted)
  //           history.push(`/restaurantes`)
  //         })
  //         .catch((error) => {
  //           if (error instanceof Error) showSnackbar('error', error.message)
  //         })
  //     }
  //   })
  // }

  const handleSearch = (word: string) => {
    if (restaurant && restaurant.plates) {
      const filtered = restaurant.plates.filter((row: any) => row.name.toLowerCase().includes(word.toLowerCase()))
      setPlates(filtered)
    }
  }
  const [loadingTable, setLoadingTable] = useState(false)

  const [isEmpty, setIsEmpty] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const firstRender = useRef(false)

  const pageRef = useRef(0)
  const wordSearch = useRef('')

  const fetchHistorial = async () => {
    setLoadingTable(true)
    try {
      const resp = await getReviewByRestaurant(
        params.idRestaurant ? params.idRestaurant : restaurant!._id,
        rowsPerPage,
        pageRef.current,
        wordSearch.current,
        lng
      )
      if (resp) {
        setTotalCount(resp.total)
        setHistoryDBFilter(resp.plannings)
        // Fix: 731 - Pending validation
        // if (resp.plannings.length === 0 && firstRender) setIsEmpty(true)
        // else setIsEmpty(false)
      }
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar('error', error.message)
        setHistoryDBFilter([])
      }
    } finally {
      setLoadingTable(false)
      if (!firstRender.current) firstRender.current = true
    }
  }
  const handleSearchHistorial = (word: string) => {
    wordSearch.current = word
    pageRef.current = 0
    fetchHistorial()
  }

  useEffect(() => {
    fetchHistorial()
  }, [rowsPerPage, lng])

  if (error404) return <NotFound link={'/restaurantes'} />

  return (
    <>
      <Box sx={{ position: 'relative' }} />
      <StickyMenuStack>
        <div>
          {MENU_RESTAURANTES.map((elm: any) => (
            <OptionButton
              startIcon={elm.Icon ? <elm.Icon /> : null}
              key={elm.value}
              sx={{
                backgroundColor: (theme) => (menuNav === elm.value ? theme.palette.grey[200] : '#fff'),
                color: (theme) => (menuNav === elm.value ? theme.palette.grey[700] : theme.palette.grey[500])
              }}
              disableElevation
              variant="contained"
              onClick={() => navigationLinking(elm.value)}
            >
              {elm.text}
            </OptionButton>
          ))}
        </div>
        {menuNav === 'estadisticas' && window.innerWidth >= 890 && (
          <StatiticsSelect options={statiticsOptionsSelect} value={type} setter={setType} />
        )}
        {menuNav === 'platos' && (
          <Hidden smDown>
            <Box sx={{ width: 200, mr: 2 }}>
              <SearchInput placeholder={labels[lng].searchPlate} name={'search'} handleSubmit={handleSearch} clean />
            </Box>
          </Hidden>
        )}
        {menuNav === 'historial' && (
          <Hidden smDown>
            <Box sx={{ width: 200, mr: 2 }}>
              <SearchInput placeholder={labels[lng].searchPlate} name={'search'} handleSubmit={handleSearchHistorial} clean />
            </Box>
          </Hidden>
        )}
      </StickyMenuStack>
      {menuNav === 'platos' && (
        <Hidden smUp>
          <br />
          <Box sx={{ width: 200, mr: 2 }}>
            <SearchInput placeholder={labels[lng].searchPlate} name={'search'} handleSubmit={handleSearch} clean />
          </Box>
        </Hidden>
      )}
      {menuNav === 'historial' && (
        <Hidden smUp>
          <br />
          <Box sx={{ width: 200, mr: 2 }}>
            <SearchInput placeholder={labels[lng].searchPlate} name={'search'} handleSubmit={handleSearchHistorial} clean />
          </Box>
        </Hidden>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {menuNav === 'resumen' && <RestaurantesDetailResumen restaurant={restaurant!} photo={photo!} fetchData={fetchRestaurant} />}
          {menuNav === 'platos' && restaurant && <RestaurantesDetailPlatos platos={plates} fetchData={fetchRestaurant} />}
          {menuNav === 'pedidos' && restaurant && <Pedidos restaurantInfo={restaurant!} />}
          {menuNav === 'planificar' && restaurant && <Planificar restaurantInfo={restaurant!} />}
          {menuNav === 'ingresos' && restaurant && <Ingresos year={2022} month={2} restaurantInfo={restaurant!} />}
          {menuNav === 'estadisticas' && restaurant && (
            <RestauranteEstadisticas type={window.innerWidth >= 890 ? type : null} restaurantInfo={restaurant!} />
          )}
          {menuNav === 'historial' && restaurant && (
            <Historial
              setHistoryDBFilter={setHistoryDBFilter}
              historyDB={historyDBFilter}
              fetchHistorial={fetchHistorial}
              {...{ isEmpty, setIsEmpty, totalCount, setTotalCount, rowsPerPage, setRowsPerPage, loadingTable, pageRef, wordSearch }}
            />
          )}

          <SearchDialog
            onClose={handleClose}
            open={openDialog}
            optionsData={[]}
            setter={changeRestaurant}
            text={labels[lng].restaurant}
            searchBy="restaurant"
            selected={[restaurant?._id]}
            restaurantEmail
          />
          <QrDialog
            open={openQrDialog}
            handleClose={() => setOpenQrDialog(false)}
            title={labels[lng].qrDialogTitle}
            text={labels[lng].qrDialogText}
            name={restaurant?.name}
            qrCode={restaurant?.qrCode}
          />
        </>
      )}
    </>
  )
}
