import React from 'react'
import { Box, Typography } from '@mui/material'
import { CardSummaryContainer } from './cards.styles'
import { BankAccountType } from 'src/interfaces/pages.types'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'

interface CardBankAccountProps {
  bankAccount?: BankAccountType
}

export default function CardBankAccount({ bankAccount }: CardBankAccountProps): JSX.Element {
  if (!bankAccount) return <></>
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <CardSummaryContainer
      sx={{
        justifyContent: 'space-between',
        px: 2,
        py: 1,
        flexWrap: 'wrap',
        minHeight: 72,
        [theme.breakpoints.down('md')]: {
          py: 2,
          px: '16px'
        }
      }}
    >
      <Box
        sx={{
          mr: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            width: '100%'
          }
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            color: '#111827',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
              width: '100%'
            }
          }}
          variant="body2"
          component="strong"
        >
          {labels[lng]?.beneficiary}
        </Typography>
        <Typography variant="body1" component="strong">
          {bankAccount.holder || ''}
        </Typography>
      </Box>
      <Box
        sx={{
          mr: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            width: '100%',
            mt: 4
          }
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            color: '#111827',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
              width: '100%'
            }
          }}
          variant="body2"
          component="strong"
        >
          NIF/CIF
        </Typography>
        <Typography variant="body1" component="strong">
          {bankAccount.nif || ''}
        </Typography>
      </Box>
      <Box
        sx={{
          mr: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            width: '100%',
            mt: 4
          }
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            color: '#111827',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
              width: '100%'
            }
          }}
          variant="body2"
          component="strong"
        >
          BIC
        </Typography>
        <Typography variant="body1" component="strong">
          {bankAccount.bic || ''}
        </Typography>
      </Box>
      <Box
        sx={{
          mr: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            width: '100%',
            mt: 4
          }
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            color: '#111827',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
              width: '100%'
            }
          }}
          variant="body2"
          component="strong"
        >
          IBAN
        </Typography>
        <Typography variant="body1" component="strong">
          {bankAccount.iban || ''}
        </Typography>
      </Box>
    </CardSummaryContainer>
  )
}
