import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { GridFlexBetween, MainBox } from './form.styles'
import { Grid, Typography } from '@mui/material'
import { genderOptionsSelect } from 'src/constants/const'

interface Props {
  setData: React.Dispatch<React.SetStateAction<string>>
  data: string
  text: string
}

export const SelectGender = ({ setData, data, text }: Props): JSX.Element => {
  const handleChange = (e: SelectChangeEvent<string>) => {
    setData(e.target.value)
  }
  return (
    <MainBox>
      <GridFlexBetween container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="h6">
            {text}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl
            size="small"
            variant="outlined"
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              '& .MuiOutlinedInput-notchedOutline': { display: 'none' },
              '& .MuiSelect-select': {
                paddingLeft: '0 !important'
              }
            }}
          >
            <Select value={data} name="gender" onChange={handleChange}>
              {genderOptionsSelect.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </GridFlexBetween>
    </MainBox>
  )
}
