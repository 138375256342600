import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver'
import { downloadZip } from 'client-zip/index'
import { Divider, IconButton, LinearProgress, Skeleton, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'

import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { AvatarResumen, BoxGrey } from 'src/styles/general-styles'
import GruposAddEdit from './GruposAddEdit'
import { deleteRestaurantGroup, getGroupsById, deleteGroups } from 'src/services/groups.services'
import { deactiveRestaurants, activeRestaurants } from 'src/services/restaurants.services'
import NotFound from 'src/components/NotFound'

import TableApi from 'src/components/Table/TableApi'
import { Order } from 'src/interfaces/pages.types'
import { AuthContext } from 'src/context/auth/authContext'
import { deleteElementsGroup } from 'src/services/deleteElements'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import { useTranslation } from 'react-i18next'

export default function GruposDetail(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const params: any = useParams()
  const history = useHistory()

  const [error404, setError404] = useState(false)
  const [rowsData, setRowsData] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState<string[]>([])
  const [group, setGroup] = useState<any | null>(null)

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
  }

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].name,
      fieldtype: 'name-avatar'
    },
    {
      idField: 'location',
      align: 'left',
      disablePadding: true,
      label: labels[lng].address,
      fieldtype: 'location'
    },
    {
      idField: 'income',
      align: 'center',
      disablePadding: false,
      label: labels[lng].orders,
      fieldtype: 'orders'
    },
    {
      idField: 'income',
      align: 'center',
      disablePadding: false,
      label: labels[lng].incomes,
      fieldtype: 'money-group'
    },
    {
      idField: 'createdAt',
      align: 'center',
      disablePadding: true,
      order: true,
      label: labels[lng].registerDate,
      fieldtype: 'date-time'
    }
  ]

  // TABLE CONTROL
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const pageRef = useRef(0)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<string>('createdAt')

  const fetchGroup = async () => {
    setError404(false)
    try {
      const groupDetails = await getGroupsById(
        params.idGroup,
        rowsPerPage,
        pageRef.current,
        undefined,
        `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`
      )
      setGroup(groupDetails.group)
      setRowsData(groupDetails.group.group)
      setTotalCount(groupDetails.total)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
      setError404(true)
    } finally {
      setLoading(false)
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchGroup()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageRef.current = 0
  }

  useEffect(() => {
    if (params.idGroup) {
      fetchGroup()
    } else {
      setError404(true)
    }
    return () => setRowsData(null)
  }, [params.plato])

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between ">
        <div className="flex-center">
          <Link to={`/grupos`}>
            <IconButton sx={{ ml: '-14px' }}>
              <ChevronLeftIcon sx={{ color: grey[500] }} />
            </IconButton>
          </Link>
          <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
            {labels[lng].groups}
          </Typography>
        </div>
        <div className="flex-center">
          <Typography variant="subtitle1" component="h1">
            {group ? group.name : <Skeleton sx={{ width: 200 }} animation="wave" />}
          </Typography>
          {group?.status === 'DISABLED' && (
            <Typography variant="h3" component="h1" sx={{ color: '#9CA3AF' }}>
              &nbsp;
              {` (${labels[lng].deactivated})`}
            </Typography>
          )}
          {/* <IconButton onClick={() => setOpenDialog(true)}>
            <Selector />
          </IconButton> */}
        </div>
        <div />
      </div>
    )
  }

  useEffect(() => {
    if (userData) fetchGroup()
  }, [rowsPerPage, order, orderBy])

  useEffect(() => {
    showHeader(Header)
  }, [group])

  const onDelete = async (data: any) => {
    Swal.fire(
      deleteSwalConfig(
        labels[lng].restaurants,
        lng,
        `<h4>Estás a punto de sacar uno o varios restaurantes de este grupo, ¿estás seguro?</h4>`,
        'Sacar del grupo'
      )
    ).then((result) => {
      if (result.isConfirmed) {
        deleteElementsGroup(data, deleteRestaurantGroup, params.idGroup)
          .then(() => {
            showSnackbar('success', 'El restaurante ha sido sacado del grupo')
            fetchGroup()
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onDeactivate = async (data: Record<string, string>) => {
    const status = data.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    const label = data.status === 'ACTIVE' ? labels[lng].restaurantDeactivated : labels[lng].restaurantActivated
    try {
      if (status === 'INACTIVE') {
        await deactiveRestaurants([data._id])
      } else {
        await activeRestaurants([data._id])
      }
      showSnackbar('success', label)
      fetchGroup()
      closeModal()
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onDeactivateAll = async () => {
    if (selected.length === 0) return
    try {
      await deactiveRestaurants(selected)
      fetchGroup()
      setSelected([])
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onActivateAll = async () => {
    if (selected.length === 0) return
    try {
      await activeRestaurants(selected)
      fetchGroup()
      setSelected([])
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onDeleteRestaurant = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].groups, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteGroups(data)
          .then(() => {
            showSnackbar('success', labels[lng].groupDeleted)
            fetchGroup()
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onDownloadQr = async (selectedIds: string[]) => {
    const files: any = []
    selectedIds.map((restaurantId) => {
      const restaurantData = rowsData.find((restaurant: any) => restaurantId === restaurant._id)

      if (restaurantData.qrCode) {
        const convertBase64ToFile = (base64String: any, fileName: any) => {
          const arr = base64String.split(',')
          const mime = arr[0].match(/:(.*?);/)[1]
          const bstr = atob(arr[1])
          let n = bstr.length
          const uint8Array = new Uint8Array(n)
          while (n--) {
            uint8Array[n] = bstr.charCodeAt(n)
          }
          const file = new File([uint8Array], fileName, { type: mime })
          return file
        }

        const file = convertBase64ToFile(restaurantData.qrCode, restaurantData.name)
        files.push(file)
      }
      return ''
    })
    const content = await downloadZip(files).blob()
    saveAs(content, 'qr-codes.zip')
  }

  if (error404) return <NotFound link={`/grupos`} />
  return (
    <>
      {!group ? (
        <LinearProgress />
      ) : (
        <>
          <BoxGrey className="flex-between" sx={{ py: 1.5, px: 2 }}>
            <div className="flex-center">
              <AvatarResumen sx={{ ml: 1 }} src={group.photo} alt={group.name} />

              <Typography sx={{ ml: 2 }} variant="h2" component="h2">
                {group.name}
              </Typography>
            </div>
            <Button
              sx={{ backgroundColor: '#fff' }}
              size="small"
              type="submit"
              color="info"
              variant="outlined"
              onClick={() => setOpenModal(true)}
            >
              {labels[lng].editGroup}
            </Button>
          </BoxGrey>
          <Divider />
          <TableApi
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            rows={rowsData}
            headCells={headCells}
            snackBar
            loading={loading}
            totalCount={totalCount}
            handleChangePage={handleChangePage}
            page={pageRef.current}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            selected={selected}
            setSelected={setSelected}
            onDeactivate={onDeactivate}
            onDeactivateAll={onDeactivateAll}
            onActivateAll={onActivateAll}
            onDelete={onDelete}
            onExport={onDownloadQr}
            onRowClick={(row) => history.push(`/restaurantes/${row._id}`)}
            textDelete={labels[lng].delete_restaurant}
          />
          {openModal && (
            <GruposAddEdit
              openModal={openModal}
              onDelete={onDeleteRestaurant}
              closeModal={closeModal}
              dataToEdit={group}
              fetchData={fetchGroup}
            />
          )}
        </>
      )}
    </>
  )
}
