import React, { useContext } from 'react'
import { Box, Divider, IconButton, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { AppBar } from '../layout.styles'

import MenuIcon from '@mui/icons-material/Menu'
import { HeaderContext } from 'src/context/header/headerContext'

interface AppBarLayoutProps {
  open: boolean
  handleDrawer: () => void
  hideButton?: boolean
}

export default function AppBarLayout({ open, handleDrawer, hideButton = false }: AppBarLayoutProps): JSX.Element {
  const theme = useTheme()
  const { Header } = useContext(HeaderContext)
  return (
    <AppBar elevation={0} position="fixed" open={open}>
      <Toolbar>
        {!hideButton && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: 1,
              [theme.breakpoints.up('sm')]: {
                display: 'none'
              }
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box sx={{ width: '100%' }}>{Header && <Header />}</Box>
      </Toolbar>
      <Divider
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }}
      />
    </AppBar>
  )
}
