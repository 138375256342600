import React, { useContext, useEffect, useState, useRef } from 'react'
import { IconButton, LinearProgress, Skeleton, Typography, Box, Grid } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'
import { Link, useParams, useHistory } from 'react-router-dom'

import { SearchDialog } from 'src/components/Modals/SearchDialog'
import UsuariosDetailResumen from './components/Resumen'

import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { getUser } from 'src/services/users.services'

import { ReactComponent as Selector } from 'src/assets/icons/general/Selector.svg'
import { labels } from 'src/labels/main_labels'
import { UserTypes } from 'src/interfaces/pages.types'
import UsuariosDetailPedidos from './components/Pedidos'
import UsuariosDetailPlatosCaducados from './components/PlatosCaducados'
import UsuariosDetailPlatosRegalados from './components/PlatosRegalados'
import UsuariosDetailBonos from './components/Bonos'
import NotFound from 'src/components/NotFound'
import { StickyMenuStack } from 'src/styles/general-styles'
import { OptionButton } from 'src/components/Form/buttonSelector.styles'
import { useTranslation } from 'react-i18next'
// import { dateTimeConvert } from 'src/utils/time-helpers'

export default function UsuariosDetail(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)

  const MENU_USUARIOS = [
    { value: 'resumen', text: labels[lng].summary },
    { value: 'pedidos', text: labels[lng].orders },
    { value: 'bonos', text: labels[lng].vouchers },
    { value: 'regalados', text: labels[lng].giftPlates },
    { value: 'caducados', text: labels[lng].expiredPlates }
  ]

  const params: any = useParams()
  const history = useHistory()
  const [openDialog, setOpenDialog] = useState(false)
  const [menuNav, setMenuNav] = useState('resumen')
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<UserTypes | null>(null)
  const [error404, setError404] = useState(false)
  const avoidCallsRef = useRef(false)

  const navigationLinking = (value: string) => {
    history.push(`/usuarios/${params.idUser}/${value}`)
  }

  useEffect(() => {
    if (params.navUser && MENU_USUARIOS.map((elm) => elm.value)) setMenuNav(params.navUser)
    else setMenuNav('resumen')
  }, [params.navUser])

  const fetchUser = async () => {
    if (avoidCallsRef.current) return
    avoidCallsRef.current = true
    setError404(false)
    setLoading(true)
    try {
      const userDetails = await getUser(params.idUser)
      setUser(userDetails)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
      setError404(true)
    } finally {
      setLoading(false)
      avoidCallsRef.current = false
    }
  }

  useEffect(() => {
    if (params.idUser) {
      fetchUser()
    }
  }, [params.idUser])

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between ">
        <div className="flex-center">
          <Link to="/usuarios">
            <IconButton sx={{ ml: '-14px' }}>
              <ChevronLeftIcon sx={{ color: grey[500] }} />
            </IconButton>
          </Link>
          <Typography variant="subtitle2">{labels[lng].users}</Typography>
        </div>
        <Grid
          gap="2px"
          item
          xs={6}
          sm={4}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'flex-start', sm: 'center' } }}
        >
          <Typography variant="subtitle1" component="h1">
            {!user ? (
              <Skeleton sx={{ width: 200 }} animation="wave" />
            ) : (
              <>
                {user.name || ''} {user.surname || ''}
              </>
            )}
          </Typography>
          {user?.status === 'PRE_USER' && (
            <Typography variant="h3" component="h1" sx={{ color: '#9CA3AF' }}>
              {' '}
              {` (${labels[lng].preUser})`}
            </Typography>
          )}
          {user?.status === 'DELETED' && (
            <Typography variant="h3" component="h1" sx={{ color: '#9CA3AF' }}>
              {' '}
              {` (${labels[lng].deleted})`}
            </Typography>
          )}
          <IconButton onClick={() => setOpenDialog(true)}>
            <Selector />
          </IconButton>
        </Grid>
        <Box sx={{ width: '85px' }} />
      </div>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [user])

  // DIALOG
  const handleClose = () => {
    setOpenDialog(false)
  }

  const changeUser = (userProp: any) => {
    history.push(`/usuarios/${userProp._id}`)
    handleClose()
  }

  if (error404) return <NotFound link="/usuarios" />

  return (
    <>
      <StickyMenuStack>
        <div>
          {MENU_USUARIOS.map((elm: any) => (
            <OptionButton
              startIcon={elm.Icon ? <elm.Icon /> : null}
              key={elm.value}
              sx={{
                backgroundColor: (theme) => (menuNav === elm.value ? theme.palette.grey[200] : '#fff'),
                color: (theme) => (menuNav === elm.value ? theme.palette.grey[700] : theme.palette.grey[500])
              }}
              disableElevation
              variant="contained"
              onClick={() => navigationLinking(elm.value)}
            >
              {elm.text}
            </OptionButton>
          ))}
        </div>
      </StickyMenuStack>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {menuNav === 'resumen' && user && <UsuariosDetailResumen user={user} setUser={setUser} />}
          {menuNav === 'caducados' && user && <UsuariosDetailPlatosCaducados userId={user._id!} />}
          {menuNav === 'regalados' && user && <UsuariosDetailPlatosRegalados userId={user._id!} />}
          {menuNav === 'pedidos' && user && <UsuariosDetailPedidos userId={user._id!} />}
          {menuNav === 'bonos' && user && <UsuariosDetailBonos bonos={user.bonos} userId={user._id!} />}

          <SearchDialog
            onClose={handleClose}
            open={openDialog}
            optionsData={[]}
            setter={changeUser}
            text={labels[lng].user}
            searchBy="users"
            selected={[user?._id]}
          />
        </>
      )}
    </>
  )
}
