type lng = 'en' | 'es'
export interface FaqType {
  [key: lng | string]: Record<string, string | string[]>[]
}

export const faq: FaqType = {
  es: [
    {
      title: '¿Cuántos platos puedo ofrecer al día?',
      text: [
        'Cada día puedes ofrecer uno de los platos disponibles de tu restaurante, que encontrarás en la pestaña “Platos” de tu perfil. Además, tienes que elegir cuál es el número máximo de pedidos de ese plato que vas a aceptar para cada día.',
        'Recuerda que hay un mínimo de platos que tienes que ofrecer cada vez que programes un plato.',
        'Por último, ten en cuenta que cuantos más días ofrezcas tus platos, más impacto generarás en los usuarios de Fudclub.'
      ]
    },
    {
      title: '¿A partir de qué hora puedo recibir pedidos? ¿En qué horario pueden ser recogidos?',
      text: [
        'De manera general, puedes recibir pedidos a partir de las 8 de la tarde del día anterior para el que ofreces tus platos en Fudclub. Sin embargo, puedes contactar con Fudclub si quieres modificar esta hora.',
        'Tus clientes pueden recoger sus pedidos dentro del horario que tú selecciones cuando configures la oferta de tus platos en tu perfil de restaurante.'
      ]
    },
    {
      title: '¿Qué ocurre si un cliente no recoge su pedido en el horario de recogida de 15 minutos que ha seleccionado?',
      text: [
        'Si un cliente quiere recoger su pedido fuera del horario de recogida de 15 minutos que ha seleccionado, como restaurante no eres responsable del estado del pedido. Sin embargo, desde Fudclub te recomendamos que, si el restraso es razonablemente pequeño, el pedido siga disponible para su recogida y en buen estado incluso fuera del horario de recogida seleccionado por el cliente.',
        'En todo caso, eres responsable de entregar el pedido en perfecto estado durante el horario de recogida de 15 minutos que el cliente haya elegido.',
        'Finalmente, si un cliente te contacta para acordar un nuevo horario de recogida, en Fudclub no podremos hacernos responsables de los acuerdos que se lleven a cabo al margen de la aplicación.'
      ]
    },
    {
      title: '¿Tengo que vender un número mínimo de platos al día?',
      text: [
        'No, en Fudclub tan solo te recomendamos que ofrezcas un número mínimo de platos al día, tantos días como quieras, con el objetivo de aumentar tus ventas. '
      ]
    },
    {
      title: 'Si un cliente de Fudclub viene a recoger su pedido sin el móvil, ¿se lo puedo entregar?',
      text: [
        'Si un cliente no tiene el móvil disponible para escanear tu código QR al recoger su pedido, puedes entregarle el pedido siempre que compruebes su identidad y esta se corresponda con la del cliente que ha realizado el pedido.',
        'Desde la pestaña “Pedidos” de tu perfil, puedes entregar el pedido deslizando el botón “Deslizar para entregar” asociado al pedido. De esta manera, el cliente confirmará la entrega del pedido la próxima vez que abra la aplicación.'
      ]
    },
    {
      title: '¿Un cliente de Fudclub me puede devolver un pedido?',
      text: [
        'No, un cliente no puede devolverte un pedido ya recogido. Sin embargo, si el pedido recogido no cumple unos mínimos estándares de calidad o si el pedido no se corresponde con la descripción mostrada a través de la aplicación, el cliente podrá transmitir dicha queja directamente a ti y a Fudclub.'
      ]
    },
    {
      title: '¿Qué hago si un cliente quiere disfrutar el pedido de Fudclub en mi restaurante?',
      text: ['Como restaurante, tú decides si los clientes de Fudclub pueden consumir tus platos de la aplicación en tu local o no.']
    },
    {
      title: 'Si un cliente cancela un pedido de Fudclub, ¿recibiría el importe del pedido?',
      text: [
        'Un cliente puede cancelar un pedido hasta la hora indicada en nuestra Política de cancelación sin perjuicio para el propio cliente, es decir, como restaurante, en este caso no cobrarías el importe del pedido cancelado.',
        'Por el contrario, si un cliente cancela un pedido después de la hora indicada en nuestra Política de cancelación, como restaurante cobrarás el importe íntegro de pedido cancelado como si hubiera sido recogido por el cliente.'
      ]
    },
    {
      title: '¿Cada cuánto tiempo recibo el importe de los platos vendidos?',
      text: [
        'Te abonaremos el importe de los platos vendidos cada 15 días, a comienzos y a mediados de cada mes, en la cuenta bancaria que se muestre en la pestaña “Información” de tu perfil.'
      ]
    },
    {
      title: '¿Tengo que pagar por estar en Fudclub?',
      text: [
        'No, el uso de la plataforma de Fudclub es totalmente gratuito. Tan solo tendrás que tener listos los pedidos que hagan tus clientes a través de la aplicación de Fudclub a la hora que hayan seleccionado.'
      ]
    },
    {
      title: '¿Dónde tengo que situar mi código QR de Fudclub?',
      text: [
        'Tienes que situar el código QR en algún lugar visible de tu restaurante y accesible para tus clientes. De esta manera el proceso de recogida de tus platos de Fudclub será más fácil y fluido.'
      ]
    },
    {
      title: '¿Cómo añado más platos a mi lista de platos de Fudclub?',
      text: [
        'Si quieres añadir más platos a tu lista, ponte en contacto con Fudclub y coordinaremos rápidamente todo lo necesario para subir más platos a tu perfil de restaurante. Todos los platos de la plataforma son añadidos por Fudclub.'
      ]
    },
    {
      title: '¿Cómo puedo planificar los platos que quiero ofrecer durante la semana?',
      text: [
        'Desde la pestaña “Planificar” de tu perfil, puedes programar tus platos para cada día de la semana. Así, siempre tendrás una semana tipo planificada que puedes ir editando a tu gusto sin preocuparte por tener que programar tus platos día a día.'
      ]
    },
    {
      title: '¿Qué días del calendario está disponible Fudclub?',
      text: [
        'Fudclub está disponible todos los días del año. Por eso, puedes ofrecer tus platos todos los días del año, incluidos los siguientes días festivos nacionales: Año Nuevo, Viernes Santo o Pascua, Fiesta del Trabajo, Asunción de la Virgen María, Fiesta Nacional de España o Día de la Hispanidad, Todos los Santos, Día de la Constitución, La Inmaculada Concepción y Navidad del Señor, según el calendario de festividades nacional.'
      ]
    }
  ],
  en: [
    {
      title: 'How many dishes can I offer each day?',
      text: [
        'Each day you can offer one of the available dishes of your restaurant, which you will find in the "Dishes" tab of your profile. In addition, you have to choose the maximum number of orders of that dish that you will accept each day.',
        'Remember there is a minimum number of dishes that you have to offer each time you schedule a dish.',
        'Finally, note that the more days you offer your dishes, the more impact you will have on Fudclub users.'
      ]
    },
    {
      title: 'From what time can I receive orders? When can they be picked up?',
      text: [
        'In general, you can receive orders from 8 pm the day before. However, you can contact Fudclub if you want to modify this schedule.',
        'Your customers can pick up their orders within the schedule that you select when you plan your dishes in your profile.'
      ]
    },
    {
      title: 'What happens if a customer doesn’t pick up their order within the selected 15 minute pickup window?',
      text: [
        'If a customer wants to pick up their order outside of the selected 15 minute pickup window, as a restaurant you won’t be responsible for the condition of the order. However, Fudclub recommends that, if there is a slight delay, the order is still available for pickup and in good condition even outside of the 15 minute pickup window selected by the customer.',
        'In any case, you are responsible for releasing the orders in perfect condition during the 15 minute pickup window selected by the customer.',
        'Finally, if a customer contacts you to arrange a new pickup time, Fudclub won’t be responsible for any arrangements made outside of the app.'
      ]
    },
    {
      title: 'Do I need to sell a minimum number of dishes each day?',
      text: [
        'No, we just recommend that you offer a minimum number of dishes each day, as many days as you want, in order to increase your sales.'
      ]
    },
    {
      title: 'If a Fudclub customer wants to pick up their order without using their phone, can I release it?',
      text: [
        "If a customer doesn't have their phone available to scan your QR code when picking up an order, you can release the order as long as you verify the identity and it matches the identity of the customer who placed the order.",
        'You can release an order from the "Orders" tab of your profile by swiping the "Swipe to release" button associated with the order. This way, the customer will confirm the release of the order next time they open the app.'
      ]
    },
    {
      title: 'Can a Fudclub customer return an order?',
      text: [
        "No, a customer can't return an order that has  already been picked up. However, if the picked up order doesn’t meet minimum quality standards or if the order doesn’t match the description shown on the app, the customer may report such complaint directly to you and to Fudclub."
      ]
    },
    {
      title: 'What should I do if a customer wants to enjoy a Fudclub order in my restaurant?',
      text: [
        'As a restaurant, you can decide whether Fudclub customers can enjoy your app dishes in your premises or not, at your own discretion.'
      ]
    },
    {
      title: 'If a customer cancels a Fudclub order, will I still get paid?',
      text: [
        'A customer can cancel an order without any detrimental effects to themselves until the time shown in our Cancellation policy, that is, as a restaurant, in this case you wouldn’t get paid for the cancelled order.',
        'However, if a customer cancels an order later, as a restaurant you will get fully paid for the cancelled order as if it had been picked up by the customer.'
      ]
    },
    {
      title: 'How often do I get paid for my sold dishes?',
      text: [
        'We will pay you for your sold dishes every 15 days, at the beginning and in the middle of each month, to the bank account shown in the "Information" tab of your profile.'
      ]
    },
    {
      title: 'Do I have to pay to be in Fudclub?',
      text: [
        'No, the use of Fudclub’s platform is totally free. You will only need to have the orders your customers place on the app ready at the time they have selected.'
      ]
    },
    {
      title: 'Where should I locate my Fudclub QR code?',
      text: [
        'You have to place your Fudclub QR code in a visible space in your restaurant and accessible to your customers. This will make the process of picking up your Fudclub dishes easier and smoother.'
      ]
    },
    {
      title: 'How can I add more dishes to my list of Fudclub dishes?',
      text: [
        'If you want to add more dishes to your list, contact Fudclub and we will quickly coordinate everything to upload more dishes to your restaurant profile. All dishes on the platform are added by Fudclub.'
      ]
    },
    {
      title: 'How can I schedule the dishes I want to offer during the week?',
      text: [
        'You can plan your dishes for each day of the week from the "Schedule" tab of your profile. This way, you will always have a planned week that you can edit as you wish, without worrying about having to schedule your dishes every day.'
      ]
    },
    {
      title: 'Which days of the calendar is Fudclub available?',
      text: [
        "Fudclub is available every day of the year. That’s why you can offer your dishes every day of the year, including the following national holidays: New Year's Day, Good Friday or Easter, Labor Day, Assumption of the Virgin Mary, Spanish National Holiday or Columbus Day, All Saints Day, Constitution Day, Immaculate Conception and Christmas Day, according to the national holiday calendar."
      ]
    }
  ]
}
