import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderContext } from 'src/context/header/headerContext'
import { theme } from 'src/styles/theme'
import { labels } from 'src/labels/main_labels'
import { ContainerFud } from 'src/styles/general-styles'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Language } from 'src/assets/icons/general/Language.svg'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { ReactComponent as Logout } from 'src/assets/icons/general/Logout.svg'
import { AuthContext } from 'src/context/auth/authContext'
// import { RestaurantContext } from "src/context/restaurants/restaurantContext"
import Swal from 'sweetalert2'
import { logoutSwalConfig } from 'src/utils/swal.utils'

export default function More(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const history = useHistory()
  const { logout } = useContext(AuthContext)
  // const { fetchRestaurantById } = useContext(RestaurantContext)

  // const changeRestaurant = (id: string) => {
  //   fetchRestaurantById(id)
  //   history.push('/pedidos');
  // }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language

    return (
      <div className="flex-center" style={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: '-2px',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.push('/mas')} />
        </Box>
        <Typography
          variant="h3"
          component="h1"
          textAlign={'center'}
          sx={{
            [theme.breakpoints.down('md')]: {
              lineHeight: '20px'
            },
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          {labels[lng].settings}
        </Typography>
      </div>
    )
  }
  useEffect(() => {
    showHeader(Header)
    window.scrollTo(0, 0)
  }, [])

  const onLogout = () => {
    Swal.fire(logoutSwalConfig(lng)).then((result: any) => {
      if (result.isConfirmed) {
        logout()
      }
    })
  }

  return (
    <ContainerFud>
      <Box
        onClick={() => history.push('/idioma')}
        sx={{
          p: '16px',
          border: '1px solid #E5E7EB',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          display={'flex'}
          alignItems="center"
          gap="14px"
          sx={{
            '& svg': {
              width: '20px',
              height: '20px'
            }
          }}
        >
          <Language />
          <Typography variant="h3" fontSize="14px" lineHeight="14px" color="#6B7280" fontWeight={500}>
            {labels[lng].language}
          </Typography>
        </Box>
        <ChevronRight sx={{ color: '#9CA3AF', fill: '#9CA3AF' }} />
      </Box>
      <Box
        onClick={() => onLogout()}
        sx={{
          p: '16px',
          border: '1px solid #E5E7EB',
          borderRadius: '8px',
          mt: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          display={'flex'}
          alignItems="center"
          gap="14px"
          sx={{
            '& svg': {
              width: '20px',
              height: '20px'
            }
          }}
        >
          <Logout />
          <Typography variant="h3" fontSize="14px" lineHeight="14px" color="#6B7280" fontWeight={500}>
            {labels[lng].logout}
          </Typography>
        </Box>
        <ChevronRight sx={{ color: '#9CA3AF', fill: '#9CA3AF' }} />
      </Box>
    </ContainerFud>
  )
}
