import React from 'react'
import FormTitle from 'src/components/Form/FormTitle'
import { labels } from 'src/labels/main_labels'
import HistoryRow from 'src/components/HistoryRow'
import { useTranslation } from 'react-i18next'

interface Props {
  history: any
  code: string
}

export default function ParametrosHistorial({ history, code }: Props): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const lastValue = (row: any) => {
    if (typeof row.lastValue === 'string' || typeof row.lastValue === 'number') {
      if (code === 'PP') {
        return row?.lastValue.toFixed(2).replace('.', ',')
      }
      return row.lastValue
    }
    if (row.lastValue && row.lastValue.name) return row.lastValue.name
    return ''
  }

  const getSymbol = () => {
    if (['PCDI', 'PCDR', 'PCPDR'].includes(code)) return ' %'
    if (['TPFR', 'TPVC'].includes(code)) return ` ${labels[lng].minutes}`
    if (code === 'PP') return ' €'
    else return ''
  }

  const displayRows = () => {
    return history
      ?.sort((row1: any, row2: any) => new Date(row2.createdAt).getTime() - new Date(row1.createdAt).getTime())
      ?.map((row: any, idx: number) => {
        return <HistoryRow date={row.updatedAt} lastValue={lastValue(row)} symbol={getSymbol()} key={`history-row-${idx}`} />
      })
  }

  return (
    <>
      <FormTitle text={labels[lng].history} />
      <div style={{ maxHeight: '192px', overflowY: 'scroll' }}>{displayRows()}</div>
    </>
  )
}
