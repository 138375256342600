import React, { useContext } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { ReactComponent as PlatosVendidos } from 'src/assets/icons/restaurante-resumen/PlatosVendidosGreen.svg'
import { ReactComponent as Gastos } from 'src/assets/icons/restaurante-resumen/GastosGreen.svg'
import { ReactComponent as DiasDisponibles } from 'src/assets/icons/restaurante-resumen/DiasDisponiblesGreen.svg'
import { ReactComponent as DD } from 'src/assets/icons/restaurante-resumen/DDGreen.svg'
import { ReactComponent as DL } from 'src/assets/icons/restaurante-resumen/DLGreen.svg'
import { ReactComponent as Descripcion } from 'src/assets/icons/resumen-icons/Descripcion.svg'
import { ReactComponent as Estilos } from 'src/assets/icons/resumen-icons/Estilos.svg'
import { ReactComponent as TiposCocina } from 'src/assets/icons/resumen-icons/TiposCocina.svg'
import { ReactComponent as Ingredientes } from 'src/assets/icons/resumen-icons/Ingredientes.svg'
import { ReactComponent as Alergenos } from 'src/assets/icons/resumen-icons/Alergenos.svg'
import { ReactComponent as CalidadRed } from 'src/assets/icons/resumen-icons/CalidadRed.svg'
import { ReactComponent as CantidadRed } from 'src/assets/icons/resumen-icons/CantidadRed.svg'
import { ReactComponent as MeGustaRed } from 'src/assets/icons/resumen-icons/MeGustaRed.svg'
import { ReactComponent as Euro } from 'src/assets/icons/resumen-icons/Euro.svg'
import { ReactComponent as LikeRed } from 'src/assets/icons/general/LikeRed.svg'

import CardSummarySmall from 'src/components/Cards/CardSummarySmall'
import CardBigIconTitleText from 'src/components/Cards/CardBigIconTitleText'
import ImageCarrousel from 'src/components/ImageCarrousel'
import BannerRecommendedPlate from 'src/components/BannerRecommendedPlate'
import { PlateTypes } from 'src/interfaces/pages.types'
import { labels } from 'src/labels/main_labels'
import { ContainerFud } from 'src/styles/general-styles'
import { AuthContext } from 'src/context/auth/authContext'
import { allergensLabels } from 'src/labels/allergens_labels'
import { useTranslation } from 'react-i18next'
import { styleFoodLabels } from 'src/labels/styleFood_labels'
import { theme } from 'src/styles/theme'
import { ChevronRight } from '@mui/icons-material'

interface ResumenProps {
  plate: PlateTypes
  onClickSeeMore?: () => void
  onClickSeeMoreRatings?: () => void
}

export default function Resumen({ plate, onClickSeeMore, onClickSeeMoreRatings }: ResumenProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { reviews, parameter, favorites, images, description, income, ingredients, foodStyles, allergens, translations } = plate

  const showAllergens = () => {
    const fullNameAllergens = allergens.map((allergen) => {
      return allergensLabels[lng][allergen]
    })
    if (fullNameAllergens.length > 0) {
      if (fullNameAllergens[0] === allergensLabels[lng].IND) {
        return labels[lng].noInfoAllergens
      }
      return fullNameAllergens.join(', ')
    } else return labels[lng].noAllergens
  }

  const showStyles = () => {
    if (foodStyles) {
      const fullNameStyles = foodStyles.map((foodStyle: any) => {
        return lng === 'es' ? foodStyle.name : foodStyle.translations.find(({ language }) => language === lng).name
      })
      if (fullNameStyles.length > 0) return fullNameStyles.join(', ')
      else return '-'
    }
  }
  const showCousines = () => {
    if (foodStyles) {
      const fullNameStyles = foodStyles
        .map((foodStyle: any) => {
          return foodStyle.cousines.map((cousines: string) => styleFoodLabels[lng][cousines])
        })
        .flat()
      if (fullNameStyles.length > 0) return [...new Set(fullNameStyles)].join(', ')
      else return '-'
    }
  }

  const showIngredients = () => {
    if (ingredients) {
      const fullNameIngredients = ingredients.map((ingredient: any) => {
        return lng === 'es' ? ingredient.name : ingredient.translations.find(({ language }) => language === lng).name
      })
      if (fullNameIngredients.length > 0) return fullNameIngredients.join(', ')
      else return '-'
    }
  }

  const getPlateName = (plate) => {
    return lng === 'en' ? (plate?.translations && plate?.translations[0] ? plate?.translations[0].name : plate.name) : plate.name
  }

  const qualityPercetage =
    reviews?.size / reviews?.quality
      ? `${(reviews?.quality ? (reviews?.quality * 100) / reviews?.size : 0).toFixed(0)}%`
      : labels[lng].noRatings

  const amountPercetage =
    reviews?.size / reviews?.amount
      ? `${(reviews?.amount ? (reviews?.amount * 100) / reviews?.size : 0).toFixed(0)}%`
      : labels[lng].noRatings

  return (
    <>
      <ContainerFud
        sx={{
          [theme.breakpoints.down('md')]: {
            padding: 0
          }
        }}
      >
        {images && (
          <ImageCarrousel recommend={parameter?.RFC.value ? labels[lng].recommend : null} images={images} title={getPlateName(plate)} />
        )}
        <ContainerFud
          sx={{
            [theme.breakpoints.up('md')]: {
              padding: 0
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: 0
            }
          }}
        >
          {parameter?.RFC.value && (
            <Box
              sx={{
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              }}
            >
              <BannerRecommendedPlate />
            </Box>
          )}
          <Box
            sx={{
              mt: '24px',
              [theme.breakpoints.up('md')]: {
                display: 'none'
              }
            }}
          >
            <Typography fontSize={'16px'} lineHeight="20px" variant="h3" color="#111827">
              {getPlateName(plate)}
            </Typography>
          </Box>
          <Grid
            sx={{
              marginTop: parameter?.RFC.value ? '-24px !important' : '-14px !important',
              [theme.breakpoints.down('md')]: {
                marginTop: '0 !important'
              }
            }}
            container
            rowSpacing={{ xs: 2, sm: 2, md: 3 }}
            columnSpacing={{ xs: 2, sm: 2, md: 3 }}
          >
            <Grid item sm={6} xs={6} lg={4}>
              <CardSummarySmall Icon={CalidadRed} title={labels[lng].quality}>
                <div className="flex">
                  {reviews?.quality ? <LikeRed className="likeRed" /> : ''}
                  {reviews?.quality ? (
                    <Typography
                      sx={{
                        ml: reviews?.quality ? 1 : 0,
                        color: '#111827',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '18px',
                          fontWeight: 600,
                          lineHeight: '22px'
                        }
                      }}
                      variant="h4"
                      component="strong"
                    >
                      {reviews?.quality || ''}
                    </Typography>
                  ) : null}
                  <Typography
                    sx={{
                      ml: reviews?.quality ? 1 : 0,
                      fontWeight: 600,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '12px',
                        lineHeight: '16px'
                      }
                    }}
                    variant="body1"
                    component="strong"
                    color="grey.400"
                  >
                    {qualityPercetage}
                  </Typography>
                </div>
              </CardSummarySmall>
            </Grid>
            <Grid item sm={6} xs={6} lg={4}>
              <CardSummarySmall Icon={CantidadRed} title={labels[lng].quantity}>
                <div className="flex">
                  {reviews?.amount ? <LikeRed className="likeRed" /> : ''}
                  {reviews?.amount ? (
                    <Typography
                      sx={{
                        ml: reviews?.amount ? 1 : 0,
                        color: '#111827',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '18px',
                          fontWeight: 600,
                          lineHeight: '22px'
                        }
                      }}
                      variant="h4"
                      component="strong"
                    >
                      {reviews?.amount || ''}
                    </Typography>
                  ) : null}
                  <Typography
                    sx={{
                      ml: reviews?.quality ? 1 : 0,
                      fontWeight: 600,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '12px',
                        lineHeight: '16px'
                      }
                    }}
                    variant="body1"
                    component="strong"
                    color="grey.400"
                  >
                    {amountPercetage}
                  </Typography>
                </div>
              </CardSummarySmall>
            </Grid>
            <Grid item sm={12} xs={6} lg={4}>
              <CardSummarySmall Icon={MeGustaRed} title={labels[lng].likes} text={favorites || '0'} />
            </Grid>
          </Grid>
          <Box
            onClick={() => onClickSeeMoreRatings && onClickSeeMoreRatings()}
            sx={{
              [theme.breakpoints.up('md')]: {
                display: 'none'
              },
              p: '16px',
              border: '1px solid #E5E7EB',
              borderRadius: '8px',
              mt: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h3" fontSize="14px" lineHeight="14px" color="#6B7280" fontWeight={500}>
              {labels[lng].seeMoreRatings}
            </Typography>
            <ChevronRight sx={{ color: '#9CA3AF', fill: '#9CA3AF' }} />
          </Box>
          <Typography
            sx={{
              mb: 1,
              mt: 4,
              [theme.breakpoints.down('md')]: {
                mt: 3
              }
            }}
            variant="body1"
          >
            {labels[lng].statistics}
          </Typography>
          <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
            <Grid item sm={6} xs={6} lg={3}>
              <CardSummarySmall
                Icon={PlatosVendidos}
                title={income?.platesSolds === 1 ? labels[lng].platesSoldSingular : labels[lng].platesSold}
                text={income?.platesSolds || '0'}
              />
            </Grid>
            <Grid item sm={6} xs={6} lg={3}>
              <CardSummarySmall
                Icon={Gastos}
                title={labels[lng].incomes}
                text={`${income?.total ? Number(income.total).toFixed(2).replace('.', ',') : '0,00'} €`}
              />
            </Grid>
            <Grid item sm={6} xs={6} lg={3}>
              <CardSummarySmall
                Icon={DiasDisponibles}
                title={income?.days.length === 1 ? labels[lng].availableDaysSingular : labels[lng].availableDays}
                text={income?.days.length || '0'}
              />
            </Grid>
            <Grid item sm={6} xs={6} lg={3}>
              <CardSummarySmall
                Icon={lng === 'es' ? DD : DL}
                title={window.innerWidth < 890 ? labels[lng].averageDayAvailableShort : labels[lng].averageDayAvailable}
                text={income?.mediaDay?.toFixed(1).replace('.', ',') || '--'}
                finalText={labels[lng].plates}
              />
            </Grid>
          </Grid>
          <Box
            onClick={() => onClickSeeMore && onClickSeeMore()}
            sx={{
              [theme.breakpoints.up('md')]: {
                display: 'none'
              },
              p: '16px',
              border: '1px solid #E5E7EB',
              borderRadius: '8px',
              mt: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h3" fontSize="14px" lineHeight="14px" color="#6B7280" fontWeight={500}>
              {labels[lng].seeMoreStatitics}
            </Typography>
            <ChevronRight sx={{ color: '#9CA3AF', fill: '#9CA3AF' }} />
          </Box>
          <Typography
            sx={{
              mb: 1,
              mt: 4,
              [theme.breakpoints.down('md')]: {
                mt: 3
              }
            }}
            variant="body1"
          >
            {labels[lng].information}
          </Typography>
          <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
            <Grid item sm={6} xs={12} lg={userData.role === 'admin' ? 4 : 6}>
              <CardBigIconTitleText
                Icon={Descripcion}
                title={labels[lng].description}
                text={lng === 'es' ? description : translations?.find(({ language }) => language === lng)?.description || ''}
              />
            </Grid>
            <Grid item sm={6} xs={12} lg={userData.role === 'admin' ? 4 : 6}>
              <CardBigIconTitleText Icon={Alergenos} title={labels[lng].allergens} text={allergens && showAllergens()} />
            </Grid>
            {userData.role === 'admin' && (
              <Grid item sm={6} xs={12} lg={4}>
                <CardBigIconTitleText Icon={Ingredientes} title={labels[lng].ingredients} text={ingredients ? showIngredients() : '-'} />
              </Grid>
            )}
            <Grid item sm={6} xs={12}>
              <CardBigIconTitleText Icon={Estilos} title={labels[lng].styles} text={foodStyles && showStyles()} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <CardBigIconTitleText Icon={TiposCocina} title={labels[lng].foodTypes} text={foodStyles && showCousines()} />
            </Grid>
          </Grid>

          {userData && userData.role === 'admin' && (
            <>
              <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
                {labels[lng].parameters}
              </Typography>
              <CardBigIconTitleText
                Icon={Euro}
                title={labels[lng].platePricePP}
                text={`${parameter?.PP.value ? Number(parameter.PP.value).toFixed(2).replace('.', ',') : '0,00'} €`}
                finalText={parameter?.PP?.default ? `(${labels[lng].byDefault})` : ''}
              />
            </>
          )}
        </ContainerFud>
      </ContainerFud>
    </>
  )
}
