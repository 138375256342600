import React, { useState, useContext, useEffect } from 'react'
import { AlertContext } from 'src/context/alert/alertContext'
import ImagePicker from 'src/components/ImagePicker'
import FormTitle from 'src/components/Form/FormTitle'
import TextInput from 'src/components/Form/TextInput'
import Box from '@mui/material/Box'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from 'src/interfaces/pages.types'
import { createIngredient, updateIngredient } from 'src/services/ingredients.services'
import { useForm } from 'src/hooks/useForm'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

const INITIAL_VALUES = { name: '' }

export default function IngredientesAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const [loading, setLoading] = useState(false)
  const { handleChange, values, translations, setPhoto, photo, resetForm } = useForm(INITIAL_VALUES, dataToEdit, ['name'])

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setPhoto(null)
  }

  useEffect(() => {
    return () => resetComponent()
  }, [dataToEdit])

  const onSave = async () => {
    setLoading(true)
    const newIngredient = {
      ...values,
      translations
    }
    newIngredient.photo = photo

    if (!dataToEdit) {
      try {
        await createIngredient(newIngredient)
        showSnackbar('success', labels[lng].ingredientCreated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    } else {
      try {
        delete newIngredient.createdAt
        delete newIngredient.updatedAt
        delete newIngredient.numberOfPlates
        await updateIngredient(newIngredient, dataToEdit._id)
        showSnackbar('success', labels[lng].ingredientUpdated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit._id)
  }

  return (
    <Modal
      title={editing ? dataToEdit.name : labels[lng].addIngredient}
      open={openModal}
      handleClose={() => {
        resetComponent()
        closeModal()
      }}
      footer={
        <FooterForm
          text={labels[lng].ingredient}
          closeModal={() => {
            resetComponent()
            closeModal()
          }}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          // FORM VALIDATION
          disabled={!values?.name || !translations[0]?.name}
        />
      }
    >
      <Box id="form-ingredientes" component="form" noValidate>
        <ImagePicker setImgData={setPhoto} imgData={values.photo} actualImage={editing ? values.photo : null} />
        <FormTitle text={labels[lng].information} />

        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          text={`${labels[lng].nameIngredient} (ES)`}
          placeholder={`${labels[lng].nameIngredient} (ES)`}
        />

        {translations &&
          translations.map((opt: { language: string; name: string | number }, idx: number) => (
            <TextInput
              required
              key={`${opt.language}-${idx}`}
              value={opt.name}
              handleChange={(e) => handleChange(e, opt)}
              name="name"
              text={`${labels[lng].nameIngredient} (${opt.language.toUpperCase()})`}
              placeholder={`${labels[lng].nameIngredient} (${opt.language.toUpperCase()})`}
            />
          ))}
      </Box>
    </Modal>
  )
}
