import React, { useContext, useEffect, useState } from 'react'
import { HeaderContext } from 'src/context/header/headerContext'
import { labels } from 'src/labels/main_labels'
import { Grid, LinearProgress, Typography } from '@mui/material'

import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { getPlanning, getRecursivePlanning } from 'src/services/plan.services'

import PlanificarAddEdit from './PlanificarAddEdit'
import CardPlanDish from 'src/components/Cards/CardPlanDish'
import CardPlanDishAddNew from 'src/components/Cards/CardPlanDish-AddNew'
import { PlanningRecursiveTypes, PlanningTypes, RestaurantTypes } from 'src/interfaces/pages.types'
import { HeadBanner } from './planificar.styles'
import { ContainerFud } from 'src/styles/general-styles'
import { configWeekDays, getWeekLabel } from './planificar.helper'
import { getEMHIRR, parameterToTime } from 'src/utils/time-helpers'

import { ReactComponent as Sun } from 'src/assets/icons/smalls/Sun.svg'
import { useTranslation } from 'react-i18next'
import { addDays, subHours } from 'date-fns'
import { theme } from 'src/styles/theme'

interface PlanificarProps {
  restaurantInfo: RestaurantTypes
}

export default function Planificar({ restaurantInfo }: PlanificarProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const { userData } = useContext(AuthContext)

  const { restaurant } = useContext(RestaurantContext)
  const [planning, setPlanning] = useState<PlanningTypes[] | null>(null)
  const [loading, setLoading] = useState(false)

  const [recursivePlanning, setRecursivePlanning] = useState<PlanningRecursiveTypes[] | null>(null)
  const [restaurantData, setRestaurantData] = useState<RestaurantTypes | null>(null)

  useEffect(() => {
    if (restaurantInfo) setRestaurantData(restaurantInfo)
    else setRestaurantData(restaurant)
    return () => setRestaurantData(null)
  }, [restaurant])

  const [editData, setEditData] = useState<any | null>(null)

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-center">
        <Typography variant="h3" component="h1">
          {labels[lng].toPlan}
        </Typography>
      </div>
    )
  }
  useEffect(() => {
    if (!restaurantInfo) showHeader(Header)
  }, [])

  const fetchAllPlanning = () => {
    if (!restaurantData) return
    setLoading(true)

    Promise.all([getPlanning(restaurantData._id!), getRecursivePlanning(restaurantData._id!)])
      .then((resp) => {
        setPlanning(resp[0])
        setRecursivePlanning(resp[1])
        setLoading(false)
      })
      .catch((error) => {
        if (error instanceof Error) showSnackbar('error', error.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (restaurantData) fetchAllPlanning()
  }, [restaurantData])

  const onEdit = (dayWeek: string, whatDayIs: string | null, idx: number, recursivePlan?: PlanningRecursiveTypes, plan?: PlanningTypes) => {
    if (userData.role === 'admin') return
    setEditData({ plan, dayWeek, whatDayIs, recursivePlan, idx })
    setOpenModal(true)
  }

  const getRecursivePlan = (day: string) => {
    return recursivePlanning?.find((elm) => elm.dayWeek === day)
  }

  const getTitleHead = () => {
    if (restaurantData) {
      const FRR = restaurantData?.parameter?.FRR
      const EMHIRR = restaurantData?.parameter?.EMHIRR
      const date = new Date()
      // const date = new Date(2022, 9, 19, 21, 0)
      const EMHIRRi = subHours(getEMHIRR(EMHIRR, FRR, date), Math.abs(new Date().getTimezoneOffset() / 60))
      if (EMHIRRi.getDate() === date.getDate()) {
        if (EMHIRRi > date) {
          return `${labels[lng].canModifyPlanToday} ${parameterToTime(EMHIRR)}`
        }
        return `${labels[lng].cantModifyHeadToday} ${parameterToTime(EMHIRR)}`
      } else if (addDays(EMHIRRi, 1) > date) {
        return `${labels[lng].canModifyPlan} ${parameterToTime(EMHIRR)}`
      }
      return labels[lng].cantModifyHeadTomorrow
    }
    return ''
  }

  return (
    <>
      {loading && <LinearProgress />}
      {planning && (
        <ContainerFud>
          <HeadBanner>
            <Sun />
            {restaurantData && restaurantData.parameter && (
              <Typography
                variant="subtitle2"
                component="h6"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'left'
                  }
                }}
              >
                {getTitleHead()}
              </Typography>
            )}
          </HeadBanner>
          {/* {(loading || !restaurantData) && <SkeletonCards />} */}

          <Grid sx={{ flexWrap: 'wrap' }} container rowSpacing={{ xs: 4, sm: 4, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {configWeekDays().map((day: string, idx: number) => {
              const plan = planning.find((dayPlan) => dayPlan.dayWeek === day)
              return (
                <Grid key={day} item xs={12} sm={6} md={4} lg={3}>
                  {plan && restaurantData ? (
                    <CardPlanDish
                      restaurant={restaurantData}
                      plan={plan}
                      dayWeek={getWeekLabel(day, lng, idx)}
                      whatDayIs={idx === 0 ? 'today' : idx === 1 ? 'tomorrow' : null}
                      handleCard={onEdit}
                      idx={idx}
                      recursivePlanning={getRecursivePlan(day)}
                    />
                  ) : (
                    <CardPlanDishAddNew
                      dayWeek={getWeekLabel(day, lng, idx)}
                      whatDayIs={idx === 0 ? 'today' : idx === 1 ? 'tomorrow' : null}
                      text={labels[lng].addPlate}
                      handleCard={onEdit}
                      idx={idx}
                      recursivePlanning={getRecursivePlan(day)}
                    />
                  )}
                </Grid>
              )
            })}
          </Grid>
        </ContainerFud>
      )}
      {openModal && (
        <PlanificarAddEdit
          closeModal={closeModal}
          openModal={openModal}
          dataToEdit={editData}
          // recursivePlanning={getRecursivePlan(day)}
          fetchData={fetchAllPlanning}
        />
      )}
    </>
  )
}
