import axios from 'axios'
import firebase from 'src/firebase/firebase'

const axiosInstanceImages = axios.create({
  baseURL: `${process.env.REACT_APP_URLAPI}`,
  headers: {
    'Content-Type': 'multipart/form-data'
  },

  withCredentials: true
})

axiosInstanceImages.interceptors.request.use(async (config) => {
  const token = await firebase?.auth?.currentUser?.getIdToken()
  if (config && config.headers && token) config.headers.Authorization = 'Bearer ' + token
  return config
})

export default axiosInstanceImages
