import React, { FC } from 'react'
import Modal from './Modal'
import { FooterFormBox } from '../Form/form.styles'
import { Box, Button, Stack, Typography } from '@mui/material'
import { theme } from 'src/styles/theme'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import FormTitle from '../Form/FormTitle'
import TextInput from '../Form/TextInput'

interface IEditCredentialsModalProps {
  title: string
  formLabel: string
  information: string
  inputType: 'text' | 'password'
  value: string
  error: string
  open: boolean
  closeModal: () => void
  submit: () => void
  disabledContinue: () => boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const EditCredentialsModal: FC<IEditCredentialsModalProps> = ({
  title,
  formLabel,
  information,
  inputType,
  value,
  error,
  open,
  closeModal,
  handleChange,
  submit,
  disabledContinue
}) => {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <Modal
      size={'sm'}
      title={title}
      open={open}
      handleClose={closeModal}
      fullScreen={window?.innerWidth < 890}
      noCloseIcon={window?.innerWidth < 890}
      // dialogStyles={{
      //   borderTop: window?.innerWidth > 890 ? 'none' : '1px solid'
      // }}
      footer={
        <FooterFormBox sx={{ justifyContent: 'flex-end' }}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              [theme.breakpoints.down('md')]: {
                width: '100%',
                padding: 0
              }
            }}
          >
            <Button
              sx={{
                [theme.breakpoints.down('md')]: {
                  width: '50%'
                }
              }}
              variant="outlined"
              color="info"
              onClick={closeModal}
            >
              {labels[lng].cancel}
            </Button>
            <Button
              sx={{
                ml: 2,
                [theme.breakpoints.down('md')]: {
                  width: '50%'
                }
              }}
              onClick={submit}
              variant="contained"
              disabled={disabledContinue()}
            >
              {labels[lng].continue}
            </Button>
          </Stack>
        </FooterFormBox>
      }
    >
      {window.innerWidth > 890 ? <FormTitle text={information} /> : null}
      <Box
        sx={{
          mb: 3
        }}
      >
        <TextInput
          value={value}
          handleChange={handleChange}
          name="newEmail"
          text={formLabel}
          placeholder={formLabel}
          type={inputType}
          errorBadge={window.innerWidth < 890 ? undefined : error}
        />
        <Box
          sx={{
            height: '1px',
            backgroundColor: '#E5E7EB',
            width: '100%'
          }}
        />
        {window.innerWidth < 890 && error ? (
          <Typography
            sx={{
              mt: '8px',
              lineHeight: '16px',
              fontSize: '12px',
              color: '#F15B70',
              fontWeight: 500,
              pl: '16px'
            }}
          >
            {error}
          </Typography>
        ) : null}
      </Box>
    </Modal>
  )
}

export default EditCredentialsModal
