import React, { useState, useEffect } from 'react'

import { labels } from 'src/labels/main_labels'
import Table from 'src/components/Table/Table'
import { useTranslation } from 'react-i18next'

interface UsuariosDetailBonosProps {
  bonos: any
  userId: string
}

export default function UsuariosDetailBonos({ bonos, userId }: UsuariosDetailBonosProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: false,
      label: labels[lng].voucher,
      fieldtype: 'user-voucher-name'
    },
    {
      idField: 'position',
      align: 'center',
      disablePadding: true,
      label: labels[lng].position,
      fieldtype: 'position'
    },
    {
      idField: 'expiredBono',
      align: 'center',
      disablePadding: false,
      label: labels[lng].expiration,
      fieldtype: 'voucher-date'
    },
    {
      idField: 'vouchersGifts',
      align: 'center',
      disablePadding: true,
      label: labels[lng].maxPlatesGift,
      fieldtype: 'voucher-gifts',
      width: 130
    },
    {
      idField: 'price',
      align: 'center',
      disablePadding: true,
      label: labels[lng].price,
      fieldtype: 'voucher-price'
    },
    {
      idField: 'discount',
      align: 'center',
      disablePadding: true,
      label: labels[lng].codeAndDiscount,
      fieldtype: 'voucher-code-discount'
    },
    {
      idField: 'createdAt',
      align: 'center',
      disablePadding: true,
      label: labels[lng].dateOfPurchase,
      fieldtype: 'date-time',
      width: 180
    }
  ]

  // TABLE CONTROL
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    if (userId && bonos) {
      const bonosTemp = bonos.active
        .map((elm: any) => {
          const idBono = elm.id_bono
          delete idBono.id
          delete idBono._id
          const bono = { ...elm, name: idBono.name, position: idBono.position, createdAt: new Date(elm.createdAt) }
          if (idBono.company) {
            bono.id_bono.giftQuantity = elm.companyConfig.giftQuantity
            bono.id_bono.quantity = elm.companyConfig.quantity
            bono.id_bono.expiration = elm.companyConfig.expires
          }
          return bono
        })
        .filter((bono) => bono.owner?._id === userId)
      setRowsData(bonosTemp)
    }
    return () => setRowsData(null)
  }, [bonos, userId])

  return (
    <>
      <Table
        rows={rowsData}
        headCells={headCells}
        snackBar
        selected={selected}
        setSelected={setSelected}
        noActions
        noCheckbox
        initialOrder="desc"
        initialOrderBy={'createdAt'}
      />
    </>
  )
}
