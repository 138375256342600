import { UserDataTypes } from 'src/interfaces/app.types'
import { RestaurantTypes } from 'src/interfaces/pages.types'
import axiosInstance from './axiosInstance'

export const getAdminData = async (): Promise<UserDataTypes> => {
  return axiosInstance
    .get('/auth/me')
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargaban los datos de usuario')
    })
}

export const getRestaurantData = async (): Promise<RestaurantTypes> => {
  return axiosInstance
    .get('/restaurant/me')
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargaban los datos del restaurante')
    })
}
