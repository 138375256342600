import { StatiticsTypes } from 'src/interfaces/pages.types'

interface ReturnChartDataTransformed {
  chartData: any[]
  dateFrom: Date
  dateTo: Date
}

export const transformChartData = (statitics: StatiticsTypes, type: string): ReturnChartDataTransformed => {
  const chartData: any[] = []
  for (const date in statitics.calendar) {
    const day = statitics.calendar[date]
    chartData.unshift({
      day:
        type === 'months'
          ? new Date(date).getMonth() + 1 + '/' + new Date(date).getFullYear().toString().slice(-2)
          : new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1),
      amount: day?.soldQuantity || 0,
      order: new Date(date).getTime(),
      date
    })
  }
  const dateFrom = new Date(chartData[0].date)
  const dateTo = new Date(chartData[chartData.length - 1].date)
  const chartDataOrdered = chartData.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
  if (type === 'weeks') chartDataOrdered.pop()
  return {
    chartData: chartDataOrdered,
    dateFrom,
    dateTo
  }
}

export const getDateFromLabel = (type: string, dateFrom: Date, dateTo: Date, lng: string): string => {
  const locale = lng === 'en' ? 'en-US' : 'es-ES'
  if (type === 'months') {
    return (
      dateFrom?.toLocaleDateString(locale, { month: 'short', year: 'numeric' }) +
      ' - ' +
      dateTo?.toLocaleDateString(locale, { month: 'short', year: 'numeric' })
    )
  }
  return (
    dateFrom?.toLocaleDateString(locale, { month: 'short', year: 'numeric', day: 'numeric' }) +
    ' - ' +
    dateTo?.toLocaleDateString(locale, { month: 'short', year: 'numeric', day: 'numeric' })
  )
}
