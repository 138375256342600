import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ReactComponent as Question } from 'src/assets/icons/general/Question.svg'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'

interface ContactWithFudclubProp {
  text1?: string
  text2?: string
}

export default function ContactWithFudclub({ text1, text2 }: ContactWithFudclubProp): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const openLink = (e: any) => {
    e.preventDefault()
    window.location.href = `mailto:ayuda@fudclub.com?subject=${labels[lng].modifyInfo}`
  }
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          alignItems: 'flex-start'
        }
      }}
    >
      <Question />
      <Box
        display={'flex'}
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap'
          }
        }}
      >
        <Typography variant="subtitle2" component="h6" sx={{ ml: 1, textAlign: { xs: 'center', sm: 'left' } }}>
          {text1 || labels[lng].someIncorrectData}
        </Typography>
        <Button
          variant="text"
          onClick={(e) => openLink(e)}
          sx={{
            [theme.breakpoints.down('md')]: {
              paddingTop: 0
            }
          }}
        >
          {text2 || labels[lng].contactFudclub2} ↗
        </Button>
      </Box>
    </Stack>
  )
}
