import { useState, ChangeEvent, useEffect } from 'react'
import { IngredientTypes, TranslationsTypes, FoodStylesTypes, RestaurantTypes } from 'src/interfaces/pages.types'
import { translateGenerator } from 'src/utils/helpers'

export const useForm = <T extends IngredientTypes | FoodStylesTypes | RestaurantTypes>(
  initialValues: T,
  form: T,
  translationKeys?: string[]
): any => {
  const [state, setState] = useState<any | null>(initialValues)
  const [translations, setTranslations] = useState<never[] | TranslationsTypes[]>([])
  const [photo, setPhoto] = useState<string | null>(null)

  const resetForm = (resetValues?: any) => {
    setState(resetValues || initialValues)
    setPhoto(null)
    if (translationKeys) {
      if (form?.translations && form.translations.length && translationKeys) {
        const translationArray = [...form.translations].map((elm) => {
          delete elm._id
          return elm
        })
        translateGenerator(translationKeys).forEach((item) => {
          if (!translationArray.some((elm) => elm.language === item.language)) {
            translationArray.push(item)
          }
        })
        setTranslations(translationArray)
      } else {
        setTranslations(
          [...translations].map(({ language, ...translation }) => {
            Object.keys(translation).forEach(function (key) {
              translation[key] = ''
            })
            return { language, ...translation }
          })
        )
      }
    }
  }

  useEffect(() => {
    if (initialValues.photo) {
      setPhoto(form.photo || initialValues.photo)
    }
    if (form) {
      const formTemp = { ...initialValues, ...form }
      setState(formTemp)
      if (form.translations && form.translations.length && translationKeys) {
        const translationArray = form.translations.map((elm) => {
          delete elm._id
          return elm
        })
        translateGenerator(translationKeys).forEach((item) => {
          if (!translationArray.some((elm) => elm.language === item.language)) {
            translationArray.push(item)
          }
        })
        setTranslations(translationArray)
      }
      if (!form.translations && translationKeys) setTranslations(translateGenerator(translationKeys))
    } else {
      if (translationKeys) setTranslations(translateGenerator(translationKeys))
    }
    return () => {
      resetForm()
    }
  }, [form])

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, translation?: any) => {
    if (event.target.name.includes('.')) {
      const parent = event.target.name.split('.')[0]
      const child = event.target.name.split('.')[1]
      setState({ ...state, [parent]: { ...state[parent], [child]: event.target.value } })
      return
    }

    if (translation) {
      const resp = { ...translations.find((elm: any) => elm.language === translation.language) }
      setTranslations((prev: any) => [
        ...prev.filter((elm: any) => elm.language !== translation.language),
        { ...resp, [event.target.name]: event.target.value }
      ])
      return
    }
    setState({ ...state, [event.target.name]: event.target.value })
  }

  return {
    ...state,
    setState,
    translations: translations,
    values: photo ? { ...state, photo } : { ...state },
    photo: photo,
    handleChange,
    setPhoto,
    resetForm
  }
}
