import { Box, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CarrouselImage = styled('div')(({ theme }) => ({
  width: '100%',
  height: 300,
  marginBottom: '1rem',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: theme.spacing(2),
  position: 'relative',
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
    height: 180
  }
}))

export const CarrouselModal = styled(Typography)(({ theme }) => ({
  // width: 900,
  boxSizing: 'border-box',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover',
  overflowX: 'hidden',
  border: 'none',
  [theme.breakpoints.down('md')]: {
    borderRadius: 0
  }
}))

export const CarrouselButtons = styled(IconButton)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.3) !important',
  color: '#fff',
  '& .MuiIconButton-root, & .MuiIconButton-root:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.3) !imporant'
  }
}))

export const CarrouselTitle = styled(Typography)(() => ({
  color: '#fff',
  position: 'absolute',
  bottom: 24,
  left: 24
  // cursor: 'pointer'
}))

export const CarrouselBulletsBox = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: 12,
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    transform: 'translateX(-50%)',
    left: '50vw',
    width: '100vw'
  }
}))

export const CarrouselBullets = styled(Box)(() => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  marginRight: '4px',
  cursor: 'pointer'
}))
