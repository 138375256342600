import React from 'react'
import { Typography, CardActionArea } from '@mui/material'
import { ReactComponent as PlusCircle } from 'src/assets/icons/card-icons/PlusCircle.svg'
import { CardContainer } from './cards.styles'

interface CardDishPreviewAddNewProps {
  handleCard: () => void
  text: string
}

export default function CardDishPreviewAddNew({ handleCard, text }: CardDishPreviewAddNewProps): JSX.Element {
  return (
    <CardContainer sx={{ borderStyle: 'dashed', height: 308 }} className="flex-center" elevation={0} onClick={handleCard}>
      <CardActionArea sx={{ height: 308, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <PlusCircle />
        <Typography variant="h5">{text}</Typography>
      </CardActionArea>
    </CardContainer>
  )
}
