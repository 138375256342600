import { EmailAuthProvider, reauthenticateWithCredential, updateEmail } from 'firebase/auth'
import firebase from '../../../firebase/firebase'
import React, { useContext, useEffect, useState } from 'react'
import LoadingApp from 'src/components/LoadingApp'
import { decriptToken, updateEmailByBearerToken } from 'src/services/auth.services'
import { AuthContext } from 'src/context/auth/authContext'
import EditCredentialsModal from 'src/components/Modals/EditCredentialsModal'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { editEmailRestaurant } from 'src/services/restaurants.services'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ChangeEmail = (props) => {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const [error, setError] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [decryptedToken, setDecryptedToken] = useState(null)
  const search = props.location.search
  const params = new URLSearchParams(search)
  const token = params.get('tokenRef')
  const { revalidate } = useContext(AuthContext)
  const history = useHistory()

  const onSubmit = async () => {
    const user = firebase.auth.currentUser
    try {
      if (user && decryptedToken) {
        const { currentEmail, newEmail } = decryptedToken
        const userCredential = EmailAuthProvider.credential(currentEmail, currentPassword)
        await reauthenticateWithCredential(user, userCredential)
        await editEmailRestaurant(newEmail)
        await updateEmail(user, newEmail)
        await user.reload()
        await revalidate()
        await updateEmailByBearerToken()
        props.history.push('/informacion?newEmail=true')
      }
    } catch (error) {
      // GESTIONAR OTROS TIPOS DE ERROR
      setError(labels[lng].INVALID_PASSWORD)
    }
  }

  const handleDisabledForm = () => currentPassword.length === 0

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCurrentPassword(e.target.value)
  }

  const closeModal = () => {
    setCurrentPassword('')
    setError('')
    setOpenModal(false)
    history.push('/informacion')
  }

  useEffect(() => {
    const changeEmail = async () => {
      try {
        if (token) {
          const user = firebase.auth.currentUser
          if (user) {
            const tokenDecrpt = await decriptToken(encodeURIComponent(token))
            if (tokenDecrpt) {
              setDecryptedToken(tokenDecrpt)
              setOpenModal(true)
            }
          } else {
            history.push('/')
          }
        }
      } catch (error) {
        // GESTIONAR POSIBLE ERROR (?)
        console.error(error)
      }
    }
    changeEmail()
  }, [token])

  return (
    <LoadingApp>
      {openModal && (
        <EditCredentialsModal
          formLabel={labels[lng].password}
          disabledContinue={handleDisabledForm}
          information={labels[lng].information}
          inputType="password"
          error={error}
          closeModal={closeModal}
          handleChange={handleChange}
          value={currentPassword}
          title={labels[lng].changeEmail}
          submit={onSubmit}
          open={openModal}
        />
      )}
    </LoadingApp>
  )
}
