import React from 'react'
import { Avatar, Box, IconButton, Typography } from '@mui/material'
import { ReactComponent as Selector } from 'src/assets/icons/general/Selector.svg'
import RestaurantWithAvatar from '../RestaurantWithAvatar'
import { RestaurantTypes } from 'src/interfaces/pages.types'

interface RestaurantSelectorNavProps {
  open: boolean
  openSelector: () => void
  restaurant: RestaurantTypes | null
  disabledButton: boolean
}

export default function RestaurantSelectorNav({ open, openSelector, restaurant, disabledButton }: RestaurantSelectorNavProps): JSX.Element {
  const name = restaurant ? restaurant.name : ''
  const street = restaurant && restaurant.location ? restaurant.location.street : ''
  const number = restaurant && restaurant.location ? restaurant.location.number : ''
  const photo = restaurant && restaurant.photo ? restaurant.photo : ''
  return (
    <>
      {open ? (
        <Box
          sx={{
            px: 1,
            height: 59,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .MuiTypography-root': { maxWidth: disabledButton ? '182px' : '150px' }
          }}
        >
          <RestaurantWithAvatar name={name} street={street} number={number} photo={photo} />
          {!disabledButton && (
            <IconButton onClick={openSelector}>
              <Selector />
            </IconButton>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            '& .MuiTypography-root': { textAlign: 'center' }
          }}
          onClick={openSelector}
        >
          <Avatar src={photo} variant="rounded" sx={{ width: 40, height: 40, mt: 0.5, border: '2px solid #EB435A' }} />
          <Typography sx={{ mt: 1 }} variant="subtitle2" color="grey.900" className="ellipsis">
            {name}
          </Typography>
          <Typography sx={{ mt: 0.5, mb: 0 }} variant="subtitle2" className="ellipsis">
            {street} {number}
          </Typography>
        </Box>
      )}
    </>
  )
}
