import { Box, IconButton } from '@mui/material'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

export const Picker = styled('label')(() => ({
  cursor: 'pointer',
  borderWidth: '2px',
  borderColor: '#e4e9f0',
  width: 180,
  borderStyle: 'dashed',
  height: 180,
  padding: '8px 16px',
  marginBottom: '20px',
  marginTop: '1rem',
  boxSizing: 'border-box',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative'
}))

export const PrevImage = styled('img')(() => ({
  cursor: 'pointer',
  width: 180,
  height: 180,
  marginBottom: '1rem',
  marginTop: '1rem',
  boxSizing: 'border-box',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover'
}))
export const BigImage = styled('img')(() => ({
  width: 900,
  boxSizing: 'border-box',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover'
}))

export const ButtonIcon = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 100,
  top: 10,
  backgroundColor: grey[900],
  color: '#fff',
  borderRadius: '6px'
}))

export const PictureBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  flexWrap: 'wrap'
}))
