import React from 'react'
import { Box, Typography } from '@mui/material'
import { labels } from 'src/labels/main_labels'
import { parameterToTime } from 'src/utils/time-helpers'
import { ChipCard } from './cards.styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { PlanningRecursiveTypes } from 'src/interfaces/pages.types'
import InfoIcon from '@mui/icons-material/Info'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

interface Props {
  recursivePlanning?: PlanningRecursiveTypes
  dayWeek: string
  whatDayIs: string
}

export default function PlanCardBadge({ recursivePlanning, dayWeek, whatDayIs }: Props): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      onTouchStart={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      enterTouchDelay={0}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#1F2937'
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#1F2937',
      minWidth: 135,
      textAlign: 'center',
      color: '#D1D5DB',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '20px',
      padding: '2px 12px',
      borderRadius: '4px',
      zIndex: 10
    }
  })
  const generateOnlyDay = () => {
    const dayWeeks = [
      labels[lng].Monday,
      labels[lng].Tuesday,
      labels[lng].Wednesday,
      labels[lng].Thursday,
      labels[lng].Friday,
      labels[lng].Saturday,
      labels[lng].Sunday
    ]
    const index = dayWeeks.indexOf(dayWeek)
    const currentDay = new Date().getDay() || 7
    return currentDay > index ? `${labels[lng].onlyNext} ` : `${labels[lng].onlyThis} `
  }

  return (
    <>
      <ChipCard sx={{}}>
        <Typography variant="body1" component="span" sx={{ fontSize: '12px', color: '#5B21B6', fontWeight: 500 }}>
          {whatDayIs ? labels[lng].only : generateOnlyDay()}{' '}
          {whatDayIs ? labels[lng][whatDayIs].toLowerCase() : lng === 'es' ? dayWeek.toLowerCase() : dayWeek}
        </Typography>
        <CustomWidthTooltip
          enterTouchDelay={0}
          placement="right"
          title={
            <Box sx={{ py: '3px' }}>
              <Typography variant="body1" sx={{ fontSize: '10px', fontWeight: 500, color: '#D1D5DB' }}>
                ↪ {labels[lng].byDefault}
              </Typography>
              {recursivePlanning ? (
                <>
                  <div>
                    <Typography variant="body1" sx={{ fontSize: '13px', fontWeight: 500, color: '#D1D5DB' }}>
                      {recursivePlanning.quantity}x{' '}
                      <strong>
                        {lng === 'en'
                          ? recursivePlanning?.plate?.translations && recursivePlanning?.plate?.translations[0]
                            ? recursivePlanning?.plate?.translations[0].name
                            : recursivePlanning.plate.name
                          : recursivePlanning.plate.name}
                      </strong>
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body1" sx={{ fontSize: '13px', color: '#D1D5DB' }}>
                      {parameterToTime(recursivePlanning.parameter?.IRr)} - {parameterToTime(recursivePlanning.parameter?.FRr)}
                    </Typography>
                  </div>
                </>
              ) : (
                <div>
                  <Typography variant="body1" sx={{ fontSize: '13px', fontWeight: 500, color: '#D1D5DB' }}>
                    {labels[lng].noPlate}
                  </Typography>
                </div>
              )}
            </Box>
          }
        >
          <InfoIcon sx={{ fontSize: '16px', ml: 0.5, color: '#5B21B6' }} />
        </CustomWidthTooltip>
      </ChipCard>
    </>
  )
}
