import IRoute from '../interfaces/routes.types'
import Platos from '../pages/RestaurantPlatform/Platos'
import PlatosDetailPage from '../pages/Common/Platos/PlatosDetail'
import InformacionPage from '../pages/RestaurantPlatform/Informacion'
import PedidosPage from '../pages/Common/Pedidos'
import HistorialPage from '../pages/Common/Historial/RestaurantHistorial'
import PlanificarPage from '../pages/Common/Planificar'
import IngresosPage from '../pages/Common/Ingresos'
import EstadisticasPage from '../pages/Common/Estadisticas/RestaurantStatitics'
import RestaurantSelection from '../pages/Welcome/RestaurantSelection'
import MasPage from '../pages/RestaurantPlatform/Mas'
import AjustesPage from '../pages/RestaurantPlatform/Ajustes'
import IdiomaPage from '../pages/RestaurantPlatform/Idioma'
import AyudaPage from '../pages/Ayuda'
import FaqPage from '../pages/Ayuda/Ayuda-PreguntasFrecuentes'
import { ChangeEmail } from 'src/pages/Common/CambioEmail'

const adminRoutes: IRoute[] = [
  {
    path: '/',
    name: 'Seleccion Restaurantes',
    component: RestaurantSelection,
    exact: true
  },
  {
    path: '/platos',
    name: 'Platos',
    component: Platos,
    exact: true
  },
  {
    path: '/platos/:plato',
    name: 'Restaurante Detalles',
    component: PlatosDetailPage,
    exact: true
  },
  {
    path: '/platos/:plato/:navPlatos',
    name: 'Platos',
    component: PlatosDetailPage,
    exact: true
  },
  {
    path: '/informacion',
    name: 'Informacion Restaurante',
    component: InformacionPage,
    exact: true
  },
  {
    path: '/pedidos',
    name: 'Pedidos',
    component: PedidosPage,
    exact: true
  },
  {
    path: '/historial',
    name: 'Historial',
    component: HistorialPage,
    exact: true
  },
  {
    path: '/planificar',
    name: 'Planificar',
    component: PlanificarPage,
    exact: true
  },
  {
    path: '/ingresos',
    name: 'Ingresos',
    component: IngresosPage,
    exact: true
  },
  {
    path: '/estadisticas',
    name: 'Estadisticas',
    component: EstadisticasPage,
    exact: true
  },
  {
    path: '/mas',
    name: 'Más',
    component: MasPage,
    exact: true
  },
  //   {
  //     path: '/restaurantes/:idRestaurant',
  //     name: 'Restaurantes',
  //     component: RestaurantesDetailPage,
  //     exact: true
  //   },
  //   {
  //     path: '/restaurantes/:idRestaurant/:navRestaurant',
  //     name: 'Restaurante Detalles',
  //     component: RestaurantesDetailPage,
  //     exact: true
  //   },
  //   {
  //     path: '/restaurantes/:idRestaurant/platos/:plato',
  //     name: 'Platos',
  //     component: PlatosDetailPage,
  //     exact: true
  //   },
  //   {
  //     path: '/restaurantes/:idRestaurant/platos/:plato/:navPlatos',
  //     name: 'Platos',
  //     component: PlatosDetailPage,
  //     exact: true
  //   },
  //   {
  //     path: '/estilos',
  //     name: 'Estilos',
  //     component: EstilosPage,
  //     exact: true
  //   },
  //   {
  //     path: '/ingredientes',
  //     name: 'Ingredientes',
  //     component: IngredientesPage,
  //     exact: true
  //   },
  //   {
  //     path: '/grupos',
  //     name: 'Grupos',
  //     component: GruposPage,
  //     exact: true
  //   },
  //   {
  //     path: '/notificaciones',
  //     name: 'Notificaciones',
  //     component: NotificacionesPage,
  //     exact: true
  //   },
  //   {
  //     path: '/codigos-descuento',
  //     name: 'Codigos',
  //     component: CodigosPage,
  //     exact: true
  //   },
  //   {
  //     path: '/administradores',
  //     name: 'Administradores',
  //     component: AdminsPage,
  //     exact: true
  //   },
  //   {
  //     path: '/bonos',
  //     name: 'Bonos',
  //     component: BonosPage,
  //     exact: true
  //   },
  //   {
  //     path: '/usuarios',
  //     name: 'Usuarios',
  //     component: UsuariosPage,
  //     exact: true
  //   },
  //   {
  //     path: '/usuarios/:userId',
  //     name: 'Usuario Detalles',
  //     component: UsuariosDetailPage,
  //     exact: true
  //   },
  //   {
  //     path: '/parametros',
  //     name: 'Parametros',
  //     component: ParametrosPage,
  //     exact: true
  //   },
  {
    path: '/changeEmail',
    name: 'Cambiar Email',
    component: ChangeEmail,
    exact: true
  },
  {
    path: '/ayuda',
    name: 'Ayuda',
    component: AyudaPage,
    exact: true
  },
  {
    path: '/ayuda/faq',
    name: 'Ayuda Faq',
    component: FaqPage,
    exact: true
  },
  {
    path: '/ajustes',
    name: 'Ajustes',
    component: AjustesPage,
    exact: true
  },
  {
    path: '/idioma',
    name: 'Idioma',
    component: IdiomaPage,
    exact: true
  }
]

export default adminRoutes
