import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'

export const OrderHeaderBox = styled(Box)(({ theme }) => ({
  minHeight: 60,
  borderBottom: '1px solid',
  borderColor: grey[300],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  flexWrap: 'wrap',
  '& .MuiBox-root': {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%'
    },
    '>div': {
      alignItems: 'center',
      justifyContent: 'center'
    },
    '.MuiTypography-root': {
      marginLeft: theme.spacing(0.5)
    }
  }
}))

export const OrderColumnBox = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: '#F9FAFB',
  minHeight: '100%',
  border: `1px solid ${grey[200]}`,
  paddingBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    minHeight: '183px',
    paddingBottom: 0
  }
}))

export const OrderColumnTitle = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  height: '45px',
  backgroundColor: 'white',
  borderBottom: `1px solid ${grey[200]}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    backgroundColor: grey[50],
    justifyContent: 'flex-start',
    paddingLeft: "16px"
  }
}))

export const OrderCollectedItem = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid',
  borderColor: grey[200],
  minHeight: '50px',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  '& .MuiChip-root': {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontWeight: 500
  },
  [theme.breakpoints.down('md')]: {
    padding: '16px',
    paddingRight: '6px'
  }
}))

export const OrderEmptyContent = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))
