import React, { useContext, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { HeaderContext } from 'src/context/header/headerContext'
import { Grid, Container, Box } from '@mui/material'
import { ReactComponent as Faq } from 'src/assets/icons/ayuda/Faq.svg'
import { ReactComponent as ComoFunciona } from 'src/assets/icons/ayuda/ComoFunciona.svg'
import { ReactComponent as Email } from 'src/assets/icons/ayuda/Email.svg'
import { ReactComponent as Phone } from 'src/assets/icons/ayuda/Phone.svg'
import { ReactComponent as Instagram } from 'src/assets/icons/ayuda/Instagram.svg'
import { ReactComponent as Check } from 'src/assets/icons/ayuda/Check.svg'

import CardAyuda from 'src/components/Cards/CardAyuda'
import { CardSummaryGrid } from 'src/components/Cards/cards.styles'
import { labels } from 'src/labels/main_labels'
import { cookiesPolicy, privacyPolicy, termsAndConditionsUrl } from 'src/constants/const'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { useHistory } from 'react-router-dom'
import { ChevronLeft } from '@mui/icons-material'

export default function Ayuda(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showHeader } = useContext(HeaderContext)

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    const history = useHistory()

    return (
      <div className="flex-center" style={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: '-2px',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.push('/mas')} />
        </Box>
        <Typography variant="h3" component="h3">
          {labels[lng].help}
        </Typography>
        <Typography variant="subtitle2" component="h3"></Typography>
      </div>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  return (
    <Container
      sx={{
        my: 3,
        [theme.breakpoints.down('md')]: {
          mt: 2
        }
      }}
    >
      <Typography
        sx={{
          mb: 1,
          mt: 4,
          [theme.breakpoints.down('md')]: {
            mt: 0
          }
        }}
        variant="body1"
      >
        {labels[lng].helpCenter}
      </Typography>
      <Grid container spacing={3} rowSpacing={{ xs: 2, sm: 2, md: 3 }}>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={Faq} text={labels[lng].faq_text} title={labels[lng].faq} link="/ayuda/faq" />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={ComoFunciona} text={labels[lng].how_it_works_text} title={labels[lng].how_it_works} />
        </CardSummaryGrid>
      </Grid>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels[lng].contactFudclub}
      </Typography>

      <Grid container spacing={3} rowSpacing={{ xs: 2, sm: 2, md: 3 }}>
        <CardSummaryGrid
          item
          xs={12}
          md={4}
          onClick={() => window.open(`mailto:ayuda@fudclub.com?subject=${labels[lng].helpRestaurant}`, '_self')}
        >
          <CardAyuda
            external={false}
            Icon={Email}
            text={labels[lng].helpEmail}
            title={labels[lng].email}
            underlineText="ayuda@fudclub.com"
          />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda
            Icon={Instagram}
            text={labels[lng].helpInstagram}
            title={labels[lng].instagram}
            externalLink="https://www.instagram.com/fudclub/"
          />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4} onClick={() => window.open('TEL:666666666')}>
          <CardAyuda Icon={Phone} text={labels[lng].helpPhone} title={labels[lng].phone} underlineText="666 666 666" />
        </CardSummaryGrid>
      </Grid>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels[lng].fudclubPolitic}
      </Typography>
      <Grid container spacing={3} rowSpacing={{ xs: 2, sm: 2, md: 3 }}>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={Check} title={labels[lng].termsConditions} externalLink={termsAndConditionsUrl} />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={Check} title={labels[lng].privacyPolicy} externalLink={privacyPolicy} />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={Check} title={labels[lng].cookiesPolicy} externalLink={cookiesPolicy} />
        </CardSummaryGrid>
      </Grid>
    </Container>
  )
}
