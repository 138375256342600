import { ReactComponent as Restaurantes } from 'src/assets/icons/nav-icons/Restaurantes.svg'
import { ReactComponent as Grupos } from 'src/assets/icons/nav-icons/Grupos.svg'
import { ReactComponent as Estilos } from 'src/assets/icons/nav-icons/Estilos.svg'
import { ReactComponent as Usuarios } from 'src/assets/icons/nav-icons/Usuarios.svg'
import { ReactComponent as Bonos } from 'src/assets/icons/nav-icons/Bonos.svg'
import { ReactComponent as Cupones } from 'src/assets/icons/nav-icons/Cupones.svg'
import { ReactComponent as Parametros } from 'src/assets/icons/nav-icons/Parametros.svg'
import { ReactComponent as Estadisticas } from 'src/assets/icons/nav-icons/Estadisticas.svg'
import { ReactComponent as Historial } from 'src/assets/icons/nav-icons/Historial.svg'
import { ReactComponent as Ingresos } from 'src/assets/icons/nav-icons/Ingresos.svg'
import { ReactComponent as Pedidos } from 'src/assets/icons/nav-icons/Pedidos.svg'
import { ReactComponent as Planificar } from 'src/assets/icons/nav-icons/Planificar.svg'
import { ReactComponent as Platos } from 'src/assets/icons/nav-icons/Platos.svg'
import { ReactComponent as Ingredientes } from 'src/assets/icons/nav-icons/Ingredientes.svg'

// LINKS IN ADMIN LAYOUT
export const navigationLinkAdminRestaurantes = [
  { text: 'restaurants', Icon: Restaurantes, link: '/restaurantes' },
  { text: 'groups', Icon: Grupos, link: '/grupos' },
  { text: 'styles', Icon: Estilos, link: '/estilos' },
  { text: 'labels', Icon: Ingredientes, link: '/etiquetas' }
]

export const navigationLinkAdminUsuarios = [
  { text: 'users', Icon: Usuarios, link: '/usuarios' },
  { text: 'vouchers', Icon: Bonos, link: '/bonos' },
  { text: 'discounts', Icon: Cupones, link: '/codigos-descuento' }
]

export const navigationLinkAdminGestion = [{ text: 'parameters', Icon: Parametros, link: '/parametros' }]

// LINKS IN RESTAURANTS LAYOUT
export const navigationLinkTuRestaurante = [
  { text: 'orders', Icon: Pedidos, link: '/pedidos' },
  { text: 'toPlan', Icon: Planificar, link: '/planificar' },
  { text: 'plates', Icon: Platos, link: '/platos' },
  { text: 'history', Icon: Historial, link: '/historial' },
  { text: 'information', Icon: Restaurantes, link: '/informacion' }
]

export const navigationLinkTuRestauranteMob = [
  { text: 'orders', Icon: Pedidos, link: '/pedidos' },
  { text: 'toPlan', Icon: Planificar, link: '/planificar' },
  { text: 'plates', Icon: Platos, link: '/platos' },
  { text: 'more', Icon: Historial, link: '/mas' }
]

export const navigationLinkVentas = [
  { text: 'incomes', Icon: Ingresos, link: '/ingresos' },
  { text: 'statistics', Icon: Estadisticas, link: '/estadisticas' }
]
