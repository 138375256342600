import axiosInstance from './axiosInstance'
import { DiscountTypes } from 'src/interfaces/pages.types'

export interface DiscountsApi {
  discounts: DiscountTypes[]
  total: number
  maxPages: number
}

export const getDiscounts = async (
  order: string,
  limit: number,
  offset: number,
  searchString?: string,
  filter?: string
): Promise<DiscountsApi> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`
  const filterQuery = filter ? `&type=${filter}` : ''

  return axiosInstance
    .get(`/discount${query}${filterQuery}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de descuentos')
    })
}

export const createDiscount = async (discount: DiscountTypes): Promise<void> => {
  return axiosInstance
    .post('/discount/create', discount)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba crear el descuento')
    })
}

export const updateDiscount = async (
  id: string,
  discount: { obv: string; status: DiscountTypes['status']; users?: string[] }
): Promise<string> => {
  return axiosInstance
    .put(`/discount/${id}`, discount)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el descuento')
    })
}

export const removeDiscount = async (discounts: string[]): Promise<void> => {
  return axiosInstance
    .put(`/discount/remove`, { discounts })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar el descuento')
    })
}

export const toggleStatusDiscount = async (discounts: string[]): Promise<string> => {
  return axiosInstance
    .put(`/discount/toggle/status`, { discounts })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error')
    })
}

export const activeStatusDiscount = async (discounts: string[]): Promise<string> => {
  return axiosInstance
    .put(`/discount/status/active`, { discounts })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error')
    })
}

export const disabledStatusDiscount = async (discounts: string[]): Promise<string> => {
  return axiosInstance
    .put(`/discount/status/disabled`, { discounts })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error')
    })
}

export const deleteUsersDiscount = async (discount: string, users: string[]): Promise<string> => {
  return axiosInstance
    .put(`/discount/deleted`, { discount, users })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar usuarios al descuento')
    })
}

export const addUsersDiscount = async (discount: string, users: string[]): Promise<string> => {
  return axiosInstance
    .put(`/discount/assigned`, { discount, users })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba añadir usuarios al descuento')
    })
}
