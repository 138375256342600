import React, { useContext, useEffect, useState, useRef } from 'react'
import SearchInput from 'src/components/SearchInput'
import { Button, Box } from '@mui/material'

import { AlertContext } from 'src/context/alert/alertContext'
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'

import CodigosAddEdit from './CodigosAddEdit'
import TableApi from 'src/components/Table/TableApi'
import FiltersFooter from 'src/components/Table/FiltersFooter'
import { Order } from 'src/interfaces/pages.types'
import { ReactComponent as User } from 'src/assets/icons/general/User.svg'
import { ReactComponent as Users } from 'src/assets/icons/general/Users.svg'
import { ReactComponent as Empty } from 'src/assets/icons/nav-icons/Planificar.svg'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { labels } from 'src/labels/main_labels'
import { activeStatusDiscount, disabledStatusDiscount, getDiscounts, toggleStatusDiscount } from 'src/services/discounts.services'
import { useTranslation } from 'react-i18next'

export default function Codigos(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { showHeader, setExportData, setExportName } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)

  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const wordSearch = useRef('')

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }
  // TABLE CONTROL
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [filter, setFilter] = useState<string>('')

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].discount.toUpperCase(),
      fieldtype: 'discount-name'
    },
    {
      idField: 'discount',
      align: 'center',
      disablePadding: false,
      label: labels[lng].percent,
      order: false,
      fieldtype: 'percentage',
      width: 120
    },
    {
      idField: 'p1',
      align: 'center',
      disablePadding: true,
      label: labels[lng].p1,
      order: false,
      fieldtype: 'number',
      width: 60
    },
    {
      idField: 'p2',
      align: 'center',
      disablePadding: true,
      label: labels[lng].p2,
      order: false,
      fieldtype: 'number',
      width: 60
    },
    {
      idField: 'p3',
      align: 'center',
      disablePadding: true,
      label: labels[lng].p3,
      order: false,
      fieldtype: 'number',
      width: 60
    },
    {
      idField: 'uses',
      align: 'center',
      disablePadding: false,
      label: labels[lng].totalUses,
      fieldtype: 'number',
      width: 120
    },
    {
      idField: 'createdAt',
      align: 'center',
      disablePadding: true,
      label: labels[lng].createdAt,
      order: true,
      fieldtype: 'date-time'
    }
  ]

  const pageRef = useRef(0)

  // EXPORT EXCEL
  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      const excelFormatted = rowsData?.map((discount: any) => {
        return {
          id: discount._id,
          Nombre: discount.name,
          Porcentaje: discount.discount || 0,
          P1: discount.p1 || 0,
          P2: discount.p2 || 0,
          P3: discount.p3 || 0,
          'Total Usos': discount.totalUses || 0,
          'Fecha de Creación': dateTimeConvert(discount.createdAt)
        }
      })
      setExportData(excelFormatted)
      setExportName('Códigos de descuento')
    }
    return () => setExportData(null)
  }, [rowsData])

  const fetchDiscountList = async () => {
    setLoadingTable(true)
    try {
      const resp = await getDiscounts(
        `${orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
        rowsPerPage,
        pageRef.current,
        wordSearch.current,
        filter
      )
      setTotalCount(resp.total)
      setRowsData(resp.discounts)
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchDiscountList()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageRef.current = 0
  }
  const handleSearch = async (word: string) => {
    wordSearch.current = word
    if (!word) {
      fetchDiscountList()
      return
    }
    const resp = await getDiscounts(`${orderBy}=${order === 'desc' ? 'des' : 'asc'}`, rowsPerPage, 0, word, filter)
    pageRef.current = 0
    setTotalCount(resp.total)
    setRowsData(resp.discounts)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between ">
        <Box sx={{ width: { sm: 250 } }}>
          <SearchInput placeholder={labels[lng].searchDiscount} name="search" handleSubmit={handleSearch} clean />
        </Box>
        <Button sx={{ ml: 2 }} size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModal(true)}>
          {labels[lng].addDiscount}
        </Button>
      </div>
    )
  }

  useEffect(() => {
    if (filter) pageRef.current = 0
    if (userData) fetchDiscountList()
  }, [rowsPerPage, order, orderBy, filter])

  useEffect(() => {
    if (initHeader) showHeader(Header)
    // return () => setInitHeader(false)
  }, [initHeader])

  const onEdit = (data: Record<string, any>) => {
    setEditData({
      ...data,
      expiration: data.expiration ? new Date(data.expiration).toISOString().split('T')[0] : ''
    })
    setOpenModal(true)
  }

  // const onDelete = async (data: any) => {
  //   if (!Array.isArray(data)) {
  //     data = [data]
  //   }
  //   Swal.fire(deleteSwalConfig(labels[lng].discounts, lng)).then((result: any) => {
  //     if (result.isConfirmed) {
  //       removeDiscount(data)
  //         .then(() => {
  //           showSnackbar('success', labels[lng].discountsRemoved)
  //           fetchDiscountList()
  //           closeModal()
  //           setSelected([])
  //         })
  //         .catch((error) => {
  //           if (error instanceof Error) showSnackbar('error', error.message)
  //         })
  //     }
  //   })
  // }

  const onDeactivate = async (data: Record<string, string>) => {
    const label = data.status === 'ACTIVE' ? labels[lng].restaurantDeactivated : labels[lng].restaurantActivated
    try {
      await toggleStatusDiscount([data._id])
      showSnackbar('success', label)
      fetchDiscountList()
      closeModal()
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onActivateAll = async () => {
    if (selected.length === 0) return
    try {
      await activeStatusDiscount(selected)
      fetchDiscountList()
      setSelected([])
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onDeactivateAll = async () => {
    if (selected.length === 0) return
    try {
      await disabledStatusDiscount(selected)
      fetchDiscountList()
      setSelected([])
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const filterOptions = [
    { value: '', text: labels[lng].noFilters, Icon: Empty },
    { value: 'NORMAL', text: labels[lng].general, Icon: User },
    { value: 'PARTICULAR', text: labels[lng].particular, Icon: Users }
  ]

  return (
    <>
      <TableApi
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rows={rowsData}
        headCells={headCells}
        snackBar
        onEdit={onEdit}
        loading={loadingTable}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
        page={pageRef.current}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        selected={selected}
        setSelected={setSelected}
        onDeactivate={onDeactivate}
        onDeactivateAll={onDeactivateAll}
        onActivateAll={onActivateAll}
        filters={<FiltersFooter filter={filter} setFilter={setFilter} options={filterOptions} />}
      />
      {openModal && <CodigosAddEdit openModal={openModal} closeModal={closeModal} dataToEdit={editData} fetchData={fetchDiscountList} />}
    </>
  )
}
