import React from 'react'
import { Grid, Typography } from '@mui/material'
import { MainBox, GridFlexBetween } from './form.styles'

interface FixedInputProps {
  text: string
  name: string
}

export default function FixedInput({ text, name }: FixedInputProps): JSX.Element {
  return (
    <MainBox sx={{ py: '20px' }}>
      <GridFlexBetween container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="h6" sx={{ textTransform: 'capitalize' }}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" component="h6">
            {text}
          </Typography>
        </Grid>
      </GridFlexBetween>
    </MainBox>
  )
}
