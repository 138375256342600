import React, { useContext, useEffect, useState } from 'react'
import { HeaderContext } from 'src/context/header/headerContext'
import DynamicTable from 'src/components/Table'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { getParameters } from 'src/services/parameters.services'
import ParametrosSimplesAddEdit from './ParametrosSimplesAddEdit'
import ParametrosComplejosAddEdit from './ParametrosComplejosAddEdit'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function Parametros(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].parameter,
      fieldtype: 'name'
    },
    {
      idField: 'defaultValues',
      align: 'center',
      disablePadding: false,
      label: labels[lng].value,
      fieldtype: 'parameter'
    }
  ]

  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [editSimpleData, setEditSimpleData] = useState<any | null>(null)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [initHeader, setInitHeader] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-center">
        <Typography variant="subtitle1" sx={{ color: '#6B7280' }}>
          {labels[lng].configParameter}
        </Typography>
      </div>
    )
  }

  const fetchParameters = async () => {
    setLoadingTable(true)

    try {
      const resp = await getParameters()
      const { EMHIRR, C, IR, FRR, FR } = resp.timeline1
      const data = [
        { name: labels[lng].CDI, type: 'chip', value: resp.user.CDI, code: 'CDI' },
        { name: labels[lng].PCDI, type: 'stringPer', value: resp.user.PCDI, code: 'PCDI' },
        { name: labels[lng].PCDR, type: 'stringPer', value: resp.user.PCDR, code: 'PCDR' },
        { name: labels[lng].PCDPR, type: 'stringPer', value: resp.user.PCDPR, code: 'PCDPR' },
        { name: labels[lng].MCCDR, type: 'string', value: resp.user.MCCDR, code: 'MCCDR' },
        { name: labels[lng].MPOD, type: 'string', value: resp.restaurant.MPOD, code: 'MPOD' },
        { name: labels[lng].PP, type: 'string', value: resp.plate.PP, code: 'PP' },
        { name: labels[lng].timeParameter, type: 'timeline1', value: { EMHIRR, C, IR, FRR, FR }, code: 'timeline1' },
        { name: labels[lng].TPFR, type: 'string', value: resp.timeline2.TPFR, code: 'TPFR' },
        { name: labels[lng].TPVC, type: 'string', value: resp.timeline2.TPVC, code: 'TPVC' }
      ]
      setRowsData(data)
      setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    fetchParameters()
  }, [])

  useEffect(() => {
    if (initHeader) showHeader(Header)
    // return () => setInitHeader(false)
  }, [initHeader])

  const onEdit = (data: Record<string, any>) => {
    if (data.code && ['CDI', 'PCDI', 'PCDPR', 'PCDR', 'MCCDR', 'MPOD', 'PP', 'TPVC', 'TPFR'].includes(data.code)) setEditSimpleData(data)
    if (data.code && ['timeline1', 'timeline2'].includes(data.code)) setEditData(data)
    setOpenModal(true)
  }
  return (
    <>
      <DynamicTable
        rows={rowsData}
        headCells={headCells}
        snackBar
        onEdit={onEdit}
        noCheckbox
        initialOrderBy="-"
        noPagination
        loading={loadingTable}
        selected={selected}
        setSelected={setSelected}
      />
      {editSimpleData && openModal && (
        <ParametrosSimplesAddEdit openModal={openModal} closeModal={closeModal} dataToEdit={editSimpleData} fetchData={fetchParameters} />
      )}
      {editData && openModal && (
        <ParametrosComplejosAddEdit openModal={openModal} closeModal={closeModal} dataToEdit={editData} fetchData={fetchParameters} />
      )}
    </>
  )
}
