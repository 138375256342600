import axios from 'axios'
import axiosInstance from './axiosInstance'

export const recoverAccountPassword = async (email: string): Promise<any> => {
  return axios
    .get(`${process.env.REACT_APP_URLAPI}/auth/recovery/${email}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba enviar correo de recuperación de contraseña')
    })
}
export const changeRecoveryAccountPassword = async (tokenRef: string, newPassword: string): Promise<any> => {
  return axios
    .post(`${process.env.REACT_APP_URLAPI}/auth/recovery/${tokenRef}`, { newPassword })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba restablecer la contraseña')
    })
}
export const changeEmailService = async (token: string): Promise<any> => {
  return axios
    .get(`${process.env.REACT_APP_URLAPI}/auth/email/${token}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba enviar correo de recuperación de contraseña')
    })
}

export const updateEmailByBearerToken = async (): Promise<any> => {
  return axiosInstance
    .get(`${process.env.REACT_APP_URLAPI}/auth/verify/email`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba enviar correo de recuperación de contraseña')
    })
}

export const decriptToken = async (token: string): Promise<any> => {
  return axiosInstance
    .get(`${process.env.REACT_APP_URLAPI}/auth/${token}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba enviar correo de recuperación de contraseña')
    })
}
