import React from 'react'
import { Box, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import { Photo } from '@mui/icons-material'

import { ReactComponent as HeartRed } from 'src/assets/icons/general/HeartRed.svg'
// import { ReactComponent as Star } from 'src/assets/icons/general/StarFilled3.svg'
import { ReactComponent as PlatosVendidos } from 'src/assets/icons/card-icons/Card-PlatosVendidos.svg'
import { ReactComponent as DiasDisponibles } from 'src/assets/icons/card-icons/Card-DiasDisponibles.svg'
import { ReactComponent as Medal } from 'src/assets/icons/card-icons/Card-Medal.svg'
import { ReactComponent as Position } from 'src/assets/icons/card-icons/Card-Position.svg'
import { CardContainer, ChipHeart, ChipRecommend, EditButton } from './cards.styles'
import { PlateTypes } from 'src/interfaces/pages.types'
import EditIcon from '@mui/icons-material/Edit'
import { labels } from 'src/labels/main_labels'
// import { CARD_DISH_WIDTH } from 'src/constants/const'
import { useTranslation } from 'react-i18next'

interface CardRestaurantPlateProps {
  plate: PlateTypes
  handleEdit?: (plate: PlateTypes) => void
  handleClick: (idPlato: string) => void
  selected?: boolean
}

export default function CardRestaurantPlate({ plate, handleEdit, handleClick, selected }: CardRestaurantPlateProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { reviews, parameter, favorites, images, _id, income } = plate
  const getPlateName = (plate) => {
    return lng === 'en' ? (plate?.translations && plate?.translations[0] ? plate?.translations[0].name : plate.name) : plate.name
  }
  return (
    <CardContainer elevation={0} sx={{ position: 'relative', height: 308, cursor: 'pointer' }}>
      {selected && (
        <Box
          onClick={() => handleClick(_id)}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            border: '4px solid #EB435A',
            height: 306,
            width: '100%',
            zIndex: 99,
            borderRadius: '8px'
          }}
        />
      )}
      {parameter?.RFC.value && (
        <ChipRecommend>
          ⭐️
          <Typography sx={{ ml: 0.5 }} variant="body1" component="span">
            {labels[lng].recommend}
          </Typography>
        </ChipRecommend>
      )}
      <ChipHeart>
        <HeartRed />
        <Typography variant="body1" component="span">
          {favorites || '0'}
        </Typography>
      </ChipHeart>
      {images && <CardMedia component="img" height="180" image={images[0]} alt={Photo.name} onClick={() => handleClick(_id)} />}
      <CardContent sx={{ pt: 2, pb: 1 }} onClick={() => handleClick(_id)}>
        {reviews?.quality || reviews?.amount ? (
          <Typography variant="body1" component="span" sx={{ display: 'flex', alignItems: 'center', fontSize: '12px', mb: 0.5 }}>
            <Medal />
            {reviews?.quality || '0'} ({(reviews?.quality ? (reviews?.quality * 100) / reviews?.size : 0).toFixed(0) || 0}%)
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Position />
            &nbsp;{reviews?.amount || '0'} ({(reviews?.amount ? (reviews?.amount * 100) / reviews?.size : 0).toFixed(0) || 0}%)
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ fontSize: '12px', lineHeight: '16px', color: '#9CA3AF', marginBottom: '12px' }}>
            {labels[lng]?.noRatings}
          </Typography>
        )}
        <Typography className="ellipsis-simple" gutterBottom variant="subtitle1" component="div" sx={{ color: '#111827' }}>
          {getPlateName(plate)}
        </Typography>
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
          <PlatosVendidos />
          <Typography maxWidth={'65%'} textOverflow="ellipsis" overflow={'hidden'} whiteSpace="nowrap" variant="body1">
            {income?.platesSolds || '0'}{' '}
            {income?.platesSolds === 1 ? labels[lng].platesSoldSingular.toLowerCase() : labels[lng].platesSold.toLowerCase()}
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
          <DiasDisponibles />
          <Typography maxWidth={'65%'} textOverflow="ellipsis" overflow={'hidden'} whiteSpace="nowrap" variant="body1">
            {income?.days.length || '0'}{' '}
            {income?.days.length === 1 ? labels[lng].availableDaysSingular.toLowerCase() : labels[lng].availableDays.toLowerCase()}
          </Typography>
        </Stack>
      </CardContent>
      {handleEdit && (
        <EditButton size="small" color="info" variant="outlined" onClick={() => handleEdit(plate)}>
          <EditIcon />
        </EditButton>
      )}
    </CardContainer>
  )
}
