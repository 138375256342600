import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Avatar, Box, Chip, Grid, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { AlertContext } from 'src/context/alert/alertContext'
import { changeStatusBooking } from 'src/services/booking.services'

import OrderWaiting from '../Swipe/OrderWaiting'
import SwipeableButtonRight from '../Swipe/SwipeableButtonRight'
import SwipeableButtonLeft from '../Swipe/SwipeableButtonLeft'

import { ReactComponent as ClockWhite } from 'src/assets/icons/smalls/ClockWhite.svg'

import { labels } from 'src/labels/main_labels'

// import { getTimeFromDate } from 'src/utils/time-helpers'
import { useTranslation } from 'react-i18next'
import { subHours } from 'date-fns'

import { getParameters } from 'src/services/parameters.services'
import { OrderCollectedItem } from './pedidos.styles'
import { ChevronRight } from '@mui/icons-material'
import Modal from '../Modals/Modal'
import { theme } from 'src/styles/theme'

interface PickUpOrdersItemProps {
  item: any
  addToRefsArr: any
  setOpenErrorDialog: Dispatch<SetStateAction<boolean>>
  setTextError: Dispatch<SetStateAction<{ title: string; text: string }>>
  fetchData: (noLoading?: boolean) => Promise<void>
}

export default function PickUpOrdersItem({
  item,
  addToRefsArr,
  setOpenErrorDialog,
  setTextError,
  fetchData
}: PickUpOrdersItemProps): JSX.Element {
  const { status, user, pickupRange, quantity, _id } = item
  const { showSnackbar } = useContext(AlertContext)
  const [modal, setOpenModal] = useState<boolean>(false)

  const [parameters, setparameters] = useState<any>()

  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const changeBookingStatus = async (status: string) => {
    try {
      await changeStatusBooking(status, _id)
      await fetchData(true)
    } catch (error) {
      if (error instanceof Error) {
        const message = error.message
        let messageError: { text: string; title: string } | undefined
        if (message === 'You cannot undo the delivery order.') {
          messageError = {
            title: labels[lng].errorUndoOrderTitle,
            text: `${labels[lng].errorUndoOrderDescription1} ${parameters.timeline2.TPFR.value} ${labels[lng].errorUndoOrderDescription2}`
          }
        } else if (message === 'You can no longer deliver this order') {
          messageError = {
            title: labels[lng].errorCannotOrderTitle,
            text: `${labels[lng].errorCannotOrderDescription1} ${parameters.timeline2.TPFR.value} ${labels[lng].errorCannotOrderDescription2}`
          }
        } else if (message === 'You cannot deliver this order yet') {
          messageError = {
            title: labels[lng].errorCannotDeliverThisOrderYetOrderTitle,
            text: labels[lng].errorCannotDeliverThisOrderYetOrderDescription
          }
        } else showSnackbar('error', error.message)
        if (messageError) {
          setTextError(messageError)
          setOpenErrorDialog(true)
        }
      }
    }
  }

  const getTimeFromDate = (time: string): string => {
    const date = time
    const hour = String(subHours(new Date(date), Math.abs(new Date().getTimezoneOffset() / 60)).getHours()).padStart(2, '0')
    const minutes = String(new Date(date).getMinutes()).padStart(2, '0')
    return `${hour}:${minutes}`
  }

  const _getParamenters = async () => {
    try {
      const parameters = await getParameters()
      setparameters(parameters)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  useEffect(() => {
    _getParamenters()
  }, [])

  return (
    <>
      <Box
        sx={{
          borderRadius: '8px',
          backgroundColor: 'white',
          border: `1px solid ${grey[200]}`,
          p: 2,
          display: { xs: 'none', md: 'block' }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8} sm={9} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={user.photo} variant="circular" sx={{ width: 40, height: 40, mr: 1 }} />
            <Stack>
              <Typography sx={{ mr: 1 }} variant="subtitle2" component="h6" color="grey.900" className="ellipsis">
                {user.name || ''} {user.surname || ''}
              </Typography>
              <Typography variant="subtitle2">x{quantity}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={3} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'flex-end', md: 'center' } }}>
            <Typography sx={{ fontSize: '12px' }} variant="subtitle2">
              {getTimeFromDate(pickupRange[0])} - {getTimeFromDate(pickupRange[1])}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            {['PENDING', 'UNCOLLECTED'].includes(status) && (
              <SwipeableButtonRight
                ref={addToRefsArr}
                onSuccess={() => changeBookingStatus('WAITING-CONFIRM')}
                color="#10B981"
                text={labels[lng].swipeToDeliver}
                id={_id}
              />
            )}
            {status === 'WAITING-CONFIRM' && (
              <SwipeableButtonLeft
                ref={addToRefsArr}
                onSuccess={() => changeBookingStatus('PENDING')}
                color="#6B7280"
                text={labels[lng].clientToConfirm}
                description={labels[lng].clientToConfirmDes}
                id={_id}
              />
            )}

            {status === 'WAITING' && (
              <OrderWaiting>
                <ClockWhite />
                <Typography sx={{ color: '#fff', ml: 1, fontWeight: 500 }} variant="body1">
                  {labels[lng].waiting}
                </Typography>
              </OrderWaiting>
            )}
          </Grid>
        </Grid>
      </Box>
      <OrderCollectedItem
        onClick={() => {
          if (['PENDING', 'UNCOLLECTED', 'WAITING-CONFIRM'].includes(status)) {
            setOpenModal(true)
          }
        }}
        sx={{
          display: { xs: 'flex', md: 'none' },
          ':last-child': {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            borderBottom: 'none'
          },
          [theme.breakpoints.down('md')]: {
            paddingRight: status === 'WAITING' ? '16px' : '6px'
          }
        }}
      >
        <Stack>
          <Typography sx={{ mr: 1 }} variant="subtitle2" component="h6" color="grey.900" className="ellipsis">
            {user.name || ''} {user.surname || ''}
          </Typography>
          <Typography variant="subtitle2" component="span">
            x{quantity}
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          {status === 'WAITING-CONFIRM' && (
            <Chip
              label={labels[lng].waitingConfirm}
              size="small"
              sx={{ backgroundColor: '#FFFFFF', color: '#4B5563', border: '1px solid #F3F4F6' }}
            />
          )}
          {status === 'WAITING' && <Chip label={labels[lng].waiting} size="small" sx={{ backgroundColor: '#FBBF24', color: '#FFF' }} />}
          <Typography sx={{ fontSize: '12px' }} variant="subtitle2">
            {getTimeFromDate(pickupRange[0])} - {getTimeFromDate(pickupRange[1])}
          </Typography>
          {['PENDING', 'UNCOLLECTED', 'WAITING-CONFIRM'].includes(status) && (
            <ChevronRight style={{ color: '#9CA3AF', fill: '#9CA3AF', marginLeft: '6px' }} />
          )}
        </Stack>
      </OrderCollectedItem>
      {modal && (
        <Modal backdropActive noTitle title={''} size="md" open={modal} handleClose={() => setOpenModal(false)} noCloseIcon>
          <Box
            sx={{
              padding: '24px 16px'
            }}
          >
            <Box textAlign={'center'} mb="32px">
              <Avatar src={user.photo} variant="circular" sx={{ width: 48, height: 48, mb: '8px', mx: 'auto' }} />
              <Typography sx={{ fontSize: '16px', lineHeight: '24px' }} variant="h2" component="h6" color="grey.900" className="ellipsis">
                {user.name || ''} {user.surname || ''}
              </Typography>
              <Typography
                sx={{ fontSize: '14px', lineHeight: '14px', textAlign: 'center', my: '8px', color: '#6B7280' }}
                variant="subtitle2"
              >
                {getTimeFromDate(pickupRange[0])} - {getTimeFromDate(pickupRange[1])}
              </Typography>
              <Typography
                sx={{ fontSize: '14px', lineHeight: '14px', textAlign: 'center', color: '#6B7280' }}
                variant="subtitle2"
                component="span"
              >
                x{quantity}
              </Typography>
            </Box>
            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
              {['PENDING', 'UNCOLLECTED'].includes(status) && (
                <SwipeableButtonRight
                  ref={addToRefsArr}
                  onSuccess={() => {
                    changeBookingStatus('WAITING-CONFIRM')
                    setOpenModal(false)
                  }}
                  color="#10B981"
                  text={labels[lng].swipeToDeliver}
                  id={_id}
                />
              )}
              {status === 'WAITING-CONFIRM' && (
                <SwipeableButtonLeft
                  ref={addToRefsArr}
                  onSuccess={() => {
                    changeBookingStatus('PENDING')
                    setOpenModal(false)
                  }}
                  color="#6B7280"
                  text={labels[lng].clientToConfirm}
                  description={labels[lng].clientToConfirmDes}
                  id={_id}
                />
              )}

              {status === 'WAITING' && (
                <OrderWaiting>
                  <ClockWhite />
                  <Typography sx={{ color: '#fff', ml: 1, fontWeight: 500 }} variant="body1">
                    {labels[lng].waiting}
                  </Typography>
                </OrderWaiting>
              )}
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  )
}
