import React, { useContext, useState, useEffect, ChangeEvent } from 'react'
import Modal from 'src/components/Modals/Modal'
import { labels } from 'src/labels/main_labels'
import { Box, Button, DialogContent, DialogContentText, Grid, Stack, Typography } from '@mui/material'
import CardDishPreview from 'src/components/Cards/CardDishPreview'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { AddEditProps, PlateTypes, PlanningTypes, PlanningRecursiveTypes, DayWeek } from 'src/interfaces/pages.types'
import { FooterFormBox } from 'src/components/Form/form.styles'
import SearchInput from 'src/components/SearchInput'
import { HeadBanner } from './planificar.styles'
import TextInput from 'src/components/Form/TextInput'
import { red } from '@mui/material/colors'
import { ModalTitle } from 'src/components/Modals/modals.styles'
import { getEMHIRR, parameterToTime } from 'src/utils/time-helpers'
import { ReactComponent as InfoRed } from 'src/assets/icons/smalls/Infored.svg'
import { ReactComponent as Info } from 'src/assets/icons/smalls/Info.svg'
import { ReactComponent as PedidosEmpty } from 'src/assets/icons/Empty/PedidosEmpty.svg'

import {
  createPlanning,
  updatePlanning,
  createRecursivePlanning,
  updateRecursivePlanning,
  deletePlanning,
  deleteRecursivePlanning
} from 'src/services/plan.services'
import EmptyPage from 'src/components/EmptyPage'
import TimeRangePickerSelect from 'src/components/Form/TimeRangePicker'
import { getParameters } from 'src/services/parameters.services'
import { useDebouncedValue } from 'src/hooks/useDebounced'
import { useTranslation } from 'react-i18next'
import { addHours, subHours, addDays } from 'date-fns/esm'
import { theme } from 'src/styles/theme'
import { translatePlate } from 'src/utils/translate.util'

interface PlanificarAddEditProps extends AddEditProps {
  recursivePlanning?: PlanningRecursiveTypes
}

const INITIAL_VALUE = { hour: 0, minutes: 0 }

export default function PlanificarAddEdit({ closeModal, openModal, dataToEdit, fetchData }: PlanificarAddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { restaurant } = useContext(RestaurantContext)
  const { showSnackbar } = useContext(AlertContext)

  const [finalStep, setFinalStep] = useState(0)
  const [plateSelected, setPlateSelected] = useState('')
  const [planSelected, setPlanSelected] = useState<PlanningTypes | null>(null)
  const [parameters, setParameters] = useState<any | null>(null)

  const [plates, setPlates] = useState<PlateTypes[]>([])
  const [empty, setEmpty] = useState<boolean>(false)
  const [availablePlates, setAvailablePlates] = useState<number | undefined>()

  const [FRr, setFRr] = useState(INITIAL_VALUE)
  const [IRr, setIRr] = useState(INITIAL_VALUE)
  const [loading, setLoading] = useState(false);

  const fetchParameters = async () => {
    const parameters = await getParameters()
    setParameters(parameters.timeline1)
  }

  const disabledSaveToday = () => {
    const hour = new Date().getHours()
    const min = new Date().getMinutes()
    const FRR = restaurant?.parameter?.FRR
    const EMHIRR = restaurant?.parameter?.EMHIRR
    const date = new Date()
    const EMHIRRi = subHours(getEMHIRR(EMHIRR, FRR, date), Math.abs(new Date().getTimezoneOffset() / 60))
    if (dataToEdit.whatDayIs === 'today') {
      return !(EMHIRRi.getDate() === date.getDate() && (EMHIRRi === date || EMHIRRi >= date))
    }
    if (dataToEdit.whatDayIs === 'tomorrow') {
      if (EMHIRRi.getDate() === date.getDate()) return false
      if (hour < EMHIRR.hour) return false
      return !(hour === EMHIRR.hour && min < EMHIRR.minutes)
    }
    return false
  }

  useEffect(() => {
    if (restaurant?.plates) {
      setPlates(restaurant.plates)
      if (restaurant.plates.length === 0) setEmpty(true)
    }
    if (dataToEdit && dataToEdit.plan) {
      const { plan } = dataToEdit
      setPlanSelected(plan)
      if (plan.quantity) setAvailablePlates(plan.quantity)
      if (plan.parameter.IRr) setIRr({ hour: plan.parameter.IRr.hour, minutes: plan.parameter.IRr.minutes })
      if (plan.parameter.FRr) setFRr({ hour: plan.parameter.FRr.hour, minutes: plan.parameter.FRr.minutes })
      if (plan.plate) setPlateSelected(plan.plate)
    }
    fetchParameters()
    return () => {
      setPlates([])
      setEmpty(false)
      setFinalStep(0)
      setPlateSelected('')
      setPlanSelected(null)
      setFRr(INITIAL_VALUE)
      setIRr(INITIAL_VALUE)
      setAvailablePlates(restaurant?.parameter?.MPOD || 0)
    }
  }, [restaurant, dataToEdit])

  const handleSearch = (word: string) => {
    if (restaurant && restaurant.plates) {
      const _plates = translatePlate([...restaurant.plates], i18n.language)
      const filtered = _plates.filter((row: any) => row.name.toLowerCase().includes(word.toLowerCase()))
      setPlates(filtered)
    }
  }

  const onSave = async (type: string) => {
    setLoading(true)
    // const theDate = new Date(2022, 9, 25, 22, 45, 0);
    const myNewDate = new Date()
    myNewDate.setDate(myNewDate.getDate() + dataToEdit?.idx)
    // myNewDate.setUTCHours(0, 0, 0, 0)
    const date = myNewDate.toISOString()
    const dateDB = addHours(myNewDate, Math.abs(new Date().getTimezoneOffset() / 60)).toISOString()

    if (type === 'all') {
      if (dataToEdit.recursivePlan) {
        try {
          const restaurant: any = dataToEdit.recursivePlan?.restaurant
          const id: any = dataToEdit.recursivePlan?._id
          const data = {
            plate: plateSelected,
            quantity: availablePlates,
            parameter: { IRr: IRr, FRr: FRr }
          }
          if (!disabledSaveToday()) {
            if (planSelected) {
              await updatePlanning({ ...data, quantity: availablePlates }, planSelected.restaurant as string, planSelected._id as string)
            } else {
              await createPlanning(
                { ...data, date: dateDB, dayWeek: new Date(date).toLocaleDateString('en-EN', { weekday: 'long' }) as DayWeek },
                restaurant
              )
            }
          }

          await updateRecursivePlanning(data, restaurant, id)
          showSnackbar('success', labels[lng].planSuccess)
          if (fetchData) fetchData()
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === 'configError') showSnackbar('error', labels[lng].planError)
            else showSnackbar('error', labels[lng].planError)
          }
        } finally {
          closeModal()
          setLoading(false)
        }
      } else {
        try {
          const restaurantId: any = restaurant?._id
          const dayEng = new Date(date).toLocaleDateString('en-EN', { weekday: 'long' }) as DayWeek

          const data: PlanningTypes = {
            plate: plateSelected,
            quantity: availablePlates,
            dayWeek: dayEng,
            parameter: { IRr: IRr, FRr: FRr }
          }
          await createRecursivePlanning(data, restaurantId)
          if (!disabledSaveToday()) {
            if (planSelected) {
              delete data.dayWeek
              await updatePlanning({ ...data, quantity: availablePlates }, planSelected.restaurant as string, planSelected._id as string)
            } else {
              data.date = dateDB
              await createPlanning(data, restaurantId)
            }
          }
          showSnackbar('success', labels[lng].planSuccess)
          if (fetchData) fetchData()
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === 'configError') showSnackbar('error', labels[lng].planError)
            else showSnackbar('error', labels[lng].planError)
          }
        } finally {
          closeModal()
          setLoading(false)
        }
      }
    }
    if (type === 'today') {
      if (planSelected) {
        try {
          const restaurant: any = planSelected?.restaurant
          const id: any = planSelected._id
          const data = {
            plate: plateSelected,
            quantity: availablePlates,
            parameter: { IRr: IRr, FRr: FRr }
          }
          await updatePlanning(data, restaurant, id)
          showSnackbar('success', labels[lng].planSuccess)
          // if (fetchData) fetchData(planSelected?.restaurant)
          if (fetchData) fetchData()
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === 'configError') showSnackbar('error', labels[lng].planError)
            else showSnackbar('error', labels[lng].planError)
          }
        } finally {
          closeModal()
          setLoading(false)
        }
      } else {
        try {
          const restaurantId: any = restaurant?._id
          const dayEng = new Date(date).toLocaleDateString('en-EN', { weekday: 'long' }) as DayWeek

          const data = {
            plate: plateSelected,
            quantity: availablePlates,
            dayWeek: dayEng,
            date: dateDB,
            parameter: { IRr: IRr, FRr: FRr }
          }

          await createPlanning(data, restaurantId)
          showSnackbar('success', labels[lng].planSuccess)
          if (fetchData) fetchData()
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === 'configError') showSnackbar('error', labels[lng].planError)
            else showSnackbar('error', labels[lng].planError)
          }
        } finally {
          closeModal()
          setLoading(false)
        }
      }
    }
  }

  const handleClickCard = (newPlateId: string) => {
    if (plateSelected === newPlateId) setPlateSelected('')
    else setPlateSelected(newPlateId)
  }

  const debouncedValue = useDebouncedValue(availablePlates?.toString(), 700)

  useEffect(() => {
    const mpod = +restaurant?.parameter?.MPOD.value || 0
    if (availablePlates && availablePlates < mpod) setAvailablePlates(mpod)
  }, [debouncedValue])

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (Number.isInteger(+event.target.value)) setAvailablePlates(+event.target.value)
  }

  const handleTime = (
    time: {
      hour: number
      minutes: number
    },
    type: string
  ) => {
    if (type === 'FRr') {
      setFRr(time)
    } else if (type === 'IRr') {
      setIRr(time)
    }
  }
  const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1)
  const getDay = (only?: boolean) => {
    if (dataToEdit?.dayWeek.includes(',')) {
      if (only) return `${labels[lng].only} ` + dataToEdit?.dayWeek.split(',')[0].toLocaleLowerCase() || ''
      return dataToEdit?.dayWeek.split(',')[1].toLocaleLowerCase() || ''
    } else {
      if (only) {
        const dayWeeks = [
          labels[lng].Monday,
          labels[lng].Tuesday,
          labels[lng].Wednesday,
          labels[lng].Thursday,
          labels[lng].Friday,
          labels[lng].Saturday,
          labels[lng].Sunday
        ]
        const dayWeek = dataToEdit?.dayWeek
        const index = dayWeeks.indexOf(dayWeek)
        const currentDay = new Date().getDay() || 7

        const label =
          currentDay > index ? `${window.innerWidth < 890 ? labels[lng].onlyNextMob : labels[lng].onlyNext} ` : `${labels[lng].onlyThis} `
        if (lng === 'en') {
          return label + dataToEdit?.dayWeek || ''
        }
        return label + dataToEdit?.dayWeek.toLocaleLowerCase() || ''
      } else return dataToEdit?.dayWeek.toLocaleLowerCase() || ''
    }
  }

  const getDayWhitPlurals = (button = false) => {
    let day
    if (dataToEdit.dayWeek.includes(',')) {
      day = dataToEdit.dayWeek.split(',')[1].toLocaleLowerCase().trim()
    } else {
      day = dataToEdit?.dayWeek.toLocaleLowerCase().trim()
    }
    if (lng === 'en') {
      if (button) {
        return capitalize(`${day}`.trim())
      }
      return capitalize(`${day}s`.trim())
    }
    if (['sábado', 'domingo'].includes(day)) day = `${day}s`
    return day || ''
  }

  const showBanner = (canEditTomorrow?: boolean) => {
    const FRR = restaurant?.parameter?.FRR
    const EMHIRR = restaurant?.parameter?.EMHIRR
    const date = new Date()
    const EMHIRRi = subHours(getEMHIRR(EMHIRR, FRR, addDays(date, dataToEdit.idx)), Math.abs(new Date().getTimezoneOffset() / 60))
    if (dataToEdit.whatDayIs === 'today') {
      if (EMHIRRi.getDate() === date.getDate() && EMHIRRi > date) {
        return `${labels[lng].canModifyPlanToday} ${parameterToTime(restaurant?.parameter?.EMHIRR)}`
      }
      return `${labels[lng].cantModifyToday} ${getDayWhitPlurals()}`
    }
    if (dataToEdit.whatDayIs === 'tomorrow' && restaurant) {
      if (canEditTomorrow) {
        return `${labels[lng][EMHIRRi.getDate() === date.getDate() ? 'canModifyPlan' : 'canModifyPlanTomorrow']} ${parameterToTime(
          restaurant.parameter?.EMHIRR
        )}`
      }
      return `${labels[lng].cantModifyTomorrow} ${getDayWhitPlurals()}`
    }
    return false
  }

  const stepOneValidation = () => {
    const mpod = +restaurant?.parameter?.MPOD.value || 0
    if (new Date(0, 0, 0, IRr.hour, IRr.minutes) < new Date(0, 0, 0, parameters?.IR?.hour, parameters?.IR?.minutes)) return true
    if (IRr.hour > FRr.hour) return true
    if (IRr.hour === FRr.hour && IRr.minutes === FRr.minutes) return true
    if (IRr.hour === FRr.hour && IRr.minutes > FRr.minutes) return true
    if ((IRr.hour === FRr.hour && IRr.minutes < FRr.minutes) || IRr.hour < FRr.hour) {
      return !(availablePlates && availablePlates >= mpod)
    }
  }

  const onDeletePlanning = async (type: 'simple' | 'recursive') => {
    try {
      if (type === 'simple' && dataToEdit.plan) {
        await deletePlanning(dataToEdit.plan.restaurant, dataToEdit.plan._id)
        showSnackbar('success', labels[lng].planSuccess)
      }
      if (type === 'recursive') {
        let success = false
        if (dataToEdit.recursivePlan) {
          success = true
          await deleteRecursivePlanning(dataToEdit.recursivePlan.restaurant, dataToEdit.recursivePlan._id)
        }
        showSnackbar('success', labels[lng].planSuccess)
        if (!disabledSaveToday() && dataToEdit.plan) {
          success = true
          await deletePlanning(dataToEdit.plan.restaurant, dataToEdit.plan._id)
        }
        if (success) {
          showSnackbar('success', labels[lng].planSuccess)
        }
      }

      if (fetchData) fetchData()
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', labels[lng].planError)
    } finally {
      closeModal()
    }
  }

  const Banner = ({ margin }: { margin: boolean }) => {
    let canEditTomorrow = true

    const myNewDate = new Date()

    myNewDate.setDate(myNewDate.getDate() + dataToEdit?.idx)
    const FRR = restaurant?.parameter?.FRR
    const EMHIRR = restaurant?.parameter?.EMHIRR
    const EMHIRRi = subHours(getEMHIRR(EMHIRR, FRR, myNewDate), Math.abs(new Date().getTimezoneOffset() / 60))
    canEditTomorrow = restaurant ? new Date() < EMHIRRi : true
    const addMargin = margin ? { marginLeft: '16px', marginRight: '16px' } : {}
    return (
      <HeadBanner sx={{ backgroundColor: canEditTomorrow ? '#F9FAFB' : '#FEF2F2', ...addMargin, px: '16px' }}>
        {canEditTomorrow ? <Info style={{ flexShrink: 0 }} /> : <InfoRed style={{ flexShrink: 0 }} />}
        <Typography sx={{ color: canEditTomorrow ? '#6B7280' : red[900], textAlign: 'left !important' }} variant="subtitle2" component="h6">
          {showBanner(canEditTomorrow)}
        </Typography>
      </HeadBanner>
    )
  }

  const getPlateName = (plate) => {
    return lng === 'en' ? (plate?.translations && plate?.translations[0] ? plate?.translations[0].name : plate.name) : plate.name
  }

  if (!dataToEdit) return <></>

  return (
    <Modal
      fullScreen={window?.innerWidth < 890}
      noCloseIcon={window?.innerWidth < 890}
      size={finalStep === 0 ? 'md' : 'sm'}
      title={dataToEdit?.dayWeek || ''}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterFormBox
          sx={{
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
              width: '100%',
              padding: 0
            }
          }}
        >
          {[0, 1].includes(finalStep) && window?.innerWidth > 890 ? (
            <Typography variant="subtitle2">
              {plates.find((elm) => elm._id === plateSelected) ? getPlateName(plates.find((elm) => elm._id === plateSelected)) : ''}
            </Typography>
          ) : (
            <div />
          )}
          <Stack
            sx={{
              [theme.breakpoints.down('md')]: {
                width: '100%',
                padding: 0
              }
            }}
            spacing={2}
            direction="row"
          >
            {finalStep === 0 && (
              <>
                <Button
                  className="button-mobile"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      width: '50%',
                      fontSize: '14px !important',
                      lineHeight: '24px'
                    }
                  }}
                  variant="outlined"
                  color="info"
                  onClick={closeModal}
                >
                  {labels[lng].cancel}
                </Button>
                <Button
                  className="button-mobile"
                  sx={{
                    ml: 2,
                    [theme.breakpoints.down('md')]: {
                      width: '50%',
                      fontSize: '14px !important',
                      lineHeight: '24px'
                    }
                  }}
                  onClick={() => setFinalStep(finalStep + 1)}
                  variant="contained"
                >
                  {labels[lng].next}
                </Button>
              </>
            )}
            {finalStep === 1 && (
              <>
                {plateSelected ? (
                  <>
                    <Button
                      className="button-mobile"
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          width: '50%',
                          fontSize: '14px !important',
                          lineHeight: '24px'
                        }
                      }}
                      variant="outlined"
                      color="info"
                      onClick={() => setFinalStep(finalStep - 1)}
                    >
                      {labels[lng].return}
                    </Button>
                    <Button
                      className="button-mobile"
                      sx={{
                        ml: 2,
                        [theme.breakpoints.down('md')]: {
                          width: '50%',
                          fontSize: '14px !important',
                          lineHeight: '24px'
                        }
                      }}
                      onClick={() => setFinalStep(finalStep + 1)}
                      variant="contained"
                      disabled={stepOneValidation()}
                    >
                      {labels[lng].save}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="button-mobile"
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          width: '50%',
                          fontSize: '14px !important',
                          lineHeight: '24px'
                        }
                      }}
                      variant="outlined"
                      color="info"
                      onClick={() => setFinalStep(finalStep - 1)}
                    >
                      {labels[lng].return}
                    </Button>
                    <Button
                      className="button-mobile"
                      sx={{
                        ml: 2,
                        [theme.breakpoints.down('md')]: {
                          width: '50%',
                          fontSize: '14px !important',
                          lineHeight: '24px'
                        }
                      }}
                      variant="contained"
                      onClick={() => setFinalStep(finalStep + 1)}
                    >
                      {labels[lng].continue}
                    </Button>
                  </>
                )}
              </>
            )}
            {finalStep === 2 && (
              <>
                {plateSelected ? (
                  <>
                    <Button
                      className="button-mobile"
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          width: '50%',
                          fontSize: '14px !important',
                          lineHeight: '24px'
                        }
                      }}
                      disabled={disabledSaveToday() || loading}
                      variant="outlined"
                      color="info"
                      onClick={() => onSave('today')}
                    >
                      {getDay(true)}
                    </Button>
                    <Button
                      className="button-mobile"
                      sx={{
                        ml: 2,
                        [theme.breakpoints.down('md')]: {
                          width: '50%',
                          fontSize: '14px !important',
                          lineHeight: '24px'
                        }
                      }}
                      onClick={() => onSave('all')}
                      variant="contained"
                      disabled={loading}
                    >
                      {labels[lng].allThe} {getDayWhitPlurals(true)}
                    </Button>
                  </>
                ) : (
                  <>
                    {/* {dataToEdit.plan && ( */}
                    <Button
                      className="button-mobile"
                      disabled={disabledSaveToday()}
                      sx={{
                        ml: 2,
                        [theme.breakpoints.down('md')]: {
                          width: '50%',
                          fontSize: '14px !important',
                          lineHeight: '24px',
                          ml: 0
                        }
                      }}
                      color="info"
                      onClick={() => onDeletePlanning('simple')}
                      variant="outlined"
                    >
                      {getDay(true)}
                    </Button>
                    {/* )} */}
                    {/* {dataToEdit.recursivePlan && ( */}
                    <Button
                      className="button-mobile"
                      sx={{
                        ml: 2,
                        [theme.breakpoints.down('md')]: {
                          width: '50%',
                          fontSize: '14px !important',
                          lineHeight: '24px'
                        }
                      }}
                      onClick={() => onDeletePlanning('recursive')}
                      variant="contained"
                    >
                      {labels[lng].allThe} {getDayWhitPlurals(true)}
                    </Button>
                    {/* )} */}
                  </>
                )}
              </>
            )}
          </Stack>
        </FooterFormBox>
      }
    >
      {finalStep === 0 && (
        <Box sx={{ p: 2, margin: 'auto' }}>
          {!!showBanner() && <Banner margin={false} />}
          {!empty && (
            <Box sx={{ mb: 2 }}>
              <SearchInput fullwidth placeholder={labels[lng].searchPlate} name={'search'} handleSubmit={handleSearch} clean />
            </Box>
          )}
          <Grid container rowSpacing={'16px'} columnSpacing={'16px'} sx={{ minHeight: '400px' }}>
            {plates && !empty ? (
              plates.length > 0 ? (
                plates.map((plate: PlateTypes) => (
                  <Grid key={plate._id} item sm={6} xs={12} md={4}>
                    <CardDishPreview plate={plate} handleClick={handleClickCard} selected={plateSelected === plate._id} />
                  </Grid>
                ))
              ) : (
                <Typography
                  sx={{
                    ml: 3,
                    mt: 3,
                    [theme.breakpoints.down('md')]: {
                      ml: 2,
                      mt: 2
                    }
                  }}
                  variant="body1"
                >
                  {labels[lng].noResultsPlanning}
                </Typography>
              )
            ) : (
              <EmptyPage
                title={labels[lng].emptyPlatesTitle}
                text={labels[lng].emptyPlatesText}
                Icon={PedidosEmpty}
                button={labels[lng].addDishesToRestaurant}
                sx={{ backgroundColor: 'inherit', width: '100%', height: '400px', marginLeft: '32px' }}
              />
            )}
          </Grid>
        </Box>
      )}

      {finalStep === 1 && (
        <>
          {plateSelected ? (
            <Box sx={{ mb: 3, mt: '16px' }}>
              {!!showBanner() && <Banner margin />}
              {window?.innerWidth < 890 ? (
                <Typography pl="16px" color="#374151" fontWeight={'500'} variant="subtitle2">
                  {plates.find((elm) => elm._id === plateSelected) ? getPlateName(plates.find((elm) => elm._id === plateSelected)) : ''}
                </Typography>
              ) : (
                <div />
              )}
              <Box
                sx={{
                  height: '1px',
                  backgroundColor: '#E5E7EB',
                  width: '100%',
                  marginTop: '16px',
                  [theme.breakpoints.up('md')]: {}
                }}
              />
              <TimeRangePickerSelect
                label={labels[lng].pickUpTime}
                handleChange={handleTime}
                valueFrom={IRr}
                valueTo={FRr}
                nameFrom="IRr"
                nameTo="FRr"
                minValueHour={parameters?.IR?.hour || 0}
                minValueMin={parameters?.IR?.minutes || 0}
                maxValueHour={parameters?.FR?.hour || 0}
                maxValueMin={parameters?.FR?.minutes || 0}
              />
              <Box
                sx={{
                  height: '1px',
                  backgroundColor: '#E5E7EB',
                  width: '100%',
                  [theme.breakpoints.up('md')]: {}
                }}
              />
              <TextInput
                value={availablePlates || ''}
                handleChange={handleChange}
                name="availablePlates"
                text={labels[lng].availablePlates}
                placeholder={labels[lng].plates}
                type="number"
                inputProps={{
                  min: +restaurant?.parameter?.MPOD.value || 0
                }}
                noTopBorder
              />
              <Box
                sx={{
                  height: '1px',
                  backgroundColor: '#E5E7EB',
                  width: '100%',
                  [theme.breakpoints.up('md')]: {}
                }}
              />
            </Box>
          ) : (
            <Box sx={{ mb: 3, mt: '16px' }}>
              {!!showBanner() && <Banner margin />}
              <Box
                sx={{
                  width: 400,
                  m: 'auto',
                  [theme.breakpoints.down('md')]: {
                    width: '100%'
                  }
                }}
                className="flex-column-center"
              >
                <ModalTitle
                  sx={{
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      padding: '8px 16px',
                      boxShadow: 'none'
                    }
                  }}
                >
                  {labels[lng].planAddEditStep2Title}
                </ModalTitle>
                <DialogContent sx={{ pt: 1, mb: 2 }}>
                  <DialogContentText
                    sx={{
                      textAlign: 'center',
                      [theme.breakpoints.down('md')]: {
                        width: 'calc(100% - 32px)',
                        maxWidth: '343px',
                        mx: 'auto'
                      }
                    }}
                  >
                    {labels[lng].planAddEditStep2Text}
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Box>
          )}
        </>
      )}
      {finalStep === 2 && (
        <>
          <Box sx={{ mb: 3, mt: '16px' }}>
            {!!showBanner() && <Banner margin />}
            <Box
              sx={{
                width: 350,
                m: 'auto',
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              }}
              className="flex-column-center"
            >
              <ModalTitle
                sx={{
                  textAlign: 'center',
                  [theme.breakpoints.down('md')]: {
                    padding: '8px 16px',
                    boxShadow: 'none',
                    maxWidth: '350px'
                  }
                }}
              >
                {labels[lng].planAddEditStep3Title} {getDayWhitPlurals(true)}?
              </ModalTitle>
              <DialogContent
                sx={{
                  pt: 1,
                  mb: 2,
                  [theme.breakpoints.down('md')]: {
                    padding: '0px 16px !important'
                  }
                }}
              >
                <DialogContentText sx={{ textAlign: 'center' }}>{labels[lng].planAddEditStep3Text}</DialogContentText>
              </DialogContent>
            </Box>
          </Box>
        </>
      )}
    </Modal>
  )
}
