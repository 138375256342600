import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import SearchInput from 'src/components/SearchInput'
import CardDishPreview from 'src/components/Cards/CardDishPreview'
import SkeletonCards from 'src/components/Cards/SkeletonCards'
import EmptyPage from 'src/components/EmptyPage'

import { HeaderContext } from 'src/context/header/headerContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { labels } from 'src/labels/main_labels'
import { PlateTypes } from 'src/interfaces/pages.types'
import { ContainerFud } from 'src/styles/general-styles'
import { ReactComponent as PedidosEmpty } from 'src/assets/icons/Empty/PedidosEmpty.svg'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { translatePlate } from 'src/utils/translate.util'

export default function Platos(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const history = useHistory()
  const { showHeader } = useContext(HeaderContext)
  const { restaurant, loading } = useContext(RestaurantContext)
  const [plates, setPlates] = useState<PlateTypes[] | null>(null)
  const [empty, setEmpty] = useState<boolean>(false)
  const [initHeader, setInitHeader] = useState(false)

  useEffect(() => {
    if (restaurant) setInitHeader(true)
    if (restaurant && restaurant.plates) {
      setPlates(restaurant.plates)
      if (restaurant.plates.length === 0) setEmpty(true)
    }
  }, [restaurant])

  const handleSearch = (word: string) => {
    if (!word) {
      if (restaurant && restaurant.plates) {
        setPlates(restaurant.plates)
        if (restaurant.plates.length === 0) setEmpty(true)
      }
      return
    }
    if (plates) {
      const _plates = translatePlate(plates, i18n.language)
      const filtered = _plates.filter((row: any) => row.name.toLowerCase().includes(word.toLowerCase()))
      setPlates(filtered)
    }
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <>
        <Typography
          variant="h3"
          component="h1"
          textAlign={'center'}
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          {labels[lng].plates}
        </Typography>

        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
          className="flex-between"
        >
          <SearchInput placeholder={labels[lng].searchPlate} name="search" handleSubmit={handleSearch} clean />
        </Box>
      </>
    )
  }
  useEffect(() => {
    showHeader(Header)
  }, [])

  useEffect(() => {
    if (initHeader) showHeader(Header)
  }, [initHeader])

  const handleClickCard = (idPlato: string) => {
    history.push(`/platos/${idPlato}`)
  }

  return (
    <>
      <Box
        sx={{
          px: '16px',
          pt: '16px',
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        }}
      >
        <SearchInput fullwidth placeholder={labels[lng].searchPlate} name="search" handleSubmit={handleSearch} clean />
      </Box>
      {plates && plates.length > 0 && !empty ? (
        <>
          <ContainerFud>
            {loading && <SkeletonCards />}
            <Grid container rowSpacing={{ xs: 4, sm: 4, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {plates.map((plate: PlateTypes) => (
                <Grid key={plate._id} item sm={6} xs={12} md={4} lg={3}>
                  <CardDishPreview plate={plate} handleClick={handleClickCard} />
                </Grid>
              ))}
            </Grid>
          </ContainerFud>
        </>
      ) : (
        !empty && (
          <Typography
            sx={{
              ml: 3,
              mt: 3,
              [theme.breakpoints.down('md')]: {
                ml: 2,
                mt: 2
              }
            }}
            variant="body1"
          >
            {labels[lng].noResultsPlanning}
          </Typography>
        )
      )}
      {!plates ||
        (empty && (
          <EmptyPage
            small
            title={labels[lng].emptyPlatesTitle}
            text={labels[lng].emptyPlatesText}
            Icon={PedidosEmpty}
            button={labels[lng].addDishesToRestaurant}
          />
        ))}
    </>
  )
}
