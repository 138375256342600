import { labels } from 'src/labels/main_labels'

export const HEADER_BAR_HEIGHT = '60px'
export const FOOTER_BAR_HEIGHT = '52px'
export const DRAWER_WIDTH_OPEN = 255
export const DRAWER_WIDTH_CLOSE = 124
export const CARD_PLANNING_HEIGHT = 350
export const CARD_DISH_HEIGHT = 337
export const CARD_DISH_WIDTH = 270

export const OPEN_LAYOUT_DEFAULT = true
export const CONTAINER_WIDTH_DEFAULT = 1250

export const termsAndConditionsUrl = 'https://www.fudclub.com/terminos-y-condiciones'
export const privacyPolicy = 'https://www.fudclub.com/politica-privacidad'
export const cookiesPolicy = 'https://www.fudclub.com/politica-cookies'

export const languages = [
  { value: 'es', text: 'español' },
  { value: 'en', text: 'inglés' }
]

export const StylesFoodValues = ['ESP', 'MDT', 'ITA', 'HMB', 'PZZ', 'AMCN', 'LTN', 'MX', 'INT', 'HLT', 'ASN', 'JPN']

export const AllergensValues = ['IND', 'GLT', 'CTC', 'EGG', 'FISH', 'PNT', 'SOY', 'MLK', 'NUT', 'CLY', 'MTD', 'SSM', 'SPT', 'MSC', 'ATC']

export const daysOfTheWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const statiticsOptionsSelect = [
  { value: 'days', label: 'statiticsDays' },
  { value: 'weeks', label: 'statiticsWeeks' },
  { value: 'months', label: 'statiticsMonths' }
]

export const genderOptionsSelect = [
  { value: '', label: '-' },
  { value: 'Woman', label: labels.es.Woman },
  { value: 'Man', label: labels.es.Man }
]

export const EDIT_PLATE_FIELDS = [
  'allergens',
  'description',
  'foodStyles',
  'images',
  'income',
  'ingredients',
  'name',
  'parameter',
  'price',
  'restaurant',
  'reviews',
  'translations'
]

export const COUPON_TYPES = ['normal', 'special', 'enterprise']
