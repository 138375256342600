import IRoute from '../interfaces/routes.types'
import RestaurantesPage from '../pages/InternalPlatform/Restaurantes'
import RestaurantesDetailPage from '../pages/InternalPlatform/RestauranteDetail'
import EstilosPage from '../pages/InternalPlatform/Estilos'
import IngredientesPage from '../pages/InternalPlatform/Ingredientes'
import GruposPage from '../pages/InternalPlatform/Grupos'
import CodigosPage from '../pages/InternalPlatform/Codigos'
import BonosPage from '../pages/InternalPlatform/Bonos'
import UsuariosPage from '../pages/InternalPlatform/Usuarios'
import UsuariosDetailPage from '../pages/InternalPlatform/UsuariosDetail'
import PlatosDetailPage from '../pages/Common/Platos/PlatosDetail'
import ParametrosPage from '../pages/InternalPlatform/Parametros'
import AyudaPage from '../pages/Ayuda'
import FaqPage from '../pages/Ayuda/Ayuda-PreguntasFrecuentes'
import GruposDetailPage from '../pages/InternalPlatform/Grupos/GruposDetail'

const adminRoutes: IRoute[] = [
  {
    path: '/restaurantes',
    name: 'Restaurantes',
    component: RestaurantesPage,
    exact: true
  },
  {
    path: '/restaurantes/:idRestaurant',
    name: 'Restaurantes',
    component: RestaurantesDetailPage,
    exact: true
  },
  {
    path: '/restaurantes/:idRestaurant/:navRestaurant',
    name: 'Restaurante Detalles',
    component: RestaurantesDetailPage,
    exact: true
  },
  {
    path: '/restaurantes/:idRestaurant/platos/:plato',
    name: 'Platos',
    component: PlatosDetailPage,
    exact: true
  },
  {
    path: '/restaurantes/:idRestaurant/platos/:plato/:navPlatos',
    name: 'Platos',
    component: PlatosDetailPage,
    exact: true
  },
  {
    path: '/estilos',
    name: 'Estilos',
    component: EstilosPage,
    exact: true
  },
  {
    path: '/etiquetas',
    name: 'Etiquetas',
    component: IngredientesPage,
    exact: true
  },
  {
    path: '/grupos',
    name: 'Grupos',
    component: GruposPage,
    exact: true
  },
  {
    path: '/grupos/:idGroup',
    name: 'Grupo Detalles',
    component: GruposDetailPage,
    exact: true
  },
  {
    path: '/codigos-descuento',
    name: 'Codigos',
    component: CodigosPage,
    exact: true
  },
  {
    path: '/bonos',
    name: 'Bonos',
    component: BonosPage,
    exact: true
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: UsuariosPage,
    exact: true
  },
  {
    path: '/usuarios/:idUser',
    name: 'Usuario Detalles',
    component: UsuariosDetailPage,
    exact: true
  },
  {
    path: '/usuarios/:idUser/:navUser',
    name: 'Usuario Detalles',
    component: UsuariosDetailPage,
    exact: true
  },
  {
    path: '/parametros',
    name: 'Parametros',
    component: ParametrosPage,
    exact: true
  },
  {
    path: '/ayuda',
    name: 'Ayuda',
    component: AyudaPage,
    exact: true
  },
  {
    path: '/ayuda/faq',
    name: 'Ayuda Faq',
    component: FaqPage,
    exact: true
  }
]

export default adminRoutes
