import { useState, useEffect } from 'react'
import firebase from '../firebase/firebase'
import Cookies from 'universal-cookie'

interface Props {
  userDataPresent: boolean
  user: any | null
}

function useAuthentication(): Props {
  const [authenticateUser, setAuthenticateUser] = useState<Props>({
    userDataPresent: false,
    user: null
  })
  const cookies = new Cookies()

  useEffect(() => {
    const unsuscribe = firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthenticateUser({ userDataPresent: true, user: user })

        cookies.set('fudClub', (user as any).auth.currentUser.accessToken, { path: '/', maxAge: 12 * 60 * 60 * 24 * 1000 })
      } else {
        setAuthenticateUser({ userDataPresent: true, user: null })

        cookies.remove('fudClub')
      }
    })
    return () => unsuscribe()
  }, [])

  return authenticateUser
}

export default useAuthentication
