import React, { useState } from 'react'
import { Stack, Paper, Popover, Typography, Box } from '@mui/material'
import { ReactComponent as Calendar } from 'src/assets/icons/general/Calendar.svg'
import { ReactComponent as ChevronDown } from 'src/assets/icons/general/ChevronDown.svg'
import CheckIcon from '@mui/icons-material/Check'

import { StatiticsButton } from './StatiticsSelect.styles'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

interface StatiticsSelectProps {
  setter: React.Dispatch<React.SetStateAction<string>>
  value: string

  options: Array<{ value: any; label: string }>
}

export default function StatiticsSelect({ value, setter, options }: StatiticsSelectProps): JSX.Element {
  const text = options.find((elm) => elm.value === value)
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  // POPOVER
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => setAnchorEl(null)
  const openPopover = Boolean(anchorEl)
  const idPop = openPopover ? 'statitics-popover' : undefined

  const handleAction = (option: { value: any; label: string }) => {
    handleClosePopover()
    setter(option.value)
  }

  return (
    <Box sx={{ minWidth: 290, display: 'flex', justifyContent: 'flex-end' }}>
      <StatiticsButton aria-describedby={idPop} onClick={(e) => handlePopover(e)} className="select-stack">
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ ml: '19.5px', mr: '41px' }}>
          <Box sx={{ pt: '7px' }}>
            <Calendar />
          </Box>
          <Typography variant="subtitle2" sx={{ color: '#374151', ml: '10.5px' }}>
            {text && labels[lng][text.label]}
          </Typography>
        </Stack>
        <div className="chevron-position">
          <ChevronDown />
        </div>
      </StatiticsButton>
      <Popover
        id={idPop}
        elevation={1}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 256, py: 1 }}>
          {options &&
            options.map((option, idx) => (
              <Stack
                sx={{ height: 36, width: '100%', px: 2, py: '12px', cursor: 'pointer' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                key={idx}
                onClick={() => handleAction(option)}
              >
                <span>{labels[lng][option.label]}</span>
                <span>{option.value === value && <CheckIcon color="primary" />}</span>
              </Stack>
            ))}
        </Paper>
      </Popover>
    </Box>
  )
}

// import MenuItem from '@mui/material/MenuItem'
// import Select, { SelectChangeEvent } from '@mui/material/Select'
// import { Box, Typography } from '@mui/material'
// import { ReactComponent as Calendar } from 'src/assets/icons/general/Calendar.svg'
// import { ReactComponent as ChevronDown } from 'src/assets/icons/general/ChevronDown.svg'
// import CheckIcon from '@mui/icons-material/Check'

// import { StatiticsFormControl } from './StatiticsSelect.styles'

// interface StatiticsSelectProps {
//   handleChange: (ev: SelectChangeEvent<string>) => void
//   value: string
//   options: Array<{ value: any; label: string }>
// }

// export default function StatiticsSelect({ value, handleChange, options }: StatiticsSelectProps): JSX.Element {
//   const text = options.find((elm) => elm.value === value)

//   return (
//     <StatiticsFormControl>
//       <Box>
//         <Calendar />
//       </Box>
//       <Select
//         id="hola"
//         value={value}
//         name={'statitics-select'}
//         onChange={handleChange}
//         IconComponent={ChevronDown}
//         renderValue={() => {
//           return (
//             <Typography variant="subtitle2" sx={{ color: '#374151' }}>
//               {text && text.label}
//             </Typography>
//           )
//         }}
//       >
//         {options &&
//           options.map((option, idx) => (
//             <MenuItem
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'space-between'
//               }}
//               key={idx}
//               value={option.value}
//             >
//               {option.label}&nbsp;{option.value === value && <CheckIcon color="primary" />}
//             </MenuItem>
//           ))}
//       </Select>
//     </StatiticsFormControl>
//   )
// }
