import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as ChartHover } from 'src/assets/icons/general/ChartHover.svg'
import { Box, Stack, Typography } from '@mui/material'

import { CartesianGrid, Tooltip, BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
}
export interface ChartProps {
  title: string
  dataChart: any
  IncomeFilter?: React.FC
}

export function RestaurantsChart({ title, dataChart, IncomeFilter }: ChartProps): JSX.Element {
  const theme = useTheme()
  const [posData, setposData] = useState<Record<string, number>>({ x: 0, y: 0 })
  const [chartInfo, setChartInfo] = useState<any>(null)
  const [isToolTipActive, setIsToolTipActive] = useState(false)

  useEffect(() => {
    setChartInfo(dataChart)
  }, [dataChart])

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ position: 'relative' }}>
          <ChartHover />
          <Typography
            sx={{ color: '#fff', position: 'absolute', top: '3px', left: '0px', width: '100%', textAlign: 'center' }}
            variant="subtitle2"
          >{`${payload[0].value}`}</Typography>
        </Box>
      )
    }
    return null
  }

  return (
    <Box
      sx={{
        my: 2,
        border: '1px solid #E5E7EB',
        borderRadius: 2,
        boxSizing: 'border-box',
        height: '400px',
        py: 2,
        '& .recharts-xAxis .recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-value': {
          display: 'none'
        },
        '& .recharts-xAxis .recharts-cartesian-axis-tick:first-child .recharts-cartesian-axis-tick-value, & .recharts-xAxis .recharts-cartesian-axis-tick:last-child .recharts-cartesian-axis-tick-value':
          {
            display: 'block'
          },
        '& .recharts-cartesian-axis-tick-line, & .recharts-cartesian-axis-line': {
          stroke: '#D1D5DB'
        },
        '& .recharts-cartesian-axis-tick-value tspan': {
          color: '#9CA3AF',
          fill: '#9CA3AF',
          fontSize: '10px',
          lineHeight: '12px',
          fontWeight: 700
        },
        [theme.breakpoints.down('md')]: {
          height: '416px',
          '& .recharts-cartesian-axis-tick-value tspan': {
            color: '#9CA3AF',
            fill: '#9CA3AF',
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 700
          }
        }
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
          px: 4,
          height: '38px',
          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
            height: '66px',
            px: 0
          }
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '18px',
            [theme.breakpoints.down('md')]: {
              fontSize: '14px',
              lineHeight: '20px',
              textAlign: 'center',
              mb: '16px'
            }
          }}
        >
          {title}
        </Typography>
        {IncomeFilter && <IncomeFilter />}
      </Stack>
      <ResponsiveContainer height={window.innerWidth < 890 ? '85%' : '90%'}>
        <BarChart
          width={40}
          height={40}
          margin={{
            left: window.innerWidth < 890 ? -30 : 0,
            bottom: 20,
            top: 10,
            right: window.innerWidth < 890 ? 20 : 40
          }}
          data={chartInfo}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Tooltip
            isAnimationActive={false}
            cursor={{ fill: 'transparent' }}
            position={{ x: posData.x + posData.width / 2 - 27, y: posData.y - 32 }}
            content={isToolTipActive ? <CustomTooltip /> : <></>}
          />
          <XAxis interval={0} dataKey="day" stroke={theme.palette.text.secondary} />
          <YAxis dataKey="amount" axisLine={false} stroke={theme.palette.text.secondary} />
          <Bar
            dataKey="amount"
            fill="#EB435A"
            radius={[35, 35, 0, 0]}
            onMouseMove={(data) => {
              setposData(data)
            }}
            onMouseEnter={() => setIsToolTipActive(true)}
            onMouseOut={() => setIsToolTipActive(false)}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
