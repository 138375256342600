type lng = 'en' | 'es'

export interface LabelType {
  [key: lng | string]: Record<string, string>
}

export const labels: LabelType = {
  es: {
    fudclub: 'Fudclub',
    login: 'Iniciar Sesión',
    loginTitle: 'Acceso Restaurantes Fudclub',
    language: 'Idioma',
    spanish: 'Español',
    english: 'English',
    password: 'Contraseña',
    passwordChanged: 'La contraseña ha sido actualizada correctamente',
    passwordChangingError: 'Ha ocurrido un problema al intentar actualizar la contraseña, por favor intentelo mas tarde.',
    incorrectPassword: 'La contraseña actual introducida es incorrecta',
    passwordRecoveryTitle: 'Contraseña cambiada con éxito',
    passwordRecoveryText: 'La contraseña asociada a tu cuenta de Fudclub ha sido actualizada correctamente.',
    newPassword: 'Nueva contraseña',
    repeatNewPassword: 'Repite tu nueva contraseña',
    maintainSession: 'Mantener sesión iniciada',
    forgotPass: 'He olvidado mi contraseña',
    newEmail: 'Nuevo email',
    changeEmail: 'Cambiar email',
    emailInUse: 'Este email ya está en uso',
    weSendEmail: 'Te hemos enviado un email',
    weSendEmail2: 'Hemos enviado un enlace a tu nuevo email para verificar tu cuenta.',
    recoverYourPassword:
      'Consulta tu bandeja de entrada para restablecer tu contraseña. Es posible que tengas que comprobar la carpeta de correo no deseado.',
    errorWhenSendingEmail1: 'Error en el envío de email',
    errorWhenSendingEmail2: 'Ha ocurrido un error al enviar el email de restaurar contraseña, por favor intentelo de nuevo mas tarde.',
    search: 'Buscar',
    update: 'Actualizar',
    edit: 'Editar',
    add: 'Añadir',
    accept: 'Aceptar',
    downloadQr: 'Descargar QR',
    delete: 'Eliminar',
    cancel: 'Cancelar',
    cancelled: 'Cancelado',
    hardCancelled: 'Cancelado sin devolución',
    name: 'Nombre',
    email: 'Email',
    surname: 'Apellidos',
    nameSurname: 'Nombre y apellidos',
    information: 'Información',
    payments: 'Pagos',
    state: 'Estado',
    address: 'Dirección',
    foodTypes: 'Tipos de cocina',
    foodType: 'Tipo de cocina',
    phone: 'Teléfono',
    message: 'Mensaje',
    description: 'Descripción',
    contactPerson: 'Persona de contacto',
    beneficiaryName: 'Nombre del beneficiario',
    beneficiary: 'Beneficiario',
    optional: 'Opcional',
    nationalId: 'NIF/CIF',
    nationalIdLabel: 'NIF/CIF de la cuenta bancaria',
    bic: 'BIC de la cuenta bancaria',
    iban: 'IBAN de la cuenta bancaria',
    emhirr: 'Hora 1 (EMHIRR)',
    frr: 'Hora 2 (FRR)',
    quantity: 'Cantidad',
    quality: 'Calidad',
    likes: 'Favoritos',
    allergens: 'Alérgenos',
    allergen: 'Alérgeno',
    noInfoAllergens: 'Información sobre alérgenos no disponible.',
    noAllergens: 'No contiene alérgenos.',
    platesSold: 'Platos vendidos',
    platesSoldSingular: 'Plato vendido',
    incomes: 'Ingresos',
    availableDays: 'Días disponibles',
    availableDaysSingular: 'Día disponible',
    dailyAverage: 'Media diaria',
    weeklyAverage: 'Media semanal',
    monthlyAverage: 'Media mensual',
    averageDayAvailable: 'Media por día disponible',
    averageDayAvailableShort: 'Media día disp.',
    statistics: 'Estadísticas',
    restaurantStatitics: 'Estadísticas de tu restaurante',
    ratings: 'Valoraciones',
    summary: 'Resumen',
    platesNumber: 'Nº platos',
    orders: 'Pedidos',
    createdAt: 'Fecha de creación',
    parameters: 'Parámetros',
    recommend: 'Recomendado',
    noRecommend: 'No recomendado',
    price: 'Precio',
    goToList: 'Ir al listado',
    toPlan: 'Planificar',
    paymentSchedule: 'Calendario & pagos',
    general: 'General',
    city: 'Ciudad',
    registerDate: 'Fecha de registro',
    contact: 'Contacto',
    neightborhood: 'Barrio',
    bankId: 'Información bancaria',
    permissions: 'Permisos',
    export: 'Exportar',
    deselectAll: 'Deseleccionar todos',
    selectAll: 'Seleccionar todos',
    selected: 'Seleccionado',
    selecteds: 'Seleccionados',
    deactivated: 'Desactivado',
    deactivate: 'Desactivar',
    deactivateAll: 'Modificar estado',
    activate: 'Activar',
    activated: 'Activado',
    value: 'Valor',
    history: 'Historial',
    hour: 'hora',
    minutes: 'minutos',
    min: 'min',
    ayuda: 'Help',
    faq: 'Preguntas frecuentes',
    faq_text: 'Consulta nuestra sección de preguntas frecuentes para resolver tus dudas.',
    how_it_works: 'Cómo funciona',
    how_it_works_text: 'Consulta cómo utilizar la plataforma de Fudclub y descubre todas sus funcionalidades.',
    helpCenter: 'Centro de ayuda',
    contactFudclub: 'Contactar con Fudclub',
    contactFudclub2: 'Contacta con Fudclub',
    someIncorrectData: '¿Algún dato incorrecto?',
    instagram: 'Instagram',
    deleted: 'Eliminado',
    recoverPassword: 'Recuperar contraseña',
    recoverPasswordTitle: 'He olvidado mi contraseña',
    recoverPasswordText: 'Introduce tu email para recuperar tu contraseña.',
    emailErrorWelcome: 'Email incorrecto. Prueba de nuevo.',
    emailError: 'Introduce tu email para recuperar tu contraseña.',
    notifications: 'Notificaciones',
    settings: 'Ajustes',
    logout: 'Cerrar sesión',
    logoutText: 'Estás a punto de cerrar sesión, ¿estás seguro?',
    next: 'Siguiente',
    return: 'Volver',
    save: 'Guardar',
    import: 'Importar',
    statusChanged: 'El estado ha sido cambiado',
    status: 'Estado',
    active: 'Activo',
    disabled: 'Inactivo',
    imagePickerText: 'Recomendado foto de 1200x1200. Formato PNG, JPG. Máximo 10 MB.',
    location: 'Localidad',
    restaurantSelectorText: 'Selecciona el restaurante al que quieres entrar',
    only: 'Solo',
    noResults: 'No hay resultados',
    moreThanThreeCharacters: 'Empieza a escribir para ver resultados',
    continue: 'Continuar',
    percent: 'Porcentaje',
    csvImport: 'Importar CSV',
    importUsers: 'Importar usuarios',
    waiting: 'Esperando',
    sales: 'Ventas',
    changePassword: 'Cambiar contraseña',
    changePasswordToContinue: 'Cambia tu contraseña para continuar',
    successDialogUpdatePassword: 'Contraseña cambiada con éxito',
    successDialogUpdatePasswordMessage: 'La contraseña asociada a tu cuenta de Fudclub ha sido actualizada correctamente.',
    planningDay: 'Planificación del dia',
    newOrdersCancelations: 'Nuevos pedidos y cancelaciones',
    pickupOrders: 'Recogida de pedidos',
    fudclubNews: 'Novedades del Fudclub',
    newOrdersCancelationsEmails: 'Emails sobre nuevos pedidos y cancelaciones',
    offersEmail: 'Emails con ofertas especiales y promociones',
    selectTime: 'Seleccionar hora',
    todayPlate: 'Plato de Hoy',
    tomorrowPlate: 'Plato de Mañana',
    date: 'Fecha',
    schedule: 'Horario',
    dispPlates: 'P. Disponibles',
    dispPlatesMob: 'P. Disp.',
    exportData: 'Exportar Datos ↓',
    yourRestaurant: 'Tu restaurante',
    management: 'Gestión',
    expirationDate: 'Fecha de vencimiento',
    recommendedByFudclub: 'Plato recomendado por Fudclub',
    Card: '↪ Por defecto:',
    loadMore: 'Cargar más',
    perPage: 'Por página',
    of: 'de',
    moreThan: 'más de',
    readAndAcceptPolicies1: 'He leído y acepto los',
    readAndAcceptPolicies2: ' y la',
    see: 'Ver',
    to: 'a',
    more: 'Más',
    configuration: 'Configuración',
    changeRestaurant: 'Cambiar restaurante',
    // HELP
    help: 'Ayuda',
    helpEmail: 'Si necesitas asistencia, ponte en contacto con nosotros en',
    helpInstagram: 'Síguenos en redes sociales y mantente al día de las novedades de Fudclub.',
    helpPhone: 'Si necesitas asistencia, ponte en contacto directo con nosotros en el ',
    fudclubPolitic: 'Política de Fudclub',
    termsConditions: 'Términos y condiciones',
    privacyPolicy: 'Política de privacidad',
    cookiesPolicy: 'Política de cookies',
    // STYLE
    editStyle: 'Editar estilo',
    addStyle: 'Añadir estilo',
    style: 'Estilo',
    styles: 'Estilos',
    searchStyle: 'Buscar estilo',
    nameStyle: 'Nombre del estilo',
    styleDeleted: 'El estilo ha sido borrado',
    styleCreated: 'El estilo ha sido creado',
    styleUpdated: 'El estilo ha sido actualizado',
    // INGREDIENTS
    editIngredient: 'Editar etiqueta',
    addIngredient: 'Añadir etiqueta',
    ingredient: 'etiqueta',
    ingredients: 'Etiquetas',
    searchIngredient: 'Buscar etiqueta',
    nameIngredient: 'Nombre de la etiqueta',
    ingredientDeleted: 'La etiqueta ha sido borrada',
    ingredientCreated: 'La etiqueta ha sido creada',
    ingredientUpdated: 'La etiqueta ha sido actualizada',
    labels: 'Etiquetas',
    // RESTAURANTS
    editRestaurant: 'Editar restaurante',
    addRestaurant: 'Añadir restaurante',
    restaurant: 'Restaurante',
    restaurants: 'Restaurantes',
    searchRestaurant: 'Buscar restaurante, nombre, email',
    nameRestaurant: 'Nombre del restaurante',
    restaurantDeleted: 'El restaurante ha sido borrado',
    restaurantCreated: 'El restaurante ha sido creado',
    restaurantUpdated: 'El restaurante ha sido actualizado',
    restaurantDeactivated: 'El restaurante ha sido desactivado',
    restaurantActivated: 'El restaurante ha sido activado',
    restaurantPhone: 'Teléfono del restaurante',
    userMessage: 'Mensaje para los usuarios',
    seeQrCode: 'Ver código QR',
    seeQrCodeSmall: 'Ver QR',
    emailRestaurant: 'Email del restaurante',
    qrDialogTitle: 'Tu código QR de Fudclub',
    qrDialogText: 'Muestra tu código QR a tus clientes cuando vayan a recoger sus pedidos.',
    platesSoldTable: 'P. Vendidos',
    downloadqr: 'Descargar QR',
    seeMoreStatitics: 'Ver más estadísticas',
    enterAddress: 'Introducir dirección del restaurante',
    chooseRestaurant: 'Elige un restaurante',
    errorDialogRestaurant: 'El email introducido ya está en uso. Contacta con el restaurante para utilizar otro diferente.',
    editTitleEmailSuccess: 'Email cambiado con éxito',
    editEmailSuccess: 'El email asociado a tu cuenta de Fudclub ha sido actualizado correctamente.',
    editMailSendConfirmation: 'Hemos enviado un enlace a tu nuevo email para verificar tu cuenta.',
    successOnSave1: 'Restaurante añadido correctamente',
    successOnSaveText1: 'Hemos enviado un email al restaurante con las instrucciones para validar su cuenta.',
    contactFudClubInformation: '¿Alguno de los datos no es correcto?',
    summaryEMHIRR: 'EMHIRR',
    summaryFRR: 'FRR',
    byDefault: 'Por defecto',
    noMessage:
      'Contacta con Fudclub para añadir un mensaje con información adicional de tus platos. Este mensaje lo verán tus clientes en la app.',
    // PLATES
    plate: 'plato',
    plates: 'Platos',
    plateName: 'Nombre del plato',
    plateDesc: 'Descripción del plato',
    platePrice: 'Precio del plato',
    platePriceMin: 'Precio del plato €',
    plateSummary: 'Resumen del plato',
    addPlate: 'Añadir plato',
    editPlate: 'Editar plato',
    plateDeleted: 'El plato ha sido borrado',
    plateCreated: 'El plato ha sido creado',
    plateUpdated: 'El plato ha sido actualizado',
    searchPlate: 'Buscar plato',
    searchHistorial: 'Buscar hitorial',
    emptyPlatesTitle: 'No tienes ningún plato disponible',
    emptyPlatesText: 'Contacta con Fudclub para añadir platos a tu restaurante',
    emptyRatingsTitle: 'Este plato no tiene valoraciones todavía',
    emptyRatingsText: 'Las valoraciones de tus clientes aparecerán aquí.',
    ratingQuantity: 'Valoración cantidad',
    ratingQuality: 'Valoración calidad',
    pickupDate: 'Fecha de recogida',
    pickupValuation: 'Fecha de valoración',
    seeMoreRatings: 'Ver más valoraciones',
    // PARAMETERS
    parameter: 'parámetro',
    from: 'desde',
    searchParameter: 'Buscar parámetro',
    addParameter: 'Añadir parámetro',
    defaultValues: 'Valores por defecto',
    parameterUpdated: 'El parámetro ha sido actualizado',
    modifiedFrom: 'modificó',
    changeIn: 'Cambio en',
    changeFrom: 'Cambio desde',
    platePricePP: 'Precio del plato (PP)',
    errorDialogTitle: 'Uy, algo no funcionó',
    values: 'Valores',
    configParameter: 'Parámetros configurables',
    errorDialogText:
      'No se pueden guardar los parámetros introducidos porque no cumplen con las condiciones de tiempo establecidas. Prueba de nuevo.',
    CDI: 'Código descuento de inicio (CDI)',
    PCDI: 'Porcentaje de código descuento de inicio (PCDI)',
    PCDR: 'Porcentaje de código descuento de referencia (PCDR)',
    PCDPR: 'Porcentaje de código descuento por refererir (PCDPR)',
    MCCDR: 'Número máximo de canjes del código desc. de referencia (MCCDR)',
    MPOD: 'Mínimo número de platos ofrecidos al día (MPOD)',
    PP: 'Precio del plato (PP)',
    TPFR: 'TPFR',
    TPVC: 'TPVC',
    timeParameter: 'Parámetros de tiempo 1',
    // HISTORIAL
    searchHistorialPlate: 'Buscar plato del historial',
    emptyHistorialTitle: 'Tu historial de plato del día está vacío',
    emptyHistorialText: 'Tus platos planificados para cada día aparecerán aquí cuando estén disponibles para reservar.',
    noResultsHistorial: 'No se ha encontrado ningún resultado.',
    noRatings: 'Sin valoraciones',
    // ORDERS
    emptyOrderTitle: 'Tu plato de hoy todavía no está disponible para reservar',
    emptyOrderTitle2: 'Tu plato de mañana todavía no está disponible para reservar',
    emptyOrderText: 'Contacta con Fudclub si quieres que esté disponible antes.',
    pickUpOrdersText1: 'Puedes recibir pedidos para hoy hasta las',
    pickUpOrdersText2: 'Puedes recibir pedidos para mañana hasta las',
    collected: 'Recogidos',
    collectedOne: 'Recogido',
    noCollectedOne: 'No recogido',
    noOrders: 'No hay ningún pedido',
    ordersCollectedEmpty1: 'Tus pedidos por recoger aparecerán aquí.',
    ordersCollectedEmpty: 'Tus pedidos recogidos aparecerán aquí.',
    waitingOrders: 'Por recoger',
    forPickUpToday: 'Para recoger hoy',
    forPickUpTomorrow: 'Para recoger mañana',
    availablePlatesOrders: 'platos disponibles',
    availablePlateOrders: 'plato disponible',
    reservedPlatesOrders: 'platos reservados',
    reservedPlateOrders: 'plato reservado',
    delivered: 'Entregado',
    swipeToDeliver: 'Desliza para entregar',
    clientToConfirm: 'Cliente por confirmar',
    clientToConfirmDes: 'Desliza para deshacer la entrega',
    errorUndoOrderTitle: 'Ya no puedes deshacer la entrega de este pedido',
    errorUndoOrderDescription1: 'Puedes deshacer la entrega de los pedidos durante el horario de recogida que has indicado más',
    errorUndoOrderDescription2: 'minutos de cortesía.',
    errorCannotOrderTitle: 'Ya no puedes entregar este pedido',
    errorCannotOrderDescription1: 'Puedes entregar los pedidos durante el horario de recogida que has indicado más',
    errorCannotOrderDescription2: 'minutos de cortesía.',
    errorCannotDeliverThisOrderYetOrderTitle: 'Todavía no puedes entregar este pedido',
    errorCannotDeliverThisOrderYetOrderDescription: 'Puedes entregar los pedidos durante el horario de recogida que has indicado.',
    noDishesAvailableTodayTitle: 'No hay ningún plato disponible para hoy',
    noDishesAvailableTomorrowTitle: 'No hay ningún plato disponible para mañana',
    noDishesAvailableDescription: 'Ve a Planificar para programar tus platos.',
    noPlate: 'No hay plato',
    // PLAN
    availablePlates: 'Platos disponibles',
    availablePlatesSingular: 'Plato disponible',
    pickUpTime: 'Horario de recogida',
    onlyNext: 'Solo próximo',
    onlyNextMob: 'Solo próx.',
    onlyThis: 'Solo este',
    allThe: 'Todos los',
    planAddEditStep2Title: 'Si continúas, no habrá plato para este día, ¿estás seguro?',
    planAddEditStep2Text: 'Debes seleccionar un plato para poder recibir pedidos para este día.',
    planAddEditStep3Title: '¿Quieres aplicar este cambio para todos los',
    planAddEditStep3Text: 'Puedes cambiar la planificación de cada día de la semana en cualquier momento.',
    planSuccess: 'Tu planificación ha sido actualizada',
    planError: 'No se ha podido actualizar tu planificación. Prueba de nuevo.',
    planDeleted: 'El planning ha sido borrado',
    planCreated: 'El planning ha sido creado',
    planUpdated: 'El planning ha sido actualizado',
    recursivePlanDeleted: 'El plan recursivo ha sido eliminado',
    recursivePlanCreated: 'El plan recursivo ha sido creado',
    recursivePlanUpdated: 'El plan recursivo ha sido actualizado',
    removePlanning: 'Solo hoy',
    removeRecursivePlanning: 'Todos los días',
    noResultsPlanning: 'No se ha encontrado ningún resultado.',
    canModifyPlan: 'Puedes modificar el plato de mañana hasta las',
    canModifyPlanToday: 'Puedes modificar el plato de hoy hasta las',
    canModifyPlanTomorrow: 'Puedes modificar el plato de mañana hasta mañana a las',
    cantModifyToday: 'Ya no puedes modificar el plato de hoy, pero sí el del resto de',
    cantModifyHeadToday: 'Ya no puedes modificar el plato de hoy, pero sí el de mañana hasta mañana a las',
    cantModifyTomorrow: 'Ya no puedes modificar el plato de mañana, pero sí el del resto de',
    cantModifyHeadTomorrow: 'Ya no puedes modificar el plato de mañana',
    cantLongerReceiveOrdersForToday: 'Ya no puedes recibir más pedidos para hoy',

    // ERRORS
    hasBeenAnError: 'Hubo un error, por favor prueba de nuevo más tarde',
    changeCompleted: 'Cambio completado con éxito',
    // GROUPS
    groups: 'Grupos',
    group: 'grupo',
    numberRestaurants: 'Nº restaurantes',
    searchGroup: 'Buscar grupo o restaurante',
    groupDeleted: 'El grupo ha sido borrado',
    addGroup: 'Añadir grupo',
    editGroup: 'Editar grupo',
    groupCreated: 'El grupo ha sido creado',
    groupUpdated: 'El grupo ha sido actualizado',
    nameGroup: 'Nombre del grupo',
    restaurantsOfGroup: 'Restaurantes del grupo',
    minimunRestaurants: 'Mínimo 2 restaurantes',
    GROUP_JOIN: 'Un restaurante no puede pertenecer a más de un grupo al mismo tiempo.',
    GROUP_JOINTitle: 'Hay algún restaurante que pertenece a más de un grupo',
    delete_restaurant: 'Sacar del grupo',
    // USERS
    enterprises: 'Empresas',
    additionalEmails: 'Emails adicionales',
    users: 'Usuarios',
    user: 'usuario',
    client: 'cliente',
    addUser: 'Añadir usuario',
    searchUser: ' Buscar usuario, nombre, email, teléfono',
    reservedP: 'P. Reservados',
    reservedPlates: 'Platos reservados',
    spending: 'Gasto',
    userName: 'Nombre de usuario',
    userDeleted: 'El usuario ha sido borrado',
    userCreated: 'Usuario añadido correctamente',
    userUpdated: 'El usuario ha sido actualizado',
    emailSent: 'Email enviado correctamente',
    emailSentDialogText1: 'Hemos enviado un email al usuario ',
    emailSentDialogText2: 'con las instrucciones para validar su cuenta.',
    errorDialogUser: 'El email introducido ya está en uso. Contacta con el usuario para utilizar otro diferente.',
    errorDialogValidateEmail: 'La estructura del email no es correcta. Contacta con el usuario para utilizar otro diferente.',
    preUser: 'Pre-Usuario',
    preRestaurant: 'Pre-Restaurante',
    birthday: 'Fecha nacimiento',
    gender: 'Sexo',
    genderEditing: 'Elige el sexo del usuario',
    giftPlates: 'Platos regalados',
    expiredPlates: 'Platos caducados',
    discountCode: 'Código de descuento',
    actingZone: 'Zona de actuación',
    discountCodeRedemptions: 'Canjes de código descuento',
    allowLocation: 'Permitir acceso a ubicación',
    offerPromotion: 'Emails con ofertas y promociones',
    newsPromotions: 'Novedades y promociones',
    giftsRenovations: 'Regalos y renovación de bonos',
    pickupReminder: 'Recordatorio de recogida',
    orderTimer: 'Hora de hacer pedidos',
    personalData: 'Datos Personales',
    paymentMethods: 'Formas de pago',
    Man: 'Hombre',
    Woman: 'Mujer',
    pickup: 'Recogida',
    realize: 'Realizada',
    giftDate: 'Fecha de regalo',
    sent: 'Enviado',
    received: 'Recibido',
    future: 'Futuros',
    past: 'Pasados',
    codeAndDiscount: 'Código y descuento',
    dateOfPurchase: 'Fecha de compra',
    file: 'Archivo',
    importSuccess: 'Importación realizada',
    importUsersTitle: 'Usuarios añadidos correctamente',
    importUsersText: 'Hemos enviado un email a los usuarios con las instrucciones para validar sus cuentas.',
    importUsersError: 'Uy, algo no funcionó',
    importUsersErrorText1: 'Los siguientes emails ya están en uso:',
    importUsersErrorText2: 'Contacta con estos usuarios para utilizar emails diferentes.',
    importUsersPrevalidation: 'Los campos "user", "surname" y "email" son obligatorios en todas las filas.',
    importFileSuccess: 'Archivo de importación correcto',
    byDefaultCard: 'Por Defecto',
    finishedCard: 'Terminada en',
    privacity: 'Privacidad',
    locationUser: 'Ubicación',
    pending: 'Pendiente',
    waitingConfirm: 'Por confirmar',
    // DISCOUNTS
    discount: 'código descuento',
    discounts: 'Códigos descuento',
    particular: 'Particular',
    totalUses: 'Total usos',
    expired: 'Caducado',
    addDiscount: 'Añadir código descuento',
    discountDeleted: 'El descuento ha sido borrado',
    discountCreated: 'El descuento ha sido creado',
    discountUpdated: 'El descuento ha sido actualizado',
    discountsRemoved: 'Los descuentos han sido borrados',
    searchDiscount: 'Buscar código descuento',
    assignedTo: 'Aplicable a',
    percentageDiscount: 'Porcentaje desc.',
    adjudication: 'Adjudicación',
    expirationDiscount: 'Vencimiento',
    expirationDiscountPlaceholder: 'Fecha de vencimiento del código descuento',
    percentageDiscountPlaceholder: 'Porcentaje de descuento %',
    totalUsesTitle: 'Máx. número de usos',
    totalUsesPlaceholder: 'Máximo número de usos del código descuento',
    usesPerUser: 'Máx. número de usos por usuario',
    usesPerUserPlaceholder: 'Máximo número de usos del código descuento por usuario',
    nameDiscount: 'Nombre del código descuento',
    p1: 'P1',
    p2: 'P2',
    p3: 'P3',
    // VOUCHER
    vouchers: 'Bonos',
    voucher: 'Bono',
    searchVoucher: 'Buscar bono',
    voucherDeleted: 'El bono ha sido borrado',
    voucherCreated: 'El bono ha sido creado',
    voucherUpdated: 'El bono ha sido actualizado',
    addVoucher: 'Añadir bono',
    normal: 'Normal',
    special: 'Especial',
    enterprise: 'Empresa',
    none: 'Ninguna',
    noFilters: 'Sin filtros',
    expiration: 'Validez',
    giftQuantity: 'Máx. platos regalo',
    type: 'Tipo',
    position: 'Posición',
    nameVoucher: 'Nombre del bono',
    enterpriseCode: 'Código de empresa',
    availableVoucher: 'Número de platos',
    minimumAmountPlates: 'Mínimo número de platos para pedir',
    priceRange: 'Precio Rango',
    priceRangePlaceholder: 'Precio por plato €',
    rangeValidity: 'Validez Rango',
    rangeValidityPlaceholder: 'Periodo de validez',
    numberVoucher: 'Nº platos',
    award: 'Adjudicación',
    validateUntil: 'Validez (días)',
    validatePeriod: 'Periodo de validez',
    maxPlatesGift: 'Máx. platos regalo',
    maxPlatesGiftText: 'Número máximo de platos a regalar',
    maxPlatesGiftPercentage: 'Porcentaje del total de platos del bono %',
    voucherPrice: 'Precio del bono €',
    voucherObservations: 'Observaciones del bono',
    observations: 'Observaciones',
    expirationVoucher: 'Validez (Días)',
    availables: 'disponibles',
    days: 'días',
    voucherCreateTitle: 'Bono adjudicado con éxito',
    voucherCreateDescription: 'El bono ha sido adjudicado correctamente a los usuarios elegidos y ya pueden hacer pedidos.',
    enterpriseVoucherCreateTitle: 'Bono creado con éxito',
    optionalVoucher: '- opcional',
    // WEEKDAYS
    today: 'Hoy',
    tomorrow: 'Mañana',
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado',
    Sunday: 'Domingo',
    // INCOMES
    incomes1q: 'Ingresos 1ª quincena',
    incomes1qMob: '1ª quincena',
    incomes2q: 'Ingresos 2ª quincena',
    incomes2qMob: '2ª quincena',
    // LOGIN ERRORS
    ERR_NO_EMAIL: 'El email introducido no pertenece a ninguna cuenta. Prueba con otro.',
    ERR_INVALID_EMAIL: 'El email introducido no es válido',
    ERR_WRONG_EMAIL_PASS: 'Email o contraseña incorrectos. Prueba de nuevo.',
    ERR_PASS_WRONG_TIMES: 'Contraseña incorrecta demasiadas veces. Prueba de nuevo en 5 minutos.',
    INVALID_PASSWORD: 'Contraseña incorrecta',
    INVALID_TOKEN: 'El enlace que hemos enviado a tu email ha caducado o ha sido usado previamente.',
    passwordsNotEqual: 'Las contraseñas no coinciden',
    passwordSecure: 'Contraseña segura',
    passwordSecureMin: 'Debe tener al menos 8 caracteres',
    // 404
    returnToList: 'Por favor, vuelve al listado y elige otro.',
    notFound: 'No hemos encontrado nada',
    // ERRORBOUNDARY
    boundaryTitle: 'Lo sentimos :( ha habido un error',
    boundaryText: 'Por favor, recarga la página',
    // PARAMETERS ERRORS
    errorIr: 'El valor de IR tiene que ser mayor que el valor C',
    errorFRR1: 'El valor de FRR tiene que ser mayor que el valor EMHIRR ',
    errorFRR2: ' y el valor de FRR + 30min tiene que ser menor o igual que el valor de FR ',
    errorEMHIRR: 'El valor de EMHIRR individual debe de ser mayor o igual que el valor al EMHIRR global ',
    errorC: 'El valor de C tiene que ser mayor o igual que el valor EMHIRR global',
    errorFR: 'El valor de FR tiene que ser mayot que el valor de IR',
    errorFRr2: 'El valor de FRr tiene que ser mayor que el valor de IRr y menor o igual que el valor de FR',
    errorTPFR: 'El valor de TPFR tiene que ser menor que el valor de TPVC',
    errorTPVC: 'El valor de TPVC tiene que ser mayor que TPFR',
    errorValidateEmail: 'Email incorrecto',
    // STATITCS SELECT
    statiticsDays: '28 días (4 semanas)',
    statiticsWeeks: '13 semanas (1 trimestre)',
    statiticsMonths: '12 meses',
    modifyInfo: 'Quiero modificar algún dato de mi restaurante',
    addDishesToRestaurant: 'Quiero añadir nuevos platos a mi restaurante',
    helpRestaurant: 'Ayuda al Restaurante Fudclub',
    ordersEarly: 'Quiero cambiar la hora a la que empiezo a recibir pedidos.'
  },

  // ENGLISH VERSION
  en: {
    fudclub: 'Fudclub',
    login: 'Log in',
    loginTitle: 'Fudclub Restaurant Login',
    language: 'Language',
    spanish: 'Español',
    english: 'English',
    password: 'Password',
    passwordChanged: 'Password successfully changed',
    passwordChangingError: 'Ha ocurrido un problema al intentar actualizar la contraseña, por favor intentelo mas tarde.',
    incorrectPassword: 'La contraseña actual introducida es incorrecta',
    passwordRecoveryTitle: 'Password successfully changed',
    passwordRecoveryText: 'The password associated with your Fudclub account has been successfully updated.',
    newPassword: 'New password',
    repeatNewPassword: 'Re-enter your new password',
    maintainSession: 'Keep me logged in',
    forgotPass: 'Forgot password?',
    newEmail: 'New email',
    changeEmail: 'Change email',
    emailInUse: 'This email is already in use',
    weSendEmail: 'We just sent you an email',
    weSendEmail2: 'We just sent a link to your new email to verify your account.',
    recoverYourPassword: 'Check your inbox to reset your password. You may need to check your spam folder.',
    errorWhenSendingEmail1: 'Error en el envío de email',
    errorWhenSendingEmail2: 'Ha ocurrido un error al enviar el email de restaurar contraseña, por favor intentelo de nuevo mas tarde.',
    search: 'Search',
    update: 'Update',
    edit: 'Edit',
    add: 'Add',
    accept: 'Accept',
    downloadQr: 'Download QR code',
    delete: 'Delete',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    hardCancelled: 'Cancelled without refund',
    name: 'Name',
    email: 'Email',
    surname: 'Surname',
    nameSurname: 'Name and surname',
    information: 'Information',
    payments: 'Pagos',
    state: 'State',
    address: 'Address',
    foodTypes: 'Cuisines',
    foodType: 'Cuisine',
    phone: 'Phone number',
    message: 'Message',
    description: 'Description',
    contactPerson: 'Contact person',
    beneficiaryName: 'Account holder',
    beneficiary: 'Account holder',
    optional: 'Optional',
    nationalId: 'NIF/CIF',
    nationalIdLabel: 'NIF/CIF de la cuenta bancaria',
    bic: 'BIC',
    iban: 'IBAN',
    emhirr: 'Hora 1 (EMHIRR)',
    frr: 'Hora 2 (FRR)',
    quantity: 'Portion',
    quality: 'Quality',
    likes: 'Favorites',
    allergens: 'Allergens',
    allergen: 'Allergen',
    noInfoAllergens: 'Allergen information not available.',
    noAllergens: 'Doesn’t contain allergens.',
    platesSold: 'Dishes sold',
    platesSoldSingular: 'Dish sold',
    incomes: 'Revenue',
    availableDays: 'Days listed',
    availableDaysSingular: 'Day listed',
    dailyAverage: 'Daily average',
    weeklyAverage: 'Weekly average',
    monthlyAverage: 'Monthly average',
    averageDayAvailable: 'Average per day listed',
    averageDayAvailableShort: 'Average per day listed',
    statistics: 'Analytics',
    restaurantStatitics: 'Restaurant analytics',
    ratings: 'Ratings',
    summary: 'Summary',
    platesNumber: 'Nº de platos',
    orders: 'Orders',
    createdAt: 'Date of creation',
    parameters: 'Parameters',
    recommend: 'Recommended',
    noRecommend: 'Not recommended',
    price: 'Price',
    goToList: 'Ir al listado',
    toPlan: 'Schedule',
    paymentSchedule: 'Calendario & pagos',
    general: 'General',
    city: 'City',
    registerDate: 'Registration date',
    contact: 'Contact',
    neightborhood: 'Neighborhood',
    bankId: 'Bank information',
    permissions: 'Preferences',
    export: 'Export',
    deselectAll: 'Deselect all',
    selectAll: 'Select all',
    selected: 'Selected',
    selecteds: 'Selected',
    deactivated: 'Disabled',
    deactivate: 'Deactivate',
    deactivateAll: 'Modify state',
    activate: 'Activate',
    activated: 'Active',
    value: 'Value',
    history: 'History',
    hour: 'hour',
    minutes: 'minutes',
    min: 'min',
    ayuda: 'Help',
    faq: 'Frequently asked questions',
    faq_text: 'Check our frequently asked questions section to get your questions answered.',
    how_it_works: 'How Fudclub works',
    how_it_works_text: 'Find out how to use Fudclub’s platform and discover all the functionalities.',
    helpCenter: 'Help center',
    contactFudclub: 'Contact Fudclub',
    contactFudclub2: 'Contact Fudclub',
    someIncorrectData: 'Are any of the fields incorrect?',
    instagram: 'Instagram',
    deleted: 'Deleted',
    recoverPassword: 'Recover password',
    recoverPasswordTitle: 'Forgot my password',
    recoverPasswordText: 'Enter your email to recover your password.',
    emailErrorWelcome: 'Incorrect email. Try again.',
    emailError: 'Enter your email to recover your password.',
    notifications: 'Notifications',
    settings: 'Settings',
    logout: 'Log out',
    logoutText: 'Are you sure you want to log out?',
    next: 'Next',
    return: 'Back',
    save: 'Save',
    import: 'Import',
    statusChanged: 'El estado ha sido cambiado',
    status: 'Estado',
    active: 'Activo',
    disabled: 'Inactivo',
    imagePickerText: 'Recomendado foto de 1200x1200. Formato PNG, JPG. Máximo 10 MB.',
    location: 'City',
    restaurantSelectorText: 'Select the restaurant you want to log in',
    only: 'Only',
    noResults: 'No results',
    moreThanThreeCharacters: 'Empieza a escribir para ver resultados',
    continue: 'Continue',
    percent: 'Porcentaje',
    csvImport: 'Import CSV',
    importUsers: 'Import users',
    waiting: 'Pending',
    sales: 'Sales',
    changePassword: 'Change password',
    changePasswordToContinue: 'Cambia tu contraseña para continuar',
    successDialogUpdatePassword: 'Contraseña cambiada con éxito',
    successDialogUpdatePasswordMessage: 'La contraseña asociada a tu cuenta de Fudclub ha sido actualizada correctamente.',
    planningDay: 'Schedule dishes',
    newOrdersCancelations: 'New orders and cancellations',
    pickupOrders: 'Order pickup',
    fudclubNews: 'Fudclub updates',
    newOrdersCancelationsEmails: 'Emails about new orders and cancellations',
    offersEmail: 'Emails with special offers and promotions',
    selectTime: 'Select time',
    todayPlate: 'Today’s dish',
    tomorrowPlate: 'Tomorrow’s dish',
    date: 'Date',
    schedule: 'Time',
    dispPlates: 'Dishes Available',
    dispPlatesMob: 'Dishes Available',
    exportData: 'Exportar Datos ↓',
    yourRestaurant: 'Your restaurant',
    management: 'Gestión',
    expirationDate: 'Fecha de vencimiento',
    recommendedByFudclub: 'Dish recommended by Fudclub',
    Card: '↪ By default:',
    loadMore: 'Load more',
    perPage: 'Per page',
    of: 'de',
    moreThan: 'más de',
    readAndAcceptPolicies1: 'I have read and accept',
    readAndAcceptPolicies2: ' and the',
    see: 'See',
    to: 'to',
    more: 'More',
    configuration: 'Configuration',
    changeRestaurant: 'Change restaurant',
    // HELP
    help: 'Help',
    helpEmail: 'If you need assistance, contact us at ',
    helpInstagram: 'Follow us on Instagram to stay up to date on all of our latest product news.',
    helpPhone: 'If you need assistance, contact us at ',
    fudclubPolitic: 'Fudclub policy',
    termsConditions: 'Terms and conditions',
    privacyPolicy: 'Privacy policy',
    cookiesPolicy: 'Cookies policy',
    // STYLE
    editStyle: 'Editar estilo',
    addStyle: 'Añadir estilo',
    style: 'Style',
    styles: 'Styles',
    searchStyle: 'Buscar estilo',
    nameStyle: 'Nombre del estilo',
    styleDeleted: 'El estilo ha sido borrado',
    styleCreated: 'El estilo ha sido creado',
    styleUpdated: 'El estilo ha sido actualizado',
    // INGREDIENTS
    editIngredient: 'Editar etiqueta',
    addIngredient: 'Añadir etiqueta',
    ingredient: 'etiqueta',
    ingredients: 'Etiquetas',
    searchIngredient: 'Buscar etiqueta',
    nameIngredient: 'Nombre de la etiqueta',
    ingredientDeleted: 'La etiqueta ha sido borrada',
    ingredientCreated: 'La etiqueta ha sido creada',
    ingredientUpdated: 'La etiqueta ha sido actualizada',
    labels: 'Etiquetas',
    // RESTAURANTS
    editRestaurant: 'Editar restaurante',
    addRestaurant: 'Añadir restaurante',
    restaurant: 'Restaurant',
    restaurants: 'Restaurants',
    searchRestaurant: 'Buscar restaurante, nombre, email',
    nameRestaurant: 'Nombre del restaurante',
    restaurantDeleted: 'El restaurante ha sido borrado',
    restaurantCreated: 'El restaurante ha sido creado',
    restaurantUpdated: 'El restaurante ha sido actualizado',
    restaurantDeactivated: 'El restaurante ha sido desactivado',
    restaurantActivated: 'El restaurante ha sido activado',
    restaurantPhone: 'Teléfono del restaurante',
    userMessage: 'Mensaje para los usuarios',
    seeQrCode: 'View QR code',
    seeQrCodeSmall: 'View QR',
    emailRestaurant: 'Email del restaurante',
    qrDialogTitle: 'Your Fudclub QR code',
    qrDialogText: 'Show your QR code to your customers when they stop by to pick up their orders.',
    platesSoldTable: 'Dishes sold',
    downloadqr: 'Download QR code',
    seeMoreStatitics: 'View more analytics',
    enterAddress: 'Introducir dirección del restaurante',
    chooseRestaurant: 'Elige un restaurante',
    errorDialogRestaurant: 'El email introducido ya está en uso. Contacta con el restaurante para utilizar otro diferente.',
    editTitleEmailSuccess: 'Email successfully changed',
    editEmailSuccess: 'The email associated with your Fudclub account has been successfully updated.',
    editMailSendConfirmation: 'We just sent a link to your new email to verify your account.',
    successOnSave1: 'Restaurante añadido correctamente',
    successOnSaveText1: 'We have sent an email to the restaurant with instructions to validate your account.',
    contactFudClubInformation: 'Are any of the fields incorrect?',
    summaryEMHIRR: 'EMHIRR',
    summaryFRR: 'FRR',
    byDefault: 'By default',
    noMessage:
      'Contact Fudclub to add a message with additional information about your dishes. This message will be shown to your customers on the app.',
    // PLATES
    plate: 'dish',
    plates: 'Dishes',
    plateName: 'Nombre del plato',
    plateDesc: 'Descripción del plato',
    platePrice: 'Precio del plato',
    platePriceMin: 'Precio del plato €',
    plateSummary: 'Dish summary',
    addPlate: 'Add dish',
    editPlate: 'Edit dish',
    plateDeleted: 'El plato ha sido borrado',
    plateCreated: 'El plato ha sido creado',
    plateUpdated: 'El plato ha sido actualizado',
    searchPlate: 'Search dish',
    searchHistorial: 'Buscar hitorial',
    emptyPlatesTitle: 'You don’t have any available dishes',
    emptyPlatesText: 'Contact Fudclub to add dishes to your restaurant.',
    emptyRatingsTitle: 'This dish hasn’t been rated yet',
    emptyRatingsText: 'Your customers’ ratings will appear here.',
    ratingQuantity: 'Portion rating',
    ratingQuality: 'Quality rating',
    pickupDate: 'Pickup Date',
    pickupValuation: 'Rating date',
    seeMoreRatings: 'View more ratings',
    // PARAMETERS
    parameter: 'parameter',
    from: 'desde',
    searchParameter: 'Buscar parámetro',
    addParameter: 'Añadir parámetro',
    defaultValues: 'Valores por defecto',
    parameterUpdated: 'El parámetro ha sido actualizado',
    modifiedFrom: 'modificó',
    changeIn: 'Cambio en',
    changeFrom: 'Cambio desde',
    platePricePP: 'Precio del plato (PP)',
    errorDialogTitle: "Oops, something didn't work",
    values: 'Valores',
    configParameter: 'Parámetros configurables',
    errorDialogText: 'The entered parameters cannot be saved because they do not meet the set time conditions. Please try again.',
    CDI: 'Código descuento de inicio (CDI)',
    PCDI: 'Porcentaje de código descuento de inicio (PCDI)',
    PCDR: 'Porcentaje de código descuento de referencia (PCDR)',
    PCDPR: 'Porcentaje de código descuento por refererir (PCDPR)',
    MCCDR: 'Número máximo de canjes del código desc. de referencia (MCCDR)',
    MPOD: 'Mínimo número de platos ofrecidos al día (MPOD)',
    PP: 'Precio del plato (PP)',
    TPFR: 'TPFR',
    TPVC: 'TPVC',
    timeParameter: 'Parámetros de tiempo 1',
    // HISTORIAL
    searchHistorialPlate: 'Search dish history',
    emptyHistorialTitle: 'Your dish history is empty',
    emptyHistorialText: 'Your scheduled dishes for each day will appear here when they are available to be ordered.',
    noResultsHistorial: 'We couldn’t find any results.',
    noRatings: 'No ratings',
    // ORDERS
    emptyOrderTitle: 'Today’s dish can’t be ordered yet',
    emptyOrderTitle2: 'Tomorrow’s dish can’t be ordered yet',
    emptyOrderText: ' Contact Fudclub if you want to make your dishes available earlier.',
    pickUpOrdersText1: 'You can receive orders for today until',
    pickUpOrdersText2: 'You can receive orders for tomorrow until',
    collected: 'Picked up',
    collectedOne: 'Released',
    noCollectedOne: 'Not picked up',
    noOrders: 'No orders yet',
    ordersCollectedEmpty1: 'Your orders to be picked up will appear here.',
    ordersCollectedEmpty: 'Your picked up orders will appear here.',
    waitingOrders: 'To be picked up',
    forPickUpToday: 'Pick up today',
    forPickUpTomorrow: 'Pick up tomorrow',
    availablePlatesOrders: 'dishes available',
    availablePlateOrders: 'dish available',
    reservedPlatesOrders: 'dishes ordered',
    reservedPlateOrders: 'dish ordered',
    delivered: 'Released',
    swipeToDeliver: 'Swipe to release',
    clientToConfirm: 'Customer to confirm',
    clientToConfirmDes: 'Swipe to undo',
    errorUndoOrderTitle: 'You can no longer undo this order',
    errorUndoOrderDescription1: 'Orders can be undone during the pickup window you have scheduled, after which a',
    errorUndoOrderDescription2: 'minute courtesy period will be added.',
    errorCannotOrderTitle: 'You can no longer release this order',
    errorCannotOrderDescription1: 'Orders can be released during the pickup window you have scheduled, after which a',
    errorCannotOrderDescription2: 'minute courtesy period will be added.',

    errorCannotDeliverThisOrderYetOrderTitle: 'You can’t release this order yet',
    errorCannotDeliverThisOrderYetOrderDescription: 'Orders can be released during the pickup window you have scheduled.',
    noDishesAvailableTodayTitle: 'Dish not available for today',
    noDishesAvailableTomorrowTitle: 'Dish not available for tomorrow',
    noDishesAvailableDescription: 'Go to Schedule to plan your dishes.',
    noPlate: 'No dish',
    // PLAN
    availablePlates: 'Available dishes',
    availablePlatesSingular: 'Available dish',
    pickUpTime: 'Pickup time',
    onlyNext: 'Only next',
    onlyNextMob: 'Only next',
    onlyThis: 'Only this',
    allThe: 'Every',
    planAddEditStep2Title: 'Are you sure you want to continue without scheduling a dish for this day?',
    planAddEditStep2Text: 'Select a dish to be able to receive orders for this day.',
    planAddEditStep3Title: 'Do you want to apply this change to every',
    planAddEditStep3Text: 'You can modify your scheduled dish for each day of the week at any time.',
    planSuccess: 'Your schedule has been updated',
    planError: 'We couldn’t update your schedule. Try again.',
    planDeleted: 'El planning ha sido borrado',
    planCreated: 'El planning ha sido creado',
    planUpdated: 'El planning ha sido actualizado',
    recursivePlanDeleted: 'El plan recursivo ha sido eliminado',
    recursivePlanCreated: 'El plan recursivo ha sido creado',
    recursivePlanUpdated: 'El plan recursivo ha sido actualizado',
    removePlanning: 'Only today',
    removeRecursivePlanning: 'Todos los días',
    noResultsPlanning: 'We couldn’t find any results.',
    canModifyPlan: 'You can modify tomorrow’s dish until',
    canModifyPlanToday: 'You can modify today’s dish until',
    canModifyPlanTomorrow: 'You can modify tomorrow’s dish until tomorrow at',
    cantModifyToday: 'You can no longer modify today’s dish, but you can modify your dish for future',
    cantModifyHeadToday: 'You can no longer modify today’s dish, but you can modify tomorrow’s dish until tomorrow at',
    cantModifyTomorrow: 'You can no longer modify tomorrow’s dish, but you can modify your dish for future',
    cantModifyHeadTomorrow: 'You can no longer modify tomorrow’s dish',
    cantLongerReceiveOrdersForToday: 'You can no longer receive orders for today',
    // ERRORS
    hasBeenAnError: 'Hubo un error, por favor prueba de nuevo más tarde',
    changeCompleted: 'Cambio completado con éxito',
    // GROUPS
    groups: 'Grupos',
    group: 'grupo',
    numberRestaurants: 'Nº restaurantes',
    searchGroup: 'Search by group or restaurant',
    groupDeleted: 'El grupo ha sido borrado',
    addGroup: 'Añadir grupo',
    editGroup: 'Editar grupo',
    groupCreated: 'El grupo ha sido creado',
    groupUpdated: 'El grupo ha sido actualizado',
    nameGroup: 'Nombre del grupo',
    restaurantsOfGroup: 'Restaurantes del grupo',
    minimunRestaurants: 'Mínimo 2 restaurantes',
    GROUP_JOIN: 'Un restaurante no puede pertenecer a más de un grupo al mismo tiempo.',
    GROUP_JOINTitle: 'Hay algún restaurante que pertenece a más de un grupo',
    delete_restaurant: 'Sacar del grupo',
    // USERS
    enterprises: 'Enterprises',
    additionalEmails: 'Additional emails',
    users: 'Usuarios',
    user: 'usuario',
    client: 'customer',
    addUser: 'Añadir usuario',
    searchUser: ' Buscar usuario, nombre, email, teléfono',
    reservedP: 'P. Reservados',
    reservedPlates: 'Platos reservados',
    spending: 'Gasto',
    userName: 'Nombre de usuario',
    userDeleted: 'El usuario ha sido borrado',
    userCreated: 'Usuario añadido correctamente',
    userUpdated: 'El usuario ha sido actualizado',
    emailSent: 'Email enviado correctamente',
    emailSentDialogText1: 'Se ha enviado un email al usuario ',
    emailSentDialogText2: 'con las instrucciones para validar su cuenta.',
    errorDialogUser: 'El email introducido ya está en uso. Contacta con el usuario para utilizar otro diferente.',
    errorDialogValidateEmail: 'La estructura del email no es correcta. Contacta con el usuario para utilizar otro diferente.',
    preUser: 'Pre-Usuario',
    preRestaurant: 'Pre-Restaurant',
    birthday: 'Fecha nacimiento',
    gender: 'Sexo',
    genderEditing: 'Elige el sexo del usuario',
    giftPlates: 'Platos regalados',
    expiredPlates: 'Platos caducados',
    discountCode: 'Código de descuento',
    actingZone: 'Zona de actuación',
    discountCodeRedemptions: 'Canjes de código descuento',
    allowLocation: 'Permitir acceso a ubicación',
    offerPromotion: 'Emails with special offers and promotions',
    newsPromotions: 'Novedades y promociones',
    giftsRenovations: 'Regalos y renovación de bonos',
    pickupReminder: 'Recordatorio de recogida',
    orderTimer: 'Hora de hacer pedidos',
    personalData: 'Datos Personales',
    paymentMethods: 'Formas de pago',
    Man: 'Hombre',
    Woman: 'Mujer',
    pickup: 'Recogida',
    realize: 'Realizada',
    giftDate: 'Fecha de regalo',
    sent: 'Enviado',
    received: 'Recibido',
    future: 'Futuros',
    past: 'Pasados',
    codeAndDiscount: 'Código y descuento',
    dateOfPurchase: 'Fecha de compra',
    file: 'Archivo',
    importSuccess: 'Importación realizada',
    importUsersTitle: 'Usuarios añadidos correctamente',
    importUsersText: 'Hemos enviado un email a los usuarios con las instrucciones para validar sus cuentas.',
    importUsersError: 'Uy, algo no funcionó',
    importUsersErrorText1: 'Los siguientes emails ya están en uso:',
    importUsersErrorText2: 'Contacta con estos usuarios para utilizar emails diferentes.',
    importUsersPrevalidation: 'Los campos "user", "surname" y "email" son obligatorios en todas las filas.',
    importFileSuccess: 'Archivo de importación correcto',
    byDefaultCard: 'By default',
    finishedCard: 'Terminada en',
    privacity: 'Privacy',
    locationUser: 'Ubicación',
    pending: 'Pendiente',
    waitingConfirm: 'Por confirmar',
    // DISCOUNTS
    discount: 'código descuento',
    discounts: 'Códigos descuento',
    particular: 'Particular',
    totalUses: 'Total usos',
    expired: 'Caducado',
    addDiscount: 'Añadir código descuento',
    discountDeleted: 'El descuento ha sido borrado',
    discountCreated: 'El descuento ha sido creado',
    discountUpdated: 'El descuento ha sido actualizado',
    discountsRemoved: 'Los descuentos han sido borrados',
    searchDiscount: 'Buscar código descuento',
    assignedTo: 'Aplicable a',
    percentageDiscount: 'Porcentaje desc.',
    adjudication: 'Adjudicación',
    expirationDiscount: 'Vencimiento',
    expirationDiscountPlaceholder: 'Fecha de vencimiento del código descuento',
    percentageDiscountPlaceholder: 'Porcentaje de descuento %',
    totalUsesTitle: 'Máx. número de usos',
    totalUsesPlaceholder: 'Máximo número de usos del código descuento',
    usesPerUser: 'Máx. número de usos por usuario',
    usesPerUserPlaceholder: 'Máximo número de usos del código descuento por usuario',
    nameDiscount: 'Nombre del código descuento',
    p1: 'P1',
    p2: 'P2',
    p3: 'P3',
    // VOUCHER
    vouchers: 'Bonos',
    voucher: 'Bono',
    searchVoucher: 'Buscar bono',
    voucherDeleted: 'El bono ha sido borrado',
    voucherCreated: 'El bono ha sido creado',
    voucherUpdated: 'El bono ha sido actualizado',
    addVoucher: 'Añadir bono',
    normal: 'Normal',
    special: 'Especial',
    enterprise: 'Enterprise',
    none: 'Ninguna',
    noFilters: 'Sin filtros',
    expiration: 'Validez',
    giftQuantity: 'Máx. platos regalo',
    type: 'Tipo',
    position: 'Posición',
    nameVoucher: 'Nombre del bono',
    enterpriseCode: "Enterprise's code",
    availableVoucher: 'Number of plates',
    minimumAmountPlates: 'Minimum number of dishes to order',
    priceRange: 'Price Range',
    priceRangePlaceholder: 'Price per dish €',
    rangeValidity: 'Range Validity',
    rangeValidityPlaceholder: 'Validity period',
    numberVoucher: 'Nº Plates',
    award: 'Adjudicación',
    validateUntil: 'Validez (días)',
    validatePeriod: 'Periodo de validez',
    maxPlatesGift: 'Máx. Platos Regalo',
    maxPlatesGiftText: 'Número máximo de platos a regalar',
    maxPlatesGiftPercentage: 'Percentage of the pack total dishes %',
    voucherPrice: 'Precio del bono €',
    voucherObservations: 'Observaciones del bono',
    observations: 'Observaciones',
    expirationVoucher: 'Validez (Días)',
    availables: 'disponibles',
    days: 'días',
    voucherCreateTitle: 'Bono adjudicado con éxito',
    voucherCreateDescription: 'El bono ha sido adjudicado correctamente a los usuarios elegidos y ya pueden hacer pedidos.',
    enterpriseVoucherCreateTitle: 'Created voucher successfully',
    optionalVoucher: '- opcional',
    // WEEKDAYS
    today: 'Today',
    tomorrow: 'Tomorrow',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    // INCOMES
    incomes1q: '1st fortnight revenue',
    incomes1qMob: '1st fortnight',
    incomes2q: '2nd fortnight revenue',
    incomes2qMob: '2nd fortnight',
    // LOGIN ERRORS
    ERR_NO_EMAIL: 'This email doesn’t match any account. Try another one.',
    ERR_INVALID_EMAIL: 'El email introducido no es válido',
    ERR_WRONG_EMAIL_PASS: 'Incorrect email or password. Try again.',
    ERR_PASS_WRONG_TIMES: 'Too many attempts. Try again in 5 minutes.',
    INVALID_PASSWORD: 'Incorrect password',
    INVALID_TOKEN: 'The link that we have sent to your email has expired or has been previously used.',
    passwordsNotEqual: 'Passwords don’t match',
    passwordSecure: 'Secure password',
    passwordSecureMin: 'It must have at least 8 characters',
    // 404
    returnToList: 'Por favor, vuelve al listado y elige otro.',
    notFound: 'No hemos encontrado nada',
    // ERRORBOUNDARY
    boundaryTitle: 'Lo sentimos :( ha habido un error',
    boundaryText: 'Por favor, recarga la página',
    // PARAMETERS ERRORS
    errorIr: 'El valor de IR tiene que ser mayor que el valor C',
    errorFRR1: 'El valor de FRR tiene que ser mayor que el valor EMHIRR ',
    errorFRR2: ' y el valor de FRR + 30min tiene que ser menor o igual que el valor de FR ',
    errorEMHIRR: 'El valor de EMHIRR individual debe de ser mayor o igual que el valor al EMHIRR global ',
    errorC: 'El valor de C tiene que ser mayor o igual que el valor EMHIRR global',
    errorFR: 'El valor de FR tiene que ser mayot que el valor de IR',
    errorFRr2: 'El valor de FRr tiene que ser mayor que el valor de IRr y menor o igual que el valor de FR',
    errorTPFR: 'El valor de TPFR tiene que ser menor que el valor de TPVC',
    errorTPVC: 'El valor de TPVC tiene que ser mayor que TPFR',
    errorValidateEmail: 'Email incorrecto',
    // STATITCS SELECT
    statiticsDays: '28 days (4 weeks)',
    statiticsWeeks: '13 weeks (1 quarter)',
    statiticsMonths: '12 months',
    modifyInfo: 'Modify my restaurant information',
    addDishesToRestaurant: 'Add new dishes to my restaurant',
    helpRestaurant: 'Help Fudclub Restaurant',
    ordersEarly: 'Make my dishes available to order earlier'
  }
}
