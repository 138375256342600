import { styled, Box, Avatar, Stack } from '@mui/material'
import { CONTAINER_WIDTH_DEFAULT, HEADER_BAR_HEIGHT } from '../constants/const'

export const BoxGrey = styled(Box)(({ theme }) => ({
  backgroundColor: '#F9FAFB',
  width: '100%',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}))

export const ContainerFud = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: CONTAINER_WIDTH_DEFAULT,
  margin: 'auto'
}))

export const AvatarResumen = styled(Avatar)(() => ({
  width: 64,
  height: 64,
  boxSizing: 'border-box',
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover'
}))

export const StickyMenuStack = styled(Stack)(({ theme }) => ({
  minHeight: 60,
  borderBottom: '1px solid #E5E7EB',
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  position: 'sticky',
  top: HEADER_BAR_HEIGHT,
  backgroundColor: '#fff',
  width: '100%',
  zIndex: 99,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(1)
  }
}))
