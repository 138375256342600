import React, { useState, SetStateAction, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ReactComponent as NoPicture } from './icons/NoPicture.svg'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined'
import { PrevImage, Picker, BigImage, ButtonIcon, PictureBox } from './imagePicker.styles'
import Modal from '../Modals/Modal'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

interface MultiImagePickerProps {
  setImgData: React.Dispatch<SetStateAction<any>>
  setDeletedImages: React.Dispatch<SetStateAction<any>>
  deletedImages: string[]
  imgData: string[]
  required?: boolean
  photoName?: string
}

export default function MultiImagePicker({
  setDeletedImages,
  setImgData,
  imgData,
  deletedImages,
  photoName
}: MultiImagePickerProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  useEffect(() => {
    if (imgData.length <= 5) {
      if (imgData.length <= 5) {
        const res = 5 - imgData.length
        for (let i = 0; i < res; i++) {
          setImgData((prev: any) => [...prev, ''])
        }
      }
    }
  }, [])

  const onChangePicture = (e: any, position: number) => {
    if (e.target.files[0]) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        // Image Code 64
        setImgData((prev: string[]) => prev.map((el: string, idx: number) => (position === idx ? reader.result : el)))

        if (imgData[position] !== '' && imgData[position] !== null) setDeletedImages([...deletedImages, imgData[position]])
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }
  const [openPicture, setOpenPicture] = useState(false)
  const [dialogImage, setDialogImage] = useState('')

  const clean = (e: any, position: number) => {
    setImgData((prev: string[]) => prev.map((el: string, idx: number) => (position === idx ? '' : el)))

    if (imgData[position] !== '') setDeletedImages([...deletedImages, imgData[position]])
  }

  const NoPictureCustom = () => {
    return <NoPicture style={{ width: 20, height: 20 }} />
  }

  const SmallPicker = ({ image, position }: { image: string; position: number }) => {
    const openImage = () => {
      setDialogImage(image)
      setOpenPicture(true)
    }

    return (
      <Box sx={{ position: 'relative' }}>
        {image && (
          <div style={{ position: 'absolute', top: 5, width: 100, height: 100 }}>
            <ButtonIcon sx={{ left: 0 }} onClick={openImage}>
              <OpenWithOutlinedIcon />
            </ButtonIcon>
            <ButtonIcon sx={{ right: 0 }} onClick={() => clean(image, position)}>
              <DeleteOutlineOutlinedIcon />
            </ButtonIcon>
          </div>
        )}
        <Picker sx={{ width: 100, height: 100, mb: 1 }}>
          <input
            style={{ display: 'none' }}
            onClick={(event: any) => {
              event.target.value = null
            }}
            id="pictures"
            type="file"
            onChange={(e) => onChangePicture(e, position)}
            multiple
          />
          {image && <PrevImage sx={{ width: 100, height: 100 }} src={image} />}
          <NoPictureCustom />
        </Picker>
      </Box>
    )
  }

  return (
    <Box sx={{ padding: 3, paddingTop: 2 }}>
      <Typography variant="subtitle2" component="h6">
        Fotos
      </Typography>
      <PictureBox
        sx={{
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <Stack>
          <SmallPicker image={imgData[0]} position={0} />
          <Typography align="center" sx={{ color: '#9CA3AF', fontWeight: 500, lineHeight: '12px', mb: '20px' }} fontSize={10}>
            Foto principal
          </Typography>
        </Stack>
        <SmallPicker image={imgData[1]} position={1} />
        <SmallPicker image={imgData[2]} position={2} />
        <SmallPicker image={imgData[3]} position={3} />
        <SmallPicker image={imgData[4]} position={4} />
      </PictureBox>
      <Typography align="center" variant="body1" color="#9CA3AF" fontSize={12}>
        {labels[lng].imagePickerText}
      </Typography>
      <Modal title={photoName || '.'} size="md" open={openPicture} handleClose={() => setOpenPicture(false)}>
        <BigImage src={dialogImage} onClick={() => setOpenPicture(false)} />
      </Modal>
    </Box>
  )
}
