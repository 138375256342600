import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import {
  CarrouselButtons,
  CarrouselImage,
  CarrouselTitle,
  CarrouselModal,
  CarrouselBullets,
  CarrouselBulletsBox
} from './imageCarrousel.style'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Modal from '../Modals/Modal'
import { theme } from 'src/styles/theme'
import { ChipRecommend } from '../Cards/cards.styles'
interface ImageCarrouselProps {
  images: string[]
  title: string
  recommend?: any
}

export default function ImageCarrousel({ images, title, recommend }: ImageCarrouselProps): JSX.Element {
  const [image, setImage] = useState(0)
  const [openPicture, setOpenPicture] = useState(false)

  const nextImage = () => {
    if (images.length - 1 > image) setImage((prev) => prev + 1)
    if (images.length - 1 === image) setImage(0)
  }

  const prevImage = () => {
    if (image > 0) setImage((prev) => prev - 1)
    if (image === 0) setImage(images.length - 1)
  }

  const modalTitle = () => {
    if (title && title.length > 70) {
      return `${title.slice(0, 70)}...`
    } else return title
  }

  return (
    <Box>
      <CarrouselImage
        style={{
          background: `linear-gradient(360deg, rgba(0, 0, 0, 0.2) 30.73%, rgba(0, 0, 0, 0) 100%), center/cover no-repeat url(${images[image]})`,
          overflowY: 'hidden'
        }}
      >
        {recommend && (
          <ChipRecommend
            sx={{
              [theme.breakpoints.up('md')]: {
                display: 'none'
              }
            }}
          >
            ⭐️
            <Typography sx={{ ml: 0.5 }} variant="body1" component="span">
              {recommend}
            </Typography>
          </ChipRecommend>
        )}
        <>
          {images.length > 1 && (
            <CarrouselButtons onClick={prevImage}>
              <ArrowBackIcon fontSize="small" />
            </CarrouselButtons>
          )}
          <Box sx={{ flex: 1, height: 300, cursor: 'pointer' }} onClick={() => setOpenPicture(true)}></Box>
          {images.length > 1 && (
            <CarrouselButtons onClick={nextImage}>
              <ArrowForwardIcon fontSize="small" />
            </CarrouselButtons>
          )}
        </>
        <CarrouselTitle
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
          variant="h2"
        >
          {title}
        </CarrouselTitle>
        <CarrouselBulletsBox>
          {images.length &&
            images.map((bullet, idx) => (
              <CarrouselBullets
                sx={{
                  backgroundColor: image === idx ? '#fff' : 'rgba(255, 255, 255, 0.5)'
                }}
                key={idx}
                onClick={() => setImage(idx)}
              />
            ))}
        </CarrouselBulletsBox>
      </CarrouselImage>
      <Modal
        fullScreen={window?.innerWidth < 890}
        hideControls
        title={modalTitle()}
        size="md"
        open={openPicture}
        handleClose={() => setOpenPicture(false)}
        modal2
        dialogStyles={{ border: 'none' }}
        hasDimensions
      >
        <CarrouselModal
          style={{
            overflowX: 'hidden',
            border: 'none',
            position: window?.innerWidth < 890 ? 'relative' : 'inherit'
          }}
        >
          {images.length > 1 && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                left: 0,
                display: 'flex',
                padding: '0 20px',
                justifyContent: 'space-between',
                top: window?.innerWidth < 890 ? 'auto' : 'calc(50% + 10px)',
                translateY: '-50%'
              }}
            >
              <CarrouselButtons onClick={prevImage}>
                <ArrowBackIcon fontSize="small" />
              </CarrouselButtons>
              <CarrouselButtons onClick={nextImage}>
                <ArrowForwardIcon fontSize="small" />
              </CarrouselButtons>
            </Box>
          )}
          <CarrouselBulletsBox>
            {images.length &&
              images.map((bullet, idx) => (
                <CarrouselBullets
                  sx={{
                    backgroundColor: image === idx ? '#fff' : 'rgba(255, 255, 255, 0.5)'
                  }}
                  key={bullet}
                  onClick={() => setImage(idx)}
                />
              ))}
          </CarrouselBulletsBox>

          <img style={{ width: '100%', maxWidth: 900 }} src={images[image]} />
        </CarrouselModal>
        {window?.innerWidth < 890 ? (
          <Typography pl="16px" color="#111827" mt="16px" fontSize={'16px'} lineHeight="20px" fontWeight={500}>
            {title}
          </Typography>
        ) : null}
      </Modal>
    </Box>
  )
}
