import React, { SetStateAction, Dispatch, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { MainBox, GridFlexBetween } from './form.styles'
import { SearchDialog } from '../Modals/SearchDialog'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
interface SelectDialogProps {
  setData: Dispatch<SetStateAction<string | never>>
  text: string
  data: any
  options: string[]
  required?: boolean
  neighborhood?: boolean
}

export default function SelectDialog({ text, data, setData, options, required, neighborhood }: SelectDialogProps): JSX.Element {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const addChip = (chipData: string) => {
    setData(chipData)
  }

  const getLabel = () => {
    if (neighborhood) {
      if (data) return `${data.cp} - ${data.name}`
      else return ''
    }
    return data
  }

  return (
    <MainBox>
      <GridFlexBetween container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="h6">
            {text} {required ? '*' : ''}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              border: '1px solid #D1D5DB',
              width: 240,
              height: 40,
              position: 'relative',
              alignItems: 'center',
              display: 'flex',
              pl: '13px',
              cursor: 'pointer',
              borderRadius: '6px',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)'
            }}
            onClick={() => setOpen(true)}
          >
            {getLabel()}
            <IconButton sx={{ position: 'absolute', right: 0 }}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
        </Grid>
      </GridFlexBetween>
      {neighborhood ? (
        <SearchDialog
          searchBy="neighborhood"
          single
          onClose={handleClose}
          selected={[data]}
          open={open}
          optionsData={options}
          setter={addChip}
          text={text.toLowerCase()}
          required
        />
      ) : (
        <SearchDialog
          single
          onClose={handleClose}
          selected={[data]}
          open={open}
          optionsData={options}
          setter={addChip}
          text={text.toLowerCase()}
        />
      )}
    </MainBox>
  )
}
