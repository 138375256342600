import { PlanningRecursiveTypes, PlanningTypes } from 'src/interfaces/pages.types'
import axiosInstance from './axiosInstance'

export const getPlanning = async (idRestaurant: string): Promise<PlanningTypes[]> => {
  return axiosInstance
    .get(`/planning/${idRestaurant}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de planificación')
    })
}

export const createPlanning = async (planning: PlanningTypes, restaurant: string): Promise<void> => {
  return axiosInstance
    .post(`/planning/${restaurant}/create`, planning)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => {
      if (error.response.status) {
        throw new Error('configError')
      } else throw new Error('Hubo un error mientras se intentaba crear la planificación')
    })
}

export const updatePlanning = async (planning: PlanningTypes, restaurant: string, id: string): Promise<void> => {
  return axiosInstance
    .put(`/planning/${restaurant}/${id}`, planning)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => {
      if (error.response.status) {
        throw new Error('configError')
      } else throw new Error('Hubo un error mientras se intentaba crear la planificación')
    })
}

export const deletePlanning = async (idRestaurant: string, idPlanning: string): Promise<void> => {
  return axiosInstance
    .delete(`/planning/${idRestaurant}/${idPlanning}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar la planificación')
    })
}

export const getRecursivePlanning = async (idRestaurant: string): Promise<PlanningRecursiveTypes[]> => {
  return axiosInstance
    .get(`/recursive_planning/${idRestaurant}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de planificación')
    })
}

export const getRecursivePlanningById = async (idRestaurant: string, idRecursivePlanning: string): Promise<PlanningRecursiveTypes[]> => {
  return axiosInstance
    .get(`/recursive_planning/${idRestaurant}/find/${idRecursivePlanning}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de planificación')
    })
}

export const createRecursivePlanning = async (planning: PlanningTypes, restaurantId: string): Promise<void> => {
  return axiosInstance
    .post(`/recursive_planning/${restaurantId}/create`, planning)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba crear la planificación recursiva')
    })
}

export const updateRecursivePlanning = async (planning: PlanningTypes, restaurant: string, id: string): Promise<void> => {
  delete planning._id
  return axiosInstance
    .put(`/recursive_planning/${restaurant}/${id}`, planning)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => {
      if (error.response.status) {
        throw new Error('configError')
      } else throw new Error('Hubo un error mientras se intentaba crear la planificación')
    })
}

export const deleteRecursivePlanning = async (idRestaurant: string, idRecursivePlanning: string): Promise<void> => {
  return axiosInstance
    .delete(`/recursive_planning/${idRestaurant}/${idRecursivePlanning}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar la planificación')
    })
}
