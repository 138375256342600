import { LabelType } from './main_labels'

export const allergensLabels: LabelType = {
  es: {
    IND: 'Información no disponible',
    GLT: 'Gluten',
    CTC: 'Crustaceos',
    EGG: 'Huevos',
    FISH: 'Pescado',
    PNT: 'Cacahuete',
    SOY: 'Soja',
    MLK: 'Leche',
    NUT: 'Frutos Secos',
    CLY: 'Apio',
    MTD: 'Mostaza',
    SSM: 'Sésamo',
    SPT: 'Sulfitos',
    MSC: 'Moluscos',
    ATC: 'Altramuces'
  },
  en: {
    IND: 'Allergen information not available.',
    GLT: 'Gluten',
    CTC: 'Crustaceans',
    EGG: 'Eggs',
    FISH: 'Fish',
    PNT: 'Peanuts',
    SOY: 'Soy',
    MLK: 'Milk',
    NUT: 'Nuts',
    CLY: 'Celery',
    MTD: 'Mustard',
    SSM: 'Sesame',
    SPT: 'Sulphites',
    MSC: 'Mollucs',
    ATC: 'Lupin'
  }
}
