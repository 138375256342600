import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { ReactComponent as PlatosVendidos } from 'src/assets/icons/restaurante-resumen/PlatosVendidosGreen.svg'
import { ReactComponent as Gastos } from 'src/assets/icons/restaurante-resumen/GastosGreen.svg'
import { ReactComponent as DiasDisponibles } from 'src/assets/icons/restaurante-resumen/DiasDisponiblesGreen.svg'
import { ReactComponent as DD } from 'src/assets/icons/restaurante-resumen/DDGreen.svg'
import { ReactComponent as DL } from 'src/assets/icons/restaurante-resumen/DLGreen.svg'
import { ReactComponent as UserFilled } from 'src/assets/icons/general/UserFilled.svg'
import { ReactComponent as Map } from 'src/assets/icons/resumen-icons/Map.svg'
import { ReactComponent as Email } from 'src/assets/icons/resumen-icons/Email.svg'
import { ReactComponent as Phone } from 'src/assets/icons/resumen-icons/Phone.svg'
import { ReactComponent as CreatedAt } from 'src/assets/icons/resumen-icons/CreatedAt.svg'
import { ReactComponent as Mensaje } from 'src/assets/icons/resumen-icons/Mensaje.svg'
import { ReactComponent as Barrio } from 'src/assets/icons/resumen-icons/Barrio.svg'

import CardSummarySmall from 'src/components/Cards/CardSummarySmall'
import CardBigIconTitleText from 'src/components/Cards/CardBigIconTitleText'
import CardBankAccount from 'src/components/Cards/CardBankAccount'
import { RestaurantTypes } from 'src/interfaces/pages.types'
import { dateTimeConvertLng } from 'src/utils/time-helpers'
import CardMap from 'src/components/Cards/CardMap'
import { labels } from 'src/labels/main_labels'
import { CardSummaryGrid } from 'src/components/Cards/cards.styles'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { EditEmail } from 'src/pages/RestaurantPlatform/Informacion/EditEmail'
import { SimpleDialog } from '../Modals/Dialog'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { ChevronRight } from '@mui/icons-material'

interface RestaurantBasicInformationProp {
  restaurant: RestaurantTypes
  neightbor?: boolean
  editEmail?: boolean
  closeMap?: boolean
}

export default function RestaurantBasicInformation({
  restaurant,
  neightbor,
  editEmail,
  closeMap
}: RestaurantBasicInformationProp): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const params: any = useParams()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const newEmail = query.get('newEmail')
  const history = useHistory()
  const { bank, email, contact, location, createdAt, message, income, translations } = restaurant

  const [openModal, setOpenModal] = useState(false)
  const [openSended, setOpenSended] = useState(false)
  const [openNewEmail, setOpenNewEmail] = useState(false)
  const closeModal = (success?: boolean) => {
    setOpenModal(false)
    if (success) setOpenSended(true)
  }

  useEffect(() => {
    if (newEmail) {
      setOpenNewEmail(true)
    }
  }, [newEmail])

  const linkToStatitics = () => {
    if (params.idRestaurant) {
      history.push(`/restaurantes/${params.idRestaurant}/estadisticas`)
    } else {
      history.push(`/estadisticas`)
    }
  }

  return (
    <>
      <Typography sx={{ mb: 1 }} variant="body1">
        {labels[lng].statistics}
      </Typography>
      <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
        <Grid item sm={6} xs={6} lg={3}>
          <CardSummarySmall
            Icon={PlatosVendidos}
            title={income?.platesSolds === 1 ? labels[lng].platesSoldSingular : labels[lng].platesSold}
            text={income?.platesSolds || '0'}
          />
        </Grid>
        <Grid item sm={6} xs={6} lg={3}>
          <CardSummarySmall
            Icon={Gastos}
            title={labels[lng].incomes}
            text={`${income?.total ? Number(income.total)?.toFixed(2).replace('.', ',') : '0,00'} €`}
          />
        </Grid>
        <Grid item sm={6} xs={6} lg={3}>
          <CardSummarySmall
            Icon={DiasDisponibles}
            title={income?.days.length === 1 ? labels[lng].availableDaysSingular : labels[lng].availableDays}
            text={income?.days.length || '0'}
          />
        </Grid>
        <Grid item sm={6} xs={6} lg={3}>
          <CardSummarySmall
            Icon={lng === 'es' ? DD : DL}
            title={window.innerWidth < 890 ? labels[lng].averageDayAvailableShort : labels[lng].averageDayAvailable}
            text={income?.days.length === 0 ? '--' : income?.mediaDay ? income?.mediaDay.toFixed(1)?.replace('.', ',') : '0,0'}
            finalText={labels[lng].plates}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }}
        onClick={() => linkToStatitics()}
      >
        <Typography variant="subtitle2" sx={{ color: '#374151', my: 2, cursor: 'pointer' }}>
          {labels[lng].seeMoreStatitics} ↗
        </Typography>
      </Box>
      <Box
        onClick={() => linkToStatitics()}
        sx={{
          [theme.breakpoints.up('md')]: {
            display: 'none'
          },
          p: '16px',
          border: '1px solid #E5E7EB',
          borderRadius: '8px',
          mt: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h3" fontSize="14px" lineHeight="14px" color="#6B7280" fontWeight={500}>
          {labels[lng].seeMoreStatitics}
        </Typography>
        <ChevronRight sx={{ color: '#9CA3AF', fill: '#9CA3AF' }} />
      </Box>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels[lng].general}
      </Typography>
      <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <CardSummaryGrid item sm={6} xs={12} lg={4}>
          <CardBigIconTitleText Icon={Map} title={labels[lng].location} text={location?.locality || '-'} />
        </CardSummaryGrid>
        <CardSummaryGrid item sm={6} xs={12} lg={4}>
          <CardBigIconTitleText
            Icon={CreatedAt}
            title={labels[lng].registerDate}
            text={createdAt ? dateTimeConvertLng(createdAt, lng, true) : '-'}
          />
        </CardSummaryGrid>
        <CardSummaryGrid item sm={12} xs={12} lg={4}>
          <CardBigIconTitleText
            Icon={Mensaje}
            title={labels[lng].message}
            specialText={!message}
            text={
              message
                ? lng === 'es'
                  ? message
                  : (
                      translations?.find(({ language }) => {
                        return language === lng
                      }) as { message: string } | undefined
                    )?.message
                : labels[lng].noMessage
            }
          />
        </CardSummaryGrid>
      </Grid>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels[lng].contact}
      </Typography>
      <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <CardSummaryGrid item sm={6} xs={12} lg={4}>
          <CardBigIconTitleText Icon={UserFilled} title={labels[lng].contactPerson} text={contact ? contact.name : '-'} />
        </CardSummaryGrid>
        <CardSummaryGrid item sm={6} xs={12} lg={4}>
          {editEmail ? (
            <CardBigIconTitleText Icon={Email} title={labels[lng].email} text={email || '-'} handleEdit={() => setOpenModal(true)} />
          ) : (
            <CardBigIconTitleText Icon={Email} title={labels[lng].email} text={email || '-'} />
          )}
        </CardSummaryGrid>
        <CardSummaryGrid item sm={12} xs={12} lg={4}>
          <CardBigIconTitleText Icon={Phone} title={labels[lng].phone} text={contact ? contact.phone : '-'} />
        </CardSummaryGrid>
      </Grid>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels[lng].address}
      </Typography>
      <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <CardSummaryGrid item sm={6} xs={12} lg={neightbor ? 4 : 6}>
          <CardBigIconTitleText
            Icon={Map}
            title={labels[lng].address}
            text={location?.street ? `${location.street}, ${location.number} - ${location.cp} ${location.locality}` : '-'}
          />
        </CardSummaryGrid>
        {neightbor && (
          <CardSummaryGrid item sm={6} xs={12} lg={4}>
            <CardBigIconTitleText
              Icon={Barrio}
              title={labels[lng].neightborhood}
              text={location && location.neighborhood ? `${location.neighborhood.cp} - ${location.neighborhood.name}` : '-'}
            />
          </CardSummaryGrid>
        )}
        <CardSummaryGrid item sm={neightbor ? 12 : 6} xs={12} lg={neightbor ? 4 : 6}>
          {!closeMap && <CardMap location={location} />}
        </CardSummaryGrid>
      </Grid>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels[lng].bankId}
      </Typography>
      {bank && <CardBankAccount bankAccount={bank} />}
      {openModal && <EditEmail open={openModal} handleClose={closeModal} emailPrev={email || ''} />}
      <SimpleDialog
        category="successRed"
        open={openSended}
        handleClose={() => setOpenSended(false)}
        title={labels[lng].weSendEmail}
        text={labels[lng].weSendEmail2}
      />
      <SimpleDialog
        category="successRed"
        open={openNewEmail}
        handleClose={() => {
          setOpenNewEmail(false)
          history.replace({ search: '' })
        }}
        title={labels[lng].editTitleEmailSuccess}
        text={labels[lng].editEmailSuccess}
      />
    </>
  )
}
