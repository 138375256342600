import React, { useContext } from 'react'
import { Badge, ListItem, ListItemText } from '@mui/material'
import { ChipHorizontal, IconColor } from './layout.styles'
import { BookingContext } from 'src/context/booking/bookingContext'

interface NavElementsProps {
  navLink: (link: string) => void
  open: boolean
  link: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>
  text: string
  badge?: boolean
}

export default function NavElements({ link, Icon, open, text, navLink, badge }: NavElementsProps): JSX.Element {
  const { bookingsNumber } = useContext(BookingContext)
  const path = location.pathname === '/' ? location.hash.split('/')[1] : location.pathname.split('/')[1]
  const active = path === link.split('/')[1]
  return (
    <ListItem style={{ backgroundColor: active ? '#E5E7EB' : '' }} button disablePadding onClick={() => navLink(link)}>
      {!open && badge ? (
        <Badge badgeContent={bookingsNumber} color="primary">
          <IconColor className={active ? (path === 'pedidos' ? 'fill' : 'stroke') : ''}>
            <Icon />
          </IconColor>
        </Badge>
      ) : (
        <IconColor className={active ? (path === 'pedidos' ? 'fill' : 'stroke') : ''}>
          <Icon />
        </IconColor>
      )}
      <ListItemText sx={{ '& .MuiTypography-root': { color: active ? '#111827' : '#4B5563' } }} primary={text} />
      {open && badge && bookingsNumber > 0 && <ChipHorizontal size="small" label={bookingsNumber} color="primary" />}
    </ListItem>
  )
}
