import { labels } from 'src/labels/main_labels'
import { SweetAlertOptions } from 'sweetalert2'

export const deleteSwalConfig = (text: string, lng: string, html?: string, confirmText?: string): SweetAlertOptions => {
  return {
    confirmButtonText: confirmText || labels[lng].delete,
    confirmButtonColor: '#EB435A',
    cancelButtonText: labels[lng].cancel,
    cancelButtonColor: '#fff',

    html:
      html ||
      `<h4 style="color:#111827">Estás a punto de eliminar ${
        text.toLocaleLowerCase() === labels[lng].ingredients.toLocaleLowerCase() ? 'una' : 'uno'
      } o ${text.toLocaleLowerCase() === labels[lng].ingredients.toLocaleLowerCase() ? 'varias' : 'varios'} ${
        text.toLocaleLowerCase() || ''
      }, ¿estás seguro?</h4>`,
    showCancelButton: true,
    showConfirmButton: true,
    width: 480
  }
}

export const logoutSwalConfig = (lng: string): SweetAlertOptions => {
  return {
    width: 440,
    confirmButtonText: labels[lng].logout,
    confirmButtonColor: '#EB435A',
    cancelButtonText: labels[lng].cancel,
    cancelButtonColor: '#fff',
    html: `<h4 style="color:#111827">${labels[lng].logoutText}</h4>`,
    showCancelButton: true,
    showConfirmButton: true
  }
}
