import React, { useEffect, useState, useContext } from 'react'
import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { RestaurantsChart } from 'src/components/Charts/RestaurantChart'
import CardSummarySmall from 'src/components/Cards/CardSummarySmall'
import { ContainerFud } from 'src/styles/general-styles'

import { ReactComponent as PlatosVendidos } from 'src/assets/icons/platos-estadisticas-icons/PlatosVendidos.svg'
import { ReactComponent as DiasDisponibles } from 'src/assets/icons/platos-estadisticas-icons/DiasDisponibles.svg'
import { ReactComponent as MediaDiaria } from 'src/assets/icons/platos-estadisticas-icons/MediaDiaria.svg'
import { ReactComponent as MediaSemanal } from 'src/assets/icons/platos-estadisticas-icons/MediaSemanal.svg'
import { ReactComponent as MediaSemanalEn } from 'src/assets/icons/platos-estadisticas-icons/MediaSemanalEn.svg'
import { ReactComponent as MediaMensual } from 'src/assets/icons/platos-estadisticas-icons/MediaMensual.svg'

import { labels } from 'src/labels/main_labels'
import { getPlateStatitics } from 'src/services/plates.services'
import { AlertContext } from 'src/context/alert/alertContext'
import { getDateFromLabel, transformChartData } from './statitics.utils'
import StatiticsFilter from './components/StatiticsFilter'
import { PlateTypes } from '../../../interfaces/pages.types'
import subDays from 'date-fns/subDays'
import { useTranslation } from 'react-i18next'
import { addDays, addMonths } from 'date-fns'
import { theme } from 'src/styles/theme'

interface PlateStatiticsProps {
  type: string
  plato: PlateTypes
  select?: any
  options?: any
  setter?: any
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function PlateStatitics({ plato, type, select, options, setter }: PlateStatiticsProps): JSX.Element {
  const { showSnackbar } = useContext(AlertContext)
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const [statitics, setStatitics] = useState<any | null>(null)
  const [chartData, setChartData] = useState<any>([])
  const [dateFrom, setDateFrom] = useState<Date>(subDays(new Date(), 27))
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [value, setValue] = useState(type)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
    setter(newValue)
  }

  const fetchStatitics = async (from: Date, to: Date) => {
    try {
      let fetchData
      if (type) fetchData = await getPlateStatitics(type, plato._id, from, to)
      if (fetchData) setStatitics(fetchData)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const controlTypeSelect = () => {
    let from
    if (type === 'days') from = subDays(new Date(), 27)
    if (type === 'months') from = subDays(new Date(), 365)
    if (type === 'weeks') from = subDays(new Date(), 91)
    if (from) fetchStatitics(from, subDays(new Date(), 0))
  }
  useEffect(() => {
    controlTypeSelect()
  }, [type, plato])

  useEffect(() => {
    if (statitics) {
      const dataTransformed = transformChartData(statitics, type)
      setDateFrom(dataTransformed.dateFrom)
      setDateTo(dataTransformed.dateTo)
      setChartData(dataTransformed.chartData)
    }
  }, [statitics])

  const prevBlockData = () => {
    let from
    if (type === 'days' || type === 'weeks') {
      const numberDays = type === 'days' ? 27 : 90
      from = subDays(dateFrom, numberDays)
    }
    if (type === 'months') {
      from = new Date(dateFrom).setMonth(new Date(dateFrom).getMonth() - 12)
    }
    if (from) fetchStatitics(subDays(new Date(from), 1), subDays(new Date(dateFrom.setHours(9, 0, 0, 0)), 1))
  }

  const nextBlockData = () => {
    let to
    if (type === 'days' || type === 'weeks') {
      const numberDays = type === 'days' ? 27 : 82
      to = addDays(dateTo, numberDays)
      if (type === 'weeks') {
        fetchStatitics(subDays(new Date(dateTo.setHours(9, 0, 0, 0)), 6), addDays(new Date(to), 1))
        return
      }
    }
    if (type === 'months') {
      to = new Date(dateTo).setMonth(new Date(dateTo).getMonth() + 12)
    }
    if (to) fetchStatitics(addDays(new Date(dateTo.setHours(9, 0, 0, 0)), 1), addDays(new Date(to), 1))
  }

  const IncomeFilterRestaurantPlatform = () => {
    const createdAt = plato.createdAt as string
    let lastDateTo = new Date(dateTo.setHours(23, 59, 59, 0))
    if (type === 'months') {
      lastDateTo = addMonths(lastDateTo, 1)
    }
    return (
      <StatiticsFilter
        type={type}
        text={getDateFromLabel(type, dateFrom, type === 'weeks' ? subDays(dateTo, 1) : dateTo, lng)}
        arrowLeft={dateFrom.getTime() > new Date(createdAt).getTime()}
        arrowRight={lastDateTo.getTime() < new Date().getTime()}
        prevAction={prevBlockData}
        nextAction={nextBlockData}
      />
    )
  }

  const getPlateName = (plate) => {
    return lng === 'en' ? (plate?.translations && plate?.translations[0] ? plate?.translations[0].name : plate.name) : plate.name
  }

  const displayTabs = () => {
    return options?.map((option, idx) => {
      const parts: string[] = labels[lng][option.label].split('(')
      const Label: any = (
        <Stack flexDirection={'column'}>
          <span>{parts[0]}</span>
          <span
            style={{
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: 500
            }}
          >
            {parts[1] ? `(${parts[1]}` : ''}
          </span>
        </Stack>
      )
      return <Tab wrapped value={option.value} label={Label} key={`tab-${idx}`} {...a11yProps(idx)} />
    })
  }

  if (!statitics) return <></>

  return (
    <>
      <Box
        sx={{
          borderBottom: 0,
          borderColor: 'divider',
          position: 'fixed',
          zIndex: 1,
          top: '60px',
          width: '100vw',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        }}
      >
        <Tabs
          sx={{
            '& .MuiTabs-flexContainer': {
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              width: '100%'
            },
            '& .MuiTab-root': {
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 500,
              textTransform: 'lowercase'
            }
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {displayTabs()}
        </Tabs>
      </Box>
      <ContainerFud sx={{ pt: 0, px: 2 }}>
        <Box
          sx={{
            mt: '76px',
            '& .select-stack': {
              mr: 0
            },
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <Typography fontSize={'16px'} lineHeight="20px" variant="h3" color="#111827" mb={select ? '16px' : 0}>
            {getPlateName(plato)}
          </Typography>
        </Box>
        {/* {
            select ? select : null
          } */}

        <RestaurantsChart title={labels[lng].platesSold} dataChart={chartData} IncomeFilter={IncomeFilterRestaurantPlatform} />
        <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid item sm={6} xs={6} lg={3}>
            <CardSummarySmall
              Icon={PlatosVendidos}
              title={statitics.totalQuantity === 1 ? labels[lng].platesSoldSingular : labels[lng].platesSold}
              text={statitics.totalQuantity}
            />
          </Grid>
          <Grid item sm={6} xs={6} lg={3}>
            <CardSummarySmall
              Icon={DiasDisponibles}
              title={+statitics?.mediaDay?.toFixed(0) === 1 ? labels[lng].availableDaysSingular : labels[lng].availableDays}
              text={statitics?.mediaDay?.toFixed(0).replace('.', ',')}
            />
          </Grid>
          <Grid item sm={6} xs={6} lg={3}>
            <CardSummarySmall
              Icon={MediaDiaria}
              title={labels[lng].dailyAverage}
              text={statitics.mediaDays.toFixed(1).replace('.', ',') || '0,0'}
              finalText={labels[lng].plates}
            />
          </Grid>
          <Grid item sm={6} xs={6} lg={3}>
            <CardSummarySmall
              Icon={lng === 'es' ? MediaSemanal : MediaSemanalEn}
              title={labels[lng].weeklyAverage}
              text={statitics.mediaWeeks.toFixed(1).replace('.', ',') || '0,0'}
              finalText={labels[lng].plates}
            />
          </Grid>
          {type === 'months' && (
            <Grid item sm={6} xs={6} lg={3}>
              <CardSummarySmall
                Icon={MediaMensual}
                title={labels[lng].monthlyAverage}
                text={statitics.mediaMonths ? statitics.mediaMonths.toFixed(1).replace('.', ',') : '0,0'}
                finalText={labels[lng].plates}
              />
            </Grid>
          )}
        </Grid>
      </ContainerFud>
    </>
  )
}
