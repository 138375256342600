/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react'
import './SwipeableButtonLeft.css'
import { ReactComponent as Arrow } from 'src/assets/icons/swipe-icons/swipeLeft.svg'

const isTouchDevice = 'ontouchstart' in document.documentElement

export default class SwipeableButton extends Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.sliderBack = React.createRef()
  }

  state = {}

  componentDidMount() {
    if (isTouchDevice && !this.unlocked) {
      document.addEventListener('touchmove', this.onDragBack)
      document.addEventListener('touchend', this.stopDragBack)
    } else if (!isTouchDevice && !this.unlocked) {
      document.addEventListener('mousemove', this.onDragBack)
      document.addEventListener('mouseup', this.stopDragBack)
    }
    this.containerWidth = this.container.current.clientWidth - 50
  }

  onDragBack = (e) => {
    if (this.unmounted || this.state.unlocked) return
    if (this.isDragging) {
      if (isTouchDevice) {
        this.sliderRight = Math.min(Math.max(0, -e.touches[0].clientX + this.startX), this.containerWidth)
      } else {
        this.sliderRight = Math.min(Math.max(0, -e.clientX + this.startX), this.containerWidth)
      }
      this.updateSliderStyleBack()
    }
  }

  updateSliderStyleBack = () => {
    if (this.unmounted || this.state.unlocked) return
    this.sliderBack.current.style.right = this.sliderRight + 50 + 'px'
  }

  stopDragBack = () => {
    if (this.unmounted || this.state.unlocked) return
    if (this.isDragging) {
      this.isDragging = false
      if (this.sliderRight > this.containerWidth * 0.85) {
        this.sliderRight = this.containerWidth
        if (this.props.onSuccess) {
          setTimeout(() => {
            this.sliderRight = 0
            this.updateSliderStyleBack()
          }, 1000)
          this.props.onSuccess(this.props.id)
          this.onSuccess()
        }
      } else {
        this.sliderRight = 0
        if (this.props.onFailure) {
          this.props.onFailure()
        }
      }
      this.updateSliderStyleBack()
    }
  }

  startDragBack = (e) => {
    if (this.unmounted || this.state.unlocked) return
    this.isDragging = true
    if (isTouchDevice) {
      this.startX = e.touches[0].clientX
    } else {
      this.startX = e.clientX
    }
  }

  onSuccess = () => {
    this.container.current.style.width = this.container.current.clientWidth + 'px'
    // this.setState({
    //   unlocked: true
    // })
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  render() {
    return (
      <div className="swipeLeft-ReactSwipeButton">
        <div className={'swipeLeft-rsbContainer ' + (this.state.unlocked ? 'swipeLeft-rsbContainerUnlocked' : '')} ref={this.container}>
          <div
            id={this.props.id}
            className="swipeLeft-rsbcSliderBack"
            ref={this.sliderBack}
            onMouseDown={this.startDragBack}
            onTouchStart={this.startDragBack}
          >
            <span className="swipeLeft-rsbcSliderBackArrow">
              <Arrow />
            </span>
            <span className="swipeLeft-rsbcSliderCircleBack" style={{ background: this.props.color }}></span>
          </div>
          <div className="swipeLeft-rsbcText">
            <div>
              {this.props.text}
              <div className="swipeLeft-rsbcSubText">{this.props.description}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
