import React, { useState, useContext, useEffect } from 'react'
import { AlertContext } from 'src/context/alert/alertContext'
import FormTitle from 'src/components/Form/FormTitle'
import TextInput from 'src/components/Form/TextInput'
import Box from '@mui/material/Box'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from 'src/interfaces/pages.types'
import { labels } from 'src/labels/main_labels'
import TimeInputSelect from 'src/components/Form/TimeInputSelect'
import { updateParameter } from 'src/services/parameters.services'
import HistoryRow from 'src/components/HistoryRow'
import { getTimeFromError } from 'src/utils/time-helpers'
import { useTranslation } from 'react-i18next'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'

export default function ParametrosComplejosAddEdit({ dataToEdit, closeModal, openModal, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const { showSnackbar, showAlert } = useContext(AlertContext)
  const { fetchParameters: apiFetchParameters } = useContext(RestaurantContext)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(dataToEdit.value)

  const [errorIR, setErrorIR] = useState('')

  useEffect(() => {
    if (dataToEdit) {
      setState(dataToEdit.value)
    }
    return () => {
      setLoading(false)
    }
  }, [dataToEdit])

  const onSave = async () => {
    setErrorIR('')
    setLoading(true)
    if (dataToEdit) {
      try {
        await updateParameter({ timeline1: state })
        showSnackbar('success', labels[lng].parameterUpdated)
        if (fetchData) fetchData()
        closeModal()
      } catch (error: any) {
        if (error.response?.data.code) {
          const code = error.response?.data.code
          let errText
          if (code.error === 'EMHIRR') errText = labels[lng].errorEMHIRR + getTimeFromError(code.value)
          if (code.error === 'C') errText = labels[lng].errorC
          if (code.error === 'IR') errText = labels[lng].errorIr
          if (code.error === 'IRr') errText = labels[lng].errorIRr
          if (code.error === 'FR') errText = labels[lng].errorFR
          if (code.error === 'FRr') errText = labels[lng].errorFRr2
          if (code.error === 'FRR')
            errText = labels[lng].errorFRR1 + getTimeFromError(code.value[0]) + labels[lng].errorFRR2 + getTimeFromError(code.value[1])
          if (code.error === 'TPFR') errText = labels[lng].errorTPFR
          if (code.error === 'TPVC') errText = labels[lng].errorTPVC

          if (errText) showAlert(labels[lng].errorDialogTitle, labels[lng].errorDialogText, 'error')
        } else {
          showAlert(labels[lng].errorDialogTitle, labels[lng].errorDialogText, 'error')
          // else showSnackbar('error', error.message)
        }
      } finally {
        setLoading(false)
        apiFetchParameters()
      }
    }
  }

  const displayRows = () => {
    const rows = Object.entries(dataToEdit.value)
      .map((parameter: any) => [...parameter[1].history.map((e: any) => ({ ...e, field: parameter[0] }))])
      .flat(1)
    return rows
      ?.sort((row1: any, row2: any) => new Date(row2.createdAt).getTime() - new Date(row1.createdAt).getTime())
      .map((row: any, idx: number) => {
        return (
          <HistoryRow
            key={`history-row-${idx}`}
            date={row && row.updatedAt}
            lastValue={row && row.lastValueHour}
            lastValueMin={row && row.lastValueMinutes}
            fieldChanged={row && row.field}
            complex
          />
        )
      })
  }

  return (
    <Modal
      title={editing ? dataToEdit.name : labels[lng].addParameter}
      open={openModal}
      handleClose={closeModal}
      footer={<FooterForm text={labels[lng].parameter} closeModal={closeModal} onSave={onSave} editing={editing} loading={loading} />}
    >
      <Box id="form-parametros" component="form" noValidate>
        <FormTitle text={labels[lng].information} />
        <TextInput
          disabled
          value={dataToEdit.name}
          name="name"
          text={labels[lng].parameter.charAt(0).toUpperCase() + labels[lng].parameter.slice(1)}
          placeholder=""
        />
        {dataToEdit.code === 'timeline1' && (
          <>
            <TimeInputSelect value={state.EMHIRR} state={state} setState={setState} name="EMHIRR" text="EMHIRR" />
            <TimeInputSelect value={state.C} state={state} setState={setState} name="C" text="C" />
            <TimeInputSelect value={state.IR} state={state} setState={setState} name="IR" text="IR" error={errorIR} />
            <TimeInputSelect value={state.FRR} state={state} setState={setState} name="FRR" text="FRR" />
            <TimeInputSelect value={state.FR} state={state} setState={setState} name="FR" text="FR" />
          </>
        )}

        {dataToEdit && <div style={{ maxHeight: '192px', overflowY: 'scroll' }}>{displayRows()}</div>}
      </Box>
    </Modal>
  )
}
