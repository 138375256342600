import React, { useMemo, useState } from 'react'
import { Avatar, Grid, Typography, Box } from '@mui/material'

import CardSummarySmall from 'src/components/Cards/CardSummarySmall'
import CardBigIconTitleText from 'src/components/Cards/CardBigIconTitleText'
import CardPaymentMethod from 'src/components/Cards/CardPaymentMethod'
import SwitchUsers from 'src/components/NotificationsPrivacityCards/SwitchUsers'
import { EditGender } from './EditGender'

import { BoxGrey, ContainerFud } from 'src/styles/general-styles'
import { ReactComponent as Reservados } from 'src/assets/icons/resumen-icons/Reservados.svg'
import { ReactComponent as Gastos } from 'src/assets/icons/resumen-icons/Gastos.svg'
import { ReactComponent as Canjes } from 'src/assets/icons/resumen-icons/Canjes.svg'
import { ReactComponent as User } from 'src/assets/icons/general/User.svg'
import { ReactComponent as Map } from 'src/assets/icons/resumen-icons/Map.svg'
import { ReactComponent as CreatedAt } from 'src/assets/icons/resumen-icons/CreatedAt.svg'
import { ReactComponent as Discount } from 'src/assets/icons/resumen-icons/Discount.svg'
import { ReactComponent as Phone } from 'src/assets/icons/resumen-icons/Phone.svg'
import { ReactComponent as Email } from 'src/assets/icons/resumen-icons/Email.svg'
import { ReactComponent as Birthday } from 'src/assets/icons/resumen-icons/Birthday.svg'

import { labels } from 'src/labels/main_labels'
import { UserTypes } from 'src/interfaces/pages.types'
import { dateTimeConvert, onlyDate } from 'src/utils/time-helpers'
import { FudclubTooltip } from 'src/components/Table/DisplayFields'
import { dataToCurrency, stringAvatar } from 'src/utils/helpers'
import { useTranslation } from 'react-i18next'

interface UsuariosDetailResumenProps {
  user: UserTypes
  setUser: React.Dispatch<React.SetStateAction<UserTypes | null>>
}

export default function UsuariosDetailResumen({ user, setUser }: UsuariosDetailResumenProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
  }

  const refreshBono = useMemo<string | void>(() => {
    const bonoRefresh = user?.bonos?.active.find((bono) => bono.refresh === true)
    if (bonoRefresh) {
      return bonoRefresh.id_bono.position
    }
  }, [user])

  return (
    <>
      <BoxGrey className="flex-between" sx={{ height: 97, py: 0 }}>
        <div className="flex-center">
          {user.photo ? (
            <Avatar sx={{ ml: 1, width: 64, height: 64 }} alt={user.name} src={user.photo} />
          ) : (
            <Avatar sx={{ ml: 1, width: 64, height: 64 }} alt={user.name} {...stringAvatar(user.name, user.surname, 64)} />
          )}
          <Typography sx={{ ml: 2 }} variant="h2" component="h2">
            {user.name || ''} {user.surname || ''}
          </Typography>
        </div>
      </BoxGrey>
      <ContainerFud>
        <Typography sx={{ mb: 1 }} variant="body1">
          {labels[lng].statistics}
        </Typography>
        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={6} xs={6} lg={4}>
            <CardSummarySmall Icon={Reservados} title={labels[lng].reservedPlates} text={user.income?.totalPlates || 0} />
          </Grid>
          <Grid item sm={6} xs={6} lg={4}>
            <CardSummarySmall Icon={Gastos} title={labels[lng].spending} text={dataToCurrency(user.income?.totalSpent)} />
          </Grid>
          <Grid item sm={12} xs={12} lg={4}>
            <CardSummarySmall Icon={Canjes} title={labels[lng].discountCodeRedemptions}>
              {!user.discount?.usersUsed || user.discount?.usersUsed.length === 0 ? (
                <Typography sx={{ textAlign: 'left', color: '#111827' }} variant={'h4'} component="strong">
                  {user.discount?.uses || 0}
                </Typography>
              ) : (
                <FudclubTooltip
                  title={
                    <Box sx={{ py: '3px', px: '5px' }}>
                      {user.discount?.usersUsed.map((user: { _id: string; email: string }, idx: number) => (
                        <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#D1D5DB' }} key={`${idx}-${user}`}>
                          {user.email}
                        </Typography>
                      ))}
                    </Box>
                  }
                  placement="right"
                >
                  <Typography sx={{ textAlign: 'left', color: '#111827', cursor: 'pointer' }} variant={'h4'} component="strong">
                    {user.discount?.uses || 0}
                  </Typography>
                </FudclubTooltip>
              )}
            </CardSummarySmall>
          </Grid>
        </Grid>
        <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
          {labels[lng].general}
        </Typography>
        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={6} xs={6} lg={4}>
            <CardBigIconTitleText Icon={Map} title={labels[lng].actingZone} text={user.address || '-'} />
          </Grid>
          <Grid item sm={6} xs={6} lg={4}>
            <CardBigIconTitleText
              Icon={CreatedAt}
              title={labels[lng].registerDate}
              text={user.createdAt && dateTimeConvert(user.createdAt)}
            />
          </Grid>
          <Grid item sm={12} xs={12} lg={4}>
            <CardBigIconTitleText Icon={Discount} title={labels[lng].discountCode} text={user.discount?.name} chip />
          </Grid>
        </Grid>

        <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
          {labels[lng].personalData}
        </Typography>
        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={6} xs={6} lg={3}>
            <CardBigIconTitleText Icon={Email} title={labels[lng].email} text={user.email || '-'} />
          </Grid>
          <Grid item sm={6} xs={6} lg={3}>
            <CardBigIconTitleText Icon={Phone} title={labels[lng].phone} text={user.phone || '-'} />
          </Grid>
          <Grid item sm={6} xs={6} lg={3}>
            <CardBigIconTitleText Icon={Birthday} title={labels[lng].birthday} text={(user.birthday && onlyDate(user.birthday)) || '-'} />
          </Grid>
          <Grid item sm={6} xs={6} lg={3}>
            <CardBigIconTitleText
              Icon={User}
              title={labels[lng].gender}
              text={user.gender ? labels[lng][user.gender] : '-'}
              handleEdit={() => setOpenModal(true)}
            />
          </Grid>
        </Grid>

        <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
          {labels[lng].paymentMethods}
        </Typography>
        <CardPaymentMethod userId={user._id!} refreshBono={refreshBono} />
        <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
          {labels[lng].permissions}
        </Typography>
        <SwitchUsers
          location_access={(user && user.location_access) || false}
          switchNotifications={user && user.notifications && user.notifications}
          switchPrivacity={user.privacity}
        />
      </ContainerFud>
      {openModal && (
        <EditGender
          open={openModal}
          handleClose={closeModal}
          user={user}
          setUser={setUser}
          genderPrev={user.gender || ''}
          id={user._id || ''}
        />
      )}
    </>
  )
}
