import { LabelType } from '../../labels/main_labels'
import { getRestaurantsNoDeleted } from 'src/services/restaurants.services'
import { getUsers } from 'src/services/users.services'
import { getDiscounts } from 'src/services/discounts.services'

export const getLabel = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  elm: any,
  searchBy?: 'users' | 'restaurant' | 'neighborhood' | 'discount' | 'group',
  labels?: LabelType
): string => {
  if (searchBy === 'group') {
    return `${elm.name} (${elm.email})`
  }
  if (searchBy === 'restaurant') {
    return `${elm.name} - ${elm.location && elm.location.street && elm.location.street}`
  }
  if (searchBy === 'neighborhood') return `${elm.cp} - ${elm.name}`
  if (searchBy === 'users') return `${elm.name} ${elm.surname || ''} ${elm.email ? `(${elm.email})` : ''}`
  if (labels) {
    return labels.es[elm]
  }
  if (elm.name) return elm.name
  return elm;
}

export const fetchData = async (
  word: string,
  setMaxPage: React.Dispatch<React.SetStateAction<number>>,
  page: number,
  searchBy?: 'users' | 'restaurant' | 'neighborhood' | 'discount'
): Promise<any> => {
  if (searchBy === 'users') {
    const resp = await getUsers('name=asc', 12, page, word)
    setMaxPage(resp.maxPages)
    resp.users = resp.users.map((elm) => {
      if (elm.status === 'DELETED') {
        elm.surname = `${elm.surname} (eliminado)`
      }
      return elm
    })
    return resp.users
  }
  if (searchBy === 'discount') {
    const resp = await getDiscounts('name=asc', 12, page, word)
    setMaxPage(resp.maxPages)
    return resp.discounts
  }
  if (searchBy === 'restaurant') {
    const resp = await getRestaurantsNoDeleted('name=asc', 12, page, word)
    setMaxPage(resp.maxPages)
    return resp.restaurants
  }
}
