import React from 'react'
import { useTranslation } from 'react-i18next'
import FormTitle from 'src/components/Form/FormTitle'
import HistoryRow from 'src/components/HistoryRow'
import { labels } from 'src/labels/main_labels'

interface HistoricPlateProp {
  parameter: any
  parameters: any
}

export default function HistoricPlate({ parameter }: HistoricPlateProp): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  let historicArray: any = []

  if (parameter.RFC.lastUpdate && parameter.RFC.lastUpdate.length > 0) {
    historicArray = [...parameter.RFC.lastUpdate.map((e: any) => ({ ...e, type: 'RFC' }))]
  }

  if (parameter.PP.lastUpdate && parameter.PP.lastUpdate.length > 0) {
    historicArray = [...historicArray, ...parameter.PP.lastUpdate.map((e: any) => ({ ...e, type: 'PP' }))]
  }

  historicArray = historicArray.sort((p1: any, p2: any) => new Date(p2.createdAt).getTime() - new Date(p1.createdAt).getTime())

  // const rfc =
  //   parameter.RFC.lastUpdate && parameter.RFC.lastUpdate.length > 0 ? parameter.RFC.lastUpdate[parameter.RFC?.lastUpdate.length - 1] : ''
  // const pp =
  //   parameter.PP.lastUpdate && parameter.PP.lastUpdate.length > 0 ? parameter.PP.lastUpdate[parameter.PP?.lastUpdate.length - 1] : ''

  const displayHistoric = () => {
    return historicArray?.map((parameter: any, idx: number) => {
      if (parameter.type === 'RFC') {
        return (
          <HistoryRow
            key={`plate-parameter-${idx}`}
            date={parameter?.updatedAt}
            complex
            fieldChanged={'RFC'}
            lastValue={parameter?.lastValue ? 'Recomendado' : 'No recomendado'}
          />
        )
      } else {
        return (
          <HistoryRow
            key={`plate-parameter-${idx}`}
            date={parameter?.updatedAt}
            lastValue={parameter?.lastValue ? parameter?.lastValue.toFixed(2).replace('.', ',') : '0,00'}
            fieldChanged={'PP'}
            byDefaultText={parameter?.default}
            symbol={' €'}
            complex
          />
        )
      }
    })
  }

  return (
    <>
      <FormTitle text={labels[lng].history} />
      <div style={{ maxHeight: '192px', overflowY: 'scroll' }}>{historicArray?.length ? displayHistoric() : null}</div>
      {/* {parameter.RFC?.lastUpdate[0] && (
        <HistoryRow
          name={parameter.RFC?.admin?.name + '  ' + parameter.RFC?.admin?.name}
          date={rfc.updatedAt}
          lastValue={rfc.lastValue ? 'Recomendado' : 'No recomendado'}
        />
      )}
      {parameter.PP?.lastUpdate[0] && (
        <HistoryRow
          name={parameter.PP?.admin?.name + '  ' + parameter.PP?.admin?.name}
          date={pp.updatedAt}
          lastValue={pp.lastValue}
          fieldChanged={labels[lng].price}
        />
      )} */}
    </>
  )
}
