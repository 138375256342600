import axiosInstance from './axiosInstance'
import { UserTypes } from 'src/interfaces/pages.types'
// import axiosInstanceImages from './axiosInstanceImages'
import { UserCardTypes } from '../interfaces/app.types'

interface UserTypesApi {
  users: UserTypes[]
  total: number
  maxPages: number
}

export const getUsers = async (order?: string, limit?: number, offset?: number, searchString?: string): Promise<UserTypesApi> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`

  return axiosInstance
    .get(`/user${query}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de usuarios')
    })
}

export const getUser = async (id: string): Promise<UserTypes> => {
  return axiosInstance
    .get(`/user/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar los datos del usuario')
    })
}

export const createPreUser = async (user: UserTypes | any[]): Promise<void> => {
  const multiple = Array.isArray(user)
  const usersFix = { users: multiple ? user : [user] }

  return axiosInstance
    .post('/user/preUser', usersFix)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => {
      // if (error?.response?.data?.message.substring(0, 33) === `BaseError: EMAIL_ALREADY_REGISTER`) throw new Error('EmailAlreadyInUse')
      if (error?.response?.data?.message.includes('EMAIL_ALREADY_REGISTER'))
        throw new Error(multiple ? error?.response?.data?.code : 'EmailAlreadyInUse')
      else if (error?.response?.data?.message.includes('must be a valid email'))
        throw new Error(multiple ? error?.response?.data?.code : 'EmailValidation')
      else throw new Error('Hubo un error mientras se intentaba crear el usuario')
    })
}

export const deleteUserAdmin = async (users: UserTypes | Array<UserTypes>): Promise<void> => {
  if (typeof users === 'string') users = [users]

  const usersFix = { users: users }

  return axiosInstance
    .put(`/user/delete`, usersFix)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaban borrar los usuarios')
    })
}

export const updateUserAdmin = async (gender: string, id: string): Promise<string> => {
  return axiosInstance
    .put(`/user/${id}`, { gender: gender })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el usuario')
    })
}

export const updatePrivacityAdmin = async (id: string): Promise<string> => {
  return axiosInstance
    .get(`/user/privacity/special_offers_and_promotions/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar la privacidad del usuario')
    })
}

export const getUserCards = async (userId: string): Promise<UserCardTypes[]> => {
  return axiosInstance
    .get(`/user/${userId}/card`)
    .then((response) => {
      const { data } = response
      return data.cards
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar las tarjetas del usuario')
    })
}

export const getUserBookingsGifts = async (userId: string): Promise<any[]> => {
  return axiosInstance
    .get(`/booking/${userId}/gift`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar los pedidos del usuario')
    })
}

export const getUserBonosPayExpired = async (userId: string): Promise<any[]> => {
  return axiosInstance
    .get(`/bono/${userId}/expired`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar los pedidos del usuario')
    })
}

export const getUserBookingsRecords = async (userId: string): Promise<any[]> => {
  return axiosInstance
    .get(`/booking/${userId}/record`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar los pedidos del usuario')
    })
}
