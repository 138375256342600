import React, { useState, useEffect, useContext } from 'react'
import { Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography, Button } from '@mui/material'
import Papa from 'papaparse'

import { AlertContext } from 'src/context/alert/alertContext'
import { ReactComponent as Importar } from 'src/assets/icons/general/Importar2.svg'
import { AddEditProps } from 'src/interfaces/pages.types'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import FormTitle from 'src/components/Form/FormTitle'
import { MainBox, GridFlexBetween } from 'src/components/Form/form.styles'
import { ModalTitle } from 'src/components/Modals/modals.styles'
import { createPreUser } from 'src/services/users.services'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

export default function ImportUsers({ closeModal, openModal, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showAlert } = useContext(AlertContext)
  const [loading, setLoading] = useState(false)
  const [errorDialog, setErrorDialog] = useState(false)
  const [usersError, setUsersError] = useState<string[] | null>(null)
  const [users, setUsers] = useState<any[] | null>(null)

  const resetComponent = () => {
    setUsers(null)
    setLoading(false)
    setUsersError(null)
  }

  useEffect(() => {
    return () => resetComponent()
  }, [])

  const onSave = async () => {
    setUsersError(null)
    setLoading(true)
    if (!users) return
    try {
      await createPreUser(users)
      showAlert(labels[lng].importUsersTitle, labels[lng].importUsersText, 'successRed')
      resetComponent()
      closeModal()
    } catch (error) {
      if (error instanceof Error) {
        setUsersError(error.message.split(','))
        setErrorDialog(true)
      }
    } finally {
      if (fetchData) fetchData()
      setLoading(false)
    }
  }

  const onChangeImport = (e: any) => {
    setUsers(null)
    if (e.target.files[0]) {
      Papa.parse(e.target.files[0], {
        complete: function (results: { data: any[] }) {
          const newUsers = results.data.map((elm: Record<string, string>) => {
            if ((!elm.name && !elm.Name) || (!elm.surname && !elm.Surname) || (!elm.email && !elm.Email)) {
              showAlert(labels[lng].importUsersError, labels[lng].importUsersPrevalidation, 'error')
              return 'null'
            }
            return { name: elm.name || elm.Name, surname: elm.surname || elm.Surname, email: elm.email || elm.Email }
          })
          if (newUsers[0] !== 'null') setUsers(newUsers)
        },
        header: true
      })
    }
  }

  return (
    <>
      <Modal
        title={labels[lng].importUsers}
        open={openModal}
        handleClose={closeModal}
        footer={
          <FooterForm
            disabled={!users}
            mainText
            text={labels[lng].importUsers}
            closeModal={closeModal}
            onSave={onSave}
            loading={loading}
            editing={false}
          />
        }
      >
        <Box id="form-import-users" component="form" noValidate>
          <FormTitle text={labels[lng].information} />
          <MainBox sx={{ mb: '24px' }}>
            <GridFlexBetween container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" component="h6">
                  {labels[lng].file}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {users && <Chip size="small" label={labels[lng].importFileSuccess} sx={{ backgroundColor: '#D1FAE5', color: '#065F46' }} />}
              </Grid>
              <Grid item xs={2}>
                <label style={{ width: 100, height: 100, cursor: 'pointer' }}>
                  <input
                    style={{ display: 'none' }}
                    id="pictures"
                    accept=".csv"
                    type="file"
                    onChange={onChangeImport}
                    onClick={(event: any) => {
                      event.target.value = null
                    }}
                  />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Importar />
                    <Typography sx={{ fontSize: '8px' }}>{labels[lng].csvImport}</Typography>
                  </div>
                </label>
              </Grid>
            </GridFlexBetween>
          </MainBox>
        </Box>
      </Modal>
      <Dialog sx={{ '& .MuiPaper-root': { maxWidth: '384px' } }} open={errorDialog}>
        <ModalTitle sx={{ pt: '24px', pb: '12px', justifyContent: 'center' }}>{labels[lng].importUsersError}</ModalTitle>
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText sx={{ textAlign: 'center', mb: '5px' }}>{labels[lng].importUsersErrorText1}</DialogContentText>
          {usersError &&
            usersError.map((email: string) => (
              <DialogContentText key={email} sx={{ textAlign: 'center', mb: '5px' }}>
                {email}
              </DialogContentText>
            ))}
          <DialogContentText sx={{ textAlign: 'center', px: 1 }}>{labels[lng].importUsersErrorText2}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="submit" fullWidth variant="contained" sx={{ m: 2 }} onClick={() => setErrorDialog(false)}>
            {labels[lng].accept}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
