import React, { useState, useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { Main, WelcomeBox } from './welcome.styles'
import RestaurantWithAvatar from 'src/components/RestaurantWithAvatar'

import { AuthContext } from 'src/context/auth/authContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { UserDataTypes, GroupUserType } from 'src/interfaces/app.types'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'

export default function RestaurantSelection(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const [loading, setLoading] = useState(false)
  const { userData } = useContext(AuthContext)
  const { fetchRestaurantById } = useContext(RestaurantContext)
  const [options, setOptions] = useState<UserDataTypes['group'] | null>(null)
  const [checked, setChecked] = useState<string | null>(null)

  useEffect(() => {
    if (userData.group) setOptions(userData.group)

    if (userData.group?.group.length === 1) {
      setLoading(true)
      fetchRestaurantById(userData.group.group[0]._id)
    }
    return () => setOptions(null)
  }, [])

  const onSubmit = () => {
    if (!checked) return
    setLoading(true)
    fetchRestaurantById(checked)
  }
  return (
    <Main>
      <WelcomeBox
        sx={{
          width: '496px',
          [theme.breakpoints.down('md')]: {
            width: 'calc(90% - 32px) !important'
          }
        }}
      >
        <Typography sx={{ textAlign: { xs: 'center' }, mb: '14px', lineHeight: '24px' }} component="h3" variant="h6">
          {labels[lng].restaurantSelectorText}
        </Typography>
        <Box sx={{ display: 'flex', width: '100%', my: 1, flexDirection: 'column' }}>
          {options &&
            options.group
              .filter((e) => !e.isNewRestaurant)
              .map((opt: GroupUserType) => (
                <RestaurantWithAvatar
                  key={opt._id}
                  id={opt._id}
                  name={opt.name}
                  street={opt.location.street || ''}
                  number={opt.location.number || ''}
                  photo={opt.photo}
                  onclick={(id: string) => setChecked(id)}
                  check={checked === opt._id}
                  height="50px"
                />
              ))}
        </Box>
        <LoadingButton disabled={!checked} onClick={onSubmit} loading={loading} fullWidth variant="contained" sx={{ mt: '24px' }}>
          {labels[lng].login}
        </LoadingButton>
      </WelcomeBox>
    </Main>
  )
}
