import { subDays, subMinutes } from 'date-fns'

export const getDateUTC = (data: string | number): Date => {
  const date = new Date(data)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}

export const dateTimeConvert = (data: string | number | Date, text?: boolean): string => {
  const date = new Date(data)
  date.toLocaleDateString('es-ES')
  if (text) return `${date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric', day: 'numeric' })}`
  return `${date.toLocaleDateString('es-ES')} ${date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}`
}

export const dateTimeConvertUTC = (data: string | number, text?: boolean): string => {
  const date = getDateUTC(data)
  date.toLocaleDateString('es-ES')
  if (text) return `${date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric', day: 'numeric' })}`
  return `${date.toLocaleDateString('es-ES')} ${date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}`
}
export const dateTimeConvertLng = (data: string | number, lng: string, text?: boolean): string => {
  const date = new Date(data)
  const locale = lng === 'en' ? 'en-US' : 'es-ES'
  date.toLocaleDateString(locale)
  if (text) return `${date.toLocaleDateString(locale, { month: 'long', year: 'numeric', day: 'numeric' })}`
  return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })}`
}

export const transformDate = (date: Date, day: number): Date => {
	return new Date(
		Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + day, 0, 0, 0)
	);
};

export const onlyDate = (data: string | number | Date, formatted?: boolean): string => {
  const date = new Date(data)

  if (formatted)
    return `${
      date.getDate().toString().padStart(2, '0') +
      '/' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '/' +
      date.getFullYear().toString().slice(-2)
    }`
  return `${date.toLocaleDateString('es-ES')}`
}

export const timeConstructor = (hour: number, minutes: number): string => {
  const min = minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString()
  const hr = hour.toString().length === 1 ? '0' + hour.toString() : hour.toString()

  return `${hr}:${min}`
}

export const parameterToTime = (parameter: { hour: string; minutes: string }): string => {
  const hour = parameter.hour ? parameter.hour : '00'
  const minutes = parameter.minutes ? parameter.minutes : '00'
  return `${hour}:${minutes}`
  // return `${parameter.hour || '00'}:${parameter.minutes || '00'} `
}

export const subsMinutesFromDateFRr = (
  { hour, minutes }: { hour: number; minutes: number },
  FRR: { hour: number; minutes: number }
): string => {
  const now = new Date()
  const date = subMinutes(new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minutes, 0), 30)
  const dateFRR = new Date(now.getFullYear(), now.getMonth(), now.getDate(), FRR.hour, FRR.minutes, 0)
  const _date = dateFRR > date ? date : dateFRR
  return `${_date.getHours()}:${_date.getMinutes().toString().padStart(2, '0')}`
}

export const getSubsMinutesFromDateFRr = (
  { hour, minutes }: { hour: number; minutes: number },
  FRR: { hour: number; minutes: number }
): Date => {
  const now = new Date()
  const date = subMinutes(new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minutes, 0), 30)
  const dateFRR = new Date(now.getFullYear(), now.getMonth(), now.getDate(), FRR.hour, FRR.minutes, 0)
  const _date = dateFRR > date ? date : dateFRR
  return _date
}

export const getSubsMinutesFromDateFRrAddDays = (
  FRr: { hour: number; minutes: number },
  FRR: { hour: number; minutes: number },
  day: number
): Date => {
  if (!FRr) return new Date()
  const now = new Date()
  const date = subMinutes(new Date(now.getFullYear(), now.getMonth(), now.getDate(), FRr.hour, FRr.minutes, 0), 30)
  const dateFRR = new Date(now.getFullYear(), now.getMonth(), now.getDate() + day, FRR.hour, FRR.minutes, 0)
  const _date = dateFRR > date ? date : dateFRR
  return _date
}

export const getTimeFromDate = (time: string): string => {
  const date = time
  const hour = String(new Date(date).getHours()).padStart(2, '0')
  const minutes = String(new Date(date).getMinutes()).padStart(2, '0')
  return `${hour}:${minutes}`
}

export const getTimeFromError = (time: string): string => {
  const hour = time.split(':')[0].padStart(2, '0')
  const minutes = time.split(':')[1].padStart(2, '0')
  return `${hour}:${minutes}`
}

export const getEMHIRR = (EMHIRR: { hour: number; minutes: number }, FRR: { hour: number; minutes: number }, date: Date): Date => {
  const dateEMHIRRNow = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getDate(), EMHIRR.hour, EMHIRR.minutes, 0))
  const dateFRR = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getDate(), FRR.hour, FRR.minutes, 0))
  const dateEMHIRRYesterday = subDays(dateEMHIRRNow, 1)
  return dateEMHIRRNow < dateFRR ? dateEMHIRRNow : dateEMHIRRYesterday
}

export const getTimeUTC = (hours: number, minutes: number): Date => {
  const date = new Date()
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 1))
}
