import React, { useContext, useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AlertContext } from 'src/context/alert/alertContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { getReviewByRestaurant } from 'src/services/plates.services'
import Historial from './Historial'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function RestaurantHistorial() {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const [historyDBFilter, setHistoryDBFilter] = useState<{ plate: { name: string } }[] | []>([])

  const [loadingTable, setLoadingTable] = useState(false)

  const { restaurant } = useContext(RestaurantContext)
  const { showSnackbar } = useContext(AlertContext)

  const [isEmpty, setIsEmpty] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const firstRender = useRef(false)

  const params: any = useParams()
  const pageRef = useRef(0)
  const wordSearch = useRef('')

  const fetchHistorial = async () => {
    setLoadingTable(true)
    try {
      const resp = await getReviewByRestaurant(
        params.idRestaurant ? params.idRestaurant : restaurant!._id,
        rowsPerPage,
        pageRef.current,
        wordSearch.current,
        lng
      )
      if (resp) {
        setTotalCount(resp.total)
        setHistoryDBFilter(resp.plannings)
        if (resp.plannings.length === 0 && firstRender) setIsEmpty(true)
      }
    } catch (error) {
      if (error instanceof Error) {
        showSnackbar('error', error.message)
        setHistoryDBFilter([])
      }
    } finally {
      setLoadingTable(false)
      if (!firstRender.current) firstRender.current = true
    }
  }

  useEffect(() => {
    fetchHistorial()
  }, [rowsPerPage])

  return (
    <Historial
      historyDB={historyDBFilter}
      fetchHistorial={fetchHistorial}
      {...{
        setHistoryDBFilter,
        isEmpty,
        setIsEmpty,
        totalCount,
        setTotalCount,
        rowsPerPage,
        setRowsPerPage,
        loadingTable,
        pageRef,
        wordSearch
      }}
    />
  )
}
