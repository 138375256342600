import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from 'src/styles/theme'

export const StatiticsFilterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: 256,
  height: 38,
  border: '1px solid #D1D5DB',
  borderRadius: '6px',
  padding: '8px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  '& .MuiSvgIcon-root': {
    fontSize: '18px',
    cursor: 'pointer'
  },
  [theme.breakpoints.down('md')]: {
    border: 'none',
    boxShadow: 'none',
    width: '100%'
  }
}))
