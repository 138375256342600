import React, { useContext, useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import SearchInput from 'src/components/SearchInput'
import { Box, Stack } from '@mui/material'

import UsuariosAddEdit from './UsuariosAddEdit'
import TableApi from 'src/components/Table/TableApi'

import { AlertContext } from 'src/context/alert/alertContext'
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'
import { deleteUserAdmin, getUsers } from 'src/services/users.services'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import { labels } from 'src/labels/main_labels'
import { Order } from 'src/interfaces/pages.types'
import ImportUsers from './ImportUsers'
import { useTranslation } from 'react-i18next'

export default function Usuarios(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { showHeader, setExportData, setExportName } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const history = useHistory()

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].user,
      fieldtype: 'user-avatar'
    },
    {
      idField: 'income',
      align: 'center',
      disablePadding: true,
      label: labels[lng].reservedP,
      fieldtype: 'reservedPlates'
    },
    {
      idField: 'income',
      align: 'center',
      disablePadding: false,
      label: labels[lng].spending,
      fieldtype: 'spending'
    },
    {
      idField: 'phone',
      align: 'center',
      disablePadding: false,
      label: labels[lng].phone,
      fieldtype: 'string'
    },
    {
      idField: 'createdAt',
      align: 'center',
      disablePadding: true,
      order: true,
      label: labels[lng].registerDate,
      fieldtype: 'date-time'
    }
  ]

  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const wordSearch = useRef('')

  const [openModalImport, setOpenModalImport] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }
  // TABLE CONTROL
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const pageRef = useRef(0)
  const avoidCallsRef = useRef(false)

  // EXCEL EXPORT
  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      ;(async () => {
        const users = await getUsers(
          `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
          1000,
          0,
          wordSearch.current
        )
        const excelFormatted = users?.users?.map((user) => {
          return {
            id: user._id,
            [labels[lng].name]: user.name,
            [labels[lng].surname]: user.surname,
            [labels[lng].email]: user.email,
            [labels[lng].privacity]: user.privacity.special_offers_and_promotions,
            [labels[lng].phone]: user.phone || '',
            'Identificador activo': user.fcm.length,
            [labels[lng].createdAt]: dateTimeConvert(user.createdAt || ''),
            [labels[lng].birthday]: user?.birthday ? dateTimeConvert(user.birthday.toString()) : '',
            [labels[lng].gender]: user.gender,
            'Fecha de eliminación': user.status === 'DELETED' ? dateTimeConvert(user.updatedAt || '') : ''
          }
        })
        setExportData(excelFormatted)
        setExportName('Usuarios')
      })()
    }
    return () => setExportData(null)
  }, [rowsData])

  const fetchUserList = async () => {
    if (avoidCallsRef.current) return
    avoidCallsRef.current = true
    setLoadingTable(true)
    try {
      const resp = await getUsers(
        `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
        rowsPerPage,
        pageRef.current,
        wordSearch.current
      )
      setTotalCount(resp.total)
      setRowsData(resp.users)
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
      avoidCallsRef.current = false
    }
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchUserList()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageRef.current = 0
  }
  const handleSearch = async (word: string) => {
    wordSearch.current = word
    if (!word) {
      fetchUserList()
      return
    }
    const resp = await getUsers(`${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`, rowsPerPage, 0, word)
    pageRef.current = 0
    setTotalCount(resp.total)
    setRowsData(resp.users)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, justifyContent: { sm: 'space-between' }, alignItems: { xs: 'center' } }}>
        <Box sx={{ width: 350 }}>
          <SearchInput fullwidth placeholder={labels[lng].searchUser} name="search" handleSubmit={handleSearch} clean />
        </Box>
        <div>
          <Button size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModalImport(true)}>
            {labels[lng].import}
          </Button>
          <Button sx={{ ml: '12px' }} size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModal(true)}>
            {labels[lng].addUser}
          </Button>
        </div>
      </Stack>
    )
  }

  useEffect(() => {
    if (userData) fetchUserList()
  }, [rowsPerPage, order, orderBy])

  useEffect(() => {
    if (initHeader) showHeader(Header)
  }, [initHeader])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].users, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteUserAdmin(data)
          .then(() => {
            showSnackbar('success', labels[lng].userDeleted)
            fetchUserList()
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = (data: Record<string, unknown>) => {
    setEditData(data)
    setOpenModal(true)
  }

  return (
    <>
      <TableApi
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rows={rowsData}
        headCells={headCells}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        loading={loadingTable}
        onRowClick={(row) => history.push(`/usuarios/${row._id}`)}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
        page={pageRef.current}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        selected={selected}
        setSelected={setSelected}
      />
      {openModal && (
        <UsuariosAddEdit
          openModal={openModal}
          closeModal={closeModal}
          dataToEdit={editData}
          onDelete={onDelete}
          fetchData={fetchUserList}
        />
      )}
      {openModalImport && (
        <ImportUsers openModal={openModalImport} closeModal={() => setOpenModalImport(false)} fetchData={fetchUserList} />
      )}
    </>
  )
}
