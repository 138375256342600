import React from 'react'
import { Typography } from '@mui/material'
import { StatiticsFilterBox } from './StatiticsFilter.styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useTranslation } from 'react-i18next'

interface StatiticsFilterProps {
  arrowLeft: boolean
  arrowRight: boolean
  text: string
  prevAction: () => void
  nextAction: () => void
  type?: string
}

export default function StatiticsFilter({ arrowLeft, arrowRight, text, prevAction, nextAction, type }: StatiticsFilterProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <StatiticsFilterBox>
      {/* <ChevronLeftIcon onClick={prevAction} /> */}
      {arrowLeft ? (
        <ChevronLeftIcon onClick={prevAction} />
      ) : (
        <ChevronLeftIcon style={{ color: '#6B7280', cursor: 'inherit', opacity: '40%' }} />
      )}
      <Typography
        sx={{ color: '#374151', textTransform: type === 'months' || lng === 'en' ? 'capitalize' : 'lowercase' }}
        variant="subtitle2"
      >
        {text}
      </Typography>
      {/* <ChevronRightIcon onClick={nextAction} /> */}
      {arrowRight ? (
        <ChevronRightIcon onClick={nextAction} />
      ) : (
        <ChevronRightIcon style={{ color: '#6B7280', cursor: 'inherit', opacity: '40%' }} />
      )}
    </StatiticsFilterBox>
  )
}
