import React from 'react'
import { Typography } from '@mui/material'
import { ReactComponent as Star } from 'src/assets/icons/general/StarFilled2.svg'
import { BannerBox } from './bannerRecommendedPlate.styles'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

export default function BannerRecommendedPlate(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <BannerBox>
      <Star />
      <Typography variant="body2">{labels[lng].recommendedByFudclub}</Typography>
    </BannerBox>
  )
}
