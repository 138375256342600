import { NeighborhoodTypes, RestaurantTypes, StatiticsTypes } from 'src/interfaces/pages.types'
import axiosInstance from './axiosInstance'
import axiosInstanceImages from './axiosInstanceImages'
import transformInToFormObject from 'src/utils/transformToFormData'

interface RestaurantsApi {
  restaurants: RestaurantTypes[]
  total: number
  maxPages: number
}

export const getRestaurants = async (order?: string, limit?: number, offset?: number, searchString?: string): Promise<RestaurantsApi> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`

  return axiosInstance
    .get(`/restaurant${query}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de restaurantes')
    })
}
export const getRestaurantsNoDeleted = async (
  order?: string,
  limit?: number,
  offset?: number,
  searchString?: string
): Promise<RestaurantsApi> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`

  return axiosInstance
    .get(`/restaurant/no_deleted${query}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de restaurantes')
    })
}

export const getRestaurantById = async (id: string): Promise<RestaurantTypes> => {
  return axiosInstance
    .get(`/restaurant/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar los datos del restaurante')
    })
}

export const createRestaurant = async (restaurant: RestaurantTypes): Promise<string> => {
  const formatted = transformInToFormObject(restaurant)

  return axiosInstanceImages
    .post('/restaurant/create', formatted)
    .then((response) => {
      const { data } = response
      return data._id
    })
    .catch((error) => {
      if (error.response.data.code) {
        throw error
      } else if (error.response?.data.message === 'The email address is already in use by another account.') {
        throw new Error('RestaurantMailError')
      } else if (error.response?.data.message.substring(0, 40) === '"value" failed custom validation because') {
        throw new Error('ParametersError')
      } else throw new Error('Hubo un error mientras se intentaba crear el restaurante')
    })
}

export const updateRestaurant = async (restaurant: RestaurantTypes, id: string): Promise<string> => {
  delete restaurant._id

  const formatted = transformInToFormObject(restaurant)
  return axiosInstanceImages
    .put(`/restaurant/${id}`, formatted)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => {
      if (error.response.data.code) {
        throw error
      } else if (error.response?.data.message === 'The email address is already in use by another account.') {
        throw new Error('RestaurantMailError')
      } else if (error.response?.data.message.substring(0, 40) === '"value" failed custom validation because') {
        throw new Error('ParametersError')
      } else throw new Error('Hubo un error mientras se intentaba actualizar el restaurante')
    })
}

export const deleteRestaurant = async (id: string): Promise<void> => {
  return axiosInstance
    .delete(`/restaurant/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar el restaurante')
    })
}

export const disabledRestaurants = async (restaurants: string | string[]): Promise<void> => {
  if (typeof restaurants === 'string') restaurants = [restaurants]
  return axiosInstance
    .put(`/restaurant/deleted`, { id_restaurants: restaurants })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar el restaurante')
    })
}

export const searchRestaurants = async (searchString: string): Promise<RestaurantsApi> => {
  return axiosInstance
    .get(`/restaurant?search=${searchString}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de restaurantes')
    })
}

export const toggleNotificationsById = async (type: string, name: string, id: string): Promise<string> => {
  return axiosInstance
    .get(`/restaurant/${id}/${type}/${name}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba realizar la acción')
    })
}

export const toggleStatus = async (selected: string[]): Promise<void> => {
  return axiosInstance
    .put(`/restaurant/toggle/status`, { restaurants: selected })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba realizar la acción')
    })
}

export const activeRestaurants = async (restaurants: string[] | string): Promise<string> => {
  if (typeof restaurants === 'string') restaurants = [restaurants]
  return axiosInstance
    .put(`/restaurant/active`, { id_restaurants: restaurants })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el estado de los restaurantes')
    })
}

export const deactiveRestaurants = async (restaurants: string[] | string): Promise<string> => {
  if (typeof restaurants === 'string') restaurants = [restaurants]
  return axiosInstance
    .put(`/restaurant/inactive`, { id_restaurants: restaurants })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el estado de los restaurantes')
    })
}

export const getNeighborhood = async (searchString: string): Promise<NeighborhoodTypes[]> => {
  return axiosInstance
    .get(`/restaurant/neighborhood?search=${searchString}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar los barrios')
    })
}

export const getNeighborhoods = async (): Promise<NeighborhoodTypes[]> => {
  return axiosInstance
    .get(`/restaurant/neighborhood`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar los barrios')
    })
}

export const editEmailRestaurant = async (email: string): Promise<string> => {
  return axiosInstance
    .put(`/restaurant/change/email`, { newEmail: email })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error: any) => {
      if (error.response.data.message === 'EMAIL_ALREADY_REGISTER') throw new Error('EMAIL_ALREADY_REGISTER')
      else throw new Error('Hubo un error mientras se intentaba actualizar el email')
    })
}

export const updatePasswordRestaurant = async (password: string): Promise<string> => {
  return axiosInstance
    .put(`/restaurant/updatePassword`, { password })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar la contraseña')
    })
}
// TODO - poner horas a 0
export const getRestaurantStatitics = async (
  idRestaurant: string,
  type: string,
  dateFrom?: Date | null,
  dateTo?: Date | null
): Promise<StatiticsTypes> => {
  const obj: { type: string; to?: Date | null; from?: Date | null } = {
    type,
    to: (dateTo && new Date(Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 0, 0, 0))) || null,
    from: (dateFrom && new Date(Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0))) || null
  }
  if (!dateFrom && !dateTo) {
    delete obj?.to
    delete obj?.from
  }
  return axiosInstance
    .post(`/restaurant/${idRestaurant}/statics`, obj)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar las estadisticas del restaurante')
    })
}

export const getRestaurantIncome = async (idRestaurant: string, year: number, month: number): Promise<any> => {
  return axiosInstance
    .post(`/restaurant/${idRestaurant}/income`, { year, month })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar los ingresos del restaurante')
    })
}

export const changeIsNewRestaurant = async (): Promise<void> => {
  return axiosInstance
    .get(`/restaurant/update/isNewRestaurant`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error')
    })
}

export const updateFcmRestaurant = async (idRestaurant: string, token: string): Promise<void> => {
  return axiosInstance
    .put(`/restaurant/${idRestaurant}/fcm`, { fcm: [token] })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error')
    })
}

export const removeFcmRestaurant = async (token: string): Promise<void> => {
  return axiosInstance
    .put(`/restaurant/fcm/remove`, { fcm: token })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error')
    })
}

export const updateLanguage = async (language: string): Promise<void> => {
  return axiosInstance
    .put(`/restaurant/language`, { language })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error()
    })
}
