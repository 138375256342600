/* eslint-disable camelcase */
import React, { useContext, ChangeEvent, useState } from 'react'
import { Grid } from '@mui/material'
import { CardSummaryGrid } from '../Cards/cards.styles'
import CardWithSwitchs from '../Cards/CardWithSwitchs'
import SwitchSmall from '../Form/SwitchSmall'
import { ReactComponent as Privacidad } from 'src/assets/icons/general/Privacidad.svg'
import { ReactComponent as Notificaciones } from 'src/assets/icons/general/Notificaciones.svg'
import { ReactComponent as Map } from 'src/assets/icons/resumen-icons/Map.svg'

import { useParams } from 'react-router-dom'
import { labels } from 'src/labels/main_labels'
import { AlertContext } from 'src/context/alert/alertContext'
import { updatePrivacityAdmin } from 'src/services/users.services'
import { UserTypes } from 'src/interfaces/pages.types'
import { useTranslation } from 'react-i18next'

interface SwitchUsersProps {
  switchNotifications?: UserTypes['notifications']
  switchPrivacity?: UserTypes['privacity']
  location_access?: boolean
}

const PRIVACITY_DEFAULT = {
  special_offers_and_promotions: false
}

export default function SwitchUsers({ switchNotifications, switchPrivacity, location_access }: SwitchUsersProps): JSX.Element {
  const { showSnackbar } = useContext(AlertContext)
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const params: any = useParams()
  const [loading, setLoading] = useState(false)

  const [privacity, setPrivacity] = useState(switchPrivacity || PRIVACITY_DEFAULT)

  const handleSwitch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (loading) return
    const newValue = event.target.checked

    setLoading(true)
    try {
      setPrivacity({ special_offers_and_promotions: newValue })
      const resp = await updatePrivacityAdmin(params.idUser)
      if (resp === 'OK') {
        showSnackbar('success', labels[lng].changeCompleted)
      } else showSnackbar('error', labels[lng].hasBeenAnError)
    } catch (error) {
      setPrivacity({ special_offers_and_promotions: !newValue })
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <CardSummaryGrid item md={4} xs={12}>
        <CardWithSwitchs Icon={Notificaciones} title={labels[lng].notifications}>
          <SwitchSmall
            disabled
            handleChange={(e) => handleSwitch(e)}
            ariaLabel={labels[lng].orderTimer}
            checked={switchNotifications?.opening || false}
            text={labels[lng].orderTimer}
          />
          <SwitchSmall
            disabled
            handleChange={(e) => handleSwitch(e)}
            ariaLabel={labels[lng].pickupReminder}
            checked={switchNotifications?.recall || false}
            text={labels[lng].pickupReminder}
          />
          <SwitchSmall
            disabled
            handleChange={(e) => handleSwitch(e)}
            ariaLabel={labels[lng].giftsRenovations}
            checked={switchNotifications?.promotion || false}
            text={labels[lng].giftsRenovations}
          />
          <SwitchSmall
            disabled
            handleChange={(e) => handleSwitch(e)}
            ariaLabel={labels[lng].newsPromotions}
            checked={switchNotifications?.coupons || false}
            text={labels[lng].newsPromotions}
          />
        </CardWithSwitchs>
      </CardSummaryGrid>
      <CardSummaryGrid item md={4} xs={12}>
        <CardWithSwitchs Icon={Map} title={labels[lng].locationUser}>
          <SwitchSmall
            disabled
            handleChange={(e) => handleSwitch(e)}
            ariaLabel={labels[lng].allowLocation}
            checked={location_access || false}
            text={labels[lng].allowLocation}
          />
        </CardWithSwitchs>
      </CardSummaryGrid>
      <CardSummaryGrid item md={4} xs={12}>
        <CardWithSwitchs Icon={Privacidad} title={labels[lng].privacity}>
          <SwitchSmall
            handleChange={(e) => handleSwitch(e)}
            ariaLabel={labels[lng].offerPromotion}
            checked={privacity.special_offers_and_promotions}
            text={labels[lng].offerPromotion}
          />
        </CardWithSwitchs>
      </CardSummaryGrid>
    </Grid>
  )
}
