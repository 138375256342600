import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import FormTitle from 'src/components/Form/FormTitle'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps, FoodStylesTypes, IngredientTypes } from 'src/interfaces/pages.types'
import { AllergensValues, EDIT_PLATE_FIELDS } from 'src/constants/const'
import { useForm } from 'src/hooks/useForm'
import SwitchForm from 'src/components/Form/SwitchForm'
import ChipSelector from 'src/components/Form/ChipSelector'
import MultiImagePicker from 'src/components/ImagePicker/MultiImagePicker'
import { labels } from 'src/labels/main_labels'
import { getFoodStyles } from 'src/services/foodStyles.services'
import { AlertContext } from 'src/context/alert/alertContext'
import { getIngredients } from 'src/services/ingredients.services'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { createPlate, updatePlate, updatePlatePhoto } from 'src/services/plates.services'
import HistoricPlate from '../../Common/Platos/PlatosDetail/HistoricPlate'
import { allergensLabels } from 'src/labels/allergens_labels'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { useTranslation } from 'react-i18next'

const INITIAL_VALUES = {
  name: '',
  description: ''
}

interface PlatoAddEditProps extends AddEditProps {
  restaurantId?: string
}

export default function PlatosAddEdit({ dataToEdit, closeModal, openModal, fetchData }: PlatoAddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const params: any = useParams()
  const { showSnackbar } = useContext(AlertContext)
  const { parameters } = useContext(RestaurantContext)
  const restaurantId = params.idRestaurant

  const editing = !!dataToEdit

  const filteredEditData: any = useMemo(() => {
    return EDIT_PLATE_FIELDS.reduce((acc, curr) => {
      if (dataToEdit?.[curr]) {
        acc[curr] = dataToEdit[curr]
      }
      return acc
    }, {})
  }, [dataToEdit])

  const { handleChange, values, translations, resetForm } = useForm(editing ? { ...filteredEditData } : INITIAL_VALUES, filteredEditData, [
    'name',
    'description'
  ])
  const [loading, setLoading] = useState(false)

  const [recommended, setRecommended] = useState(false)
  const [price, setPrice] = useState<string | number>('')

  const [foodStyles, setFoodStyles] = useState<string[] | never[]>([])
  const [allergens, setAllergens] = useState<string[] | never[]>([])
  const [ingredients, setIngredients] = useState<string[] | never[]>([])
  const [images, setImages] = useState<string[]>(['', '', '', '', ''])
  const [deletedImages, setDeletedImages] = useState<string[]>([])
  const [isOpenPP, setIsOpenPP] = useState<boolean>(false)

  const resetComponent = () => {
    setLoading(false)

    if (dataToEdit) {
      resetForm()
      setImages(dataToEdit.images)
      setIngredients(dataToEdit.ingredients.map((ingredient: any) => ingredient._id))
      setAllergens(dataToEdit.allergens)
      setFoodStyles(dataToEdit.foodStyles.map((foodStyle: any) => foodStyle._id))
      setRecommended(dataToEdit.parameter.RFC?.value)
      setPrice(dataToEdit.parameter.PP.value)
    } else {
      resetForm()
      setImages(['', '', '', '', ''])
      setDeletedImages([])
      setIngredients([])
      setAllergens([])
      setFoodStyles([])
      setPrice('')
      setRecommended(false)
    }
    if (!dataToEdit && parameters) {
      setPrice(parameters.plate.PP.value)
      setIsOpenPP(true)
    }
  }

  useEffect(() => {
    if (dataToEdit) {
      setImages(dataToEdit.images)
      setIngredients(dataToEdit.ingredients.map((ingredient: any) => ingredient._id))
      setAllergens(dataToEdit.allergens)
      setFoodStyles(dataToEdit.foodStyles.map((foodStyle: any) => foodStyle._id))
      setRecommended(dataToEdit.parameter.RFC?.value)
      setPrice(dataToEdit.parameter.PP.value)
    }

    return () => resetComponent()
  }, [dataToEdit])

  useEffect(() => {
    if (!dataToEdit && parameters) {
      setPrice(parameters.plate.PP.value)
      setIsOpenPP(true)
    }
  }, [parameters])

  const onSave = async () => {
    const newPlate = JSON.parse(JSON.stringify(values))
    newPlate.translations = translations
    newPlate.images = images.filter((elm) => elm)
    newPlate.foodStyles = foodStyles
    newPlate.allergens = allergens
    newPlate.ingredients = ingredients
    newPlate.restaurant = restaurantId
    delete newPlate.parameters
    newPlate.parameter = {
      RFC: { value: recommended },
      PP: { value: +price }
    }
    setLoading(true)
    if (!dataToEdit) {
      try {
        // delete newPlate.parameter
        // newPlate.parameters = {
        //   RFC: recommended,
        //   PP: +price
        // }
        await createPlate({ ...newPlate, price })
        showSnackbar('success', labels[lng].plateCreated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    } else {
      delete newPlate.createdAt
      delete newPlate.updatedAt
      // delete newPlate.pp
      delete newPlate.reviews
      delete newPlate.income
      delete newPlate.staticts
      delete newPlate._id
      delete newPlate.favorites
      delete newPlate.planning
      if (+dataToEdit.parameter.PP.value === +price) {
        delete newPlate.parameter.PP
      }

      if (dataToEdit.parameter.RFC.value === recommended) {
        delete newPlate.parameter.RFC
      }
      // delete newPlate.recommended
      // newPlate.parameter = {
      //   RFC: { value: recommended },
      //   PP: { value: +price }
      // }
      try {
        await updatePlate(newPlate, dataToEdit._id)

        await updatePlatePhoto({ images, deletedImages }, dataToEdit._id)

        showSnackbar('success', labels[lng].plateUpdated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    }
  }
  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (type === 'recommended') setRecommended(event.target.checked)
  }

  // const handleDelete = () => {
  //   if (dataToEdit && onDelete) onDelete(dataToEdit)
  // }

  const [foodStylesOptions, setFoodStylesOptions] = useState<FoodStylesTypes[]>([])
  const [ingredientsOptions, setIngredientsOptions] = useState<IngredientTypes[]>([])

  const fetchOptions = async () => {
    try {
      const foodStyles = await getFoodStyles()
      setFoodStylesOptions(foodStyles.foodStyles)
      const ingredient = await getIngredients()
      setIngredientsOptions(ingredient.ingredients)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  useEffect(() => {
    fetchOptions()
    return () => {
      setFoodStylesOptions([])
    }
  }, [])

  return (
    <Modal
      title={editing ? values.name : labels[lng].addPlate}
      open={openModal}
      handleClose={() => {
        resetComponent()
        closeModal()
      }}
      footer={
        <FooterForm
          text={labels[lng].plate}
          closeModal={() => {
            resetComponent()
            closeModal()
          }}
          onSave={onSave}
          editing={editing}
          loading={loading}
          disabled={
            !values?.name ||
            !values?.description ||
            !translations[0]?.name ||
            !translations[0]?.description ||
            foodStyles.length === 0 ||
            ingredients.length === 0 ||
            !price ||
            images.length === 0
          }
        />
      }
    >
      <Box id="form-platos" component="form" noValidate>
        {images && (
          <MultiImagePicker
            required
            setDeletedImages={setDeletedImages}
            deletedImages={deletedImages}
            setImgData={setImages}
            imgData={images}
          />
        )}
        <FormTitle text={labels[lng].information} />

        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          id="name"
          text={`${labels[lng].plateName} (ES)`}
          placeholder={`${labels[lng].plateName} (ES)`}
          noTopBorder
        />
        {translations.map((opt: { language: string; name: string | number; description: string }, idx: number) => (
          <TextInput
            required
            key={`${opt.language}-${idx}`}
            value={opt.name}
            handleChange={(e) => handleChange(e, opt)}
            name="name"
            text={`${labels[lng].plateName} (${opt.language.toUpperCase()})`}
            placeholder={`${labels[lng].plateName} (${opt.language.toUpperCase()})`}
          />
        ))}
        <TextInput
          required
          value={values.description}
          handleChange={(e) => handleChange(e)}
          name="description"
          id="description"
          text={`${labels[lng].plateDesc} (ES)`}
          placeholder={`${labels[lng].plateDesc} (ES)`}
          multiline
        />
        {translations.map((opt: { language: string; name: string | number; description: string }, idx: number) => (
          <TextInput
            required
            key={`${opt.language}-${idx}`}
            value={opt.description}
            handleChange={(e) => handleChange(e, opt)}
            name="description"
            id="description"
            text={`${labels[lng].plateDesc} (${opt.language.toUpperCase()})`}
            placeholder={`${labels[lng].plateDesc} (${opt.language.toUpperCase()})`}
            multiline
          />
        ))}

        {/* <TextInput
          required
          value={values.price}
          handleChange={(e) => handleChange(e)}
          name="price"
          id="price"
          text={labels[lng].price}
          placeholder={labels[lng].platePrice}
          price
          type="number"
        /> */}
        <ChipSelector
          required
          data={allergens}
          setData={setAllergens}
          labels={allergensLabels}
          options={AllergensValues}
          text={`${labels[lng].allergens} - ${labels[lng].optional.toLowerCase()}`}
          searchText={labels[lng].allergen}
        />
        <ChipSelector
          required
          data={ingredients}
          setData={setIngredients}
          options={ingredientsOptions}
          text={labels[lng].ingredients}
          searchText={labels[lng].ingredient}
          fieldToSave="_id"
        />
        <ChipSelector
          required
          fieldToSave="_id"
          data={foodStyles}
          setData={setFoodStyles}
          options={foodStylesOptions}
          text={labels[lng].styles}
          searchText={labels[lng].style}
        />
        {values.createdAt && (
          <TextInput
            type="text"
            value={dateTimeConvert(values.createdAt, false)}
            name="createdAt"
            id="createdAt"
            text={labels[lng].createdAt}
            placeholder=""
            disabled
            noBottomBorder
          />
        )}
        <FormTitle text={labels[lng].parameters} />
        <SwitchForm
          text="RFC"
          handleChange={(e) => handleSwitch(e, 'recommended')}
          checked={recommended}
          trueLabel={labels[lng].recommend}
          falseLabel={labels[lng].noRecommend}
        />
        <TextInput
          type="number"
          value={(typeof price === 'string' ? +price : price).toFixed(2)}
          handleChange={(e) => {
            setIsOpenPP(true)
            setPrice(e.target.value)
          }}
          name="pp"
          id="pp"
          text={`${labels[lng].platePrice} (PP)`}
          placeholder={labels[lng].platePriceMin}
          price
          step="any"
          byDefaultText={isOpenPP ? +price === parameters?.plate.PP.value : dataToEdit?.parameter?.PP?.default}
        />
        {editing && dataToEdit && <HistoricPlate parameter={dataToEdit.parameter} parameters={parameters} />}
      </Box>
    </Modal>
  )
}
