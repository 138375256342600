import React, { useEffect, useState, useContext } from 'react'

import { labels } from 'src/labels/main_labels'

import Table from 'src/components/Table/Table'
import FiltersFooter from 'src/components/Table/FiltersFooter'
import { ReactComponent as Empty } from 'src/assets/icons/nav-icons/Planificar.svg'
import { ReactComponent as Enviado } from 'src/assets/icons/general/Enviado.svg'
import { ReactComponent as Recibido } from 'src/assets/icons/general/Recibido.svg'
import { getUserBookingsRecords } from 'src/services/users.services'
import { AlertContext } from 'src/context/alert/alertContext'
import { useTranslation } from 'react-i18next'

interface UsuariosDetailPedidosProps {
  userId: string
}

export default function UsuariosDetailPedidos({ userId }: UsuariosDetailPedidosProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showSnackbar } = useContext(AlertContext)

  // TABLE CONTROL
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [data, setData] = useState<any | null>(null)

  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filter, setFilter] = useState<string>('')

  const headCells: any[] = [
    {
      idField: 'plate',
      align: 'left',
      disablePadding: true,
      label: labels[lng].plate,
      fieldtype: 'user-order-plate'
    },
    {
      idField: 'plate',
      align: 'left',
      disablePadding: false,
      label: labels[lng].restaurant,
      fieldtype: 'user-order-location'
    },
    {
      idField: 'createdAt',
      align: 'center',
      disablePadding: false,
      label: labels[lng].realize,
      fieldtype: 'date-time',
      width: 150
    },
    {
      idField: 'pickup',
      align: 'center',
      disablePadding: false,
      label: labels[lng].pickup,
      fieldtype: 'user-order-pickup'
    },
    {
      idField: 'status',
      align: 'left',
      disablePadding: false,
      label: labels[lng].state,
      fieldtype: 'user-order-status'
    }
  ]

  const fetchList = async () => {
    setLoadingTable(true)
    try {
      const bookings = await getUserBookingsRecords(userId)
      setRowsData(bookings)
      setData(bookings)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  useEffect(() => {
    if (data && filter) {
      if (filter === 'future') setRowsData(data.filter((elm: any) => ['PENDING', 'WAITING'].includes(elm.status)))
      if (filter === 'past')
        setRowsData(
          data.filter(
            (elm: any) =>
              ['WAITING-CONFIRM', 'SOFT-CANCELED', 'HARD-CANCELED', 'COMPLETED', 'UNCOLLECTED'].includes(elm.status) ||
              new Date(elm.pickupRange[0]) < new Date()
          )
        )
    }
    if (data && !filter) setRowsData(data)
  }, [filter])

  const filterOptions = [
    { value: '', text: labels[lng].noFilters, Icon: Empty },
    { value: 'future', text: labels[lng].future, Icon: Enviado },
    { value: 'past', text: labels[lng].past, Icon: Recibido }
  ]

  return (
    <>
      <Table
        rows={rowsData}
        headCells={headCells}
        snackBar
        selected={selected}
        setSelected={setSelected}
        noActions
        noCheckbox
        initialOrderBy="createdAt"
        initialOrder="desc"
        loading={loadingTable}
        filters={<FiltersFooter filter={filter} setFilter={setFilter} options={filterOptions} />}
      />
    </>
  )
}
