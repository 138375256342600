import React, { SetStateAction, Dispatch, useState, useEffect } from 'react'
import { Chip, Grid, Typography } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { MainBox, GridFlexBetween, ChipGrid, ChipElement, ChipAdd } from './form.styles'
import { SearchDialog } from '../Modals/SearchDialog'
import { LabelType, labels as labelsMain } from 'src/labels/main_labels'
import { ReactComponent as Importar } from 'src/assets/icons/general/Importar.svg'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'
interface ChipSelectorProps {
  setUsersDelete?: Dispatch<SetStateAction<string[]>>
  setUsersAdd?: Dispatch<SetStateAction<string[]>>
  setData: Dispatch<SetStateAction<string[] | never[]>>
  text: string
  data: string[]
  options: any[]
  labels?: LabelType
  required?: boolean
  fieldToSave?: string
  searchBy?: 'users' | 'restaurant' | 'discount'
  importUsers?: boolean
  onlyOneSelected?: boolean
  restaurantEmail?: boolean
  searchText?: string
  miltiple?: boolean
  isEnterprise?: boolean
}

export default function ChipSelector({
  text,
  data,
  setData,
  setUsersAdd,
  options,
  labels,
  fieldToSave,
  searchBy,
  importUsers,
  onlyOneSelected,
  restaurantEmail,
  searchText,
  miltiple,
  isEnterprise = false
}: ChipSelectorProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const handleDelete = (idx: number) => {
    setData((prev) =>
      prev.filter((elm: string, i: number) => {
        if (idx === i) {
          setUsersAdd &&
            setUsersAdd((preUsersAdd) => {
              const findIndex = preUsersAdd.findIndex((userAdd) => {
                return userAdd === (elm as any)._id
              })
              if (findIndex !== -1) {
                preUsersAdd.splice(findIndex, 1)
              }
              return preUsersAdd
            })
          return false
        }
        return true
      })
    )
  }

  const [open, setOpen] = useState(false)
  const [newOptions, setNewOptions] = useState(options)

  useEffect(() => {
    setNewOptions(options)
  }, [options])

  const handleClose = () => {
    setOpen(false)
  }

  const addChip = (chipData: any) => {
    const dataToSave = fieldToSave ? chipData[fieldToSave] : chipData
    if (miltiple && typeof dataToSave === 'object') dataToSave.isNewAdd = true
    if (typeof dataToSave === 'string') {
      const findIndex = data.findIndex((elm) => elm === dataToSave)
      if (findIndex !== -1) {
        handleDelete(findIndex)
        return
      }
    }

    if (!miltiple && typeof dataToSave !== 'string') {
      const findIndex = (data as unknown as { _id: string }[]).findIndex((elm) => elm._id === dataToSave._id)
      if (findIndex !== -1) {
        handleDelete(findIndex)
        return
      }
    }
    setUsersAdd && setUsersAdd((prev) => [...prev, dataToSave._id])
    setData((prev) => [...prev, dataToSave])
  }

  const getLabel = (elm: any) => {
    if (isEnterprise) return elm.email
    if (restaurantEmail) {
      return `${elm.name}`
    }
    if (searchBy) {
      return elm.name
    }
    if (fieldToSave && options) {
      const label = options.find((opt) => opt[fieldToSave] === elm)
      if (label) {
        return label.name
      }
    }
    if (elm.name) {
      return elm.name
    }
    if (labels) {
      return labels[lng][elm]
    }
  }

  const onChangeImport = (e: any) => {
    // let dataFromCsv: any[]
    if (e.target.files[0]) {
      Papa.parse(e.target.files[0], {
        complete: function (results: { data: any[] }) {
          const res = results.data.filter((elm: any) => elm._id && elm.name && elm.surname)
          setNewOptions((prev) => [...prev, ...res])
          // dataFromCsv = res.map((el) => el._id)
          setData((prev) => [...prev, ...res])
        },
        header: true
      })
    }
  }

  return (
    <MainBox>
      <GridFlexBetween container spacing={1}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="h6">
            {text}
          </Typography>
        </Grid>
        <ChipGrid item xs={importUsers ? 6 : 8}>
          {data &&
            data.map((elm: any, idx: number) => (
              <ChipElement key={idx}>
                <Chip
                  size="small"
                  label={getLabel(elm)}
                  variant="outlined"
                  onDelete={isEnterprise || typeof elm === 'string' || elm.isNewAdd || !miltiple ? () => handleDelete(idx) : undefined}
                />
              </ChipElement>
            ))}
          {((onlyOneSelected && data.length === 0) || !onlyOneSelected) && (
            <ChipAdd
              onClick={() => setOpen(true)}
              icon={<AddOutlinedIcon />}
              size="small"
              label={labelsMain[lng].add}
              variant="filled"
              color="primary"
            />
          )}
        </ChipGrid>
        {importUsers && (
          <Grid item xs={2}>
            <label style={{ width: 100, height: 100, cursor: 'pointer' }}>
              <input
                style={{ display: 'none' }}
                id="pictures"
                accept=".csv"
                type="file"
                onChange={onChangeImport}
                onClick={(event: any) => {
                  event.target.value = null
                }}
              />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Importar />
                <Typography sx={{ fontSize: '8px' }}>{labelsMain[lng].csvImport}</Typography>
              </div>
            </label>
          </Grid>
        )}
      </GridFlexBetween>
      {open && (
        <SearchDialog
          single={onlyOneSelected}
          searchBy={searchBy}
          onClose={handleClose}
          open={open}
          optionsData={newOptions}
          setter={addChip}
          selected={data}
          text={text}
          labels={labels}
          restaurantEmail={restaurantEmail}
          searchText={searchText}
        />
      )}
    </MainBox>
  )
}
