import React, { SetStateAction, Dispatch, useEffect } from 'react'
import { Chip, Grid, Stack, TextField, Typography } from '@mui/material'
import { GridFlexBetween, MainBox } from './form.styles'
import { labels } from 'src/labels/main_labels'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'react-i18next'

interface TimeInputProps {
  name: string
  text: string
  value: any
  required?: boolean
  setState: Dispatch<SetStateAction<any>>
  setIsOpen?: () => void
  state: any
  error?: string
  byDefaultText?: boolean
}

export default function TimeInputSelect({
  name,
  value,
  text,
  setState,
  setIsOpen,
  state,
  error,
  byDefaultText
}: TimeInputProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const [stateFrom, setStateFrom] = React.useState<Date | null>()
  const handleTime = (time: any, name: string) => {
    const hour = time.toLocaleString('es-ES', { hour: 'numeric' })
    const minutes = time.toLocaleString('es-ES', { minute: 'numeric' })
    setState({ ...state, [name]: { hour: +hour, minutes: +minutes } })
    if (setIsOpen) {
      setIsOpen()
    }
  }
  useEffect(() => {
    const dateFrom = new Date(0, 0, 0, value.hour, value.minutes)
    if (dateFrom) setStateFrom(dateFrom)
    return () => setStateFrom(null)
  }, [])

  // OLD DESIGN
  // const handleChange = (event: SelectChangeEvent<string>) => {
  //   const key = name
  //   const interiorKey = event.target.name
  //   setState({ ...state, [key]: { ...state[key], [interiorKey]: event.target.value } })
  // }

  return (
    <MainBox sx={{ borderBottom: 'none' }}>
      <GridFlexBetween container spacing={0}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="label" sx={{ textTransform: 'capitalize' }}>
            {text}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <Stack direction="row">
            <div className="flex" style={{ position: 'relative' }}>
              <Select data={value.hour || 0} name={`hour`} text={labels[lng].hour} handleChange={handleChange} options={timeHours} />
            </div>
            <div className="flex" style={{ position: 'relative' }}>
              <Select data={value.minutes || 0} handleChange={handleChange} name={`minutes`} text={labels[lng].min} options={timeMinutes} />
            </div>
          </Stack> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileTimePicker
              shouldDisableTime={(timeValue, clockType) => {
                return clockType === 'minutes' && ![15, 0, 30, 45].includes(timeValue)
              }}
              DialogProps={{
                PaperProps: {
                  sx: { '& *:focus': { outline: 'none' } }
                }
              }}
              renderInput={(params) => (
                <Stack direction="row" alignItems="center">
                  <TextField
                    sx={{
                      pl: 1,
                      width: 50,
                      '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                      '.MuiInputBase-root:after': { borderBottom: 'none !important' }
                    }}
                    variant="standard"
                    {...params}
                  />
                  {byDefaultText && <Typography variant="body1">({labels[lng].byDefault})</Typography>}
                </Stack>
              )}
              value={stateFrom}
              onAccept={(newValue) => {
                handleTime(newValue, name)
              }}
              onChange={(newValue) => {
                setStateFrom(newValue)
              }}
              minutesStep={15}
              ampm={false}
              ampmInClock={false}
              // cancelText={labels[lng].cancel}
              toolbarTitle={labels[lng].selectTime}
              // minTime={new Date(0, 0, 0, minValueHour, minValueMin)}
              // maxTime={new Date(0, 0, 0, maxValueHour, maxValueMin)}
            />
          </LocalizationProvider>
          {error && (
            <Chip
              sx={{ height: 20, backgroundColor: '#FEE2E2', color: '#991B1B', fontSize: '12px', lineHeight: '16px', fontWeight: 500 }}
              label={error}
            />
          )}
        </Grid>
      </GridFlexBetween>
    </MainBox>
  )
}
