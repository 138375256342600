import React from 'react'
import { Box, Avatar, Typography, Stack } from '@mui/material'
import { OrderHeaderBox } from './pedidos.styles'
import { ReactComponent as BadgeRecommendedPlate } from 'src/assets/icons/general/BadgeRecommendedPlate.svg'
import { ReactComponent as Clock } from 'src/assets/icons/smalls/Clock.svg'
import { ReactComponent as Fork } from 'src/assets/icons/smalls/Fork.svg'
import { ReactComponent as Check } from 'src/assets/icons/smalls/Check.svg'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { subHours } from 'date-fns'
import { PlanningTypes } from 'src/interfaces/pages.types'
import { theme } from 'src/styles/theme'

interface PedidosHeaderProps {
  parameter?: { IRr: any; FRr: any } | null
  EMHIRR: { hour: number; minutes: number } | null
  RFC?: boolean
  planning?: PlanningTypes
  quantity?: number
  uses?: number
  name?: string
  photo?: string
}

export const transformDate = (date: Date, { hour, minutes }: { hour: number; minutes: number }, day: number): Date => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + day, hour, minutes, 0))
}

export default function PedidosHeader({ parameter, quantity, uses, name, photo, EMHIRR, RFC, planning }: PedidosHeaderProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  let forPickUp = labels[lng].forPickUpToday
  if (EMHIRR && planning) {
    const now = new Date()
    const planningDate = subHours(new Date(planning.date as string), Math.abs(new Date().getTimezoneOffset() / 60))
    if (now < planningDate) {
      forPickUp = labels[lng].forPickUpTomorrow
    }
  }

  return (
    <>
      <OrderHeaderBox
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }}
      >
        <Stack direction="row" sx={{ alignItems: 'center', mr: 2, my: 1 }}>
          <Avatar variant="rounded" sx={{ width: 40, height: 40, mr: 1 }} src={photo} />
          <Typography sx={{ mr: 1 }} variant="subtitle2" component="h6" color="grey.700">
            {name && name}
          </Typography>
          {RFC && (
            <Stack height={'20px'} width={'28px'} justifyContent={'center'} alignItems={'center'}>
              <BadgeRecommendedPlate />
            </Stack>
          )}
        </Stack>
        <Box>
          <Stack direction="row" sx={{ mr: { sm: 3 } }}>
            <Clock />
            <Typography variant="subtitle2">
              {forPickUp},{' '}
              {parameter &&
                parameter.IRr.hour +
                  ':' +
                  String(parameter.IRr.minutes).padStart(2, '0') +
                  ' - ' +
                  parameter?.FRr.hour +
                  ':' +
                  String(parameter?.FRr.minutes).padStart(2, '0')}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mr: { sm: 3 } }}>
            <Fork />
            <Typography variant="subtitle2">
              {quantity || '0'} {quantity === 1 ? labels[lng].availablePlateOrders : labels[lng].availablePlatesOrders}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Check />
            <Typography variant="subtitle2">
              {uses} {uses === 1 ? labels[lng].reservedPlateOrders : labels[lng].reservedPlatesOrders}
            </Typography>
          </Stack>
        </Box>
      </OrderHeaderBox>
      <Box
        m="16px 16px 0"
        border="1px solid #E5E7EB"
        borderRadius={'8px'}
        p="12px 0px 16px"
        sx={{
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        }}
      >
        <Stack direction="row" sx={{ alignItems: 'center', px: '16px', pb: '12px', borderBottom: '1px solid #E5E7EB' }}>
          <Typography sx={{ mr: 1, fontWeight: '500', color: '#374151' }} variant="subtitle2" component="h6" color="grey.700">
            {name && name}
          </Typography>

          {RFC && (
            <Stack height={'20px'} width={'28px'} justifyContent={'center'} alignItems={'center'}>
              <BadgeRecommendedPlate />
            </Stack>
          )}
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center', px: '16px', pt: '16px', justifyContent: 'space-between' }}>
          <Box>
            <Typography
              sx={{
                fontSize: '18px',
                lineHeight: '20px',
                fontWeight: 600,
                textAlign: 'center',
                color: '#4B5563'
              }}
              variant="subtitle2"
            >
              {parameter &&
                parameter.IRr.hour +
                  ':' +
                  String(parameter.IRr.minutes).padStart(2, '0') +
                  ' - ' +
                  parameter?.FRr.hour +
                  ':' +
                  String(parameter?.FRr.minutes).padStart(2, '0')}
            </Typography>
            <Typography
              sx={{
                fontSize: '11px',
                lineHeight: '20px',
                fontWeight: 500,
                textAlign: 'center'
              }}
            >
              {forPickUp}
            </Typography>
          </Box>
          <Box textAlign={'center'}>
            <Typography
              sx={{
                fontSize: '24px',
                lineHeight: '20px',
                fontWeight: 600,
                textAlign: 'center',
                color: '#4B5563'
              }}
              variant="subtitle2"
            >
              {quantity || '0'}
            </Typography>
            <Typography
              sx={{
                fontSize: '11px',
                lineHeight: '20px',
                fontWeight: 500,
                textAlign: 'center',
                textTransform: 'capitalize'
              }}
            >
              {labels[lng].availables}
            </Typography>
          </Box>
          <Box textAlign={'center'}>
            <Typography
              sx={{
                fontSize: '24px',
                lineHeight: '20px',
                fontWeight: 600,
                textAlign: 'center',
                color: '#4B5563'
              }}
              variant="subtitle2"
            >
              {uses || '0'}
            </Typography>
            <Typography
              sx={{
                fontSize: '11px',
                lineHeight: '20px',
                fontWeight: 500,
                textAlign: 'center',
                textTransform: 'capitalize'
              }}
            >
              {labels[lng].reservedPlatesOrders.replace('platos', '').replace('dishes', '')}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
