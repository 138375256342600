import React, { FC, useContext, PropsWithChildren, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import {
  CssBaseline,
  Divider,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Dialog,
  Box,
  Popover,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Badge,
  BadgeProps
} from '@mui/material'

import LogoZone from './components/LogoZone'
import AppBarLayout from './components/AppBarLayout'
import NavElements from './NavElements'
import RestaurantWithAvatar from '../RestaurantWithAvatar'
import RestaurantSelectorNav from './RestaurantSelectorNav'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ReactComponent as Settings } from 'src/assets/icons/general/Settings.svg'
import { ReactComponent as Help } from 'src/assets/icons/general/Help.svg'

import { DrawerHeader, Drawer, DrawerNav, ListCustom, NavTitle, BoxNavBottom, Main, IconColor } from './layout.styles'

import { HeaderContext } from 'src/context/header/headerContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { AuthContext } from 'src/context/auth/authContext'

import { navigationLinkTuRestaurante, navigationLinkVentas, navigationLinkTuRestauranteMob } from 'src/routes/navigationLinks'
import { labels } from 'src/labels/main_labels'
import { GroupUserType } from 'src/interfaces/app.types'
import { logoutSwalConfig } from 'src/utils/swal.utils'
import PopoverLogoutLanguage from './components/PopoverLogoutLanguage'
import { WelcomeBox } from 'src/pages/Welcome/welcome.styles'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { BookingContext } from 'src/context/booking/bookingContext'
import styled from '@emotion/styled'

interface LayoutProps {
  children: React.ReactNode
}

interface NavElement {
  link: string
  Icon: FC
  text: string
}

const NO_TABBAR = ['historial']

export default function LayoutRestaurants({ children }: PropsWithChildren<LayoutProps>): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const history = useHistory()
  const { logout, userData } = useContext(AuthContext)
  const { restaurant, fetchRestaurantById } = useContext(RestaurantContext)
  const { setTotalBookings, bookingsNumber } = useContext(BookingContext)
  const [value, setValue] = React.useState('')

  const [open, setOpen] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)
  const { setOpenLayout } = useContext(HeaderContext)
  const path = location.pathname === '/' ? location.hash.split('/')[1] : location.pathname.split('/')[1]

  useEffect(() => {
    if (width < 1025) {
      setOpen(false)
      setOpenLayout(false)
    } else {
      setOpen(true)
      setOpenLayout(true)
    }
  }, [width])

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Import header from context
  const handleDrawer = () => {
    setOpen(!open)
    setOpenLayout(!open)
  }

  const navLink = (link: string) => {
    history.push(link)
  }

  const onLogout = () => {
    Swal.fire(logoutSwalConfig(lng)).then((result: any) => {
      if (result.isConfirmed) {
        logout()
      }
    })
  }

  // DIALOG RESTAURANTS
  const [openDialog, setOpenDialog] = useState(false)

  const openRestaurantSelector = () => {
    if (userData.group) setOpenDialog(true)
  }

  const changeRestaurant = (id: string) => {
    setTotalBookings(0)
    fetchRestaurantById(id)
    history.push('/pedidos')
  }

  // POPOVER
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const openPopover = Boolean(anchorEl)
  const idPop = openPopover ? 'logout-popover' : undefined
  const group: any[] = userData?.group?.group || []

  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      right: -4,
      top: 8,
      border: `2px solid #FFF`,
      padding: '0 4px',
      fontWeight: '600'
    }
  }))

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }}
      >
        <Drawer variant="permanent" open={open}>
          <LogoZone open={open} handleDrawer={handleDrawer} />
          <Divider />
          <RestaurantSelectorNav
            restaurant={restaurant}
            open={open}
            openSelector={openRestaurantSelector}
            disabledButton={group.length <= 1}
          />
          <DrawerNav>
            <div>
              <NavTitle variant="subtitle2">{labels[lng].yourRestaurant}</NavTitle>
              <ListCustom disablePadding>
                {navigationLinkTuRestaurante.map((elm: NavElement, index: number) => (
                  <NavElements
                    badge={elm.text === 'orders'}
                    key={index}
                    open={open}
                    link={elm.link}
                    Icon={elm.Icon}
                    text={labels[lng][elm.text]}
                    navLink={navLink}
                  />
                ))}
              </ListCustom>

              <NavTitle variant="subtitle2" sx={{ mt: '20px' }}>
                {labels[lng].sales}
              </NavTitle>
              <ListCustom disablePadding>
                {navigationLinkVentas.map((elm: NavElement, index: number) => (
                  <NavElements key={index} open={open} link={elm.link} Icon={elm.Icon} text={labels[lng][elm.text]} navLink={navLink} />
                ))}
              </ListCustom>
            </div>
            <ListCustom disablePadding>
              <ListItem
                style={{ backgroundColor: path === 'ayuda' ? '#E5E7EB' : '' }}
                button
                disablePadding
                sx={{ mb: '5px !important' }}
                onClick={() => navLink('/ayuda')}
              >
                <IconColor className={path === 'ayuda' ? 'stroke' : ''} sx={{ height: '18px' }}>
                  <Help />
                </IconColor>
                <ListItemText
                  sx={{ '& .MuiTypography-root': { color: path === 'ayuda' ? '#111827' : '#4B5563' } }}
                  primary={labels[lng].help}
                />
              </ListItem>

              <Box>
                <Divider />
                <BoxNavBottom>
                  <Button sx={{ ml: open ? 1.5 : 2, '> svg': { ml: 0 } }} color="info" disabled={open} onClick={(e) => handlePopover(e)}>
                    <Settings />
                    <ListItemText sx={{ '& .MuiTypography-root': { color: '#4B5563' } }} primary={labels[lng].settings} />
                  </Button>
                  {open && (
                    <IconButton sx={{ width: '40px' }} onClick={(e) => handlePopover(e)}>
                      <MoreHorizIcon color="info" />
                    </IconButton>
                  )}
                </BoxNavBottom>
              </Box>
            </ListCustom>
          </DrawerNav>
        </Drawer>
      </Box>
      {!NO_TABBAR.includes(path) && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
          elevation={3}
        >
          <BottomNavigation
            sx={{
              [theme.breakpoints.up('md')]: {
                display: 'none'
              },
              // alignItems: 'flex-start',
              minHeight: '52px',
              paddingTop: '0',
              marginBottom: 'env(safe-area-inset-bottom)',
              boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04);',
              '& .MuiBottomNavigationAction-label': {
                fontSize: '10px !important',
                lineHeight: '16px',
                fontWeight: 700,
                color: '#9CA3AF',
                '&.Mui-selected': {
                  color: '#9CA3A'
                }
              },
              '& .active ~ span': {
                color: '#EB435A !important'
              },
              '& .first svg, & .first svg path': {
                fill: '#EB435A !important'
              },
              '& .active-button .MuiBottomNavigationAction-label': {
                color: '#EB435A !important'
              },
              '& .active-button span .fill svg path': {
                fill: '#EB435A !important'
              }
            }}
            style={{
              width: '100%'
            }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue)
              navLink(newValue)
            }}
          >
            {navigationLinkTuRestauranteMob.map((elm: NavElement, index: number) => {
              const active = path === elm.link.split('/')[1]
              const IconComponent = elm.Icon

              return (
                <BottomNavigationAction
                  value={elm.link}
                  key={index}
                  label={labels[lng][elm.text]}
                  className={active ? (path === 'pedidos' ? 'active-button' : 'stroke') : ''}
                  icon={
                    elm.text === 'orders' ? (
                      <StyledBadge badgeContent={bookingsNumber} color="primary" max={9} overlap="circular">
                        <IconColor className={active ? (path === 'pedidos' ? 'fill' : 'stroke') : ''}>
                          <IconComponent />
                        </IconColor>
                      </StyledBadge>
                    ) : (
                      <IconColor
                        color={active ? '#EB435A' : '#9CA3AF'}
                        className={active ? `active ${index === 0 ? 'first' : ''}` : 'disactive'}
                      >
                        <IconComponent />
                      </IconColor>
                    )
                  }
                />
              )
            })}
          </BottomNavigation>
        </Paper>
      )}
      <Main
        sx={{
          [theme.breakpoints.down('md')]: {
            paddingBottom: '76px',
            '& header': {
              boxShadow: window.location.href.includes('estadisticas') ? 'none' : '0px 2px 10px 0px #0000000D'
            }
          }
        }}
      >
        <DrawerHeader className="drawer-header" />
        <AppBarLayout hideButton handleDrawer={handleDrawer} open={open} />
        {children}
      </Main>
      <Popover
        id={idPop}
        elevation={1}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <PopoverLogoutLanguage onLogout={onLogout} onClose={() => setAnchorEl(null)} />
      </Popover>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <WelcomeBox
          sx={{
            width: '496px',
            [theme.breakpoints.down('md')]: {
              width: 'calc(100% - 32px) !important'
            }
          }}
        >
          <Typography sx={{ textAlign: { xs: 'center' }, mb: '14px', lineHeight: '24px' }} component="h3" variant="h6">
            {labels[lng].restaurantSelectorText}
          </Typography>
          <Box sx={{ display: 'flex', width: '100%', my: 1, flexDirection: 'column' }}>
            {userData?.group &&
              userData.group.group
                .filter((e) => !e.isNewRestaurant)
                .map((opt: GroupUserType) => (
                  <RestaurantWithAvatar
                    maxWidthText="352px"
                    height="50px"
                    key={opt._id}
                    id={opt._id}
                    name={opt.name}
                    street={opt.location.street || ''}
                    number={opt.location.number || ''}
                    photo={opt.photo}
                    onclick={(id: string) => changeRestaurant(id)}
                    check={restaurant?._id === opt._id}
                  />
                ))}
          </Box>
        </WelcomeBox>
      </Dialog>
    </Box>
  )
}
