import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const Waiting = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  float: 'left',
  width: '100%',
  height: '50px',
  position: 'relative',
  backgroundColor: '#FBBF24',
  borderRadius: '25px'
}))
export default Waiting
