import { Box, Dialog, Grid, Typography } from '@mui/material'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderContext } from 'src/context/header/headerContext'
import { theme } from 'src/styles/theme'
import { labels } from 'src/labels/main_labels'
import { ContainerFud } from 'src/styles/general-styles'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Estadisticas } from 'src/assets/icons/nav-icons/Estadisticas.svg'
import { ReactComponent as Historial } from 'src/assets/icons/nav-icons/Historial.svg'
import { ReactComponent as Ingresos } from 'src/assets/icons/nav-icons/Ingresos.svg'
import { ReactComponent as Restaurantes } from 'src/assets/icons/nav-icons/Restaurantes.svg'
import { ReactComponent as Settings } from 'src/assets/icons/general/Settings.svg'
import { ReactComponent as Help } from 'src/assets/icons/general/Help.svg'
import { ChevronRight } from '@mui/icons-material'
import { ReactComponent as Logout } from 'src/assets/icons/general/Logout.svg'
import { WelcomeBox } from 'src/pages/Welcome/welcome.styles'
import RestaurantWithAvatar from 'src/components/RestaurantWithAvatar'
import { GroupUserType } from 'src/interfaces/app.types'
import { AuthContext } from 'src/context/auth/authContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'

interface MoreCardProps {
  Icon: FC
  title: string
  navLink?: () => void
}

function MoreCard({ Icon, title, navLink }: MoreCardProps): JSX.Element {
  return (
    <Box
      border="1px solid #E5E7EB"
      p="8px 16px"
      onClick={navLink}
      borderRadius="8px"
      minHeight="112px"
      display="flex"
      justifyContent="space-between"
      flexDirection={'column'}
      sx={{
        '& svg': {
          width: '30px',
          height: '30px',
          mt: '21px'
        }
      }}
    >
      <Box display={'flex'} justifyContent="center">
        <Icon />
      </Box>
      <Typography
        variant="subtitle2"
        component="strong"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600
          }
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default function More(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const history = useHistory()
  const { userData } = useContext(AuthContext)
  const { restaurant, fetchRestaurantById } = useContext(RestaurantContext)
  // DIALOG RESTAURANTS
  const [openDialog, setOpenDialog] = useState(false)

  const changeRestaurant = (id: string) => {
    fetchRestaurantById(id)
    history.push('/pedidos')
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <Typography
        variant="h3"
        component="h1"
        textAlign={'center'}
        sx={{
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        }}
      >
        {labels[lng].more}
      </Typography>
    )
  }
  useEffect(() => {
    showHeader(Header)
    window.scrollTo(0, 0)
  }, [])

  const navLink = (link: string) => {
    history.push(link)
  }

  return (
    <ContainerFud>
      <Typography variant="body1">{labels[lng].yourRestaurant}</Typography>
      <Grid mt={'0px !important'} container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
        <Grid item sm={6} xs={6} lg={3}>
          <MoreCard title={labels[lng].history} navLink={() => navLink('/historial')} Icon={Historial} />
        </Grid>
        <Grid item sm={6} xs={6} lg={3}>
          <MoreCard title={labels[lng].information} navLink={() => navLink('/informacion')} Icon={Restaurantes} />
        </Grid>
      </Grid>

      <Typography variant="body1" mt={4}>
        {labels[lng].sales}
      </Typography>
      <Grid mt={'0px !important'} container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
        <Grid item sm={6} xs={6} lg={3}>
          <MoreCard title={labels[lng].incomes} navLink={() => navLink('/ingresos')} Icon={Ingresos} />
        </Grid>
        <Grid item sm={6} xs={6} lg={3}>
          <MoreCard title={labels[lng].statistics} navLink={() => navLink('/estadisticas')} Icon={Estadisticas} />
        </Grid>
      </Grid>

      <Typography variant="body1" mt={4}>
        {labels[lng].configuration}
      </Typography>
      <Grid mt={'0px !important'} container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
        <Grid item sm={6} xs={6} lg={3}>
          <MoreCard title={labels[lng].help} navLink={() => navLink('/ayuda')} Icon={Help} />
        </Grid>
        <Grid item sm={6} xs={6} lg={3}>
          <MoreCard title={labels[lng].settings} navLink={() => navLink('/ajustes')} Icon={Settings} />
        </Grid>
      </Grid>

      {(userData?.group?.group?.length || 0) > 1 ? (
        <>
          <Typography variant="body1" mt={4}>
            {labels[lng].changeRestaurant}
          </Typography>
          <Box
            onClick={() => setOpenDialog(true)}
            sx={{
              p: '16px',
              border: '1px solid #E5E7EB',
              borderRadius: '8px',
              mt: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              display={'flex'}
              alignItems="center"
              gap="14px"
              sx={{
                '& svg': {
                  width: '20px',
                  height: '20px'
                }
              }}
            >
              <Logout />
              <Typography variant="h3" fontSize="14px" lineHeight="14px" color="#6B7280" fontWeight={500}>
                {labels[lng].changeRestaurant}
              </Typography>
            </Box>
            <ChevronRight sx={{ color: '#9CA3AF', fill: '#9CA3AF' }} />
          </Box>
        </>
      ) : null}

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            margin: '16px'
          }
        }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <WelcomeBox
          sx={{
            maxWidth: '496px',
            [theme.breakpoints.down('md')]: {
              width: 'calc(100%) !important'
            }
          }}
        >
          <Typography sx={{ textAlign: { xs: 'center' }, mb: '14px', lineHeight: '24px' }} component="h3" variant="h6">
            {labels[lng].restaurantSelectorText}
          </Typography>
          <Box sx={{ display: 'flex', width: '100%', my: 1, flexDirection: 'column' }}>
            {userData?.group &&
              userData.group.group
                .filter((e) => !e.isNewRestaurant)
                .map((opt: GroupUserType) => (
                  <RestaurantWithAvatar
                    height="50px"
                    key={opt._id}
                    id={opt._id}
                    name={opt.name}
                    street={opt.location.street || ''}
                    number={opt.location.number || ''}
                    photo={opt.photo}
                    onclick={(id: string) => changeRestaurant(id)}
                    check={restaurant?._id === opt._id}
                  />
                ))}
          </Box>
        </WelcomeBox>
      </Dialog>
    </ContainerFud>
  )
}
