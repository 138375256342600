import React from 'react'
import { Button, Container } from '@mui/material'
import { Link } from 'react-router-dom'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

type NotFoundProps = {
  link: string
}

export default function NotFound({ link }: NotFoundProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <Container>
      <h1>{labels[lng].notFound} :(</h1>
      <p>{labels[lng].returnToList}</p>
      <Link to={link} style={{ textDecoration: 'none' }}>
        <Button variant="outlined" sx={{ textTransform: 'uppercase' }}>
          {labels[lng].goToList}
        </Button>
      </Link>
    </Container>
  )
}
