import React, { useEffect, useState, useContext } from 'react'
import Swal from 'sweetalert2'
import { useHistory, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

import CardAddNew from 'src/components/Cards/CardDishPreview-AddNew'
import CardDishPreview from 'src/components/Cards/CardDishPreview'
import { PlateTypes } from 'src/interfaces/pages.types'

import TableSnackbar from 'src/components/Table/TableSnackbar'

import { AlertContext } from 'src/context/alert/alertContext'
import { deletePlate } from 'src/services/plates.services'
import { labels } from 'src/labels/main_labels'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import deleteElements from 'src/services/deleteElements'
import { ContainerFud } from 'src/styles/general-styles'
import PlatosAddEdit from 'src/pages/RestaurantPlatform/Platos/PlatosAddEdit'
import { useTranslation } from 'react-i18next'

interface RestaurantesDetailPlatosProp {
  platos: PlateTypes[] | undefined
  fetchData: () => void
}
export default function RestaurantesDetailPlatos({ platos, fetchData }: RestaurantesDetailPlatosProp): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showSnackbar } = useContext(AlertContext)
  const history = useHistory()
  const params: any = useParams()

  const [platoToEdit, setPlatoToEdit] = useState<PlateTypes | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setPlatoToEdit(null)
  }

  const [selected, setSelected] = useState<string[]>([])

  const handleSelectAllClick = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (platos && event && event.target.checked) {
      const newSelecteds = platos.map((n) => n._id)
      setSelected(newSelecteds)
      return
    }
    if (platos && event && !event.target.checked && platos.length !== selected.length) {
      const newSelecteds = platos.map((n) => n._id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const onDelete = async (data?: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].plates, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data ? data._id : selected, deletePlate)
          .then(() => {
            showSnackbar('success', labels[lng].plateDeleted)
            closeModal()
            fetchData()
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const handleEdit = (plato: PlateTypes) => {
    setPlatoToEdit(plato)
  }

  useEffect(() => {
    if (platoToEdit) setOpenModal(true)
  }, [platoToEdit])

  const handleClickCard = (idPlato: string) => {
    history.push(`/restaurantes/${params.idRestaurant}/platos/${idPlato}`)
  }

  return (
    <>
      <ContainerFud>
        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={6} xs={12} md={4} lg={3}>
            <CardAddNew text={labels[lng].addPlate} handleCard={() => setOpenModal(true)} />
          </Grid>
          {platos &&
            platos.map((plate: PlateTypes) => (
              <Grid key={plate._id} item sm={6} xs={12} md={4} lg={3}>
                <CardDishPreview plate={plate} handleEdit={handleEdit} handleClick={handleClickCard} />
              </Grid>
            ))}
        </Grid>
      </ContainerFud>
      {openModal && (
        <PlatosAddEdit
          restaurantId={params.idRestaurant}
          openModal={openModal}
          closeModal={closeModal}
          dataToEdit={platoToEdit}
          onDelete={onDelete}
          fetchData={fetchData}
        />
      )}
      {platos && (
        <TableSnackbar selected={selected} totalLength={platos.length} onSelectAllClick={handleSelectAllClick} onDelete={onDelete} />
      )}
    </>
  )
}
