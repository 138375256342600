import axios, { AxiosError } from 'axios'
import { BookingTypes, BookingApiTypes } from 'src/interfaces/pages.types'
import axiosInstance from './axiosInstance'

export const getBookings = async (idRestaurant: string): Promise<BookingApiTypes> => {
  return axiosInstance
    .get(`/booking/${idRestaurant}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de reservas')
    })
}

export const getBookingNumber = async (idRestaurant: string): Promise<BookingTypes> => {
  return axiosInstance
    .get(`/booking/${idRestaurant}/active`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar el numero de reservas')
    })
}

export const createBooking = async (newBooking: BookingTypes): Promise<void> => {
  return axiosInstance
    .post(`/booking`, newBooking)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba crear la planificación')
    })
}

export const changeStatusBooking = async (newStatus: string, bookingId: string): Promise<void> => {
  return axiosInstance
    .put(`/booking/status/${bookingId}`, { status: newStatus })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data.message)
      } else {
        throw new Error('Hubo un error mientras se intentaba actualizar el estado de la reserva')
      }
    })
}
