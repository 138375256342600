import React from 'react'
import { ListItemText, Paper, MenuList, MenuItem, Divider } from '@mui/material'

import { ReactComponent as Edit } from 'src/assets/icons/general/Edit.svg'
import { ReactComponent as Delete } from 'src/assets/icons/general/DeleteFilled.svg'
import { ReactComponent as Desactivate } from 'src/assets/icons/general/Desactivate.svg'

import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

interface PopoverTableDataProps {
  rowPopover: Record<string, unknown>
  onDelete?: (data: any) => void
  onEdit?: (data: Record<string, unknown>) => void
  onDeactivate?: (data: Record<string, any>) => void
  handleClosePopover: () => void
  textDelete?: string
}

export default function PopoverTableData({
  onEdit,
  onDeactivate,
  onDelete,
  rowPopover,
  handleClosePopover,
  textDelete
}: PopoverTableDataProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const canEdit = () => {
    if (rowPopover.status) {
      return !!(onEdit && ['ACTIVE', 'PRE_USER', 'DISABLED', 'INACTIVE'].includes(rowPopover.status as string))
    } else {
      return true
    }
  }

  return (
    <Paper sx={{ width: 200, maxWidth: '100%' }}>
      <MenuList sx={{ '& .MuiListItemText-root ': { '& .MuiTypography-root': { color: '#374151' } } }}>
        {onEdit && canEdit() && (
          <MenuItem
            onClick={() => {
              onEdit(rowPopover)
              handleClosePopover()
            }}
          >
            <Edit />
            <ListItemText sx={{ ml: 1 }} primary={labels[lng].edit} />
          </MenuItem>
        )}
        {onEdit && onDeactivate && rowPopover.status && <Divider sx={{ my: 1 }} />}
        {onDeactivate && (
          <MenuItem
            onClick={() => {
              onDeactivate(rowPopover)
              handleClosePopover()
            }}
          >
            <Desactivate />
            <ListItemText sx={{ ml: 1 }} primary={rowPopover.status === 'ACTIVE' ? labels[lng].deactivate : labels[lng].activate} />
          </MenuItem>
        )}
        {onDelete && <Divider sx={{ my: 1 }} />}
        {onDelete && (
          <MenuItem
            onClick={() => {
              onDelete(rowPopover._id)
              handleClosePopover()
            }}
          >
            <Delete />
            <ListItemText sx={{ ml: 1 }} primary={textDelete || labels[lng].delete} />
          </MenuItem>
        )}
      </MenuList>
    </Paper>
  )
}
