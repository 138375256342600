import React, { createContext, FC, useEffect, useState, useContext } from 'react'
import firebase from 'src/firebase/firebase'
import authenticateUser from 'src/hooks/useAuthentication'
import { getAdminData } from 'src/services/admin.services'
import { AlertContext } from '../alert/alertContext'
import { UserDataTypes } from 'src/interfaces/app.types'
import { getAuth, browserSessionPersistence, setPersistence } from 'firebase/auth'
import { removeFcmRestaurant } from 'src/services/restaurants.services'
export interface AuthContextProps {
  user: Record<string, any>
  firebase: any
  userData: UserDataTypes
  revalidate: () => Promise<void>
  initLoading: boolean
  logout: () => Promise<void>
}

export const AuthContext = createContext({} as AuthContextProps)

export const AuthProvider: FC = ({ children }) => {
  const { showSnackbar } = useContext(AlertContext)

  const [userData, setUserData] = useState<any | null>(null)
  const user = authenticateUser()
  const [initLoading, setInitLoading] = useState(true)

  const logout = async (): Promise<void> => {
    setUserData(null)
    localStorage.removeItem('rest_sel')
    const fcm = await firebase.verify()
    if (fcm) {
      await removeFcmRestaurant(fcm)
    }
    await firebase.logout()
  }

  const revalidate = async (): Promise<void> => {
    getAdminData()
      .then((result) => {
        const auth = getAuth()
        setUserData(result)
        result?.role === 'admin' && setPersistence(auth, browserSessionPersistence)
      })
      .then(() => setInitLoading(false))
      .catch((error) => {
        if (error instanceof Error) showSnackbar('error', error.message)
        firebase
          .verify()
          .then(async (fcm) => {
            if (fcm) {
              await removeFcmRestaurant(fcm)
            }
          })
          .catch(console.error)
        firebase.logout()
      })
  }

  useEffect(() => {
    if (user.user) {
      revalidate()
    } else if (user && !user.user && user.userDataPresent) setInitLoading(false)
  }, [user])

  return <AuthContext.Provider value={{ user, userData, revalidate, firebase, initLoading, logout }}>{children}</AuthContext.Provider>
}

export default AuthProvider
