import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { labels } from 'src/labels/main_labels'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { ReactComponent as HistoricIcon } from 'src/assets/icons/general/HistoricIcon.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  name?: string
  surname?: string
  date: string
  lastValue: string
  lastValueMin?: string | number
  fieldChanged?: string
  complex?: boolean
  symbol?: string
  byDefaultText?: boolean
}

export default function HistoryRow({
  name,
  date,
  lastValue,
  surname,
  fieldChanged,
  lastValueMin,
  complex,
  symbol,
  byDefaultText
}: Props): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <>
      <Box sx={{ py: 1, pl: 2, pr: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className="flex-center">
          <HistoricIcon />
          {name && (
            <Typography variant="subtitle2" sx={{ textTransform: 'capitalize', ml: 2 }}>
              {name || ''} {surname || ''}
            </Typography>
          )}
          <Typography variant="subtitle2" sx={{ ml: 2, fontWeight: 400 }}>
            {complex ? labels[lng].changeIn : labels[lng].changeFrom}
          </Typography>
          <Typography variant="subtitle2" sx={{ ml: 1, fontWeight: 400, color: '#111827' }}>
            {fieldChanged || ''}
          </Typography>
          {complex && (
            <Typography variant="subtitle2" sx={{ ml: 1, fontWeight: 400 }}>
              {labels[lng].from}
            </Typography>
          )}
          <Typography variant="subtitle2" sx={{ ml: 1, fontWeight: 400, color: '#111827' }}>
            {lastValue}
            {(lastValueMin || lastValueMin === 0) && `:${String(lastValueMin).padStart(2, '0')}`}
            {symbol}
            {byDefaultText && ` (${labels[lng].byDefault})`}
          </Typography>
        </div>
        <Typography variant="subtitle2" component="span" sx={{ fontWeight: 400 }}>
          {dateTimeConvert(date)}
        </Typography>
      </Box>
    </>
  )
}
