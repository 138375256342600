import React, { FC, useContext, PropsWithChildren, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { CssBaseline, Divider, IconButton, Button, Box, Popover, Typography } from '@mui/material'

import NavElements from './NavElements'
import LogoZone from './components/LogoZone'
import AppBarLayout from './components/AppBarLayout'
import ButtonExportData from './components/ButtonExportData'
import PopoverLogoutLanguage from './components/PopoverLogoutLanguage'

import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ReactComponent as Avatar } from 'src/assets/icons/general/Avatar.svg'
import { navigationLinkAdminRestaurantes, navigationLinkAdminUsuarios, navigationLinkAdminGestion } from 'src/routes/navigationLinks'
import { OPEN_LAYOUT_DEFAULT } from 'src/constants/const'
import { logoutSwalConfig } from 'src/utils/swal.utils'
import { DrawerHeader, Drawer, DrawerNav, ListCustom, NavTitle, BoxNavBottom, Main } from './layout.styles'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

interface LayoutProps {
  children: React.ReactNode
}

interface NavElement {
  link: string
  Icon: FC
  text: string
}

export default function LayoutAdmin({ children }: PropsWithChildren<LayoutProps>): JSX.Element {
  const history = useHistory()
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const [open, setOpen] = useState(OPEN_LAYOUT_DEFAULT)
  const [width, setWidth] = useState(window.innerWidth)
  const { setOpenLayout, exportOnClick, exportData, isLoading } = useContext(HeaderContext)
  const { userData, logout } = useContext(AuthContext)

  useEffect(() => {
    if (width < 900) {
      setOpen(false)
      setOpenLayout(false)
    } else {
      setOpen(true)
      setOpenLayout(true)
    }
  }, [width])

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Import header from context
  const handleDrawer = () => {
    setOpen(!open)
    setOpenLayout(!open)
  }

  const navLink = (link: string) => {
    history.push(link)
  }

  const onLogout = () => {
    Swal.fire(logoutSwalConfig(lng)).then((result: any) => {
      if (result.isConfirmed) {
        logout()
      }
    })
  }

  // POPOVER
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const openPopover = Boolean(anchorEl)
  const idPop = openPopover ? 'logout-popover' : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <LogoZone open={open} handleDrawer={handleDrawer} />
        <Divider />
        <DrawerNav>
          <div>
            <NavTitle variant="subtitle2">{labels[lng].restaurants}</NavTitle>
            <ListCustom disablePadding>
              {navigationLinkAdminRestaurantes.map((elm: NavElement, index: number) => (
                <NavElements key={index} open={open} link={elm.link} Icon={elm.Icon} text={labels[lng][elm.text]} navLink={navLink} />
              ))}
            </ListCustom>

            <NavTitle variant="subtitle2">{labels[lng].users}</NavTitle>
            <ListCustom disablePadding>
              {navigationLinkAdminUsuarios.map((elm: NavElement, index: number) => (
                <NavElements key={index} open={open} link={elm.link} Icon={elm.Icon} text={labels[lng][elm.text]} navLink={navLink} />
              ))}
            </ListCustom>

            <NavTitle variant="subtitle2">{labels[lng].management}</NavTitle>
            <ListCustom disablePadding>
              {navigationLinkAdminGestion.map((elm: NavElement, index: number) => (
                <NavElements key={index} open={open} link={elm.link} Icon={elm.Icon} text={labels[lng][elm.text]} navLink={navLink} />
              ))}
            </ListCustom>
          </div>
          <ListCustom disablePadding>
            {exportData && exportData?.length > 0 && <ButtonExportData onClick={exportOnClick} isLoading={isLoading} open={open} />}
            <Box>
              <Divider />
              <BoxNavBottom>
                <Button disabled={open} sx={{ pl: 1, textAlign: 'left' }} onClick={(e) => handlePopover(e)}>
                  <Avatar />
                  {userData && (
                    <Typography variant="body2" sx={{ color: '#1A2D30' }}>
                      {labels[lng].fudclub}
                    </Typography>
                  )}
                </Button>
                {open && (
                  <IconButton sx={{ width: '40px' }} onClick={(e) => handlePopover(e)}>
                    <MoreHorizIcon color="info" />
                  </IconButton>
                )}
              </BoxNavBottom>
            </Box>
          </ListCustom>
        </DrawerNav>
      </Drawer>
      <Main>
        <DrawerHeader />
        <AppBarLayout handleDrawer={handleDrawer} open={open} />
        {children}
      </Main>

      <Popover
        id={idPop}
        elevation={1}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <PopoverLogoutLanguage onLogout={onLogout} onClose={() => setAnchorEl(null)} />
      </Popover>
    </Box>
  )
}
