import React, { useState, useContext } from 'react'
import { labels } from 'src/labels/main_labels'
import { AlertContext } from 'src/context/alert/alertContext'
import { editEmailRestaurant } from 'src/services/restaurants.services'
import { validateEmail } from 'src/utils/validateForm'
import { useTranslation } from 'react-i18next'
import EditCredentialsModal from 'src/components/Modals/EditCredentialsModal'

interface EditEmailProps {
  open: boolean
  handleClose: (success?: boolean) => void
  emailPrev?: string
}

export const EditEmail = ({ open, handleClose }: EditEmailProps): JSX.Element => {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showSnackbar } = useContext(AlertContext)
  const [emailValue, setEmailValue] = useState('')
  const [error, setError] = useState('')

  const submit = async () => {
    setError('')
    try {
      await editEmailRestaurant(emailValue)
      showSnackbar('success', labels[lng].editMailSendConfirmation)
      handleClose(true)
      setError('')
    } catch (error: any) {
      if (error.message) setError(labels[lng].emailInUse)
      else if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const closeModal = () => {
    setError('')
    setEmailValue('')
    handleClose()
  }

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailValue(ev.target.value)
  }

  const disabledContinue = () => {
    // if (emailValue === emailPrev) return true
    if (!validateEmail(emailValue)) return true
    else return false
  }

  return (
    <EditCredentialsModal
      title={labels[lng].changeEmail}
      formLabel={labels[lng].newEmail}
      information={labels[lng].information}
      inputType="text"
      value={emailValue}
      error={error}
      open={open}
      closeModal={closeModal}
      handleChange={handleChange}
      submit={submit}
      disabledContinue={disabledContinue}
    />
  )
}
