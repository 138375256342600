import { languages } from 'src/constants/const'

export const translateGenerator = (translationKeys: string[]): any[] => {
  let translation: any[] = []
  // && !translations.map((x) => x.language).includes(lang.value)
  const obj: Record<string, string> = {}
  for (const key of translationKeys) {
    obj[key] = ''
  }
  languages.forEach((lang) => {
    if (lang.value !== 'es') translation = [...translation, { language: lang.value, ...obj }]
  })
  return translation
}

export const stringAvatar = (name: string, surname?: string, size?: number): Record<string, any> => {
  let text = ''
  if (!name && !surname) text = ''
  else if (surname) text = `${name.charAt(0) || ''}${surname.charAt(0) || ''}`
  else if (name.length <= 1) text = `${name.charAt(0) || ''}`
  else text = `${name.charAt(0)}${name.split(' ')[1] ? name.split(' ')[1][0] : ''}`

  if (text) text = text.toUpperCase()

  return {
    sx: {
      width: size || 40,
      height: size || 40,
      backgroundColor: '#6B7280',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '16px'
    },
    children: text
  }
}

export const dataToCurrency = (data?: string | number): string => {
  if (!data) return '0,00 €'
  return Number(data).toFixed(2).replace('.', ',') + ' €'
}

export const getParenthesizedText = (text: string): string => {
  return `(${text})`
}

export const getCouponType = (dataToEdit?: { special: boolean; company: boolean }): 'special' | 'enterprise' | 'normal' => {
  if (dataToEdit?.special) return 'special'
  if (dataToEdit?.company) return 'enterprise'
  return 'normal'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseEnterpriseCoupon = (dataToEdit: any): any => {
  if (!dataToEdit.company) return
  const values = {
    name: dataToEdit.name,
    giftQuantity: dataToEdit.companyConfig.maxGiftQuantity,
    obv: dataToEdit.obv,
    enterpriseCode: dataToEdit.companyConfig.code,
    minimumAmountPlates: dataToEdit.companyConfig.minQuantity,
    'firstDishRange-min': dataToEdit.companyConfig.range1.min,
    'firstDishRange-max': dataToEdit.companyConfig.range1.max,
    firstRangePrice: dataToEdit.companyConfig.range1.price,
    firstRangeValidity: dataToEdit.companyConfig.range1.expiration,
    'secondDishRange-min': dataToEdit.companyConfig.range2.min,
    'secondDishRange-max': dataToEdit.companyConfig.range2.max,
    secondRangePrice: dataToEdit.companyConfig.range2.price,
    secondRangeValidity: dataToEdit.companyConfig.range2.expiration,
    thirdDishRange: dataToEdit.companyConfig.range3.min,
    thirdRangePrice: dataToEdit.companyConfig.range3.price,
    thirdRangeValidity: dataToEdit.companyConfig.range3.expiration
  }
  return values
}
