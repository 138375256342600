import React, { useContext, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionSummary, AccordionDetails } from './ayuda.styles'
import { HeaderContext } from 'src/context/header/headerContext'
import { Link, useHistory } from 'react-router-dom'
import { Box, IconButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'
import { labels } from 'src/labels/main_labels'
import { faq } from 'src/labels/faq_labels'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { ChevronLeft } from '@mui/icons-material'

export default function Ayuda(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const history = useHistory()
  const { showHeader } = useContext(HeaderContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expanded, setExpanded] = React.useState<string | false>('panel1')
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }
  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between" style={{ position: 'relative' }}>
        <div className="flex-center">
          <Link to={`/ayuda`}>
            <IconButton
              sx={{
                ml: '-14px',
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              }}
            >
              <ChevronLeftIcon sx={{ color: grey[500] }} />
            </IconButton>
          </Link>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: '-0.5px',
              [theme.breakpoints.up('md')]: {
                display: 'none'
              }
            }}
          >
            <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.goBack()} />
          </Box>
          <Typography
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            }}
            variant="subtitle2"
          >
            {labels[lng].help}
          </Typography>
        </div>
        <Typography
          variant="subtitle2"
          component="h3"
          sx={{
            [theme.breakpoints.down('md')]: {
              color: '#6B7280',
              fontWeight: 600
            }
          }}
        >
          {labels[lng].faq}
        </Typography>
        <Typography variant="subtitle2" component="h3"></Typography>
      </div>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  return (
    <Box
      sx={{
        [theme.breakpoints.down('md')]: {
          pb: '16px'
        }
      }}
    >
      {faq[lng].map((question: any) => (
        <Accordion key={question.title} onChange={handleChange(question.title)}>
          <AccordionSummary
            sx={{
              '> .MuiAccordionSummary-content': {
                marginLeft: '0 !important'
              }
            }}
          >
            <Typography variant="subtitle1">{question.title}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ border: 'none' }}>
            {question.text.map((txt: string) => (
              <Typography sx={{ mb: 1, color: '#6B7280', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }} key={txt}>
                {txt}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}
