// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useContext, useEffect } from 'react'
import { BrowserRouter, Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import LayoutAdmin from 'src/components/Layout/LayoutAdmin'
import LayoutRestaurants from 'src/components/Layout/LayoutRestaurants'

import Welcome from 'src/pages/Welcome'
import adminRoutes from './AdminRoutes'
import restaurantRoutes from './RestaurantRoutes'

import { AuthContext } from 'src/context/auth/authContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { SimpleDialog } from 'src/components/Modals/Dialog'
import { Alert, AlertColor, Snackbar } from '@mui/material'
import LoadingApp from 'src/components/LoadingApp'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import RestaurantSelection from 'src/pages/Welcome/RestaurantSelection'
import { UpdatePassword } from 'src/pages/RestaurantPlatform/Informacion/UpdatePassword'
import { Main, MainRedirect } from 'src/pages/Welcome/welcome.styles'
import { useTranslation } from 'react-i18next'
import ScrollToTop from 'src/utils/scrollToTop'

const AppRouter: FC = () => {
  const { user, userData, initLoading } = useContext(AuthContext)
  const { alert, alertState, hideAlert, snackbar } = useContext(AlertContext)
  const { restaurant, loading } = useContext(RestaurantContext)
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const handleClose = () => {
    hideAlert()
  }
  useEffect(() => {
    if (userData) {
      const language = userData.language
      i18n.changeLanguage(language)
    }
  }, [userData])

  if (initLoading || loading) return <LoadingApp />

  return (
    <div>
      {user.user && alert && (
        <SimpleDialog
          open={alert}
          handleClose={handleClose}
          title={alertState.alertTitle as string}
          text={alertState.alertMsg as string}
          category={alertState.category as string}
          centerTitle={alertState.center as boolean}
        />
      )}
      {snackbar && (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbar}>
          <Alert severity={alertState.snackbarType as AlertColor}>{alertState.snackbarText}</Alert>
        </Snackbar>
      )}

      <BrowserRouter>
        <>
          <ScrollToTop />
          {user && user.user && userData ? (
            <Switch>
              {userData.role === 'admin' ? (
                <LayoutAdmin>
                  {/* AVOID RE RENDERS */}
                  <Route exact path="/" render={() => <Redirect to="/restaurantes" />} />
                  {adminRoutes.map((route, index) => {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={(props: RouteComponentProps) => <route.component name={route.name} {...props} {...route.props} />}
                      />
                    )
                  })}
                  <Route exact path="/404" render={() => <h1>Not found!</h1>} />
                </LayoutAdmin>
              ) : (
                <>
                  {restaurant ? (
                    <>
                      {restaurant.isNewRestaurant ? (
                        <Main
                          sx={{
                            background: `center/cover no-repeat url('./img/LoginBackground.webp')`
                          }}
                        >
                          <UpdatePassword
                            isNewRestaurant
                            open={true}
                            mandatory
                            lng={lng}
                            handleClose={() => {
                              if (window) {
                                window.location.href = '/'
                              }
                            }}
                          />
                        </Main>
                      ) : (
                        <LayoutRestaurants>
                          {/* AVOID RE RENDERS */}
                          <Route exact path="/" render={() => <Redirect to="/pedidos" />} />
                          {restaurantRoutes.map((route, index) => {
                            return (
                              <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props: RouteComponentProps) => <route.component name={route.name} {...props} {...route.props} />}
                              />
                            )
                          })}
                          <Route exact path="/404" render={() => <h1>Not found!</h1>} />
                        </LayoutRestaurants>
                      )}
                    </>
                  ) : (
                    <RestaurantSelection />
                  )}
                </>
              )}
            </Switch>
          ) : (
            <Switch>
              <Route
                path="/recovery/:tokenRef/:email"
                render={(props) => (
                  <MainRedirect>
                    <UpdatePassword
                      isNewRestaurant={false}
                      open={true}
                      lng={lng}
                      recovery
                      optionsRecovery={{
                        tokenRef: decodeURIComponent(props.match.params.tokenRef),
                        email: decodeURIComponent(props.match.params.email)
                      }}
                      handleClose={() => {
                        if (window) {
                          window.location.href = '/'
                        }
                      }}
                    />
                  </MainRedirect>
                )}
              />
              <Route exact path="/*" render={() => <Welcome />} />
              {/* <Redirect to="/" />
                <Welcome /> */}
            </Switch>
          )}
        </>
      </BrowserRouter>
    </div>
  )
}

export default AppRouter
