import { IngredientTypes } from 'src/interfaces/pages.types'
import axiosInstance from './axiosInstance'
import axiosInstanceImages from './axiosInstanceImages'
import transformInToFormObject from 'src/utils/transformToFormData'

interface IngredientsApi {
  ingredients: IngredientTypes[]
  total: number
}

export const getIngredients = async (order?: string, limit?: number, offset?: number, searchString?: string): Promise<IngredientsApi> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`

  return axiosInstance
    .get(`/ingredient${query}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de ingredientes')
    })
}

export const createIngredient = async (ingredient: IngredientTypes): Promise<void> => {
  const formattedIngredient = transformInToFormObject(ingredient)

  return axiosInstanceImages
    .post('/ingredient/create', formattedIngredient)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba crear el ingrediente')
    })
}

export const updateIngredient = async (ingredient: IngredientTypes, id: string): Promise<void> => {
  delete ingredient._id

  const formattedIngredient = transformInToFormObject(ingredient)

  return axiosInstanceImages
    .put(`/ingredient/${id}`, formattedIngredient)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el ingrediente')
    })
}

export const deleteIngredient = async (id: string): Promise<void> => {
  return axiosInstance
    .delete(`/ingredient/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar el ingrediente')
    })
}
