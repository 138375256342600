import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import SearchInput from 'src/components/SearchInput'
import { getIngredients, deleteIngredient } from 'src/services/ingredients.services'
import { Order } from 'src/interfaces/pages.types'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import IngredientesAddEdit from './IngredientesAddEdit'
import { labels } from 'src/labels/main_labels'
import deleteElements from 'src/services/deleteElements'
import TableApi from 'src/components/Table/TableApi'
import { useTranslation } from 'react-i18next'

export default function Ingredientes(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showHeader, setExportData, setExportName } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)

  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const wordSearch = useRef('')

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].ingredient,
      order: true,
      fieldtype: 'name-avatar'
    },
    {
      idField: 'numberOfPlates',
      align: 'center',
      disablePadding: true,
      label: labels[lng].platesNumber,
      fieldtype: 'string'
    }
  ]

  // TABLE CONTROL
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const pageRef = useRef(0)

  // EXCEL EXPORT

  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      ;(async () => {
        const ingredients = await getIngredients(
          `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
          rowsPerPage,
          pageRef.current,
          wordSearch.current
        )
        const excelFormatted = ingredients.ingredients?.map((ingredient: any) => {
          return {
            Id: ingredient._id,
            Nombre: ingredient.name,
            'Nº de Platos': ingredient.numberOfPlates || 0
          }
        })
        setExportData(excelFormatted)
        setExportName('Ingredientes')
      })()
    }
    return () => setExportData(null)
  }, [rowsData])

  const fetchIngredients = async () => {
    setLoadingTable(true)
    try {
      const resp = await getIngredients(
        `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
        rowsPerPage,
        pageRef.current,
        wordSearch.current
      )
      setTotalCount(resp.total)
      setRowsData(resp.ingredients)
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchIngredients()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageRef.current = 0
  }

  const handleSearch = async (word: string) => {
    wordSearch.current = word
    if (!word) {
      fetchIngredients()
      return
    }
    const resp = await getIngredients(
      `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
      rowsPerPage,
      0,
      word
    )
    pageRef.current = 0
    setTotalCount(resp.total)
    setRowsData(resp.ingredients)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between ">
        <SearchInput placeholder={labels[lng].searchIngredient} name="search" handleSubmit={handleSearch} clean />
        <Button size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModal(true)}>
          {labels[lng].addIngredient}
        </Button>
      </div>
    )
  }

  useEffect(() => {
    fetchIngredients()
  }, [rowsPerPage, order, orderBy])

  useEffect(() => {
    if (initHeader) showHeader(Header)
    // return () => setInitHeader(false)
  }, [initHeader])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].ingredients, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, deleteIngredient)
          .then(() => {
            showSnackbar('success', labels[lng].ingredientDeleted)
            fetchIngredients()
            closeModal()
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = (data: Record<string, unknown>) => {
    setEditData(data)
    setOpenModal(true)
  }

  return (
    <>
      <TableApi
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rows={rowsData}
        headCells={headCells}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        loading={loadingTable}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
        page={pageRef.current}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        selected={selected}
        setSelected={setSelected}
      />
      {openModal && (
        <IngredientesAddEdit
          openModal={openModal}
          closeModal={closeModal}
          dataToEdit={editData}
          onDelete={onDelete}
          fetchData={fetchIngredients}
        />
      )}
    </>
  )
}
