import { LabelType } from './main_labels'

export const styleFoodLabels: LabelType = {
  es: {
    ESP: 'Española',
    MDT: 'Mediterránea',
    ITA: 'Italiana',
    HMB: 'Hamburguesa',
    PZZ: 'Pizza',
    AMCN: 'Americana',
    LTN: 'Latina',
    MX: 'Mexicana',
    INT: 'Internacional',
    HLT: 'Saludable',
    ASN: 'Asiática',
    JPN: 'Japonesa'
  },
  en: {
    ESP: 'Spanish',
    MDT: 'Mediterranean',
    ITA: 'Italian',
    HMB: 'Burger',
    PZZ: 'Pizza',
    AMCN: 'American',
    LTN: 'Latin',
    MX: 'Mexican',
    INT: 'International',
    HLT: 'Healthy',
    ASN: 'Asin',
    JPN: 'Japanese'
  }
}
