import React from 'react'
import { Button, Typography } from '@mui/material'
import { ReactComponent as Delete } from 'src/assets/icons/general/Delete.svg'

import LoadingButton from '@mui/lab/LoadingButton'
import { labels } from 'src/labels/main_labels'
import ContactWithFudclub from '../ContactWithFudclub'
import { FooterFormBox } from './form.styles'
import { useTranslation } from 'react-i18next'

interface FooterFormProps {
  editing: boolean
  closeModal: () => void
  onSave: () => void
  text: string
  extraInfo?: string
  onDelete?: () => void
  loading?: boolean
  disabled?: boolean
  client?: boolean
  mainText?: boolean
}

export default function FooterForm({
  editing,
  text,
  closeModal,
  onSave,
  extraInfo,
  onDelete,
  loading,
  disabled,
  client,
  mainText
}: FooterFormProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <FooterFormBox sx={{ justifyContent: (editing && onDelete) || extraInfo || client ? 'space-between' : 'flex-end' }}>
      {editing && onDelete && (
        <Button sx={{ mr: 2 }} startIcon={<Delete />} variant="text" color="info" onClick={onDelete}>
          {labels[lng].delete} {text}
        </Button>
      )}
      {client && <ContactWithFudclub />}
      {extraInfo && (
        <Typography variant="subtitle2" component="h6" sx={{ ml: 1, textAlign: { xs: 'center', sm: 'left' } }}>
          {extraInfo}
        </Typography>
      )}
      <div>
        <Button variant="outlined" color="info" onClick={closeModal}>
          {labels[lng].cancel}
        </Button>
        <LoadingButton
          sx={{ ml: 2, fontSize: '14px', fontFamily: 'Inter' }}
          onClick={onSave}
          loading={loading}
          variant="contained"
          disabled={disabled}
        >
          {mainText ? (
            text
          ) : (
            <>
              {editing ? labels[lng].update : labels[lng].add} {text}
            </>
          )}
        </LoadingButton>
      </div>
    </FooterFormBox>
  )
}
