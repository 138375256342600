import React, { useState, useEffect, useContext } from 'react'

import ImagePicker from 'src/components/ImagePicker'
import FormTitle from 'src/components/Form/FormTitle'

import TextInput from 'src/components/Form/TextInput'
import Box from '@mui/material/Box'
import ChipSelector from 'src/components/Form/ChipSelector'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'

import { AddEditProps } from 'src/interfaces/pages.types'
import { useForm } from 'src/hooks/useForm'
import { activeGroup, createGroup, deactiveGroup, updateGroup } from 'src/services/groups.services'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import SwitchForm from 'src/components/Form/SwitchForm'
import { useTranslation } from 'react-i18next'

const INITIAL_VALUES = { name: '' }

export default function GruposAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const { showSnackbar, showAlert } = useContext(AlertContext)
  const [loading, setLoading] = useState(false)
  const [restaurantArray, setRestaurantArray] = useState<any[] | never[]>([])
  const [status, setStatus] = useState('ACTIVE')

  const { handleChange, values, setPhoto, photo, resetForm } = useForm(!dataToEdit ? INITIAL_VALUES : dataToEdit, dataToEdit)

  const resetComponent = () => {
    setLoading(false)
    if (!dataToEdit) {
      setRestaurantArray([])
      setStatus('ACTIVE')
    }
    resetForm()
    setPhoto(null)
  }

  useEffect(() => {
    if (dataToEdit) {
      setRestaurantArray(dataToEdit.group)
      setStatus(dataToEdit.status)
    }
    return () => resetComponent()
  }, [dataToEdit])

  const onSave = async () => {
    setLoading(true)
    const newGroup = {
      ...values,
      group: restaurantArray
    }
    newGroup.photo = photo

    if (!dataToEdit) {
      try {
        await createGroup(newGroup)
        showSnackbar('success', labels[lng].groupCreated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === 'GROUP_JOIN') showAlert(labels[lng].GROUP_JOINTitle, labels[lng].GROUP_JOIN, 'error', false, true)
          else showSnackbar('error', error.message)
        }
      } finally {
        setLoading(false)
      }
    } else {
      delete newGroup.createdAt
      delete newGroup.updatedAt
      delete newGroup.numberOfPlates

      try {
        await updateGroup(newGroup, dataToEdit._id)
        if (status === 'ACTIVE') {
          await activeGroup(dataToEdit._id)
        } else {
          await deactiveGroup(dataToEdit._id)
        }
        showSnackbar('success', labels[lng].groupUpdated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === 'GROUP_JOIN') showAlert(labels[lng].GROUP_JOINTitle, labels[lng].GROUP_JOIN, 'error', false, true)
          else showSnackbar('error', error.message)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit._id)
  }

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setStatus('ACTIVE')
    else setStatus('DISABLED')
  }
  return (
    <Modal
      title={editing ? values.name : labels[lng].addGroup}
      open={openModal}
      handleClose={() => {
        resetComponent()
        closeModal()
      }}
      footer={
        <FooterForm
          text={labels[lng].group}
          loading={loading}
          closeModal={() => {
            resetComponent()
            closeModal()
          }}
          onSave={onSave}
          editing={editing}
          // extraInfo={restaurantArray.length <= 1 ? labels[lng].minimunRestaurants : ''}
          onDelete={onDelete ? handleDelete : undefined}
          disabled={!values.name || restaurantArray.length <= 0}
        />
      }
    >
      <Box id="form-grupos" component="form" noValidate>
        <ImagePicker setImgData={setPhoto} imgData={values.photo} actualImage={editing ? values.photo : null} />

        <FormTitle text={labels[lng].information} />

        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          id="name"
          text={labels[lng].name}
          placeholder={labels[lng].nameGroup}
        />
        <ChipSelector
          searchBy="restaurant"
          required
          data={restaurantArray}
          setData={setRestaurantArray}
          text={labels[lng].restaurant}
          options={[]}
          restaurantEmail
        />
        <SwitchForm text={labels[lng].state} handleChange={(e) => handleSwitch(e)} checked={status === 'ACTIVE'} />
      </Box>
    </Modal>
  )
}
