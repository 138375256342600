import React, { PropsWithChildren } from 'react'
import { DialogContent, DialogActions, Breakpoint, Box, DialogProps } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { ModalTitle, ModalDialog } from './modals.styles'
import { theme } from 'src/styles/theme'
import ChevronLeft from '@mui/icons-material/ChevronLeft'

export interface Props extends DialogProps {
  open: boolean
  handleClose: () => void
  title: string
  text?: string
  type?: string
  size?: Breakpoint
  noIcon?: boolean
  btnText?: string
  children?: React.ReactNode
  footer?: JSX.Element
  modal2?: boolean
  noCloseIcon?: boolean
  dialogStyles?: Record<string, any>
  fullScreen?: boolean
  hideControls?: boolean
  noTitle?: boolean
  backdropActive?: boolean
  recovery?: boolean
  hasDimensions?: boolean
}

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
  modal2?: boolean
  noCloseIcon?: boolean
  backIcon?: boolean
  hideControls?: boolean
}

const ModalTopTitle = (props: DialogTitleProps) => {
  const { children, modal2, onClose, noCloseIcon, backIcon, hideControls, ...other } = props

  return (
    <Box boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.05)'} zIndex={10}>
      <ModalTitle
        className="modal-title"
        sx={{
          fontSize: backIcon ? '14px' : modal2 ? '20px' : '18px',
          fontWeight: backIcon ? '600' : modal2 ? 700 : 600,
          padding: backIcon ? '16px' : modal2 ? '12px 16px 12px 16px' : 2,
          paddingLeft: backIcon ? '16px' : modal2 ? '16px' : 3,
          color: backIcon ? '#4B5563' : 'black',
          '& .MuiButtonBase-root': {
            p: modal2 ? '4px' : ''
          },
          [theme.breakpoints.down('md')]: {
            maxHeight: '60px',
            justifyContent: hideControls ? 'flex-end' : 'space-between',
            color: '#6B7280'
          }
        }}
        {...other}
      >
        {backIcon && !hideControls ? <ChevronLeft onClick={onClose} style={{ color: '#9CA3AF', fill: '#9CA3AF' }} /> : null}
        {!hideControls && children}
        {!noCloseIcon ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : (
          <Box sx={{ height: 40, width: backIcon ? '24px' : 0 }} />
        )}
      </ModalTitle>
    </Box>
  )
}

export default function Modal({
  open,
  handleClose,
  title,
  size = 'sm',
  children,
  footer,
  modal2,
  noCloseIcon,
  dialogStyles = {},
  fullScreen = false,
  hideControls = false,
  noTitle = false,
  backdropActive = false,
  recovery = false,
  hasDimensions = false
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <ModalDialog
      fullScreen={fullScreen}
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '100%',
          padding: fullScreen ? 0 : '24px 16px',
          margin: 0,
          '& .MuiPaper-root': {
            borderRadius: fullScreen ? 0 : '8px'
          }
        },
        '& .MuiBackdrop-root': {
          backgroundColor: recovery ? 'transparent' : 'rgba(0, 0, 0, 0.5)'
        },
        '& .MuiPaper-root': {
          ...(!fullScreen && hasDimensions ? { maxWidth: '672px', maxHeight: '728px' } : {})
        }
      }}
      fullWidth
      maxWidth={size}
      aria-labelledby={title.replace(' ', '-')}
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && backdropActive) {
          handleClose()
        }
      }}
    >
      {!noTitle && (
        <ModalTopTitle
          id={title.replace(' ', '-')}
          onClose={handleClose}
          modal2={modal2}
          noCloseIcon={noCloseIcon}
          backIcon={fullScreen}
          hideControls={hideControls && fullScreen}
        >
          {title}
        </ModalTopTitle>
      )}
      <DialogContent
        dividers
        style={{ overflowX: 'hidden', ...dialogStyles }}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {children}
      </DialogContent>
      {footer && (
        <DialogActions
          sx={{
            borderTop: 'none',
            zIndex: 0
          }}
        >
          {footer}
        </DialogActions>
      )}
    </ModalDialog>
  )
}
