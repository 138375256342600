import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'

export const OptionButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.grey[300]
  }
}))

export const NoTextBox = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: theme.palette.grey[200],
  width: '100%',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2)
}))
