import axiosInstance from './axiosInstance'
import axiosInstanceImages from './axiosInstanceImages'
import { GroupTypes } from '../interfaces/pages.types'

interface GroupApi {
  groups: GroupTypes[]
  total: number
  maxPages: number
}

export const getGroups = async (order?: string, limit?: number, offset?: number, searchString?: string): Promise<GroupApi> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`

  return axiosInstance
    .get(`/group${query}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de grupos')
    })
}

export const getGroupsById = async (
  idGroup: string,
  limit?: number,
  offset?: number,
  searchString?: string,
  order?: string
): Promise<any> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`

  return axiosInstance
    .get(`/group/${idGroup}${query}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de restaurantes')
    })
}

export const deleteGroups = async (groups: GroupTypes | GroupTypes[]): Promise<string> => {
  if (typeof groups === 'string') groups = [groups]
  return axiosInstance
    .post(`/group/delete`, { groups: groups })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaban borrar los grupos')
    })
}

export const createGroup = async (group: GroupTypes): Promise<void> => {
  const formData = new FormData()
  const restaurants = group.group.map((restaurant: any) => restaurant._id)
  if (group.photo) formData.append('photo', group.photo)
  formData.append('name', group.name)
  restaurants.forEach((restaurant: any, idx: number) => formData.append(`group[${idx}]`, restaurant))
  return axiosInstanceImages
    .post('/group', formData)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error: any) => {
      if (error.response.data.code === 'GROUP_JOIN') throw new Error('GROUP_JOIN')
      else throw new Error('Hubo un error mientras se intentaba crear el grupo')
    })
}

export const updateGroup = async (group: GroupTypes, id: string): Promise<string> => {
  delete group._id
  const formData = new FormData()
  const restaurants = group?.group?.map((restaurant: any) => restaurant._id)
  if (group.photo) formData.append('photo', group.photo)
  formData.append('name', group?.name)
  restaurants.forEach((restaurant: any, idx: number) => formData.append(`group[${idx}]`, restaurant))
  return axiosInstanceImages
    .put(`/group/${id}`, formData)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error: any) => {
      if (error.response.data.code === 'GROUP_JOIN') throw new Error('GROUP_JOIN')
      else throw new Error('Hubo un error mientras se intentaba actualizar el grupo')
    })
}

export const toogleStatusGroup = async (id: string): Promise<string> => {
  return axiosInstance
    .get(`/group/toogle_status/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el estado del grupo')
    })
}

export const activeGroup = async (groups: string[] | string): Promise<string> => {
  if (typeof groups === 'string') groups = [groups]
  return axiosInstance
    .put(`/group/active`, { id_groups: groups })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el estado del grupo')
    })
}

export const deactiveGroup = async (groups: string[] | string): Promise<string> => {
  if (typeof groups === 'string') groups = [groups]
  return axiosInstance
    .put(`/group/disabled`, { id_groups: groups })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cambiar el estado de los grupos')
    })
}

export const deleteRestaurantGroup = async (group: string, groups: string[] | string): Promise<void> => {
  if (typeof groups === 'string') groups = [groups]
  return axiosInstance
    .put(`/group/remove/${group}`, { restaurant: groups })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cambiar el estado de los grupos')
    })
}
