import React, { useEffect } from 'react'
import { Grid, Stack, TextField, Typography } from '@mui/material'
import { GridFlexBetween, MainBox } from './form.styles'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'

interface TimeRangePickerProps {
  handleChange: (time: any, type: string) => void
  label: string
  valueTo: {
    hour: number
    minutes: number
  }
  valueFrom: {
    hour: number
    minutes: number
  }
  nameTo: string
  nameFrom: string
  minValueHour: number
  minValueMin: number
  maxValueHour: number
  maxValueMin: number
}

export default function TimeRangePickerSelect({
  label,
  handleChange,
  valueTo,
  valueFrom,
  nameTo,
  nameFrom,
  minValueHour,
  minValueMin,
  maxValueHour,
  maxValueMin
}: TimeRangePickerProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const handleTime = (time: any, type: string) => {
    const hour = time.toLocaleString('es-ES', { hour: 'numeric' })
    const minutes = time.toLocaleString('es-ES', { minute: 'numeric' })
    handleChange({ hour: +hour, minutes: +minutes }, type)
  }
  const [stateFrom, setStateFrom] = React.useState<Date | null>(new Date(0, 0, 0, 0, 0))
  const [stateTo, setStateTo] = React.useState<Date | null>(new Date(0, 0, 0, 0, 0))

  useEffect(() => {
    const dateFrom = new Date(0, 0, 0, valueFrom.hour, valueFrom.minutes)
    const dateTo = new Date(0, 0, 0, valueTo.hour, valueTo.minutes)
    if (dateFrom) setStateFrom(dateFrom)
    if (dateTo) setStateTo(dateTo)
  }, [])

  return (
    <MainBox
      sx={{
        borderTop: 'none',
        [theme.breakpoints.down('md')]: {
          padding: '16px'
        }
      }}
    >
      <GridFlexBetween container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="label">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction={'row'} spacing={4} sx={{ alignItems: 'center' }}>
              <MobileTimePicker
                shouldDisableTime={(timeValue, clockType) => {
                  return clockType === 'minutes' && ![15, 0, 30, 45].includes(timeValue)
                }}
                DialogProps={{
                  PaperProps: {
                    sx: { '& *:focus': { outline: 'none' } }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: 45,
                      '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                      '.MuiInputBase-root:after': { borderBottom: 'none !important' }
                    }}
                    variant="standard"
                    {...params}
                  />
                )}
                value={stateFrom}
                onAccept={(newValue) => {
                  if (nameFrom === 'IRr' && newValue && newValue < new Date(0, 0, 0, minValueHour, minValueMin)) {
                    newValue?.setMinutes(minValueMin)
                  }
                  handleTime(newValue, nameFrom)
                }}
                onChange={(newValue) => {
                  if (nameFrom === 'IRr' && newValue && newValue < new Date(0, 0, 0, minValueHour, minValueMin)) {
                    newValue?.setMinutes(minValueMin)
                  }
                  setStateFrom(newValue)
                }}
                minutesStep={15}
                ampm={false}
                ampmInClock={false}
                // cancelText={labels[lng].cancel}
                toolbarTitle={labels[lng].selectTime}
                minTime={new Date(0, 0, 0, minValueHour, minValueMin)}
                maxTime={new Date(0, 0, 0, maxValueHour, maxValueMin)}
              />

              <Typography variant="subtitle2" component="label">
                {labels[lng].to}
              </Typography>

              <MobileTimePicker
                shouldDisableTime={(timeValue, clockType) => {
                  return clockType === 'minutes' && ![15, 0, 30, 45].includes(timeValue)
                }}
                DialogProps={{
                  PaperProps: {
                    sx: { '& *:focus': { outline: 'none' } }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: 45,
                      '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                      '.MuiInputBase-root:after': { borderBottom: 'none !important' }
                    }}
                    variant="standard"
                    {...params}
                  />
                )}
                disabled={stateFrom?.getTime() === new Date(0, 0, 0, 0, 0).getTime()}
                value={stateTo}
                onAccept={(newValue) => {
                  handleTime(newValue, nameTo)
                }}
                onChange={(newValue) => {
                  setStateTo(newValue)
                }}
                minutesStep={15}
                ampm={false}
                ampmInClock={false}
                // cancelText={labels[lng].cancel}
                toolbarTitle={labels[lng].selectTime}
                minTime={stateFrom}
                maxTime={new Date(0, 0, 0, maxValueHour, maxValueMin)}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
      </GridFlexBetween>
    </MainBox>
  )
}
