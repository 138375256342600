import React, { useState, useContext, useEffect, ChangeEvent } from 'react'
import { AlertContext } from 'src/context/alert/alertContext'
import FormTitle from 'src/components/Form/FormTitle'
import TextInput from 'src/components/Form/TextInput'
import Box from '@mui/material/Box'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from 'src/interfaces/pages.types'
import { labels } from 'src/labels/main_labels'
import ParametrosHistorial from './ParametrosHistorial'
import { updateParameter } from 'src/services/parameters.services'
import FixedInput from 'src/components/Form/FixedInput'
// import ChipSelector from 'src/components/Form/ChipSelector'
import { useTranslation } from 'react-i18next'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'

export default function ParametrosSimplesAddEdit({ dataToEdit, closeModal, openModal, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const { fetchParameters: apiFetchParameters } = useContext(RestaurantContext)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState('')
  // const [discountArray, setDiscountArray] = useState<any[] | never[]>([])

  useEffect(() => {
    if (dataToEdit) setState(dataToEdit.value.value)
    // if (dataToEdit.code === 'CDI' && dataToEdit.value.value) setDiscountArray([dataToEdit.value.value])
    return () => {
      setLoading(false)
      setState('')
    }
  }, [dataToEdit])

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState(event.target.value)
    // setState({ ...state, [event.target.name]: event.target.value })
  }

  // useEffect(() => {
  //   if (dataToEdit.code === 'CDI' && discountArray.length > 0) {
  //     if (discountArray[0]) setState(discountArray[0]._id)
  //   }
  // }, [discountArray])

  const onSave = async () => {
    setLoading(true)
    if (dataToEdit) {
      try {
        await updateParameter({ [dataToEdit.code]: state })
        showSnackbar('success', labels[lng].parameterUpdated)
        if (fetchData) fetchData()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', labels[lng].errorDialogText)
      } finally {
        setLoading(false)
        apiFetchParameters()
      }
    }
  }

  const percentage = ['PCDI', 'PCDR', 'PCDPR']
  const price = ['PP']

  return (
    <Modal
      title={editing ? dataToEdit.name : labels[lng].addParameter}
      open={openModal}
      handleClose={closeModal}
      footer={<FooterForm text={labels[lng].parameter} closeModal={closeModal} onSave={onSave} editing={editing} loading={loading} />}
    >
      <Box id="form-parametros" component="form" noValidate>
        <FormTitle text={labels[lng].information} />

        <FixedInput name={labels[lng].parameter.charAt(0).toUpperCase() + labels[lng].parameter.slice(1)} text={dataToEdit.name} />

        {dataToEdit.code !== 'CDI' ? (
          <TextInput
            value={state}
            handleChange={(e) => handleChange(e)}
            name="value"
            text={labels[lng].value}
            placeholder={`${labels[lng].value} ${percentage.includes(dataToEdit.code) ? '%' : '€'}`}
            type="number"
            percentage={percentage.includes(dataToEdit.code)}
            price={price.includes(dataToEdit.code)}
          />
        ) : (
          <TextInput
            value={state}
            handleChange={(e) => handleChange(e)}
            name="value"
            text={labels[lng].value}
            type="str"
            placeholder={labels[lng].value}
          />
        )}
        {dataToEdit && dataToEdit.value && dataToEdit.value.lastUpdate && (
          <ParametrosHistorial history={dataToEdit.value.history} code={dataToEdit.code} />
        )}
      </Box>
    </Modal>
  )
}
