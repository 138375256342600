import { FoodStylesTypes } from 'src/interfaces/pages.types'
import axiosInstance from './axiosInstance'
import axiosInstanceImages from './axiosInstanceImages'
import transformInToFormObject from 'src/utils/transformToFormData'

interface FoodStylesApi {
  foodStyles: FoodStylesTypes[]
  total: number
}

export const getFoodStyles = async (order?: string, limit?: number, offset?: number, searchString?: string): Promise<FoodStylesApi> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`

  return axiosInstance
    .get(`/foodStyle${query}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de estilos')
    })
}

export const createFoodStyle = async (newFoodStyle: Record<string, unknown>): Promise<void> => {
  const newObj = transformInToFormObject(newFoodStyle)

  return axiosInstanceImages
    .post('/foodStyle/create', newObj)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba crear el estilo')
    })
}

export const updateFoodStyle = async (updatedFoodStyle: Record<string, any>, id: string): Promise<void> => {
  delete updatedFoodStyle._id

  const newObj = transformInToFormObject(updatedFoodStyle)

  return axiosInstanceImages
    .put(`/foodStyle/${id}`, newObj)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el estilo')
    })
}

export const deleteFoodStyle = async (id: string): Promise<void> => {
  return axiosInstance
    .delete(`/foodStyle/${id}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba borrar el estilo')
    })
}
