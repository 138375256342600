import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Typography, Grid, LinearProgress, Box } from '@mui/material'

import { AuthContext } from 'src/context/auth/authContext'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { getRestaurantIncome } from 'src/services/restaurants.services'
import { RestaurantsChart } from 'src/components/Charts/RestaurantChart'
import CardSummarySmall from 'src/components/Cards/CardSummarySmall'
import { ContainerFud } from 'src/styles/general-styles'
import { ReactComponent as PlatosVendidos } from 'src/assets/icons/platos-estadisticas-icons/PlatosVendidos.svg'
import { ReactComponent as DiasDisponibles } from 'src/assets/icons/platos-estadisticas-icons/DiasDisponibles.svg'
import { ReactComponent as MediaDiaria } from 'src/assets/icons/platos-estadisticas-icons/MediaDiaria.svg'
import { ReactComponent as PrimeraQuincena } from 'src/assets/icons/platos-estadisticas-icons/PrimeraQuincena.svg'
import { ReactComponent as PrimeraQuincenaEn } from 'src/assets/icons/platos-estadisticas-icons/PrimeraQuincenaEn.svg'
import { ReactComponent as Incomes } from 'src/assets/icons/platos-estadisticas-icons/Incomes.svg'
import { ReactComponent as SegundaQuincena } from 'src/assets/icons/platos-estadisticas-icons/SegundaQuincena.svg'
import { ReactComponent as SegundaQuincenaEn } from 'src/assets/icons/platos-estadisticas-icons/SegundaQuincenaEn.svg'
import { labels } from 'src/labels/main_labels'
import { monthsLabels } from '../../../labels/months_labels'
import StatiticsFilter from '../Estadisticas/components/StatiticsFilter'
import { RestaurantTypes } from 'src/interfaces/pages.types'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { ChevronLeft } from '@mui/icons-material'

interface IncomesProps {
  year: number
  month: number
  restaurantInfo: RestaurantTypes
}

export default function Ingresos({ restaurantInfo }: IncomesProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { showHeader } = useContext(HeaderContext)
  const { restaurant } = useContext(RestaurantContext)
  const { showSnackbar } = useContext(AlertContext)
  const params: any = useParams()

  const [filterState, setFilterState] = useState(new Date())
  const [incomes, setIncomes] = useState<any>(null)
  const [chartData, setChartData] = useState<any>([])

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    const history = useHistory()

    return (
      <div className="flex-center" style={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: '-2px',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.goBack()} />
        </Box>
        <Typography
          variant="h3"
          component="h1"
          sx={{
            [theme.breakpoints.down('md')]: {
              lineHeight: '20px'
            }
          }}
        >
          {labels[lng].incomes}
        </Typography>
      </div>
    )
  }
  useEffect(() => {
    if (userData.role !== 'admin') showHeader(Header)
  }, [])

  const fetchIncomes = async () => {
    try {
      let fetchData
      if (params.idRestaurant)
        fetchData = await getRestaurantIncome(params.idRestaurant, new Date(filterState).getFullYear(), new Date(filterState).getMonth())
      if (restaurant?._id)
        fetchData = await getRestaurantIncome(restaurant._id, new Date(filterState).getFullYear(), new Date(filterState).getMonth())
      if (fetchData) setIncomes(fetchData)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  useEffect(() => {
    fetchIncomes()
  }, [params.idRestaurant, restaurant, filterState])

  useEffect(() => {
    if (incomes && incomes.calendar) {
      const dataFixed = Object.entries(incomes.calendar)
      const chartData = dataFixed.map((register: any) => {
        return {
          day: new Date(register[0]).getDate() + '/' + (new Date(register[0]).getMonth() + 1),
          amount: register[1]?.soldQuantity || 0
        }
      })
      setChartData(chartData.reverse())
    }
  }, [incomes])

  const IncomeFilter = () => {
    const prevAction = () => {
      const addDate = filterState.setMonth(filterState.getMonth() - 1)
      setFilterState(new Date(addDate))
    }
    const nextAction = () => {
      const addDate = filterState.setMonth(filterState.getMonth() + 1)
      setFilterState(new Date(addDate))
    }
    const restInfo = restaurant || restaurantInfo
    const arrowLeft =
      filterState.getTime() > new Date(restInfo.createdAt!).getTime() && filterState.getMonth() !== new Date(restInfo.createdAt!).getMonth()
    // filterState.getMonth() + 1 > new Date(restInfo.createdAt!).getMonth() &&
    // filterState.getFullYear() <= new Date(restInfo.createdAt!).getFullYear()
    const lastDateTo = new Date(filterState.setHours(23, 59, 59, 0))

    const arrowRight = lastDateTo.getTime() < new Date().getTime()
    return (
      <StatiticsFilter
        text={monthsLabels[lng][new Date(filterState).getMonth()] + ' ' + new Date(filterState).getFullYear()}
        arrowLeft={arrowLeft}
        arrowRight={arrowRight}
        prevAction={prevAction}
        nextAction={nextAction}
        type="months"
      />
    )
  }

  return (
    <>
      {!incomes ? (
        <LinearProgress />
      ) : (
        <ContainerFud sx={{ pt: 0, px: 2 }}>
          <RestaurantsChart title={labels[lng].platesSold} dataChart={chartData} IncomeFilter={IncomeFilter} />
          <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
            <Grid item sm={6} xs={6} lg={4}>
              <CardSummarySmall
                Icon={PlatosVendidos}
                title={incomes?.totalQuantity === 1 ? labels[lng].platesSoldSingular : labels[lng].platesSold}
                text={incomes?.totalQuantity}
              />
            </Grid>
            <Grid item sm={6} xs={6} lg={4}>
              <CardSummarySmall
                Icon={DiasDisponibles}
                title={+incomes?.mediaDay?.toFixed(0) === 1 ? labels[lng].availableDaysSingular : labels[lng].availableDays}
                text={incomes?.mediaDay?.toFixed(0).replace('.', ',')}
              />
            </Grid>
            <Grid item sm={6} xs={6} lg={4}>
              <CardSummarySmall
                Icon={MediaDiaria}
                title={labels[lng].dailyAverage}
                text={incomes?.mediaDays.toFixed(1).replace('.', ',')}
                finalText={labels[lng].plates}
              />
            </Grid>
            <Grid item sm={6} xs={6} lg={4}>
              <CardSummarySmall
                Icon={Incomes}
                title={labels[lng].incomes}
                text={
                  Number(incomes?.totalIncome || 0)
                    .toFixed(2)
                    .replace('.', ',') + ' €'
                }
              />
            </Grid>
            <Grid item sm={6} xs={6} lg={4}>
              <CardSummarySmall
                Icon={lng === 'es' ? PrimeraQuincena : PrimeraQuincenaEn}
                title={window.innerWidth < 890 ? labels[lng].incomes1qMob : labels[lng].incomes1q}
                text={
                  Number(incomes?.totalQ1 || 0)
                    .toFixed(2)
                    .replace('.', ',') + ' €'
                }
              />
            </Grid>
            <Grid item sm={6} xs={6} lg={4}>
              <CardSummarySmall
                Icon={lng === 'es' ? SegundaQuincena : SegundaQuincenaEn}
                title={window.innerWidth < 890 ? labels[lng].incomes2qMob : labels[lng].incomes2q}
                text={
                  Number(incomes?.totalQ2 || 0)
                    .toFixed(2)
                    .replace('.', ',') + ' €'
                }
              />
            </Grid>
          </Grid>
        </ContainerFud>
      )}
    </>
  )
}
