import React, { createContext, useReducer, FC, useState } from 'react'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { OPEN_LAYOUT_DEFAULT } from 'src/constants/const'
import { headerReducer } from './headerReducer'
import {
  bonoPayExcel,
  bonosCompanyExcel,
  bonosExcel,
  bookingCanceledExcel,
  bookingExcel,
  bookingReviewExcel,
  cardsExcel,
  cityExcel,
  cousineExcel,
  districtExcel,
  emailsExcel,
  expiredPlatesExcel,
  foodStyleToExcel,
  foodStylesCousinesExcel,
  giftsPlatesExcel,
  neighborhoodExcel,
  parameterEMHIRRExcel,
  parameterFRRExcel,
  parameterPPExcel,
  parameterRFCExcel,
  phoneExcel,
  planningExcel,
  platesExcel,
  platesFoodStylesExcel,
  restaurantPaymentExcel,
  restaurantsExcel,
  usersExcel
} from 'src/utils/excel.util'

export interface HeaderState {
  header: FC | null
  open: boolean
}

export interface HeaderContextProps {
  Header: FC | null
  openLayout: boolean
  showHeader: (header: FC) => void
  setOpenLayout: (open: boolean) => void
  isHeader: boolean
  exportOnClick: () => void
  setExportData: (data: any) => void
  exportData: any[]
  setExportName: (name: string) => void
  isLoading: boolean
}

// Initial State
export const headerInitialState: HeaderState = {
  header: null,
  open: OPEN_LAYOUT_DEFAULT
}

// Create context
export const HeaderContext = createContext({} as HeaderContextProps)

const HeaderProvider: FC = ({ children }) => {
  const [headerState, dispatch] = useReducer(headerReducer, headerInitialState)
  const [exportData, setExportData] = useState<null | any>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [exportName, setExportName] = useState('Exportación')
  const [isLoading, setIsLoading] = useState(false)

  const showHeader = (header: FC) => {
    dispatch({ type: 'showHeader', payload: header })
  }

  const setOpenLayout = (open: boolean) => {
    dispatch({ type: 'SET_OPEN', payload: open })
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportOnClick = async () => {
    setIsLoading(true)
    const allExcel = await Promise.all([
      neighborhoodExcel(),
      districtExcel(),
      cityExcel(),
      foodStyleToExcel(),
      cousineExcel(),
      foodStylesCousinesExcel(),
      usersExcel(),
      cardsExcel(),
      restaurantsExcel(),
      platesExcel(),
      platesFoodStylesExcel(),
      parameterPPExcel(),
      parameterEMHIRRExcel(),
      parameterFRRExcel(),
      parameterRFCExcel(),
      planningExcel(),
      bonosExcel(),
      bonosCompanyExcel(),
      bonoPayExcel(),
      emailsExcel(),
      phoneExcel(),
      giftsPlatesExcel(),
      expiredPlatesExcel(),
      bookingExcel(),
      bookingCanceledExcel(),
      bookingReviewExcel(),
      restaurantPaymentExcel()
    ])
    setIsLoading(false)
    const SheetNames: string[] = []
    const Sheets = allExcel.reduce((acc, curr) => {
      acc[curr[0] as string] = curr[1]
      SheetNames.push(curr[0] as string)
      return acc
    }, {})

    const wb = {
      Sheets,
      SheetNames
    }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    saveAs(data, 'fudclub_excel' + fileExtension)
  }

  return (
    <HeaderContext.Provider
      value={{
        Header: headerState.header,
        openLayout: headerState.open,
        setOpenLayout,
        showHeader,
        isHeader: !!headerState.header,
        exportOnClick,
        setExportData,
        exportData,
        setExportName,
        isLoading
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderProvider
