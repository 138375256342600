import { styled, Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { HEADER_BAR_HEIGHT } from 'src/constants/const'

export const EmptyPageBox = styled(Box)(({ theme }) => {
  return {
    backgroundColor: grey[50],
    margin: theme.spacing(2),
    height: `calc(100vh - ${HEADER_BAR_HEIGHT} - 90px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '8px',
    '& .MuiTypography-root': {
      maxWidth: 400,
      padding: '0 0.5rem'
    },
    [theme.breakpoints.down('md')]: {
      height: `calc(100vh - ${HEADER_BAR_HEIGHT} - 88px)`,

      marginBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - ${HEADER_BAR_HEIGHT} - 88px)`,

      marginBottom: 0
    }
  }
})

export const EmptyPageText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  color: '#6B7280',
  fontWeight: 400
}))
