import React, { useState } from 'react'
import { ListItemText, Paper, MenuList, MenuItem, Divider, Popover, IconButton, Stack } from '@mui/material'

import { ReactComponent as Logout } from 'src/assets/icons/general/Logout.svg'
import { ReactComponent as Language } from 'src/assets/icons/general/Language.svg'
import { ReactComponent as ArrowRight } from 'src/assets/icons/general/ArrowRight.svg'

import { labels } from 'src/labels/main_labels'
import CheckIcon from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'
import { updateLanguage } from 'src/services/restaurants.services'

interface PopoverLogoutLanguageProps {
  onLogout: () => void
  onClose: () => void
}

export default function PopoverLogoutLanguage({ onLogout, onClose }: PopoverLogoutLanguageProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  // POPOVER
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
    updateLanguage(value).catch((err) => {
      console.error('An error occurred while updating language. ', err)
    })
  }
  const openPopover = Boolean(anchorEl)
  const idPop = 'language-popover'
  return (
    <>
      <Paper
        sx={{
          width: 200,
          height: '101px',
          maxWidth: '100%',
          boxShadow:
            '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05) !important'
        }}
      >
        <MenuList sx={{ '& .MuiListItemText-root ': { '& .MuiTypography-root': { color: '#374151' } } }}>
          <MenuItem>
            <IconButton
              sx={{ width: '100%', p: 0, display: 'flex', justifyContent: 'space-between', '&:hover': { backgroundColor: 'transparent' } }}
              onClick={(e) => handlePopover(e)}
            >
              <Stack flexDirection="row" alignItems="center">
                <Language />
                <ListItemText sx={{ ml: '14px' }} primary={labels[lng].language} />
              </Stack>
              <ArrowRight />
            </IconButton>
          </MenuItem>
          <Divider sx={{ my: 1 }} />
          <MenuItem onClick={onLogout}>
            <Logout />
            <ListItemText sx={{ ml: '14px' }} primary={labels[lng].logout} />
          </MenuItem>
        </MenuList>
      </Paper>
      <Popover
        sx={{
          left: '24px'
        }}
        id={idPop}
        elevation={1}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper
          sx={{
            width: 200,
            height: '101px',
            maxWidth: '100%',
            boxShadow:
              '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05) !important'
          }}
        >
          <MenuList sx={{ '& .MuiListItemText-root ': { '& .MuiTypography-root': { color: '#374151', margin: 0, padding: 0 } } }}>
            <MenuItem
              onClick={() => {
                changeLanguage('es')
                onClose()
              }}
              sx={{ height: '32px' }}
            >
              <ListItemText sx={{ ml: '14px' }} primary={labels[lng].spanish} />
              {i18n.language === 'es' && <CheckIcon style={{ width: '16px' }} color="primary" />}
            </MenuItem>
            <Divider sx={{ my: 1 }} />
            <MenuItem
              onClick={() => {
                changeLanguage('en')
                onClose()
              }}
            >
              <ListItemText sx={{ ml: '14px' }} primary={labels[lng].english} />
              {i18n.language === 'en' && <CheckIcon style={{ width: '16px' }} color="primary" />}
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  )
}
