import React from 'react'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { theme } from 'src/styles/theme'

interface RestaurantWithAvatarProps {
  check?: boolean
  onclick?: (id: string) => void
  id?: string
  name: string
  street: string
  number: string
  photo: string
  height?: string
  maxWidthText?: string
}

export default function RestaurantWithAvatar({
  check,
  name,
  street,
  number,
  photo,
  id,
  onclick,
  height,
  maxWidthText
}: RestaurantWithAvatarProps): JSX.Element {
  const handleAction = () => {
    if (onclick && id) onclick(id)
  }

  return (
    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: height || '63px', cursor: 'pointer' }}
        onClick={handleAction}
      >
        <Avatar variant="rounded" sx={{ width: 40, height: 40, mx: 1 }} src={photo} />
        <Stack direction="column">
          <Typography
            variant="subtitle2"
            color="grey.900"
            className="ellipsis-simple"
            sx={{
              maxWidth: maxWidthText || '396px',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                maxWidth: '242px'
              }
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="subtitle2"
            className="ellipsis-simple"
            sx={{
              maxWidth: maxWidthText || '396px',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                maxWidth: '242px'
              }
            }}
          >
            {street} {number}
          </Typography>
        </Stack>
      </Box>
      {check && <CheckIcon color="primary" />}
    </Stack>
  )
}
