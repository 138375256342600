import React, { useContext, useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2'
import { Box, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { downloadZip } from 'client-zip/index'

import { dateTimeConvert } from 'src/utils/time-helpers'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import SearchInput from 'src/components/SearchInput'
import RestaurantesAddEdit from './RestaurantesAddEdit'
import TableApi from 'src/components/Table/TableApi'

import { Order } from 'src/interfaces/pages.types'
import deleteElements from 'src/services/deleteElements'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import {
  getRestaurants,
  updateRestaurant,
  deactiveRestaurants,
  activeRestaurants,
  disabledRestaurants
} from 'src/services/restaurants.services'
import { getRestaurantById } from '../../../services/restaurants.services'
import { useTranslation } from 'react-i18next'

export default function Restaurantes(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].restaurant,
      order: true,
      fieldtype: 'name-avatar'
    },
    {
      idField: 'location',
      align: 'left',
      disablePadding: true,
      label: labels[lng].address,
      order: true,
      fieldtype: 'location'
    },
    {
      idField: 'income',
      align: 'center',
      disablePadding: false,
      label: labels[lng].platesSoldTable,
      fieldtype: 'platesSoldTable'
    },
    {
      idField: 'income',
      align: 'center',
      disablePadding: false,
      label: labels[lng].incomes,
      fieldtype: 'incomeTotal'
    },
    {
      idField: 'createdAt',
      align: 'center',
      disablePadding: true,
      label: labels[lng].registerDate,
      order: true,
      fieldtype: 'date-time'
    }
  ]

  const { showHeader, setExportData, setExportName } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)

  const history = useHistory()
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const wordSearch = useRef('')

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }
  // TABLE CONTROL
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const pageRef = useRef(0)
  const avoidCallsRef = useRef(false)

  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      ;(async () => {
        const restaurants = await getRestaurants(
          `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
          0,
          0,
          wordSearch.current
        )
        const excelFormatted = restaurants?.restaurants?.map((restaurant) => {
          return {
            id: restaurant._id,
            [labels[lng].name]: restaurant.name,
            [labels[lng].address]: restaurant.location?.street,
            Latitud: restaurant.location?.position.coordinates[0],
            Longitud: restaurant.location?.position.coordinates[1],
            Zona: restaurant.location?.neighborhood || '',
            [labels[lng].email]: restaurant.email,
            [labels[lng].privacity]: restaurant.privacity?.special_offers_and_promotions,
            [labels[lng].createdAt]: dateTimeConvert(restaurant.createdAt || ''),
            'Fecha de baja': restaurant.status === 'DELETED' ? dateTimeConvert(restaurant.updatedAt || '') : ''
          }
        })
        setExportData(excelFormatted)
        setExportName('Restaurantes')
      })()
    }
    return () => setExportData(null)
  }, [rowsData])

  const fetchRestaurantList = async () => {
    if (avoidCallsRef.current) return
    avoidCallsRef.current = true
    setLoadingTable(true)
    try {
      const resp = await getRestaurants(
        `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
        rowsPerPage,
        pageRef.current,
        wordSearch.current
      )
      setTotalCount(resp.total)
      setRowsData(resp.restaurants)
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
      avoidCallsRef.current = false
    }
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchRestaurantList()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageRef.current = 0
  }
  const handleSearch = async (word: string) => {
    wordSearch.current = word
    if (!word) {
      fetchRestaurantList()
      return
    }
    const resp = await getRestaurants(
      `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
      rowsPerPage,
      0,
      word
    )
    pageRef.current = 0
    setTotalCount(resp.total)
    setRowsData(resp.restaurants)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between ">
        <Box sx={{ width: { sm: 350 } }}>
          <SearchInput placeholder={labels[lng].searchRestaurant} name="search" handleSubmit={handleSearch} clean />
        </Box>
        <Button size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModal(true)}>
          {labels[lng].addRestaurant}
        </Button>
      </div>
    )
  }

  useEffect(() => {
    if (userData) fetchRestaurantList()
  }, [rowsPerPage, order, orderBy])

  useEffect(() => {
    if (initHeader) showHeader(Header)
    // return () => setInitHeader(false)
  }, [initHeader])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].restaurants, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, disabledRestaurants)
          .then(() => {
            showSnackbar('success', labels[lng].restaurantDeleted)
            fetchRestaurantList()
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = async (data: Record<string, any>) => {
    const resp = await getRestaurantById(data._id)
    setEditData(resp)
    setOpenModal(true)
  }

  const onDeactivate = async (data: Record<string, string>) => {
    const status = data.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    const label = data.status === 'ACTIVE' ? labels[lng].restaurantDeactivated : labels[lng].restaurantActivated
    try {
      await updateRestaurant({ status, name: data.name }, data._id)
      showSnackbar('success', label)
      fetchRestaurantList()
      closeModal()
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onDeactivateAll = async () => {
    if (selected.length === 0) return
    try {
      await deactiveRestaurants(selected)
      fetchRestaurantList()
      setSelected([])
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onActivateAll = async () => {
    if (selected.length === 0) return
    try {
      await activeRestaurants(selected)
      fetchRestaurantList()
      setSelected([])
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onDownloadQr = async (selectedIds: string[]) => {
    const files: any = []
    selectedIds.map((restaurantId) => {
      const restaurantData = rowsData.find((restaurant: any) => restaurantId === restaurant._id)

      if (restaurantData.qrCode) {
        const convertBase64ToFile = (base64String: any, fileName: any) => {
          const arr = base64String.split(',')
          const mime = arr[0].match(/:(.*?);/)[1]
          const bstr = atob(arr[1])
          let n = bstr.length
          const uint8Array = new Uint8Array(n)
          while (n--) {
            uint8Array[n] = bstr.charCodeAt(n)
          }
          const file = new File([uint8Array], fileName, { type: mime })
          return file
        }

        const file = convertBase64ToFile(restaurantData.qrCode, restaurantData.name)
        // saveAs(file, restaurantData.name)
        files.push(file)
      }
      return ''
    })
    const content = await downloadZip(files).blob()
    saveAs(content, 'qr-codes.zip')
  }

  return (
    <>
      <TableApi
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rows={rowsData}
        headCells={headCells}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        onDeactivate={onDeactivate}
        loading={loadingTable}
        onRowClick={(row) => history.push(`/restaurantes/${row._id}`)}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
        page={pageRef.current}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        onExport={onDownloadQr}
        selected={selected}
        setSelected={setSelected}
        onDeactivateAll={onDeactivateAll}
        onActivateAll={onActivateAll}
        checkRestaurant
      />
      {openModal && (
        <RestaurantesAddEdit
          openModal={openModal}
          closeModal={closeModal}
          dataToEdit={editData}
          onDelete={onDelete}
          fetchData={fetchRestaurantList}
        />
      )}
    </>
  )
}
