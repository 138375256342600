import React, { useState, useContext } from 'react'
import Button from '@mui/material/Button'

import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { Dialog, DialogContent, DialogActions } from '@mui/material'
import { labels } from 'src/labels/main_labels'
import { AlertContext } from 'src/context/alert/alertContext'
import { updateUserAdmin } from 'src/services/users.services'
import { UserTypes } from 'src/interfaces/pages.types'
import { genderOptionsSelect } from 'src/constants/const'
import { useTranslation } from 'react-i18next'
import { ModalTitle } from 'src/components/Modals/modals.styles'

interface EditGenderProps {
  open: boolean
  handleClose: () => void
  genderPrev?: string
  id: string
  user: UserTypes
  setUser: React.Dispatch<React.SetStateAction<UserTypes | null>>
}

export const EditGender = ({ open, handleClose, genderPrev, id, user, setUser }: EditGenderProps): JSX.Element => {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showSnackbar } = useContext(AlertContext)

  const [state, setState] = useState(genderPrev || '')

  const submit = async () => {
    try {
      await updateUserAdmin(state, id)
      setUser({ ...user, gender: state })
      showSnackbar('success', labels[lng].userUpdated)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      handleClose()
    }
  }

  const handleChange = (e: SelectChangeEvent<string>) => {
    setState(e.target.value)
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogContent sx={{ pb: 0 }}>
        <ModalTitle sx={{ textAlign: 'center', mb: 2, display: 'block' }}>{labels[lng].genderEditing}</ModalTitle>
        <FormControl
          size="small"
          variant="outlined"
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            '& .MuiOutlinedInput-notchedOutline': { display: 'none' }
          }}
        >
          <Select value={state} name="gender" onChange={handleChange}>
            {genderOptionsSelect.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={!state} type="submit" fullWidth variant="contained" sx={{ mx: 2, mb: 2 }} onClick={submit}>
          {labels[lng].save}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
