import React, { FC } from 'react'
import { BoxProps, Button } from '@mui/material'
import { EmptyPageBox } from './EmptyPage.styles'
import { labels } from 'src/labels/main_labels'
import EmptyHead from './EmptyHead'
import { useTranslation } from 'react-i18next'
import { HEADER_BAR_HEIGHT } from 'src/constants/const'

interface EmptyPageProps {
  title: string
  text: string
  Icon: FC
  children?: React.ReactNode
  button?: string
  small?: boolean
}

export default function EmptyPage({ Icon, title, text, children, button, small, ...props }: EmptyPageProps & BoxProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <EmptyPageBox height={small ? `calc(100vh - ${HEADER_BAR_HEIGHT} - 32px)` : ''} {...props}>
      <EmptyHead Icon={Icon} title={title} text={text} />
      {button && (
        <Button
          sx={{ backgroundColor: '#fff', mt: '12px', textTransform: 'inherit' }}
          size="small"
          type="submit"
          color="info"
          variant="outlined"
          onClick={() => window.open(`mailto:ayuda@fudclub.com?subject=${button}`, '_self')}
        >
          {labels[lng].contactFudclub} ↗
        </Button>
      )}
      {children}
    </EmptyPageBox>
  )
}
