/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { ReactComponent as Marker } from 'src/assets/icons/map/Marker.svg'
// import { grey } from '@mui/material/colors'
// import Places from 'src/components/Places/Autocomplete'

function createMapOptions(maps: any) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    fullscreenControl: false
  }
}

const AnyReactComponent = ({ text }: any) => <Marker style={{ transform: 'scaleX(-1)' }} />

interface Props {
  address: any
  type: any
}

const SimpleMap = (props: Props): JSX.Element => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 })
  const [zoom, setZoom] = useState(15)
  // const [geoLocation, setGeoLocation] = useState<any>(null)

  useEffect(() => {
    if (props.address) {
      setCenter({ lat: props.address?.position.coordinates[0], lng: props.address.position.coordinates[1] })
    }
  }, [props])
  return (
    <>
      {/* <div style={{ width: '90%', margin: 'auto', marginTop: 10, marginBottom: 10, overflow: 'hidden', zIndex: 9999 }}>
        <Places setGeoLocation={setGeoLocation} />
      </div> */}
      <div style={{ height: '100%', width: '100%', margin: 'auto', overflow: 'hidden', borderRadius: 8 }}>
        <GoogleMapReact
          draggable={false}
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}` }}
          // defaultCenter={{ lat: 0, lng: 0 }}
          defaultZoom={zoom}
          center={center}
          options={createMapOptions}
        >
          <AnyReactComponent lat={center.lat} lng={center.lng} />
        </GoogleMapReact>
      </div>
      {/* <div style={{ width: '90%', margin: 'auto', marginTop: 10, marginBottom: 10, overflow: 'hidden', color: '#9CA3AF' }}>
        <span style={{ fontSize: 14 }}>
          {' '}
          {geoLocation
            ? geoLocation.formatted_address
            : !!props.address && props.address.street + ' ' + props.address.number + ',' + props.address.cp}
        </span>
      </div> */}
    </>
  )
}

export default SimpleMap
