import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { EmptyPageText } from './EmptyPage.styles'
import { theme } from 'src/styles/theme'

interface EmptyHeadProps {
  title: string
  text: string
  Icon: FC
}

export default function EmptyHead({ Icon, title, text }: EmptyHeadProps): JSX.Element {
  return (
    <>
      <Icon />
      <Typography
        sx={{
          mt: '20px',
          color: '#6B7280',
          maxWidth: '500px !important',
          [theme.breakpoints.down('md')]: {
            fontSize: '18px',
            lineHeight: '28px'
          }
        }}
        variant="h6"
        component="h2"
      >
        {title}
      </Typography>
      <EmptyPageText
        variant="subtitle2"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '24px'
          }
        }}
      >
        {text}
      </EmptyPageText>
    </>
  )
}
