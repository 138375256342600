import { VoucherTypes } from 'src/interfaces/pages.types'
import axiosInstance from './axiosInstance'

interface VoucherTypesApi {
  bonos: VoucherTypes[]
  total: number
}

export const getVouchersAdmin = async (
  order: string,
  limit: number,
  offset: number,
  searchString?: string,
  filter?: string
): Promise<VoucherTypesApi> => {
  const pagination = limit ? `${order}&limit=${limit}&offset=${offset || 0}` : ''
  const query = searchString ? `?search=${searchString}&${pagination}` : `?${pagination}`
  const filterQuery = filter ? `&type=${filter}` : ''

  return axiosInstance
    .get(`/bono/admin/${query}${filterQuery}`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de bonos')
    })
}

export const getVouchersUser = async (): Promise<VoucherTypes[]> => {
  return axiosInstance
    .get(`/bono/`)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba cargar la lista de bonos')
    })
}

export const createVoucher = async (voucher: VoucherTypes): Promise<void> => {
  return axiosInstance
    .post('/bono/create', voucher)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba crear el bono')
    })
}

export const updateVoucher = async (id: string, voucher: VoucherTypes): Promise<string> => {
  return axiosInstance
    .put(`/bono/${id}`, voucher)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba actualizar el bono')
    })
}

export const addVoucherToUsers = async (bonoId: string, users: string[]): Promise<string> => {
  return axiosInstance
    .put(`/bono/assigned`, { bono: bonoId, users })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba agregar el bono al usuario')
    })
}

export const removeVoucherToUsers = async (bonoId: string, users: string[]): Promise<string> => {
  return axiosInstance
    .put(`/bono/deleted`, { bono: bonoId, users })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba retirar el bono al usuario')
    })
}
