import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BannerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '52px',
  borderLeft: '3px solid #FBBF24',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  backgroundColor: '#FFFBEB',
  display: 'flex',
  alignItems: 'center',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  '& .MuiTypography-root': {
    color: '#92400E',
    marginLeft: theme.spacing(1)
  }
}))
