import React, { FC } from 'react'
import { ReactComponent as Logo } from 'src/assets/icons/general/LogoFudclub-big.svg'
import { Box } from '@mui/system'
import Spinner from '../Spinner'

const LoadingApp: FC = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <Logo />
      <Spinner />
      {children}
    </Box>
  )
}

export default LoadingApp
