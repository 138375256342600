import React, { useState, ChangeEvent, useContext } from 'react'
import Swal from 'sweetalert2'
import { Button, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { BoxGrey, AvatarResumen, ContainerFud } from 'src/styles/general-styles'

import NotificationsPrivacityCards from 'src/components/NotificationsPrivacityCards'
import { CardSummaryGrid } from 'src/components/Cards/cards.styles'
import CardBigIconTitleText from 'src/components/Cards/CardBigIconTitleText'
import RestaurantBasicInformation from 'src/components/RestaurantBasicInformation'
import RestaurantesAddEdit from '../Restaurantes/RestaurantesAddEdit'

import { labels } from 'src/labels/main_labels'
import deleteElements from 'src/services/deleteElements'
import { deleteRestaurant, toggleNotificationsById } from 'src/services/restaurants.services'
import { AlertContext } from 'src/context/alert/alertContext'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import { ReactComponent as Mpod } from 'src/assets/icons/general/Mpod.svg'
import { ReactComponent as Clock } from 'src/assets/icons/general/Clock.svg'

import { RestaurantTypes } from 'src/interfaces/pages.types'
import { useTranslation } from 'react-i18next'

interface RestaurantDetailProp {
  restaurant: RestaurantTypes
  photo: string
  fetchData: () => void
}

const INITIAL_NOTIFICATIONS = {
  dish_of_the_day_reminder: true,
  orders_and_cancellations: true,
  order_picking: true,
  news: true
}

const INITIAL_PRIVACITY = { new_orders_and_cancellations: true, special_offers_and_promotions: true }

export default function RestaurantesDetailResumen({ restaurant, photo, fetchData }: RestaurantDetailProp): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showSnackbar } = useContext(AlertContext)
  const history = useHistory()

  const [switchPrivacity, setSwitchPrivacity] = useState(restaurant.privacity || INITIAL_PRIVACITY)
  const [loadingSwitchs, setLoadingSwitchs] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
  }

  const handleSwitch = async (event: ChangeEvent<HTMLInputElement>, name: string, type: string) => {
    if (loadingSwitchs) return
    const newValue = event.target.checked
    setLoadingSwitchs(true)
    if (!restaurant._id) return
    setSwitchPrivacity({ ...switchPrivacity, [name]: newValue })
    try {
      const resp = await toggleNotificationsById(type, name, restaurant._id)
      if (resp === 'OK' && type === 'privacity') {
        showSnackbar('success', labels[lng].changeCompleted)
      } else showSnackbar('error', labels[lng].hasBeenAnError)
    } catch (error) {
      setSwitchPrivacity({ ...switchPrivacity, [name]: !newValue })
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingSwitchs(false)
    }
  }

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].restaurants, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, deleteRestaurant)
          .then(() => {
            showSnackbar('success', labels[lng].restaurantDeleted)
            closeModal()
            history.push('/restaurantes')
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const getParameter = (type: 'MPOD' | 'EMHIRR' | 'FRR') => {
    if (restaurant && restaurant.parameter) {
      if (type === 'MPOD') return restaurant.parameter.MPOD.value
      else {
        return `${String(restaurant.parameter[type].hour).padStart(2, '0')}:${String(restaurant.parameter[type].minutes).padStart(2, '0')}`
      }
    }
    return '-'
  }

  return (
    <>
      <BoxGrey className="flex-between" sx={{ py: 1.5, px: 2 }}>
        <div className="flex-center">
          <AvatarResumen sx={{ ml: 1 }} src={photo} alt={restaurant.name} />

          <Typography sx={{ ml: 2 }} variant="h2" component="h2">
            {restaurant.name}
          </Typography>
        </div>
        <Button
          sx={{ backgroundColor: '#fff' }}
          size="small"
          type="submit"
          color="info"
          variant="outlined"
          onClick={() => setOpenModal(true)}
          disabled={restaurant.status === 'DELETED'}
        >
          {labels[lng].editRestaurant}
        </Button>
      </BoxGrey>
      <ContainerFud>
        <RestaurantBasicInformation restaurant={restaurant} neightbor closeMap={openModal} />
        <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
          {labels[lng].parameters}
        </Typography>
        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <CardSummaryGrid item sm={6} xs={12} lg={4}>
            <CardBigIconTitleText
              Icon={Mpod}
              title="MPOD"
              text={getParameter('MPOD')}
              finalText={restaurant?.parameter?.MPOD?.default ? `(${labels[lng].byDefault})` : ''}
            />
          </CardSummaryGrid>
          <CardSummaryGrid item sm={6} xs={12} lg={4}>
            <CardBigIconTitleText
              Icon={Clock}
              title={labels[lng].summaryEMHIRR}
              text={getParameter('EMHIRR')}
              finalText={restaurant?.parameter?.EMHIRR?.default ? `(${labels[lng].byDefault})` : ''}
            />
          </CardSummaryGrid>
          <CardSummaryGrid item sm={12} xs={12} lg={4}>
            <CardBigIconTitleText
              Icon={Clock}
              title={labels[lng].summaryFRR}
              text={getParameter('FRR')}
              finalText={restaurant?.parameter?.FRR?.default ? `(${labels[lng].byDefault})` : ''}
            />
          </CardSummaryGrid>
        </Grid>
        <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
          {labels[lng].permissions}
        </Typography>
        <NotificationsPrivacityCards
          handleSwitch={handleSwitch}
          switchPrivacity={switchPrivacity}
          switchNotifications={restaurant.notifications || INITIAL_NOTIFICATIONS}
        />
      </ContainerFud>
      {openModal && (
        <RestaurantesAddEdit
          openModal={openModal}
          closeModal={closeModal}
          dataToEdit={{ ...restaurant }}
          onDelete={onDelete}
          fetchData={fetchData}
        />
      )}
    </>
  )
}
