import React, { useState, useContext, useEffect } from 'react'

import { labels } from 'src/labels/main_labels'
import Table from 'src/components/Table/Table'
import { ReactComponent as Recibido } from 'src/assets/icons/general/Recibido.svg'
import { ReactComponent as Enviado } from 'src/assets/icons/general/Enviado.svg'
// import { ReactComponent as Caducado } from 'src/assets/icons/general/Caducado.svg'
import { ReactComponent as Empty } from 'src/assets/icons/nav-icons/Planificar.svg'
import FiltersFooter from 'src/components/Table/FiltersFooter'
import { getUserBookingsGifts } from 'src/services/users.services'
import { AlertContext } from 'src/context/alert/alertContext'
import BonosAddEdit from '../../Bonos/BonosAddEdit'
import { useTranslation } from 'react-i18next'

interface UsuariosDetailPlatosRegaladosProps {
  userId: string
}

export default function UsuariosDetailPlatosRegalados({ userId }: UsuariosDetailPlatosRegaladosProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const [openModal, setOpenModal] = useState(false)
  const [voucherPreview, setVoucherPreview] = useState(null)

  useEffect(() => {
    if (voucherPreview) setOpenModal(true)
    else setOpenModal(false)
  }, [voucherPreview])

  const headCells: any[] = [
    {
      idField: 'type',
      align: 'left',
      disablePadding: false,
      label: labels[lng].type,
      fieldtype: 'user-gift-type'
    },
    {
      idField: 'userEmail',
      align: 'center',
      disablePadding: true,
      label: labels[lng].user,
      fieldtype: 'string',
      width: 220
    },
    {
      idField: 'price',
      align: 'center',
      disablePadding: true,
      label: labels[lng].price,
      fieldtype: 'voucher-gift-price'
    },
    {
      idField: 'voucher',
      align: 'center',
      disablePadding: false,
      label: labels[lng].voucher,
      fieldtype: 'user-gift-voucher',
      open: (bono: any) => setVoucherPreview(bono)
    },
    {
      idField: 'dateGift',
      align: 'center',
      disablePadding: false,
      label: labels[lng].giftDate,
      fieldtype: 'date-time'
    }
  ]

  const { showSnackbar } = useContext(AlertContext)

  // TABLE CONTROL
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [data, setData] = useState<any | null>(null)

  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filter, setFilter] = useState<string>('')

  const fetchList = async () => {
    setLoadingTable(true)
    try {
      const bookings = (await getUserBookingsGifts(userId))?.map((elm, index) => {
        if (elm.id_bono.company) {
          elm.id_bono.giftQuantity = elm.companyConfig.giftQuantity
          elm.id_bono.quantity = elm.companyConfig.quantity
          elm.id_bono.expiration = elm.companyConfig.expires
          elm.id_bono.price = true
        }
        return { ...elm, _id: Math.random() + index }
      })
      setRowsData(bookings)
      setData(bookings)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  useEffect(() => {
    if (data && filter) {
      const newData = [...data].filter((elm: any) => elm.type === filter)
      setRowsData(newData)
    }
    if (data && !filter) setRowsData(data)
  }, [filter])

  const filterOptions = [
    { value: '', text: labels[lng].noFilters, Icon: Empty },
    { value: 'send', text: labels[lng].sent, Icon: Enviado },
    { value: 'received', text: labels[lng].received, Icon: Recibido }
    // { value: 'expired', text: labels[lng].expired, Icon: Caducado }
  ]

  return (
    <>
      <Table
        rows={rowsData}
        headCells={headCells}
        snackBar
        selected={selected}
        setSelected={setSelected}
        noActions
        noCheckbox
        initialOrderBy="dateGift"
        initialOrder="desc"
        loading={loadingTable}
        filters={<FiltersFooter filter={filter} setFilter={setFilter} options={filterOptions} />}
      />
      {openModal && (
        <BonosAddEdit
          disabled
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false)
            setVoucherPreview(null)
          }}
          dataToEdit={voucherPreview}
        />
      )}
    </>
  )
}
