import { Box, styled } from '@mui/material'

export const HeadBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F9FAFB',
  borderRadius: '8px',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  marginBottom: theme.spacing(2),
  '& .MuiTypography-root': {
    textAlign: 'center',
    marginLeft: theme.spacing(2)
  },
  [theme.breakpoints.down('md')]: {
    padding: '12px 16px',
    '& .MuiTypography-root': {
      textAlign: 'left',
      marginLeft: theme.spacing(1)
    },
    '& svg': {
      flexShrink: 0
    }
  }
}))
