import { PlateTypes } from 'src/interfaces/pages.types'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const translatePlate = (plates: PlateTypes[], language?: string) => {
  return plates.map((row) => {
    if (language === 'es') return row
    else {
      const name = row.translations?.find((row) => row.language === language)?.name || row.name
      return { ...row, name }
    }
  })
}
