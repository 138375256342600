import { labels } from 'src/labels/main_labels'
import { daysOfTheWeek } from 'src/constants/const'

export const getWeekLabel = (day: string, lng: string, idx?: number): string => {
  if (idx === 0) return `${labels[lng].today}, ${labels[lng][day]}`
  if (idx === 1) return `${labels[lng].tomorrow}, ${labels[lng][day]}`
  return labels[lng][day]
}

export const configWeekDays = (): string[] => {
  const date = new Date()
  const firstDay = date.toLocaleDateString('en-EN', { weekday: 'long' })
  return [...daysOfTheWeek].splice(daysOfTheWeek.indexOf(firstDay), 7)
}
