import React, { useState, useEffect, useContext } from 'react'
import { AlertContext } from 'src/context/alert/alertContext'
import ImagePicker from 'src/components/ImagePicker'
import FormTitle from 'src/components/Form/FormTitle'
import TextInput from 'src/components/Form/TextInput'
import ChipSelector from 'src/components/Form/ChipSelector'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from 'src/interfaces/pages.types'
import { createFoodStyle, updateFoodStyle } from 'src/services/foodStyles.services'
import { useForm } from 'src/hooks/useForm'

import Box from '@mui/material/Box'
import { StylesFoodValues } from 'src/constants/const'
import { labels } from 'src/labels/main_labels'
import { styleFoodLabels } from 'src/labels/styleFood_labels'
import { useTranslation } from 'react-i18next'

const INITIAL_VALUES = { name: '' }

export default function EstilosAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const [cousines, setCousines] = useState<string[] | never[]>([])
  const [loading, setLoading] = useState(false)
  const { handleChange, values, translations, setPhoto, photo, resetForm } = useForm(INITIAL_VALUES, dataToEdit, ['name'])

  const resetComponent = () => {
    setCousines([])
    setLoading(false)
    resetForm()
    setPhoto(null)
  }

  useEffect(() => {
    if (dataToEdit) setCousines(dataToEdit.cousines)
    return () => resetComponent()
  }, [dataToEdit])

  const onSave = async () => {
    setLoading(true)
    const newFoodStyle = {
      ...values,
      cousines,
      translations
    }
    newFoodStyle.photo = photo

    if (!dataToEdit) {
      try {
        await createFoodStyle(newFoodStyle)
        showSnackbar('success', labels[lng].styleCreated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    } else {
      delete newFoodStyle.createdAt
      delete newFoodStyle.updatedAt
      delete newFoodStyle.numberOfPlates

      try {
        await updateFoodStyle(newFoodStyle, dataToEdit._id)
        showSnackbar('success', labels[lng].styleUpdated)
        if (fetchData) fetchData()
        resetComponent()
        closeModal()
      } catch (error) {
        if (error instanceof Error) showSnackbar('error', error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit._id)
  }

  return (
    <Modal
      title={editing ? dataToEdit.name : labels[lng].addStyle}
      open={openModal}
      handleClose={() => {
        resetComponent()
        closeModal()
      }}
      footer={
        <FooterForm
          text={labels[lng].style}
          closeModal={() => {
            resetComponent()
            closeModal()
          }}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={!values?.name || !translations[0]?.name || cousines?.length === 0}
        />
      }
    >
      <Box id="form-estilos" component="form" noValidate>
        <ImagePicker setImgData={setPhoto} imgData={values.photo} actualImage={editing ? values.photo : null} />
        <FormTitle text={labels[lng].information} />
        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          id="name"
          text={`${labels[lng].nameStyle} (ES)`}
          placeholder={`${labels[lng].nameStyle} (ES)`}
        />
        {translations.map((opt: { language: string; name: string | number }, idx: number) => (
          <TextInput
            required
            key={`${opt.language}-${idx}`}
            value={opt.name}
            handleChange={(e) => handleChange(e, opt)}
            name="name"
            text={`${labels[lng].nameStyle} (${opt.language.toUpperCase()})`}
            placeholder={`${labels[lng].nameStyle} (${opt.language.toUpperCase()})`}
          />
        ))}
        <ChipSelector
          required
          data={cousines}
          setData={setCousines}
          text={labels[lng].foodTypes}
          searchText={labels[lng].foodType}
          options={StylesFoodValues}
          labels={styleFoodLabels}
        />
      </Box>
    </Modal>
  )
}
