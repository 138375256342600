import React, { useState, SetStateAction, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as NoPicture } from './icons/NoPicture.svg'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined'
import { PrevImage, Picker, BigImage, ButtonIcon, PictureBox } from './imagePicker.styles'
import Modal from '../Modals/Modal'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

interface ImagePickerProps {
  setImgData: React.Dispatch<SetStateAction<any>>
  imgData: any
  actualImage?: string
  required?: boolean
  photoName?: string
}

export default function ImagePicker({ setImgData, imgData, actualImage, required, photoName }: ImagePickerProps): JSX.Element {
  const [picture, setPicture] = useState<string | null>(null)

  const clean = () => {
    setImgData(null)
    setPicture(null)
  }

  useEffect(() => {
    if (actualImage) setPicture(actualImage)
  }, [actualImage])

  const onChangePicture = (e: any) => {
    if (e.target.files[0]) {
      // setPicture(e.target.files[0])
      setImgData(e.target.files[0])
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        // Image Code 64
        setPicture(reader.result as string)
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }
  const [openPicture, setOpenPicture] = useState(false)
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  return (
    <Box sx={{ padding: 3, paddingTop: 2 }}>
      <Typography variant="subtitle2" component="h6">
        Foto {required ? '*' : ''}
      </Typography>
      <PictureBox>
        {picture && (
          <div style={{ position: 'absolute', width: 180, height: 180 }}>
            <ButtonIcon sx={{ left: 10 }} onClick={() => setOpenPicture(true)}>
              <OpenWithOutlinedIcon />
            </ButtonIcon>

            <ButtonIcon sx={{ right: 10 }} onClick={() => clean()}>
              <DeleteOutlineOutlinedIcon />
            </ButtonIcon>
          </div>
        )}
        <Picker>
          <input
            style={{ display: 'none' }}
            id="pictures"
            accept="image/png,image/jpeg"
            type="file"
            onChange={onChangePicture}
            onClick={(event: any) => {
              event.target.value = null
            }}
          />
          {picture && <PrevImage src={picture} />}
          <NoPicture />
        </Picker>
      </PictureBox>
      <Typography align="center" sx={{ color: '#9CA3AF' }} variant="body1" fontSize={12}>
        {labels[lng].imagePickerText}
      </Typography>
      <Modal title={photoName || 'Foto'} size="md" open={openPicture} handleClose={() => setOpenPicture(false)}>
        <BigImage src={picture || imgData} onClick={() => setOpenPicture(false)} />
      </Modal>
    </Box>
  )
}
