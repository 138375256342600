import React from 'react'
import { Grid, Skeleton } from '@mui/material'
import { Box } from '@mui/system'

interface SkeletonCardsProps {
  cards?: number
  width?: number
  height?: number
}

export default function SkeletonCards({ cards, width, height }: SkeletonCardsProps): JSX.Element {
  const skeletonCard = () => {
    const numberOfCards = cards || 8
    const arr: number[] = []
    for (let i = 0; i < numberOfCards; i++) {
      arr.push(i)
    }
    return arr
  }

  return (
    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {skeletonCard().map((elm: number) => (
        <Grid key={elm} item sm={6} xs={12} md={4} lg={3} xl={2}>
          <Box>
            <Skeleton
              variant="rectangular"
              sx={{
                maxWidth: width || 250,
                height: height || 310,
                boxSizing: 'border-box',
                borderRadius: '8px'
              }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
