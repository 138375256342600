/* global google */
import React from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import PlacesAutocomplete from 'react-places-autocomplete'
import 'src/styles/customStyles.css'
import vector from 'src/assets/icons/map/Marker_original.svg'
import { labels } from 'src/labels/main_labels'
import { Chip } from '@mui/material'

const trasformAddressComponentToAddress = (place) => {
  let cp
  let number
  let street
  let city

  place.address_components.forEach((component) => {
    if (component.types.includes('postal_code')) cp = component.long_name
    if (component.types.includes('street_number')) number = component.long_name
    if (component.types.includes('route')) street = component.long_name
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (component.types.includes('locality')) city = component.long_name
  })

  const newAddress = {
    cp,
    street
  }
  if (number && number !== 'undefined') newAddress.number = number
  return newAddress
}

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { address: '' }
    this.placesService = new window.google.maps.places.PlacesService(document.createElement('div'))
    this.handleChange = this.handleChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleChange = (address) => {
    this.setState({ address })
  }

  handleSelect = (address, placeId) => {
    this.setState({ address })

    const request = {
      placeId: placeId,
      fields: ['name', 'geometry', 'formatted_address', 'address_components']
    }
    this.placesService.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.props.onPlaceChanged(place)
        this.props.setAddress(trasformAddressComponentToAddress(place))
      }
    })
  }

  render() {
    return (
      <PlacesAutocomplete debounce={2000} value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="autocomplete-container">
            <input
              style={{
                borderColor: this.props.error ? '#EB435A' : '#D1D5DB',
                background: 'url(/img/map.svg) no-repeat 10px 9px'
              }}
              {...getInputProps({
                placeholder: labels.es.enterAddress,
                className: 'location-search-input'
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Cargando...</div>}
              {suggestions.map((suggestion, idx) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <div
                    key={idx}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

class MapContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = { places: [], numberError: '' }
    this.showPlace = this.showPlace.bind(this)
    this.mapRef = React.createRef()
  }

  showPlace(place) {
    this.setState({ places: [place] })
    this.mapRef.current.map.setCenter(place.geometry.location)
  }

  containerStyle = {
    position: 'sticky',
    width: '95%',
    height: '25vh',
    margin: 'auto',
    marginTop: '10px',
    marginBottom: '10px'
  }

  containerStyleShow = {
    width: '23%'
  }

  getText() {
    const address = this.state.places[0] ? trasformAddressComponentToAddress(this.state.places[0]) : this.props.address
    let text = address?.street || ''
    if (address && address.number && address.number !== 'undefined') text = text + ' ' + address.number
    if (address && address.cp) text = text + ' ' + address.cp
    return text
  }

  getError() {
    const address = this.state.places[0] ? trasformAddressComponentToAddress(this.state.places[0]) : this.props.address
    if ((address && address.number === 'undefined') || (address && !address.number)) return true
    else return false
  }

  render() {
    if (this.props.type !== 'simple') {
      return (
        <div className="map-container">
          <LocationSearchInput onPlaceChanged={this.showPlace} setAddress={this.props.setAddress} error={this.getError()} />

          <Map
            containerStyle={this.containerStyle}
            ref={this.mapRef}
            streetViewControl={false}
            mapTypeControl={false}
            scaleControl={false}
            fullscreenControl={false}
            zoomControl={false}
            draggable={false}
            google={this.props.google}
            className={'map'}
            zoom={15}
            initialCenter={
              this.props.address && this.props.address.position && this.props.address.position.coordinates
                ? { lat: this.props.address.position?.coordinates[0], lng: this.props.address.position?.coordinates[1] }
                : { lat: 40.4167278, lng: -3.7033387 }
            }
            // center={
            //   this.props.address && this.props.address.position.coordinates
            //     ? { lat: this.props.address.position.coordinates[0], lng: this.props.address.position.coordinates[1] }
            //     : { lat: 40.4167278, lng: -3.7033387 }
            // }
          >
            {this.props.address && (
              <Marker
                icon={{
                  url: vector
                  // anchor: new google.maps.Point(5, 58)
                }}
                position={
                  this.props.address && this.props.address.position?.coordinates
                    ? { lat: this.props.address.position?.coordinates[0], lng: this.props.address.position?.coordinates[1] }
                    : { lat: 40.4167278, lng: -3.7033387 }
                }
              />
            )}
            {this.state.places.map((place, i) => {
              return (
                <Marker
                  icon={{
                    url: vector
                    // anchor: new google.maps.Point(5, 58)
                  }}
                  key={i}
                  position={place.geometry.location}
                />
              )
            })}
          </Map>
          <div style={{ width: '95%', margin: 'auto', marginTop: 10, marginBottom: 10, overflow: 'hidden', color: '#9CA3AF' }}>
            <span style={{ fontSize: 14 }}>{this.getText()} </span>
            {this.getError() && (
              <Chip
                label="Es obligatorio introducir el número de calle"
                size="small"
                sx={{ backgroundColor: '#FEE2E2', color: '#991B1B' }}
              />
            )}
          </div>
        </div>
      )
    } else
      return (
        <div>
          <Map
            containerStyle={this.containerStyle}
            ref={this.mapRef}
            streetViewControl={false}
            mapTypeControl={false}
            scaleControl={false}
            fullscreenControl={false}
            zoomControl={false}
            draggable={false}
            google={this.props.google}
            className={'map'}
            zoom={15}
            initialCenter={
              this.props.address && this.props.address.position?.coordinates
                ? { lat: this.props.address.position.coordinates[0], lng: this.props.address.position.coordinates[1] }
                : { lat: 40.4167278, lng: -3.7033387 }
            }
          >
            {this.props.address && (
              <Marker
                icon={{
                  url: vector
                  // anchor: new google.maps.Point(5, 58)
                }}
                position={
                  this.props.address && this.props.address.position?.coordinates
                    ? { lat: this.props.address.position.coordinates[0], lng: this.props.address.position.coordinates[1] }
                    : { lat: 40.4167278, lng: -3.7033387 }
                }
              />
            )}
          </Map>
        </div>
      )
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places'],
  language: 'es'
})(MapContainer)
