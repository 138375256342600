import React from 'react'
import { Typography } from '@mui/material'
import { BoxGrey } from 'src/styles/general-styles'

interface FormTitleProps {
  text: string
  isSubtitle?: boolean
}

export default function FormTitle({ text, isSubtitle }: FormTitleProps): JSX.Element {
  return (
    <BoxGrey>
      <Typography variant="body2" component="h4" color={isSubtitle ? 'grey.500' : '#111827'} sx={{ textTransform: 'capitalize' }}>
        {text}
      </Typography>
    </BoxGrey>
  )
}
