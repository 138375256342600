import React, { SetStateAction } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { GridFlexBetween } from 'src/components/Form/form.styles'
import { MainBox } from './form.styles'
import { NoTextBox, OptionButton } from './buttonSelector.styles'
import { IconColor } from '../Layout/layout.styles'

interface ButtonSelectorProps {
  valueSelected: string | boolean
  setValue?: React.Dispatch<SetStateAction<any>>
  setCouponType?: React.Dispatch<SetStateAction<'normal' | 'special' | 'enterprise'>>
  setSpecial?: React.Dispatch<SetStateAction<boolean>>
  options: any[]
  text?: string
  required?: boolean
  disabled?: boolean
  noEditable?: boolean
  height?: string
  resetForm?: () => void
}

export default function ButtonSelector({
  valueSelected,
  setValue,
  setSpecial,
  setCouponType,
  options,
  text,
  required,
  disabled,
  noEditable,
  height,
  resetForm
}: ButtonSelectorProps): JSX.Element {
  const OptionsSelector = (): JSX.Element => {
    return (
      <>
        {options.map((elm: any) => {
          const active = valueSelected === elm.value
          return (
            <OptionButton
              startIcon={
                elm.Icon ? (
                  <IconColor style={{ height: '20px', lineHeight: 1 }} className={active ? 'stroke' : ''}>
                    {' '}
                    <elm.Icon />
                  </IconColor>
                ) : null
              }
              key={elm.value}
              sx={{
                backgroundColor: (theme) => (active ? theme.palette.grey[200] : '#fff')
              }}
              disableElevation
              variant="contained"
              onClick={() => {
                resetForm && resetForm()
                if (setValue) setValue(elm.value)
                if (setSpecial) setSpecial(elm.value !== 'normal')
                if (setCouponType) setCouponType(elm.value)
              }}
              disabled={disabled || false}
              style={{ color: active ? '#374151' : '#6B7280', alignItems: 'center' }}
            >
              {elm.text}
            </OptionButton>
          )
        })}
      </>
    )
  }
  const OptionNoEditable = () => {
    const optionSelected = options.find((elm) => {
      return elm.value === valueSelected
    })
    return (
      <Button sx={{ color: '#6B7280' }} disableElevation variant="text" startIcon={optionSelected.Icon ? <optionSelected.Icon /> : null}>
        {optionSelected.text}
      </Button>
    )
  }

  if (text)
    return (
      <MainBox sx={{ height: height, py: height ? 0 : 2 }}>
        <GridFlexBetween container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle2" component="h6">
              {text} {required ? '*' : ''}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {noEditable ? <OptionNoEditable /> : <OptionsSelector />}
          </Grid>
        </GridFlexBetween>
      </MainBox>
    )
  return (
    <NoTextBox>
      <OptionsSelector />
    </NoTextBox>
  )
}
