import React, { useContext, useEffect, useState, useRef } from 'react'
import { ReactComponent as Enterprise } from 'src/assets/icons/general/Briefcase.svg'
import SearchInput from 'src/components/SearchInput'
import { Button } from '@mui/material'
import { labels } from 'src/labels/main_labels'
import { AlertContext } from 'src/context/alert/alertContext'
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'

import TableApi from 'src/components/Table/TableApi'
import FiltersFooter from 'src/components/Table/FiltersFooter'
import BonosAddEdit from './BonosAddEdit'
import { Order } from 'src/interfaces/pages.types'
import { getVouchersAdmin } from 'src/services/voucher.services'
import { ReactComponent as Normal } from 'src/assets/icons/general/Normal.svg'
import { ReactComponent as Especial } from 'src/assets/icons/general/Especial.svg'
import { ReactComponent as Empty } from 'src/assets/icons/nav-icons/Planificar.svg'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { useTranslation } from 'react-i18next'

export default function Bonos(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { showHeader, setExportData, setExportName } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].voucher,
      order: true,
      fieldtype: 'voucher-name'
    },
    {
      idField: 'quantity',
      align: 'center',
      disablePadding: true,
      label: labels[lng].platesNumber,
      fieldtype: 'bonosNumber',
      width: 122
    },
    {
      idField: 'expiration',
      align: 'center',
      disablePadding: true,
      label: labels[lng].expirationVoucher,
      fieldtype: 'bonosNumber',
      width: 120
    },
    {
      idField: 'giftQuantity',
      align: 'center',
      disablePadding: true,
      label: labels[lng].giftQuantity,
      fieldtype: 'bonosNumber',
      width: 153
    },
    {
      idField: 'totalPrice',
      align: 'center',
      disablePadding: false,
      label: labels[lng].price,
      fieldtype: 'money'
    },
    {
      idField: 'createdAt',
      align: 'center',
      disablePadding: false,
      label: labels[lng].createdAt,
      fieldtype: 'date-time',
      order: true
    }
  ]

  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const wordSearch = useRef('')

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }
  // TABLE CONTROL
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [filter, setFilter] = useState<string>('')

  // EXPORT EXCEL

  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      ;(async () => {
        const vouchers = await getVouchersAdmin(
          `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
          0,
          0,
          wordSearch.current,
          filter
        )
        const excelFormatted = vouchers?.bonos.map((voucher) => {
          return {
            id: voucher._id,
            Precio: voucher.totalPrice?.toFixed(2).replace(',', '.') + ' €' || '',
            'Nº de Platos': voucher.quantity || 0,
            'Validez(Dias)': voucher.expiration || 0,
            'Max. Platos Regalo': voucher.giftQuantity || 0,
            'Fecha de Creación': voucher.createdAt && dateTimeConvert(voucher.createdAt),
            Nombre: voucher.name || ''
          }
        })
        setExportData(excelFormatted)
        setExportName('Bonos')
      })()
    }
    return () => setExportData(null)
  }, [rowsData])

  const pageRef = useRef(0)

  const fetchVoucherList = async () => {
    setLoadingTable(true)
    try {
      const resp = await getVouchersAdmin(
        `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
        rowsPerPage,
        pageRef.current,
        wordSearch.current,
        filter
      )
      setTotalCount(resp.total)
      setRowsData(resp.bonos)
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchVoucherList()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageRef.current = 0
  }
  const handleSearch = async (word: string) => {
    wordSearch.current = word
    if (!word) {
      fetchVoucherList()
      return
    }
    const resp = await getVouchersAdmin(
      `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
      rowsPerPage,
      0,
      word,
      filter
    )
    pageRef.current = 0
    setTotalCount(resp.total)
    setRowsData(resp.bonos)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between ">
        <SearchInput placeholder={labels[lng].searchVoucher} name="search" handleSubmit={handleSearch} clean />
        <Button sx={{ ml: 2 }} size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModal(true)}>
          {labels[lng].addVoucher}
        </Button>
      </div>
    )
  }

  useEffect(() => {
    if (filter) pageRef.current = 0
    if (userData) fetchVoucherList()
  }, [rowsPerPage, order, orderBy, filter])

  useEffect(() => {
    if (initHeader) showHeader(Header)
    // return () => setInitHeader(false)
  }, [initHeader])

  const onEdit = (data: Record<string, unknown>) => {
    setEditData(data)
    setOpenModal(true)
  }

  const filterOptions = [
    { value: '', text: labels[lng].noFilters, Icon: Empty },
    { value: 'normal', text: labels[lng].normal, Icon: Normal },
    { value: 'special', text: labels[lng].special, Icon: Especial },
    { value: 'company', text: labels[lng].enterprise, Icon: Enterprise }
  ]

  return (
    <>
      <TableApi
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rows={rowsData}
        headCells={headCells}
        snackBar
        onEdit={onEdit}
        loading={loadingTable}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
        page={pageRef.current}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        selected={selected}
        setSelected={setSelected}
        filters={<FiltersFooter filter={filter} setFilter={setFilter} options={filterOptions} />}
        noCheckbox
      />
      <BonosAddEdit openModal={openModal} closeModal={closeModal} dataToEdit={editData} fetchData={fetchVoucherList} />
    </>
  )
}
