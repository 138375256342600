import React, { useState, useEffect, useContext } from 'react'
import EmptyPage from 'src/components/EmptyPage'
import DynamicTable from 'src/components/Table/Table'
import { labels } from 'src/labels/main_labels'
import { ReactComponent as PedidosEmpty } from 'src/assets/icons/Empty/PedidosEmpty.svg'
import { getReviewByPlate } from 'src/services/plates.services'
import { PlateReviewTypes } from 'src/interfaces/pages.types'
import { AlertContext } from 'src/context/alert/alertContext'
import { LinearProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ValoracionesProps {
  platoId: string
}

export default function Valoraciones({ platoId }: ValoracionesProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showSnackbar } = useContext(AlertContext)

  const headCells: any[] = [
    {
      idField: 'user',
      align: 'left',
      disablePadding: true,
      label: labels[lng].client,
      order: true,
      fieldtype: 'name-valoration'
    },
    {
      idField: 'quality',
      align: 'center',
      disablePadding: true,
      label: labels[lng].ratingQuality,
      fieldtype: 'rating'
    },
    {
      idField: 'amount',
      align: 'center',
      disablePadding: true,
      label: labels[lng].ratingQuantity,
      fieldtype: 'rating'
    },
    {
      idField: 'updatedAt',
      align: 'center',
      disablePadding: true,
      label: labels[lng].pickupValuation,
      fieldtype: 'date-time'
    }
  ]

  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [ratings, setRatings] = useState<PlateReviewTypes[] | null>(null)

  const fetchRatings = async () => {
    setLoadingTable(true)
    try {
      const reviews = await getReviewByPlate(platoId)
      setRatings(reviews)
    } catch (error) {
      setRatings([])
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    fetchRatings()
  }, [])

  if (!ratings) return <LinearProgress />

  return (
    <>
      {ratings && ratings.length > 0 ? (
        <DynamicTable
          rows={ratings}
          headCells={headCells}
          snackBar
          loading={loadingTable}
          noActions
          selected={selected}
          setSelected={setSelected}
          noCheckbox
          initialOrder="desc"
          initialOrderBy="updatedAt"
        />
      ) : (
        <EmptyPage title={labels[lng].emptyRatingsTitle} text={labels[lng].emptyRatingsText} Icon={PedidosEmpty} />
      )}
    </>
  )
}
