import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import { Button } from '@mui/material'

export const StatiticsFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputBase-root': { height: 38, borderRadius: '6px', paddingLeft: '38px', marginRight: theme.spacing(3) },
  '& .MuiInputBase-input': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter'
  },
  '& .MuiOutlinedInput-root': {
    '> div': {
      marginRight: '8px'
    },
    '& svg': {
      position: 'absolute',
      top: '16px',
      right: '16px'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D1D5DB !important',
    borderWidth: '1px !important',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    }
  },
  '> .MuiBox-root': {
    position: 'absolute',
    top: '8px',
    left: '19.5px'
  }
}))
export const StatiticsButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 38,
  // width: 251,
  border: '1px solid #D1D5DB',
  borderRadius: '6px',
  padding: '0px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  position: 'relative',
  cursor: 'pointer',
  marginRight: theme.spacing(3),
  '> .chevron-position': {
    position: 'absolute',
    top: '5px',
    right: '18px'
  }
}))
