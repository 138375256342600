/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as XLSX from 'xlsx'
import {
  bonoPayExcelService,
  bonosCompanyExcelService,
  bonosExcelService,
  bookingCanceledExcelService,
  bookingExcelService,
  bookingReviewExcelService,
  cardsExcelService,
  emailsExcelService,
  expiredPlatesExcelService,
  foodStylesExcelService,
  foodStylesSousinesExcelService,
  giftsPlatesExcelService,
  phonesExcelService,
  plateParamenterPPExcelService,
  plateParamenterRFCExcelService,
  platesExcelService,
  platesFoodStylesExcelService,
  restaurantParamenterEMHIRRExcelService,
  restaurantParamenterFRRExcelService,
  restaurantPaymentExcelService,
  restaurantPlanningExcelService,
  restaurantsExcelService,
  usersExcelService
} from 'src/services/excel.service'

export const foodStyleToExcel = async () => {
  const foodStyleData = await foodStylesExcelService()
  const nameExcel = '04_Estilos'
  const excelData = foodStyleData.map((foodStyle) => {
    return {
      'Id Estilo': foodStyle._id,
      Estilo: foodStyle.name
    }
  })
  const wsf = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsf]
}

export const foodStylesCousinesExcel = async () => {
  const foodStyleData = await foodStylesSousinesExcelService()
  const nameExcel = '06_Estilos y tipos de cocina'
  const excelData = foodStyleData.map((foodStyle, index) => {
    return {
      'Id Asignación estilo a cocina': index + 1,
      'Id Tipo de cocina': foodStyle.cousin,
      'Id Estilo': foodStyle.foodStyle
    }
  })
  const wsfc = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsfc]
}

export const usersExcel = async () => {
  const usersData = await usersExcelService()
  const nameExcel = '11_Usuarios'
  const excelData = usersData.map((user) => {
    return {
      'Id Usuario': user._id,
      Nombre: user.name,
      Apellido: user.surname,
      'Dirección activa de email': user.email,
      'Recepción de emails de marketing (SÍ/NO)': user.privacity.special_offers_and_promotions ? 'VERDADERO' : 'FALSO',
      'Teléfono activo': user.phone,
      'Identificador activo': user.card || '',
      'Fecha de registro': user.createdAt,
      'Fecha de nacimiento': user.birthday,
      Sexo: user.gender ? (user.gender === 'Man' ? 'Hombre' : 'Mujer') : '',
      'Zona de actuación': user.address,
      'Latitud de actuación': user.location[0],
      'Longitud de actuación': user.location[1],
      'Fecha de eliminación de cuenta': user.status === 'DELETED' ? user.updatedAt : ''
    }
  })
  const wsu = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsu]
}

export const restaurantsExcel = async () => {
  const restaurantsData = await restaurantsExcelService()
  const nameExcel = '21_Restaurantes'
  const excelData = restaurantsData.map((restaurant) => {
    return {
      'Id Restaurante': restaurant._id,
      'Nombre del restaurante': restaurant.name,
      Dirección:
        restaurant.location.street + ' ' + restaurant.location.number + ' ' + restaurant.location.locality + ' ' + restaurant.location.cp,
      'Latitud de dirección': restaurant.location.position.coordinates[0],
      'Longitud de dirección': restaurant.location.position.coordinates[1],
      'Id Zona': restaurant.location.neighborhood.cp,
      Email: restaurant.email,
      'Recepción de emails de marketing (SÍ/NO)': restaurant.privacity.special_offers_and_promotions ? 'VERDADERO' : 'FALSO',
      'Fecha de alta': restaurant.createdAt,
      'Fecha de baja': restaurant.status === 'DELETED' ? restaurant.updatedAt : '',
      'Nombre del beneficiario': restaurant.bank.holder,
      'NIF/CIF del beneficiario': restaurant.bank.nif,
      'BIC del beneficiario': restaurant.bank.bic,
      'IBAN del beneficiario': restaurant.bank.iban
    }
  })
  const wsr = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsr]
}

export const platesExcel = async () => {
  const platesData = await platesExcelService()
  const nameExcel = '31_Platos'
  const excelData = platesData.map((plate) => {
    return {
      'Id Plato': plate._id,
      'Nombre del plato': plate.name,
      'Id Restaurante': plate.restaurant,
      'Fecha de creación': plate.createdAt
    }
  })
  const wsp = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsp]
}

export const platesFoodStylesExcel = async () => {
  const platesData = await platesFoodStylesExcelService()
  const nameExcel = '32_Estilos y platos'
  const excelData = platesData.map((plate, index) => {
    return {
      'Id Asignación estilo a plato': index + 1,
      'Id Plato': plate.plate,
      'Id Estilo': plate.foodStyle
    }
  })
  const wspfs = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wspfs]
}

export const bonosExcel = async () => {
  const bonosExcel = await bonosExcelService()
  const nameExcel = '51_Bonos'
  const excelData = bonosExcel.map((bono) => {
    return {
      'Id Bono': bono._id,
      Precio: bono.totalPrice,
      'Número de platos': bono.quantity || '1',
      'Periodo de validez': bono.expiration,
      'Máx. platos a regalar': bono.giftQuantity,
      'Fecha de creación': bono.createdAt
    }
  })
  const wsb = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsb]
}

export const bonosCompanyExcel = async () => {
  const bonosExcel = await bonosCompanyExcelService()
  const nameExcel = '51B_Bonos'
  const excelData = bonosExcel.map((bono) => {
    return {
      'Id Bono': bono._id,
      'Código de empresa': bono.companyConfig.code,
      'Rango 1 nº de platos A': bono.companyConfig.range1.min,
      'Rango 1 nº de platos B': bono.companyConfig.range1.max,
      'Precio Rango 1': bono.companyConfig.range1.price,
      'Validez 1': bono.companyConfig.range1.expiration,
      'Rango 2 nº de platos A': bono.companyConfig.range2.min,
      'Rango 2 nº de platos B': bono.companyConfig.range2.max,
      'Precio Rango 2': bono.companyConfig.range2.price,
      'Validez 2': bono.companyConfig.range2.expiration,
      'Rango 3 nº de platos': bono.companyConfig.range3.active ? bono.companyConfig.range3.min : '',
      'Precio Rango 3': bono.companyConfig.range3.active ? bono.companyConfig.range3.price : '',
      'Validez 3': bono.companyConfig.range3.active ? bono.companyConfig.range3.expiration : '',
      'Máx. platos a regalar (%)': bono.companyConfig.maxGiftQuantity,
      'Fecha de creación': bono.createdAt
    }
  })
  const wsb = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsb]
}

export const bonoPayExcel = async () => {
  const bonosPayExcel = await bonoPayExcelService()
  const nameExcel = '52_Compra Bonos'
  const excelData = bonosPayExcel.map((bono) => {
    let inicio: 'FALSO' | 'VERDADERO' = 'FALSO'
    if (bono.initRefresh === true) {
      inicio = 'VERDADERO'
    } else if (bono.initRefresh === false) {
      inicio = 'FALSO'
    } else if (bono.refresh || (bono.isRefreshed && bono.discount)) {
      inicio = 'VERDADERO'
    } else {
      inicio = 'FALSO'
    }

    const auto = bono.isRefreshed ? (inicio === 'FALSO' ? 'VERDADERO' : 'FALSO') : 'FALSO'
    let discount = ''
    if (bono.discount) {
      discount = bono.discount.name
    } else if (bono.typeBono === '6') {
      discount = bono.id_bono.companyConfig.code
    }
    return {
      'Id Compra Bono': bono._id,
      'Id Usuario': bono.owner,
      'Fecha de compra': bono.date,
      'Hora de compra': bono.hour,
      'Posición de bono': bono.attached ? 5 : bono.id_bono.special ? 4 : +bono.id_bono.position || +bono.typeBono || undefined,
      'Id Bono': bono.id_bono._id,
      'Tipo de descuento': bono.discount ? bono.discount.type2 : bono.typeBono === '6' ? '6' : '',
      'Código descuento': discount,
      'Descuento (%)': bono.discount ? bono.discount.discount : '',
      Identificador: bono.card,
      'Inicio de suscripción (SÍ/NO)': inicio,
      'Compra automática (SÍ/NO)': auto,
      'Número de platos': bono.companyConfig?.quantity || '',
      Precio: bono.companyConfig ? bono.companyConfig.price_plate * bono.companyConfig.quantity : ''
    }
  })
  const wsbp = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsbp]
}

export const emailsExcel = async () => {
  const emailsExcel = await emailsExcelService()
  const nameExcel = '53_Emails'
  const excelData = emailsExcel.map((email) => {
    return {
      'Dirección de email usada en compras': email
    }
  })
  const wse = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wse]
}

export const phoneExcel = async () => {
  const phonesData = await phonesExcelService()
  const nameExcel = '54_Teléfonos'
  const excelData = phonesData.map((phone) => {
    return {
      'Teléfono usado en compras': phone
    }
  })
  const wsph = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsph]
}

export const giftsPlatesExcel = async () => {
  const giftsPlatesData = await giftsPlatesExcelService()
  const nameExcel = '55_Platos regalados'
  const excelData = giftsPlatesData.map((gift, index) => {
    return {
      'Id Envío': index + 1,
      'Fecha de envío': gift.date,
      'Hora de envío': gift.hour,
      'Id Compra Bono': gift._id,
      'Id Usuario receptor': gift.user
    }
  })
  const wsgp = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsgp]
}

export const expiredPlatesExcel = async () => {
  const expiredPlatesData = await expiredPlatesExcelService()
  const nameExcel = '56_Platos caducados'
  const excelData = expiredPlatesData.map((expired, index) => {
    return {
      'Id Plato caducado': index + 1,
      'Fecha de caducidad': expired.date,
      'Id Compra Bono': expired._id,
      'Id Usuario': expired.user
    }
  })
  const wsep = XLSX.utils.json_to_sheet(excelData)
  return [nameExcel, wsep]
}

export const bookingExcel = async () => {
  const bookingExcelDate = await bookingExcelService()
  const nameExcel = '61_Pedidos'
  const excelDataBooking = bookingExcelDate.map((item: any) => {
    return {
      'Id Pedido': item._id,
      'Hora de realización de pedido': item.hour_create,
      'Id Oferta': item.planning,
      'Hora de recogida inicio': item.hour_init,
      'Hora de recogida real': item.hour_end,
      Latitud: item.latitude,
      Longitud: item.longitude,
      'Id Compra Bono': item.bono_pay,
      'Id Usuario': item.user
    }
  })
  const wsbk = XLSX.utils.json_to_sheet(excelDataBooking)
  return [nameExcel, wsbk]
}

export const bookingCanceledExcel = async () => {
  const bookingExcelDate = await bookingCanceledExcelService()
  const nameExcel = '62_Pedidos cancelados'
  const excelDataBooking = bookingExcelDate.map((booking) => {
    return {
      'Id Pedido': booking._id,
      'Hora de cancelación': booking.hour,
      '¿Cancelado antes de C? (SÍ/NO)': booking.hardCanceled
    }
  })
  const wsbkc = XLSX.utils.json_to_sheet(excelDataBooking)
  return [nameExcel, wsbkc]
}

export const bookingReviewExcel = async () => {
  const bookingReviewExcelDate = await bookingReviewExcelService()
  const nameExcel = '63_Pedidos valorados'
  const excelDataBookingReview = bookingReviewExcelDate.map((booking) => {
    return {
      'Id Pedido': booking.booking,
      'Fecha de valoración': booking.date,
      'Hora de valoración': booking.hour,
      'Puntuación Calidad (Sí/NO)': booking.quality,
      'Puntuación Cantidad (Sí/NO)': booking.amount
    }
  })
  const wsbkr = XLSX.utils.json_to_sheet(excelDataBookingReview)
  return [nameExcel, wsbkr]
}

export const restaurantPaymentExcel = async () => {
  const restaurantPaymentExcel = await restaurantPaymentExcelService()
  const nameExcel = '71_Pagos a restaurantes'
  const excelDataRestaurantPayment = restaurantPaymentExcel.map((item: any, index: number) => {
    return {
      'Id Pago': index + 1,
      'Id Restaurante': item._id,
      'Nombre del beneficiario': item.name,
      'NIF/CIF del beneficiario': item.bank,
      'BIC del beneficiario': item.bic,
      'IBAN del beneficiario': item.iban,
      'Importe por vendidos': +item.totalIncome,
      Concepto: item.concept
    }
  })
  const wsrp = XLSX.utils.json_to_sheet(excelDataRestaurantPayment)
  return [nameExcel, wsrp]
}

const NEIGHBORHOOD = [
  { 'Id Zona': '28011', Barrio: 'Palacio', 'Id Distrito': '2801' },
  { 'Id Zona': '28012', Barrio: 'Embajadores', 'Id Distrito': '2801' },
  { 'Id Zona': '28013', Barrio: 'Cortes', 'Id Distrito': '2801' },
  { 'Id Zona': '28014', Barrio: 'Justicia', 'Id Distrito': '2801' },
  { 'Id Zona': '28015', Barrio: 'Universidad', 'Id Distrito': '2801' },
  { 'Id Zona': '28016', Barrio: 'Sol', 'Id Distrito': '2801' },
  { 'Id Zona': '28021', Barrio: 'Imperial', 'Id Distrito': '2802' },
  { 'Id Zona': '28022', Barrio: 'Acacias', 'Id Distrito': '2802' },
  { 'Id Zona': '28023', Barrio: 'Chopera', 'Id Distrito': '2802' },
  { 'Id Zona': '28024', Barrio: 'Legazpi', 'Id Distrito': '2802' },
  { 'Id Zona': '28025', Barrio: 'Delicias', 'Id Distrito': '2802' },
  { 'Id Zona': '28026', Barrio: 'Palos de Moguer', 'Id Distrito': '2802' },
  { 'Id Zona': '28027', Barrio: 'Atocha', 'Id Distrito': '2802' },
  { 'Id Zona': '28031', Barrio: 'Pacífico', 'Id Distrito': '2803' },
  { 'Id Zona': '28032', Barrio: 'Adelfas', 'Id Distrito': '2803' },
  { 'Id Zona': '28033', Barrio: 'Estrella', 'Id Distrito': '2803' },
  { 'Id Zona': '28034', Barrio: 'Ibiza', 'Id Distrito': '2803' },
  { 'Id Zona': '28035', Barrio: 'Jerónimos', 'Id Distrito': '2803' },
  { 'Id Zona': '28036', Barrio: 'Niño Jesús', 'Id Distrito': '2803' },
  { 'Id Zona': '28041', Barrio: 'Recoletos', 'Id Distrito': '2804' },
  { 'Id Zona': '28042', Barrio: 'Goya', 'Id Distrito': '2804' },
  { 'Id Zona': '28043', Barrio: 'Fuente del Berro', 'Id Distrito': '2804' },
  { 'Id Zona': '28044', Barrio: 'Guindalera', 'Id Distrito': '2804' },
  { 'Id Zona': '28045', Barrio: 'Lista', 'Id Distrito': '2804' },
  { 'Id Zona': '28046', Barrio: 'Castellana', 'Id Distrito': '2804' },
  { 'Id Zona': '28051', Barrio: 'El Viso', 'Id Distrito': '2805' },
  { 'Id Zona': '28052', Barrio: 'Prosperidad', 'Id Distrito': '2805' },
  { 'Id Zona': '28053', Barrio: 'Ciudad Jardín', 'Id Distrito': '2805' },
  { 'Id Zona': '28054', Barrio: 'Hispanoamérica', 'Id Distrito': '2805' },
  { 'Id Zona': '28055', Barrio: 'Nueva España', 'Id Distrito': '2805' },
  { 'Id Zona': '28056', Barrio: 'Castilla', 'Id Distrito': '2805' },
  { 'Id Zona': '28061', Barrio: 'Bellas Vistas', 'Id Distrito': '2806' },
  { 'Id Zona': '28062', Barrio: 'Cuatro Caminos', 'Id Distrito': '2806' },
  { 'Id Zona': '28063', Barrio: 'Castillejos', 'Id Distrito': '2806' },
  { 'Id Zona': '28064', Barrio: 'Almenara', 'Id Distrito': '2806' },
  { 'Id Zona': '28065', Barrio: 'Valdeacederas', 'Id Distrito': '2806' },
  { 'Id Zona': '28066', Barrio: 'Berruguete', 'Id Distrito': '2806' },
  { 'Id Zona': '28071', Barrio: 'Gaztambide', 'Id Distrito': '2807' },
  { 'Id Zona': '28072', Barrio: 'Arapiles', 'Id Distrito': '2807' },
  { 'Id Zona': '28073', Barrio: 'Trafalgar', 'Id Distrito': '2807' },
  { 'Id Zona': '28074', Barrio: 'Almagro', 'Id Distrito': '2807' },
  { 'Id Zona': '28075', Barrio: 'Ríos Rosas', 'Id Distrito': '2807' },
  { 'Id Zona': '28076', Barrio: 'Vallehermoso', 'Id Distrito': '2807' },
  { 'Id Zona': '28081', Barrio: 'El Pardo', 'Id Distrito': '2808' },
  { 'Id Zona': '28082', Barrio: 'Fuentelarreina', 'Id Distrito': '2808' },
  { 'Id Zona': '28083', Barrio: 'Peñagrande', 'Id Distrito': '2808' },
  { 'Id Zona': '28084', Barrio: 'El Pilar', 'Id Distrito': '2808' },
  { 'Id Zona': '28085', Barrio: 'La Paz', 'Id Distrito': '2808' },
  { 'Id Zona': '28086', Barrio: 'Valverde', 'Id Distrito': '2808' },
  { 'Id Zona': '28087', Barrio: 'Mirasierra', 'Id Distrito': '2808' },
  { 'Id Zona': '28088', Barrio: 'El Goloso', 'Id Distrito': '2808' },
  { 'Id Zona': '28091', Barrio: 'Casa de Campo', 'Id Distrito': '2809' },
  { 'Id Zona': '28092', Barrio: 'Argüelles', 'Id Distrito': '2809' },
  { 'Id Zona': '28093', Barrio: 'Ciudad Universitaria', 'Id Distrito': '2809' },
  { 'Id Zona': '28094', Barrio: 'Valdezarza', 'Id Distrito': '2809' },
  { 'Id Zona': '28095', Barrio: 'Valdemarín', 'Id Distrito': '2809' },
  { 'Id Zona': '28096', Barrio: 'El Plantío', 'Id Distrito': '2809' },
  { 'Id Zona': '28097', Barrio: 'Aravaca', 'Id Distrito': '2809' },
  { 'Id Zona': '28101', Barrio: 'Los Cármenes', 'Id Distrito': '2810' },
  { 'Id Zona': '28102', Barrio: 'Puerta del Ángel', 'Id Distrito': '2810' },
  { 'Id Zona': '28103', Barrio: 'Lucero', 'Id Distrito': '2810' },
  { 'Id Zona': '28104', Barrio: 'Aluche', 'Id Distrito': '2810' },
  { 'Id Zona': '28105', Barrio: 'Campamento', 'Id Distrito': '2810' },
  { 'Id Zona': '28106', Barrio: 'Cuatro Vientos', 'Id Distrito': '2810' },
  { 'Id Zona': '28107', Barrio: 'Las Águilas', 'Id Distrito': '2810' },
  { 'Id Zona': '28111', Barrio: 'Comillas', 'Id Distrito': '2811' },
  { 'Id Zona': '28112', Barrio: 'Opañel', 'Id Distrito': '2811' },
  { 'Id Zona': '28113', Barrio: 'San Isidro', 'Id Distrito': '2811' },
  { 'Id Zona': '28114', Barrio: 'Vista Alegre', 'Id Distrito': '2811' },
  { 'Id Zona': '28115', Barrio: 'Puerta Bonita', 'Id Distrito': '2811' },
  { 'Id Zona': '28116', Barrio: 'Buenavista', 'Id Distrito': '2811' },
  { 'Id Zona': '28117', Barrio: 'Abrantes', 'Id Distrito': '2811' },
  { 'Id Zona': '28121', Barrio: 'Orcasitas', 'Id Distrito': '2812' },
  { 'Id Zona': '28122', Barrio: 'Orcasur', 'Id Distrito': '2812' },
  { 'Id Zona': '28123', Barrio: 'San Fermín', 'Id Distrito': '2812' },
  { 'Id Zona': '28124', Barrio: 'Almendrales', 'Id Distrito': '2812' },
  { 'Id Zona': '28125', Barrio: 'Moscardó', 'Id Distrito': '2812' },
  { 'Id Zona': '28126', Barrio: 'Zofio', 'Id Distrito': '2812' },
  { 'Id Zona': '28127', Barrio: 'Pradolongo', 'Id Distrito': '2812' },
  { 'Id Zona': '28131', Barrio: 'Entrevías', 'Id Distrito': '2813' },
  { 'Id Zona': '28132', Barrio: 'San Diego', 'Id Distrito': '2813' },
  { 'Id Zona': '28133', Barrio: 'Palomeras Bajas', 'Id Distrito': '2813' },
  { 'Id Zona': '28134', Barrio: 'Palomeras Sureste', 'Id Distrito': '2813' },
  { 'Id Zona': '28135', Barrio: 'Portazgo', 'Id Distrito': '2813' },
  { 'Id Zona': '28136', Barrio: 'Numancia', 'Id Distrito': '2813' },
  { 'Id Zona': '28141', Barrio: 'Pavones', 'Id Distrito': '2814' },
  { 'Id Zona': '28142', Barrio: 'Horcajo', 'Id Distrito': '2814' },
  { 'Id Zona': '28143', Barrio: 'Marroquina', 'Id Distrito': '2814' },
  { 'Id Zona': '28144', Barrio: 'Media Legua', 'Id Distrito': '2814' },
  { 'Id Zona': '28145', Barrio: 'Fontarrón', 'Id Distrito': '2814' },
  { 'Id Zona': '28146', Barrio: 'Vinateros', 'Id Distrito': '2814' },
  { 'Id Zona': '28151', Barrio: 'Ventas', 'Id Distrito': '2815' },
  { 'Id Zona': '28152', Barrio: 'Pueblo Nuevo', 'Id Distrito': '2815' },
  { 'Id Zona': '28153', Barrio: 'Quintana', 'Id Distrito': '2815' },
  { 'Id Zona': '28154', Barrio: 'Concepción', 'Id Distrito': '2815' },
  { 'Id Zona': '28155', Barrio: 'San Pascual', 'Id Distrito': '2815' },
  { 'Id Zona': '28156', Barrio: 'San Juan Bautista', 'Id Distrito': '2815' },
  { 'Id Zona': '28157', Barrio: 'Colina', 'Id Distrito': '2815' },
  { 'Id Zona': '28158', Barrio: 'Atalaya', 'Id Distrito': '2815' },
  { 'Id Zona': '28159', Barrio: 'Costillares', 'Id Distrito': '2815' },
  { 'Id Zona': '28161', Barrio: 'Palomas', 'Id Distrito': '2816' },
  { 'Id Zona': '28162', Barrio: 'Piovera', 'Id Distrito': '2816' },
  { 'Id Zona': '28163', Barrio: 'Canillas', 'Id Distrito': '2816' },
  { 'Id Zona': '28164', Barrio: 'Pinar del Rey', 'Id Distrito': '2816' },
  { 'Id Zona': '28165', Barrio: 'Apóstol Santiago', 'Id Distrito': '2816' },
  { 'Id Zona': '28166', Barrio: 'Valdefuentes', 'Id Distrito': '2816' },
  { 'Id Zona': '28171', Barrio: 'San Andrés', 'Id Distrito': '2817' },
  { 'Id Zona': '28172', Barrio: 'San Cristóbal', 'Id Distrito': '2817' },
  { 'Id Zona': '28173', Barrio: 'Butarque', 'Id Distrito': '2817' },
  { 'Id Zona': '28174', Barrio: 'Los Rosales', 'Id Distrito': '2817' },
  { 'Id Zona': '28175', Barrio: 'Los Ángeles', 'Id Distrito': '2817' },
  {
    'Id Zona': '28181',
    Barrio: 'Casco Histórico de Vallecas',
    'Id Distrito': '2818'
  },
  { 'Id Zona': '28182', Barrio: 'Santa Eugenia', 'Id Distrito': '2818' },
  { 'Id Zona': '28183', Barrio: 'Ensanche de Vallecas', 'Id Distrito': '2818' },
  {
    'Id Zona': '28191',
    Barrio: 'Casco Histórico de Vicálvaro',
    'Id Distrito': '2819'
  },
  { 'Id Zona': '28192', Barrio: 'Valdebernardo', 'Id Distrito': '2819' },
  { 'Id Zona': '28193', Barrio: 'Valderribas', 'Id Distrito': '2819' },
  { 'Id Zona': '28194', Barrio: 'Cañaveral', 'Id Distrito': '2819' },
  { 'Id Zona': '28201', Barrio: 'Simancas', 'Id Distrito': '2820' },
  { 'Id Zona': '28202', Barrio: 'Hellín', 'Id Distrito': '2820' },
  { 'Id Zona': '28203', Barrio: 'Amposta', 'Id Distrito': '2820' },
  { 'Id Zona': '28204', Barrio: 'Arcos', 'Id Distrito': '2820' },
  { 'Id Zona': '28205', Barrio: 'Rosas', 'Id Distrito': '2820' },
  { 'Id Zona': '28206', Barrio: 'Rejas', 'Id Distrito': '2820' },
  { 'Id Zona': '28207', Barrio: 'Canillejas', 'Id Distrito': '2820' },
  { 'Id Zona': '28208', Barrio: 'Salvador', 'Id Distrito': '2820' },
  { 'Id Zona': '28211', Barrio: 'Alameda de Osuna', 'Id Distrito': '2821' },
  { 'Id Zona': '28212', Barrio: 'Aeropuerto', 'Id Distrito': '2821' },
  {
    'Id Zona': '28213',
    Barrio: 'Casco Histórico de Barajas',
    'Id Distrito': '2821'
  },
  { 'Id Zona': '28214', Barrio: 'Timón', 'Id Distrito': '2821' },
  { 'Id Zona': '28215', Barrio: 'Corralejos', 'Id Distrito': '2821' },
  { 'Id Zona': '28001', Barrio: 'Pozuelo de Alarcón', 'Id Distrito': '2800' },
  { 'Id Zona': '28002', Barrio: 'Majadahonda', 'Id Distrito': '2800' },
  { 'Id Zona': '28003', Barrio: 'Las Rozas', 'Id Distrito': '2800' },
  { 'Id Zona': '28004', Barrio: 'Alcobendas', 'Id Distrito': '2800' },
  { 'Id Zona': '28005', Barrio: 'Tres Cantos', 'Id Distrito': '2800' },
  { 'Id Zona': 8001, Barrio: 'El Raval', 'Id Distrito': 1 },
  { 'Id Zona': 8002, Barrio: 'El Gòtic', 'Id Distrito': 1 },
  { 'Id Zona': 8003, Barrio: 'La Barceloneta', 'Id Distrito': 1 },
  {
    'Id Zona': 8004,
    Barrio: 'Sant Pere, Santa Caterina i La Ribera',
    'Id Distrito': 1
  },
  { 'Id Zona': 8005, Barrio: 'El Fort Pienc', 'Id Distrito': 2 },
  { 'Id Zona': 8006, Barrio: 'La Sagrada Familia', 'Id Distrito': 2 },
  { 'Id Zona': 8007, Barrio: "La Dreta de l'Eixample", 'Id Distrito': 2 },
  {
    'Id Zona': 8008,
    Barrio: "L'Antiga Esquerra de l'Eixample",
    'Id Distrito': 2
  },
  {
    'Id Zona': 8009,
    Barrio: "La Nova Esquerra de l'Eixample",
    'Id Distrito': 2
  },
  { 'Id Zona': 8010, Barrio: 'Sant Antoni', 'Id Distrito': 2 },
  { 'Id Zona': 8011, Barrio: 'El Poble-sec', 'Id Distrito': 3 },
  { 'Id Zona': 8012, Barrio: 'La Marina del Prat Vermell', 'Id Distrito': 3 },
  { 'Id Zona': 8013, Barrio: 'La Marina de Port', 'Id Distrito': 3 },
  { 'Id Zona': 8014, Barrio: 'La Font de la Guatlla', 'Id Distrito': 3 },
  { 'Id Zona': 8015, Barrio: 'Hostafrancs', 'Id Distrito': 3 },
  { 'Id Zona': 8016, Barrio: 'La Bordeta', 'Id Distrito': 3 },
  { 'Id Zona': 8017, Barrio: 'Sants-Badal', 'Id Distrito': 3 },
  { 'Id Zona': 8018, Barrio: 'Sants', 'Id Distrito': 3 },
  { 'Id Zona': 8019, Barrio: 'Les Corts', 'Id Distrito': 4 },
  { 'Id Zona': 8020, Barrio: 'La Maternitat i Sant Ramon', 'Id Distrito': 4 },
  { 'Id Zona': 8021, Barrio: 'Pedralbes', 'Id Distrito': 4 },
  {
    'Id Zona': 8022,
    Barrio: 'Vallvidrera, el Tibidabo i les Planes',
    'Id Distrito': 5
  },
  { 'Id Zona': 8023, Barrio: 'Sarrià', 'Id Distrito': 5 },
  { 'Id Zona': 8024, Barrio: 'Les Tres Torres', 'Id Distrito': 5 },
  { 'Id Zona': 8025, Barrio: 'Sant Gervasi-la Bonanova', 'Id Distrito': 5 },
  { 'Id Zona': 8026, Barrio: 'Sant Gervasi-Galvany', 'Id Distrito': 5 },
  { 'Id Zona': 8027, Barrio: 'El Putxet i el Farró', 'Id Distrito': 5 },
  { 'Id Zona': 8028, Barrio: 'Vallcarca i els Penitents', 'Id Distrito': 6 },
  { 'Id Zona': 8029, Barrio: 'El Coll', 'Id Distrito': 6 },
  { 'Id Zona': 8030, Barrio: 'La Salut', 'Id Distrito': 6 },
  { 'Id Zona': 8031, Barrio: 'La Vila de Gràcia', 'Id Distrito': 6 },
  {
    'Id Zona': 8032,
    Barrio: "El Camp d'en Grassot i Gràcia Nova",
    'Id Distrito': 6
  },
  { 'Id Zona': 8033, Barrio: 'El Baix Guinardó', 'Id Distrito': 7 },
  { 'Id Zona': 8034, Barrio: 'Can Baró', 'Id Distrito': 7 },
  { 'Id Zona': 8035, Barrio: 'El Guinardó', 'Id Distrito': 7 },
  { 'Id Zona': 8036, Barrio: "La Font d'en Fargues", 'Id Distrito': 7 },
  { 'Id Zona': 8037, Barrio: 'El Carmel', 'Id Distrito': 7 },
  { 'Id Zona': 8038, Barrio: 'La Teixonera', 'Id Distrito': 7 },
  { 'Id Zona': 8039, Barrio: 'Sant Genís dels Agudells', 'Id Distrito': 7 },
  { 'Id Zona': 8040, Barrio: 'Montbau', 'Id Distrito': 7 },
  { 'Id Zona': 8041, Barrio: "La Vall d'Hebron", 'Id Distrito': 7 },
  { 'Id Zona': 8042, Barrio: 'La Clota', 'Id Distrito': 7 },
  { 'Id Zona': 8043, Barrio: 'Horta', 'Id Distrito': 7 },
  {
    'Id Zona': 8044,
    Barrio: 'Vilapicina i La Torre Llobeta',
    'Id Distrito': 8
  },
  { 'Id Zona': 8045, Barrio: 'Porta', 'Id Distrito': 8 },
  { 'Id Zona': 8046, Barrio: 'El Turó de la Peira', 'Id Distrito': 8 },
  { 'Id Zona': 8047, Barrio: 'Can Peguera', 'Id Distrito': 8 },
  { 'Id Zona': 8048, Barrio: 'La Guineueta', 'Id Distrito': 8 },
  { 'Id Zona': 8049, Barrio: 'Canyelles', 'Id Distrito': 8 },
  { 'Id Zona': 8050, Barrio: 'Les Roquetes', 'Id Distrito': 8 },
  { 'Id Zona': 8051, Barrio: 'Verdun', 'Id Distrito': 8 },
  { 'Id Zona': 8052, Barrio: 'La Prosperitat', 'Id Distrito': 8 },
  { 'Id Zona': 8053, Barrio: 'La Trinitat Nova', 'Id Distrito': 8 },
  { 'Id Zona': 8054, Barrio: 'Torre Baró', 'Id Distrito': 8 },
  { 'Id Zona': 8055, Barrio: 'Ciutat Meridiana', 'Id Distrito': 8 },
  { 'Id Zona': 8056, Barrio: 'Vallbona', 'Id Distrito': 8 },
  { 'Id Zona': 8057, Barrio: 'La Trinitat Vella', 'Id Distrito': 9 },
  { 'Id Zona': 8058, Barrio: 'Baró de Viver', 'Id Distrito': 9 },
  { 'Id Zona': 8059, Barrio: 'El Bon Pastor', 'Id Distrito': 9 },
  { 'Id Zona': 8060, Barrio: 'Sant Andreu de Palomar', 'Id Distrito': 9 },
  { 'Id Zona': 8061, Barrio: 'La Sagrera', 'Id Distrito': 9 },
  { 'Id Zona': 8062, Barrio: 'El Congrés i els Indians', 'Id Distrito': 9 },
  { 'Id Zona': 8063, Barrio: 'Navas', 'Id Distrito': 9 },
  { 'Id Zona': 8064, Barrio: "El Camp de l'Arpa del Clot", 'Id Distrito': 10 },
  { 'Id Zona': 8065, Barrio: 'El Clot', 'Id Distrito': 10 },
  {
    'Id Zona': 8066,
    Barrio: 'El Parc i la Llacuna del Poblenou',
    'Id Distrito': 10
  },
  {
    'Id Zona': 8067,
    Barrio: 'La Vila Olímpica del Poblenou',
    'Id Distrito': 10
  },
  { 'Id Zona': 8068, Barrio: 'El Poblenou', 'Id Distrito': 10 },
  {
    'Id Zona': 8069,
    Barrio: 'Diagonal Mar-Front Marítim del Poblenou',
    'Id Distrito': 10
  },
  { 'Id Zona': 8070, Barrio: 'El Besòs i el Maresme', 'Id Distrito': 10 },
  { 'Id Zona': 8071, Barrio: 'Provençals del Poblenou', 'Id Distrito': 10 },
  { 'Id Zona': 8072, Barrio: 'Sant Martí de Provençals', 'Id Distrito': 10 },
  { 'Id Zona': 8073, Barrio: 'La Verneda i la Pau', 'Id Distrito': 10 },
  { 'Id Zona': 8081, Barrio: 'L’Hospitalet de Llobregat', 'Id Distrito': 808 },
  { 'Id Zona': 8082, Barrio: 'Sant Adrià de Besòs', 'Id Distrito': 808 },
  { 'Id Zona': 8083, Barrio: 'Badalona', 'Id Distrito': 808 }
]

export const neighborhoodExcel = () => {
  const nameExcel = '01_Barrios'
  const wsn = XLSX.utils.json_to_sheet(NEIGHBORHOOD)
  return [nameExcel, wsn]
}

const DISTRICT = [
  { 'Id Distrito': 2801, Distrito: 'Centro', 'Id Ciudad': 28 },
  { 'Id Distrito': 2802, Distrito: 'Arganzuela', 'Id Ciudad': 28 },
  { 'Id Distrito': 2803, Distrito: 'Retiro', 'Id Ciudad': 28 },
  { 'Id Distrito': 2804, Distrito: 'Salamanca', 'Id Ciudad': 28 },
  { 'Id Distrito': 2805, Distrito: 'Chamartín', 'Id Ciudad': 28 },
  { 'Id Distrito': 2806, Distrito: 'Tetuán', 'Id Ciudad': 28 },
  { 'Id Distrito': 2807, Distrito: 'Chamberí', 'Id Ciudad': 28 },
  { 'Id Distrito': 2808, Distrito: 'Fuencarral-El Pardo', 'Id Ciudad': 28 },
  { 'Id Distrito': 2809, Distrito: 'Moncloa-Aravaca', 'Id Ciudad': 28 },
  { 'Id Distrito': 2810, Distrito: 'Latina', 'Id Ciudad': 28 },
  { 'Id Distrito': 2811, Distrito: 'Carabanchel', 'Id Ciudad': 28 },
  { 'Id Distrito': 2812, Distrito: 'Usera', 'Id Ciudad': 28 },
  { 'Id Distrito': 2813, Distrito: 'Puente de Vallecas', 'Id Ciudad': 28 },
  { 'Id Distrito': 2814, Distrito: 'Moratalaz', 'Id Ciudad': 28 },
  { 'Id Distrito': 2815, Distrito: 'Ciudad Lineal', 'Id Ciudad': 28 },
  { 'Id Distrito': 2816, Distrito: 'Hortaleza', 'Id Ciudad': 28 },
  { 'Id Distrito': 2817, Distrito: 'Villaverde', 'Id Ciudad': 28 },
  { 'Id Distrito': 2818, Distrito: 'Villa de Vallecas', 'Id Ciudad': 28 },
  { 'Id Distrito': 2819, Distrito: 'Vicálvaro', 'Id Ciudad': 28 },
  { 'Id Distrito': 2820, Distrito: 'San Blas-Canillejas', 'Id Ciudad': 28 },
  { 'Id Distrito': 2821, Distrito: 'Barajas', 'Id Ciudad': 28 },
  { 'Id Distrito': 2800, Distrito: 'Municipio Madrid', 'Id Ciudad': 28 },
  { 'Id Distrito': 1, Distrito: 'Ciutat Vella', 'Id Ciudad': 8 },
  { 'Id Distrito': 2, Distrito: 'Eixample', 'Id Ciudad': 8 },
  { 'Id Distrito': 3, Distrito: 'Sants-Montjuïc', 'Id Ciudad': 8 },
  { 'Id Distrito': 4, Distrito: 'Les Corts', 'Id Ciudad': 8 },
  { 'Id Distrito': 5, Distrito: 'Sarrià-Sant Gervasi', 'Id Ciudad': 8 },
  { 'Id Distrito': 6, Distrito: 'Gràcia', 'Id Ciudad': 8 },
  { 'Id Distrito': 7, Distrito: 'Horta-Guinardó', 'Id Ciudad': 8 },
  { 'Id Distrito': 8, Distrito: 'Nou Barris', 'Id Ciudad': 8 },
  { 'Id Distrito': 9, Distrito: 'Sant Andreu', 'Id Ciudad': 8 },
  { 'Id Distrito': 10, Distrito: 'Sant Martí', 'Id Ciudad': 8 },
  { 'Id Distrito': 808, Distrito: 'Municipio Barcelona', 'Id Ciudad': 8 }
]

export const districtExcel = () => {
  const nameExcel = '02_Distritos'
  const wsd = XLSX.utils.json_to_sheet(DISTRICT)
  return [nameExcel, wsd]
}

const CITY = [
  { 'Id Ciudad': 8, Ciudad: 'Barcelona' },
  { 'Id Ciudad': 28, Ciudad: 'Madrid' }
]

export const cityExcel = () => {
  const nameExcel = '03_Ciudades'
  const wsc = XLSX.utils.json_to_sheet(CITY)
  return [nameExcel, wsc]
}

const COUSINE = [
  {
    'Id Tipo de Cocina': 1,
    'Tipo de Cocina': 'Español'
  },
  {
    'Id Tipo de Cocina': 2,
    'Tipo de Cocina': 'Mediterráneo'
  },
  {
    'Id Tipo de Cocina': 3,
    'Tipo de Cocina': 'Italiano'
  },
  {
    'Id Tipo de Cocina': 4,
    'Tipo de Cocina': 'Hamburguesa'
  },
  {
    'Id Tipo de Cocina': 5,
    'Tipo de Cocina': 'Pizza'
  },
  {
    'Id Tipo de Cocina': 6,
    'Tipo de Cocina': 'Americano'
  },
  {
    'Id Tipo de Cocina': 7,
    'Tipo de Cocina': 'Latino'
  },
  {
    'Id Tipo de Cocina': 8,
    'Tipo de Cocina': 'Mejicano'
  },
  {
    'Id Tipo de Cocina': 9,
    'Tipo de Cocina': 'Internacional'
  },
  {
    'Id Tipo de Cocina': 10,
    'Tipo de Cocina': 'Healthy'
  },
  {
    'Id Tipo de Cocina': 11,
    'Tipo de Cocina': 'Asiático'
  },
  {
    'Id Tipo de Cocina': 12,
    'Tipo de Cocina': 'Japonés'
  }
]

export const cousineExcel = () => {
  const nameExcel = '05_Tipos de Cocina'
  const wsc = XLSX.utils.json_to_sheet(COUSINE)
  return [nameExcel, wsc]
}

export const planningExcel = async () => {
  const nameExcel = '41_Platos ofrecidos'
  const planningsPaymentExcel = await restaurantPlanningExcelService()

  const excelDataPlannings = planningsPaymentExcel.map((planning) => {
    return {
      'Id Oferta': planning._id,
      'Id Plato': planning.plate,
      'Número de platos': planning.quantity,
      'Fecha de recogida': planning.date,
      'Hora de apertura': planning.IRr,
      'Hora de cierre': planning.FRr
    }
  })

  const wsc = XLSX.utils.json_to_sheet(excelDataPlannings)
  return [nameExcel, wsc]
}

export const parameterPPExcel = async () => {
  const nameExcel = '33_Cambio de PP'
  const parameterPP = await plateParamenterPPExcelService()

  const excelDataParameterPP = parameterPP.map((parameter, index) => {
    return {
      'Id Cambio PP': index + 1,
      'Id Plato': parameter.plate,
      'Fecha de creación': parameter.dateString,
      'Hora de creación': parameter.hour,
      PP: parameter.PP
    }
  })

  const wsc = XLSX.utils.json_to_sheet(excelDataParameterPP)
  return [nameExcel, wsc]
}

export const parameterEMHIRRExcel = async () => {
  const nameExcel = '34_Cambio de EMHIRR'
  const parameterEMHIRR = await restaurantParamenterEMHIRRExcelService()

  const excelDataParameterEMHIRR = parameterEMHIRR.map((parameter, index) => {
    return {
      'Id Cambio EMHIRR': index + 1,
      'Id Restaurante': parameter.restaurant,
      'Fecha de creación': parameter.dateString,
      'Hora de creación': parameter.hour,
      EMHIRR: parameter.EMHIRR
    }
  })

  const wsc = XLSX.utils.json_to_sheet(excelDataParameterEMHIRR)
  return [nameExcel, wsc]
}

export const parameterFRRExcel = async () => {
  const nameExcel = '35_Cambio de FRR'
  const parameterFRR = await restaurantParamenterFRRExcelService()

  const excelDataParameterFRR = parameterFRR.map((parameter, index) => {
    return {
      'Id Cambio FRR': index + 1,
      'Id Restaurante': parameter.restaurant,
      'Fecha de creación': parameter.dateString,
      'Hora de creación': parameter.hour,
      FRR: parameter.FRR
    }
  })

  const wsc = XLSX.utils.json_to_sheet(excelDataParameterFRR)
  return [nameExcel, wsc]
}

export const parameterRFCExcel = async () => {
  const nameExcel = '36_Cambio de RFC'
  const parameterRFC = await plateParamenterRFCExcelService()

  const excelDataParameterRFC = parameterRFC.map((parameter, index) => {
    return {
      'Id Cambio RFC': index + 1,
      'Id Plato': parameter.plate,
      'Fecha de creación': parameter.dateString,
      'Hora de creación': parameter.hour,
      RFC: parameter.RFC
    }
  })

  const wsc = XLSX.utils.json_to_sheet(excelDataParameterRFC)
  return [nameExcel, wsc]
}

export const cardsExcel = async () => {
  const nameExcel = '12_Tarjetas'
  const cards = await cardsExcelService()

  const excelDataCards = cards.map((card) => {
    return {
      Identificador: card.id,
      '8 dígitos de tarjeta': card.numberCard
        .split('-')
        .map((item) => {
          if (item.length > 4) {
            const numbers = item.slice(0, 4)
            return numbers + 'XXXX'
          }
          return item
        })
        .join('-')
    }
  })

  const wsc = XLSX.utils.json_to_sheet(excelDataCards)
  return [nameExcel, wsc]
}
