import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import { HeaderContext } from 'src/context/header/headerContext'
import SearchInput from 'src/components/SearchInput'
import { getFoodStyles, deleteFoodStyle } from 'src/services/foodStyles.services'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import EstilosAddEdit from './EstilosAddEdit'
import deleteElements from 'src/services/deleteElements'
import TableApi from 'src/components/Table/TableApi'
import { Order } from 'src/interfaces/pages.types'
import { styleFoodLabels } from 'src/labels/styleFood_labels'
import { useTranslation } from 'react-i18next'

export default function Estilos(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { showHeader, setExportData, setExportName } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)

  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const wordSearch = useRef('')

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }
  // TABLE CONTROL
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const pageRef = useRef(0)

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].style,
      order: true,
      fieldtype: 'name-avatar'
    },
    {
      idField: 'numberOfPlates',
      align: 'center',
      disablePadding: false,
      label: labels[lng].platesNumber,
      fieldtype: 'number'
    },
    {
      idField: 'cousines',
      align: 'left',
      disablePadding: true,
      label: labels[lng].foodTypes,
      fieldtype: 'chips-estilos'
    }
  ]

  // EXCEL EXPORT

  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      ;(async () => {
        const style = await getFoodStyles(
          `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
          0,
          0,
          wordSearch.current
        )
        const excelFormatted = style.foodStyles?.map((style: any) => {
          const cousinesToString = style.cousines.map((cousine: string) => styleFoodLabels[lng][cousine])
          const cousinesString = cousinesToString.join(',')
          return {
            Nombre: style.name,
            'Nº de Platos': style.numberOfPlates || 0,
            'Tipos de Cocina': cousinesString
          }
        })
        setExportData(excelFormatted)
        setExportName('Estilos')
      })()
    }
    return () => setExportData(null)
  }, [rowsData])

  const fetchFoodStyles = async () => {
    setLoadingTable(true)
    try {
      const resp = await getFoodStyles(
        `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
        rowsPerPage,
        pageRef.current,
        wordSearch.current
      )
      setTotalCount(resp.total)
      setRowsData(resp.foodStyles)
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchFoodStyles()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageRef.current = 0
  }
  const handleSearch = async (word: string) => {
    wordSearch.current = word
    if (!word) {
      fetchFoodStyles()
      return
    }
    const resp = await getFoodStyles(
      `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
      rowsPerPage,
      0,
      word
    )
    pageRef.current = 0
    setTotalCount(resp.total)
    setRowsData(resp.foodStyles)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between ">
        <SearchInput placeholder={labels[lng].searchStyle} name="search" handleSubmit={handleSearch} clean />
        <Button size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModal(true)}>
          {labels[lng].addStyle}
        </Button>
      </div>
    )
  }

  useEffect(() => {
    fetchFoodStyles()
  }, [rowsPerPage, order, orderBy])

  useEffect(() => {
    if (initHeader) showHeader(Header)
    // return () => setInitHeader(false)
  }, [initHeader])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].styles, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, deleteFoodStyle)
          .then(() => {
            showSnackbar('success', labels[lng].styleDeleted)
            fetchFoodStyles()
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = (data: Record<string, unknown>) => {
    setEditData(data)
    setOpenModal(true)
  }

  return (
    <>
      <TableApi
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rows={rowsData}
        headCells={headCells}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        loading={loadingTable}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
        page={pageRef.current}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        selected={selected}
        setSelected={setSelected}
      />
      {openModal && (
        <EstilosAddEdit
          openModal={openModal}
          closeModal={closeModal}
          dataToEdit={editData}
          onDelete={onDelete}
          fetchData={fetchFoodStyles}
        />
      )}
    </>
  )
}
