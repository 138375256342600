export default function transformInToFormObject(data: Record<string, any>): any {
  function dataURLtoFile(dataurl: any, filename: any) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const getBase64FromUrl = async (url: string) => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        resolve(base64data)
      }
    })
  }

  const formData = new FormData()
  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach(async (obj: any, index: any) => {
        if (key === 'images') {
          if (typeof obj === 'string') {
            if (obj.substring(0, 4) !== 'data') {
              const urlToBase64 = await getBase64FromUrl(obj)
              const file = dataURLtoFile(urlToBase64, `image${index}.png`)

              formData.append(`${key}`, file)
            } else {
              const file = dataURLtoFile(obj, `image${index}.png`)

              formData.append(`${key}`, file)
            }
          } else if (typeof obj === 'object') formData.append(`${key}`, obj)
        } else if (typeof obj === 'object') {
          const keyList = Object.keys(obj)

          keyList.forEach((keyItem) => {
            // const keyName = [key, '[', index, ']', '.', keyItem].join('')
            const keyName = [key, '[', index, ']', '[', keyItem, ']'].join('')
            formData.append(keyName, obj[keyItem])
          })
        } else if (typeof obj === 'string') {
          formData.append(`${key}[${index}]`, obj)
        }
      })
    } else if (typeof data[key] === 'object') {
      if (key === 'photo') {
        formData.append(key, data[key])
      } else {
        if (key === 'parameter') {
          for (const innerKey in data[key]) {
            if (innerKey === 'MPOD' || innerKey === 'RFC' || innerKey === 'PP') {
              formData.append(`${key}[${innerKey}]`, data[key][innerKey].value)
            } else {
              formData.append(`${key}[${innerKey}][hour]`, data[key][innerKey].hour)
              formData.append(`${key}[${innerKey}][minutes]`, data[key][innerKey].minutes)
            }
          }
        } else {
          for (const innerKey in data[key]) {
            formData.append(`${key}[${innerKey}]`, data[key][innerKey])
          }
        }
      }
    } else {
      formData.append(key, data[key])
    }
  }
  return formData
}
