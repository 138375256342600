import React, { useState, useContext, useEffect } from 'react'

import { labels } from 'src/labels/main_labels'
import Table from 'src/components/Table/Table'
import { getUserBonosPayExpired } from 'src/services/users.services'
import { AlertContext } from 'src/context/alert/alertContext'
import { useTranslation } from 'react-i18next'
import BonosAddEdit from '../../Bonos/BonosAddEdit'

interface UsuariosDetailPlatosCaducadosProps {
  userId: string
}

export default function UsuariosDetailPlatosCaducados({ userId }: UsuariosDetailPlatosCaducadosProps): JSX.Element {
  const { showSnackbar } = useContext(AlertContext)
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  // TABLE CONTROL
  const [rowsData, setRowsData] = useState<any | null>(null)

  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [voucherPreview, setVoucherPreview] = useState(null)

  useEffect(() => {
    if (voucherPreview) setOpenModal(true)
    else setOpenModal(false)
  }, [voucherPreview])

  const headCells: any[] = [
    {
      idField: 'vouchers',
      align: 'center',
      disablePadding: true,
      label: labels[lng].quantity,
      fieldtype: 'quantity-vouchers'
    },
    {
      idField: 'price',
      align: 'center',
      disablePadding: true,
      label: labels[lng].price,
      fieldtype: 'voucher-expired-price'
    },
    {
      idField: 'voucher',
      align: 'center',
      disablePadding: false,
      label: labels[lng].voucher,
      fieldtype: 'user-gift-voucher',
      open: (bono: any) => setVoucherPreview(bono)
    },
    {
      idField: 'expiredBono',
      align: 'center',
      disablePadding: true,
      label: labels[lng].expirationDate,
      fieldtype: 'date-time-expired'
    }
  ]

  const fetchList = async () => {
    setLoadingTable(true)
    try {
      const bookings = await getUserBonosPayExpired(userId)
      // const bookingsFiltered = bookings.filter((elm) => new Date(elm.expiredBono) < new Date())
      setRowsData(bookings.map((elm) => {
        if (elm.id_bono.company) {
          elm.id_bono.giftQuantity = elm.companyConfig.giftQuantity;
          elm.id_bono.quantity = elm.companyConfig.quantity;
          elm.id_bono.expiration = elm.companyConfig.expires;
          elm.id_bono.price = true;
        }
        return elm;
      }))
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <>
      <Table
        rows={rowsData}
        headCells={headCells}
        snackBar
        selected={selected}
        setSelected={setSelected}
        initialOrderBy="expiredBono"
        initialOrder="desc"
        noActions
        noCheckbox
        loading={loadingTable}
      />
      {openModal && (
        <BonosAddEdit
          disabled
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false)
            setVoucherPreview(null)
          }}
          dataToEdit={voucherPreview}
        />
      )}
    </>
  )
}
