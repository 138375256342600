import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Typography, Stack, LinearProgress, Box, Tab, Tabs } from '@mui/material'

import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'

import { RestaurantsChart } from 'src/components/Charts/RestaurantChart'
import CardSummarySmall from 'src/components/Cards/CardSummarySmall'
import { ContainerFud } from 'src/styles/general-styles'
import { labels } from 'src/labels/main_labels'
import { getRestaurantStatitics } from 'src/services/restaurants.services'
import StatiticsSelect from 'src/components/Form/StatiticsSelect'
import { statiticsOptionsSelect } from 'src/constants/const'
import { ReactComponent as PlatosVendidos } from 'src/assets/icons/platos-estadisticas-icons/PlatosVendidos.svg'
import { ReactComponent as DiasDisponibles } from 'src/assets/icons/platos-estadisticas-icons/DiasDisponibles.svg'
import { ReactComponent as MediaDiaria } from 'src/assets/icons/platos-estadisticas-icons/MediaDiaria.svg'
import { ReactComponent as MediaSemanal } from 'src/assets/icons/platos-estadisticas-icons/MediaSemanal.svg'
import { ReactComponent as MediaSemanalEn } from 'src/assets/icons/platos-estadisticas-icons/MediaSemanalEn.svg'
import { ReactComponent as Incomes } from 'src/assets/icons/platos-estadisticas-icons/Incomes.svg'
import { ReactComponent as MediaMensual } from 'src/assets/icons/platos-estadisticas-icons/MediaMensual.svg'
import subDays from 'date-fns/subDays'
import { getDateFromLabel, transformChartData } from './statitics.utils'
import { RestaurantTypes } from 'src/interfaces/pages.types'
import StatiticsFilter from './components/StatiticsFilter'
import { dataToCurrency } from 'src/utils/helpers'
import { useTranslation } from 'react-i18next'
import { addDays, addMonths } from 'date-fns'
import { theme } from 'src/styles/theme'
import { ChevronLeft } from '@mui/icons-material'

interface RestauranteEstadisticasProps {
  type: string
  restaurantInfo: RestaurantTypes
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function RestauranteEstadisticas({ type, restaurantInfo }: RestauranteEstadisticasProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { showHeader } = useContext(HeaderContext)
  const { restaurant } = useContext(RestaurantContext)
  const { showSnackbar } = useContext(AlertContext)

  const params: any = useParams()
  const [statitics, setStatitics] = useState<any>(null)
  const [chartData, setChartData] = useState<any>([])
  const [type2, setType2] = useState('days')
  const [dateFrom, setDateFrom] = useState<Date>(subDays(new Date(), 27))
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [value, setValue] = useState(type2)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
    setType2(newValue)
  }

  const fetchStatitics = async (from: Date, to: Date) => {
    try {
      let fetchData
      if ((type || type2) && params.idRestaurant) fetchData = await getRestaurantStatitics(params.idRestaurant, type || type2, from, to)
      if (restaurant?._id) fetchData = await getRestaurantStatitics(restaurant._id, type2, from, to)
      if (fetchData) {
        setStatitics(fetchData)
      }
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  useEffect(() => {
    fetchStatitics(subDays(new Date(), 27), new Date())
  }, [params.idRestaurant, restaurant])

  const controlTypeSelect = () => {
    let from
    const tempType = type || type2
    if (tempType === 'days') from = subDays(new Date(), 27)
    if (tempType === 'months') from = subDays(new Date(), 365)
    if (tempType === 'weeks') from = subDays(new Date(), 91)
    if (from) fetchStatitics(from, subDays(new Date(), 0))
  }

  useEffect(() => {
    if (statitics) controlTypeSelect()
  }, [type, type2])

  useEffect(() => {
    if (statitics && statitics.calendar) {
      const tempType = type || type2
      const dataTransformed = transformChartData(statitics, tempType)
      setDateFrom(dataTransformed.dateFrom)
      setDateTo(dataTransformed.dateTo)
      setChartData(dataTransformed.chartData)
    }
  }, [statitics])

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    const history = useHistory()

    return (
      <div className="flex-center" style={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: '-2px',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.goBack()} />
        </Box>
        <Typography variant="h3" component="h1">
          {labels[lng].restaurantStatitics}
        </Typography>
      </div>
    )
  }

  useEffect(() => {
    if (userData.role !== 'admin') showHeader(Header)
  }, [])
  const prevBlockData = () => {
    const tempType = type || type2
    let from
    if (tempType === 'days' || tempType === 'weeks') {
      const numberDays = tempType === 'days' ? 27 : 90
      from = subDays(dateFrom, numberDays)
    }
    if (tempType === 'months') {
      from = new Date(dateFrom).setMonth(new Date(dateFrom).getMonth() - 12)
    }
    if (from) fetchStatitics(subDays(new Date(from), 1), subDays(new Date(dateFrom.setHours(9, 0, 0, 0)), 1))
  }

  const nextBlockData = () => {
    const tempType = type || type2
    let to
    if (tempType === 'days' || tempType === 'weeks') {
      const numberDays = tempType === 'days' ? 27 : 82

      to = addDays(dateTo, numberDays)
      if (tempType === 'weeks') {
        fetchStatitics(subDays(new Date(dateTo.setHours(9, 0, 0, 0)), 6), addDays(new Date(to), 1))
        return
      }
    }
    if (tempType === 'months') {
      to = new Date(dateTo).setMonth(new Date(dateTo).getMonth() + 12)
    }
    if (to) fetchStatitics(addDays(new Date(dateTo.setHours(9, 0, 0, 0)), 1), addDays(new Date(to), 1))
  }

  const IncomeFilterRestaurantPlatform = () => {
    const createdAt = restaurant?.createdAt || restaurantInfo.createdAt!
    const tempType = type || type2
    let lastDateTo = new Date(dateTo)
    lastDateTo.setHours(23, 59, 59, 0)
    if (tempType === 'months') {
      lastDateTo = addMonths(lastDateTo, 1);
    }
    return (
      <StatiticsFilter
        type={tempType}
        text={getDateFromLabel(tempType, dateFrom, tempType === 'weeks' ? subDays(dateTo, 1) : dateTo, lng)}
        arrowLeft={dateFrom.getTime() > new Date(createdAt).getTime()}
        arrowRight={lastDateTo.getTime() < new Date().getTime()}
        prevAction={prevBlockData}
        nextAction={nextBlockData}
      />
    )
  }

  const displayTabs = () => {
    const res = statiticsOptionsSelect?.map((option, idx) => {
      const parts: string[] = labels[lng][option.label].split('(')
      const Label: any = (
        <Stack flexDirection={'column'}>
          <span>{parts[0]}</span>
          <span
            style={{
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: 500
            }}
          >
            {parts[1] ? `(${parts[1]}` : ''}
          </span>
        </Stack>
      )
      return <Tab wrapped value={option.value} label={Label} key={`tab-${idx}`} {...a11yProps(idx)} />
    })

    return res
  }

  return (
    <>
      {!statitics ? (
        <LinearProgress />
      ) : (
        <>
          {userData?.role !== 'admin' && window.innerWidth >= 890 && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ height: 60, borderBottom: { xs: 'none', md: '1px solid #E5E7EB' }, pl: 2, py: 1 }}
              position={'sticky'}
              top={'60px'}
              bgcolor={'#FFFFFF'}
              zIndex={1}
            >
              <StatiticsSelect options={statiticsOptionsSelect} value={type2} setter={setType2} />
            </Stack>
          )}
          {window.innerWidth < 890 && (
            <Box
              sx={{
                borderBottom: 0,
                borderColor: 'divider',
                position: 'fixed',
                zIndex: 1,
                width: '100vw',
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                [theme.breakpoints.up('md')]: {
                  display: 'none'
                }
              }}
            >
              <Tabs
                sx={{
                  '& .MuiTabs-flexContainer': {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    width: '100%'
                  },
                  '& .MuiTab-root': {
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 500,
                    textTransform: 'lowercase'
                  }
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {displayTabs()}
              </Tabs>
            </Box>
          )}
          <ContainerFud sx={{ p: 2, pt: 0 }}>
            <Box
              sx={{
                height: '60px',
                [theme.breakpoints.up('md')]: {
                  display: 'none'
                }
              }}
            />
            <RestaurantsChart title={labels[lng].platesSold} dataChart={chartData} IncomeFilter={IncomeFilterRestaurantPlatform} />
            <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
              <Grid item sm={6} xs={6} lg={3}>
                <CardSummarySmall
                  Icon={PlatosVendidos}
                  title={statitics?.totalQuantity === 1 ? labels[lng].platesSoldSingular : labels[lng].platesSold}
                  text={statitics && statitics?.totalQuantity}
                />
              </Grid>
              <Grid item sm={6} xs={6} lg={3}>
                <CardSummarySmall
                  Icon={DiasDisponibles}
                  title={+statitics?.mediaDay?.toFixed(0) === 1 ? labels[lng].availableDaysSingular : labels[lng].availableDays}
                  text={statitics && statitics?.mediaDay?.toFixed(0).replace('.', ',')}
                />
              </Grid>
              <Grid item sm={6} xs={6} lg={3}>
                <CardSummarySmall
                  Icon={MediaDiaria}
                  title={labels[lng].dailyAverage}
                  text={(statitics && statitics?.mediaDays?.toFixed(1).replace('.', ',')) || '0,0'}
                  finalText={labels[lng].plates}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={6}
                lg={(type || type2) === 'months' ? 6 : 3}
                sx={{
                  [theme.breakpoints.up('md')]: {
                    display: 'none'
                  }
                }}
              >
                <CardSummarySmall Icon={Incomes} title={labels[lng].incomes} text={statitics && dataToCurrency(statitics?.totalIncome)} />
              </Grid>
              <Grid item sm={6} xs={12} lg={3}>
                <CardSummarySmall
                  variant="subtitle1"
                  Icon={lng === 'es' ? MediaSemanal : MediaSemanalEn}
                  title={labels[lng].weeklyAverage}
                  text={(statitics && statitics?.mediaWeeks?.toFixed(1).replace('.', ',')) || '0,0'}
                  finalText={labels[lng].plates}
                  extraFinalText={dataToCurrency(statitics?.mediaIncomeWeeks)}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={6}
                lg={(type || type2) === 'months' ? 6 : 3}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    display: 'none'
                  }
                }}
              >
                <CardSummarySmall Icon={Incomes} title={labels[lng].incomes} text={statitics && dataToCurrency(statitics?.totalIncome)} />
              </Grid>
              {(type || type2) === 'months' && (
                <Grid item sm={6} xs={12}>
                  <CardSummarySmall
                    variant="subtitle1"
                    Icon={MediaMensual}
                    title={labels[lng].monthlyAverage}
                    text={(statitics && statitics?.mediaMonths.toFixed(1).replace('.', ',')) || '0,0'}
                    finalText={labels[lng].plates}
                    extraFinalText={dataToCurrency(statitics?.mediaIncomeMonths)}
                  />
                </Grid>
              )}
            </Grid>
          </ContainerFud>
        </>
      )}
    </>
  )
}
