import React, { FC } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { CardSummaryContainer } from './cards.styles'
import { Variant } from '@mui/material/styles/createTypography'
import { theme } from 'src/styles/theme'

interface CardSummarySmallProps {
  Icon: FC
  title: string
  children?: React.ReactNode
  text?: string | number
  variant?: Variant
  finalText?: string
  extraFinalText?: string
}

export default function CardSummarySmall({
  children,
  title,
  Icon,
  text,
  variant = 'h4',
  finalText,
  extraFinalText
}: CardSummarySmallProps): JSX.Element {
  return (
    <CardSummaryContainer>
      <Icon />
      <Box
        sx={{
          ml: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          [theme.breakpoints.down('md')]: {
            ml: '10px'
          }
        }}
      >
        <Typography
          variant="subtitle2"
          component="strong"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '12px',
              lineHeight: '16px',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1
            }
          }}
        >
          {title}
        </Typography>

        <Stack direction="row" sx={{ alignItems: 'flex-end', flexWrap: 'wrap' }}>
          {text || text === 0 ? (
            <Typography
              sx={{
                color: '#111827',
                fontWeight: 600,
                [theme.breakpoints.down('md')]: {
                  fontSize: '18px',
                  lineHeight: '22px'
                }
              }}
              variant={variant}
              component="strong"
            >
              {text.toString() || '-'}
            </Typography>
          ) : null}
          {children ? children : null}

          {finalText ? (
            <Typography
              sx={{
                fontWeight: 600,
                pb: variant === 'subtitle1' ? 0 : 0.5,
                pl: 0.5,
                [theme.breakpoints.down('md')]: {
                  fontSize: '12px',
                  lineHeight: '22px',
                  pb: 0
                }
              }}
              variant="body1"
              component="strong"
            >
              {finalText.toLowerCase()}
            </Typography>
          ) : null}
          {extraFinalText ? (
            <Typography
              sx={{
                ml: 0.5,
                fontWeight: 600,
                color: '#111827',
                [theme.breakpoints.down('md')]: {
                  fontSize: '18px',
                  lineHeight: '22px'
                }
              }}
              variant={variant}
              component="strong"
            >
              ({extraFinalText})
            </Typography>
          ) : null}
        </Stack>
      </Box>
    </CardSummaryContainer>
  )
}
