import React, { useState, useEffect, useContext } from 'react'
import { CardContent, CardMedia, Stack, Typography } from '@mui/material'

import { ReactComponent as Food } from 'src/assets/icons/card-icons/Food.svg'
import { ReactComponent as Clock } from 'src/assets/icons/card-icons/Clock.svg'
import { CardContainer, CardHeader, EditButton } from './cards.styles'
import EditIcon from '@mui/icons-material/Edit'
import { PlanningRecursiveTypes, PlanningTypes, PlateTypes, RestaurantTypes } from 'src/interfaces/pages.types'
import { timeConstructor } from 'src/utils/time-helpers'
import { AuthContext } from 'src/context/auth/authContext'
import PlanCardBadge from './PlanCardBadge'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'

interface CardPlanDishProps {
  plan: PlanningTypes
  dayWeek: string
  whatDayIs: string | null
  idx: number
  handleCard: (dayWeek: string, whatDayIs: string | null, idx: number, recursivePlan?: PlanningRecursiveTypes, plan?: PlanningTypes) => void
  recursivePlanning?: PlanningRecursiveTypes
  restaurant: RestaurantTypes
}

export default function CardPlanDish({
  plan,
  dayWeek,
  handleCard,
  whatDayIs,
  recursivePlanning,
  idx,
  restaurant
}: CardPlanDishProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const [plate, setPlate] = useState<PlateTypes | undefined>(undefined)

  const getPlate = () => {
    if (restaurant && restaurant.plates) {
      setPlate(restaurant.plates.find((elm) => elm._id === plan.plate))
    }
  }
  useEffect(() => {
    getPlate()
    return () => {
      setPlate(undefined)
    }
  }, [restaurant, plan])

  const samePlateToRecursivePlanning = () => {
    const FRrHourPlanning = plan.parameter?.FRr.hour
    const FRrMinPlanning = plan.parameter?.FRr.minutes
    const IRrHourPlanning = plan.parameter?.IRr.hour
    const IRrMinPlanning = plan.parameter?.IRr.minutes
    const quantityPlanning = plan?.quantity
    const FRrHourRecursive = recursivePlanning?.parameter?.FRr.hour
    const FRrMinRecursive = recursivePlanning?.parameter?.FRr.minutes
    const IRrHourRecursive = recursivePlanning?.parameter?.IRr.hour
    const IRrMinRecursive = recursivePlanning?.parameter?.IRr.minutes
    const quantityRecursive = recursivePlanning?.quantity
    const arrayParameters = {
      FRrHours: [FRrHourPlanning, FRrHourRecursive],
      IRrHours: [IRrHourPlanning, IRrHourRecursive],
      FRrMinutes: [FRrMinPlanning, FRrMinRecursive],
      IRrMinutes: [IRrMinPlanning, IRrMinRecursive],
      quantity: [quantityPlanning, quantityRecursive]
    }

    return plate?._id !== recursivePlanning?.plate._id || !Object.values(arrayParameters).every(([time1, time2]) => time1 === time2)
  }

  const getPlateName = (plate: PlateTypes) => {
    return lng === 'en' ? (plate?.translations && plate?.translations[0] ? plate?.translations[0].name : plate.name) : plate.name
  }

  if (!plate) return <></>
  return (
    <CardContainer elevation={0}>
      {recursivePlanning && samePlateToRecursivePlanning() && (
        <PlanCardBadge whatDayIs={whatDayIs || ''} dayWeek={dayWeek} recursivePlanning={recursivePlanning} />
      )}
      {!recursivePlanning && plan && <PlanCardBadge whatDayIs={whatDayIs || ''} dayWeek={dayWeek} />}
      <CardHeader>
        <Typography variant="subtitle2">{dayWeek}</Typography>
      </CardHeader>
      <CardMedia component="img" height="193" image={plate.images ? plate.images[0] : ''} alt={plate.name} />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography gutterBottom variant="subtitle1" component="div" className="ellipsis-simple">
          {getPlateName(plate)}
        </Typography>
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
          <Clock />
          <Typography variant="body1">
            {timeConstructor(plan.parameter?.IRr.hour, plan.parameter?.IRr.minutes)} -{' '}
            {timeConstructor(plan.parameter?.FRr.hour, plan.parameter?.FRr.minutes)}
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
          <Food />
          <Typography maxWidth={'70%'} textOverflow="ellipsis" overflow={'hidden'} whiteSpace="nowrap" variant="body1">
            {plan.quantity} {plan.quantity === 1 ? labels[lng].availablePlateOrders : labels[lng].availablePlatesOrders}
          </Typography>
        </Stack>
        {userData && (
          <EditButton
            size="small"
            color="info"
            variant="outlined"
            onClick={() => handleCard(dayWeek, whatDayIs, idx, recursivePlanning, plan)}
          >
            <EditIcon />
          </EditButton>
        )}
      </CardContent>
    </CardContainer>
  )
}
