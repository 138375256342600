import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import { ReactComponent as PlusCircleGray } from 'src/assets/icons/card-icons/PlusCircleGray.svg'
import { CardContainer, CardHeader, CardWithAction, CardNoAction } from './cards.styles'
import { PlanningRecursiveTypes } from 'src/interfaces/pages.types'
import { AuthContext } from 'src/context/auth/authContext'
import PlanCardBadge from './PlanCardBadge'

interface CardPlanDishAddNewProps {
  handleCard: (dayWeek: string, whatDayIs: string | null, idx: number, recursivePlan?: PlanningRecursiveTypes) => void
  text: string
  whatDayIs: string | null
  dayWeek: string
  idx: number
  recursivePlanning?: PlanningRecursiveTypes
}

export default function CardPlanDishAddNew({
  handleCard,
  text,
  dayWeek,
  whatDayIs,
  idx,
  recursivePlanning
}: CardPlanDishAddNewProps): JSX.Element {
  const { userData } = useContext(AuthContext)

  return (
    <CardContainer sx={{ borderStyle: 'dashed' }} elevation={0} onClick={() => handleCard(dayWeek, whatDayIs, idx, recursivePlanning)}>
      {recursivePlanning && <PlanCardBadge whatDayIs={whatDayIs || ''} dayWeek={dayWeek} recursivePlanning={recursivePlanning} />}
      <CardHeader>
        <Typography variant="subtitle2">{dayWeek}</Typography>
      </CardHeader>
      {userData.role !== 'admin' ? (
        <CardWithAction>
          <PlusCircleGray />
          <Typography variant="h6">{text}</Typography>
        </CardWithAction>
      ) : (
        <CardNoAction>
          <PlusCircleGray />
          <Typography variant="h6">{text}</Typography>
        </CardNoAction>
      )}
    </CardContainer>
  )
}
