import { styled } from '@mui/material/styles'
import { DialogTitle, Dialog } from '@mui/material'
import { grey } from '@mui/material/colors'

export const ModalTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 500,
  fontStyle: 'normal',
  lineHeight: '24px',
  letterSpacing: '0em',
  margin: theme.spacing(0),

  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#111827',
  '& .MuiIconButton-root': {
    color: grey[500]
  },
  paddingTop: 0,
  [theme.breakpoints.down('md')]: {
    // boxShadow: '0px 2px 10px 0px #0000000D',
    zIndex: 0
  }
}))

export const ModalDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  },
  [theme.breakpoints.down('md')]: {
    margin: '16px',
    widht: '100%',
    '& .MuiPaper-root': {
      margin: 0,
      width: '100%'
    },
    '& .MuiDialogContent-root': {
      borderBottom: 'none',
      borderTop: 'none'
    },
    '& .MuiDialogActions-root': {
      boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
      paddingTop: '12px',
      marginBottom: 'env(safe-area-inset-bottom)',
      minHeight: '70px'
    }
  }
}))
