import { RestaurantState } from './restaurantContext'
import { FETCH_RESTAURANT, REMOVE_RESTAURANT, FETCH_NEIGHBORHOOD, FETCH_PARAMETERS } from '../actions'
import { RestaurantTypes, NeighborhoodTypes } from 'src/interfaces/pages.types'

type RestaurantAction =
  | { type: 'FETCH_RESTAURANT'; payload: { restaurant: RestaurantTypes } }
  | { type: 'FETCH_NEIGHBORHOOD'; payload: { neighborhood: NeighborhoodTypes[] } }
  | { type: 'FETCH_PARAMETERS'; payload: { parameters: any } }
  | { type: 'REMOVE_RESTAURANT' }

export const restaurantReducer = (state: RestaurantState, action: RestaurantAction): RestaurantState => {
  switch (action.type) {
    case FETCH_RESTAURANT:
      return {
        ...state,
        restaurant: action.payload.restaurant
      }
    case FETCH_PARAMETERS:
      return {
        ...state,
        parameters: action.payload.parameters
      }
    case FETCH_NEIGHBORHOOD:
      return {
        ...state,
        neighborhood: action.payload.neighborhood
      }
    case REMOVE_RESTAURANT:
      return {
        ...state,
        restaurant: null
      }
    default:
      return state
  }
}
