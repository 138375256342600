import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Link, useHistory, useParams } from 'react-router-dom'
import { Box, IconButton, LinearProgress, Skeleton, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'

import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { getRestaurantById } from 'src/services/restaurants.services'
import { getPlateById, deletePlate } from 'src/services/plates.services'
import { ReactComponent as Selector } from 'src/assets/icons/general/Selector.svg'

import { PlateTypes } from 'src/interfaces/pages.types'
import { SearchDialog } from 'src/components/Modals/SearchDialog'
import { labels } from 'src/labels/main_labels'
import Resumen from 'src/pages/Common/Platos/Resumen'
import PlateStatitics from 'src/pages/Common/Estadisticas/PlateStatitics'
import Valoraciones from 'src/pages/Common/Platos/Valoraciones'
import StatiticsSelect from 'src/components/Form/StatiticsSelect'
import { statiticsOptionsSelect } from 'src/constants/const'
import NotFound from 'src/components/NotFound'
import { OptionButton } from 'src/components/Form/buttonSelector.styles'
import { StickyMenuStack } from 'src/styles/general-styles'
import Button from '@mui/material/Button'
import deleteElements from 'src/services/deleteElements'
import PlatosAddEdit from 'src/pages/RestaurantPlatform/Platos/PlatosAddEdit'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'
import { ChevronLeft } from '@mui/icons-material'

export default function PlatosDetail(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const MENU_PLATOS = [
    { value: 'resumen', text: labels[lng].summary },
    { value: 'estadisticas', text: labels[lng].statistics },
    { value: 'valoraciones', text: labels[lng].ratings }
  ]

  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const { restaurant } = useContext(RestaurantContext)
  const history = useHistory()
  const params: any = useParams()
  const [openDialog, setOpenDialog] = useState(false)
  const [menuNav, setMenuNav] = useState('resumen')
  const [openModal, setOpenModal] = useState(false)

  const [error404, setError404] = useState(false)
  const [plato, setPlato] = useState<PlateTypes | null>(null)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('days')

  const navigationLinking = (value: string) => {
    if (params.idRestaurant) history.push(`/restaurantes/${params.idRestaurant}/platos/${params.plato}/${value}`)
    else history.push(`/platos/${params.plato}/${value}`)
  }

  // DIALOG
  const handleClose = () => {
    setOpenDialog(false)
  }

  const changePlato = (plato: PlateTypes) => {
    handleClose()
    if (params.idRestaurant) history.push(`/restaurantes/${params.idRestaurant}/platos/${plato._id}`)
    else history.push(`/platos/${plato._id}`)
  }

  useEffect(() => {
    if (params.navPlatos && MENU_PLATOS.map((elm) => elm.value)) setMenuNav(params.navPlatos)
    else setMenuNav('resumen')
  }, [params.navPlatos])

  const fetchPlato = async () => {
    setError404(false)
    setLoading(true)
    try {
      const platoDetails = await getPlateById(params.plato)
      setPlato(platoDetails)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
      setError404(true)
    } finally {
      setLoading(false)
    }
  }

  const [options, setOptions] = useState<PlateTypes[]>([])
  const fetchRestaurant = async () => {
    const idRest = params.idRestaurant || restaurant?._id
    const restaurantDetails = await getRestaurantById(idRest)
    if (restaurantDetails && restaurantDetails.plates) setOptions(restaurantDetails.plates)
  }

  useEffect(() => {
    if (params.plato) {
      fetchRestaurant()
      fetchPlato()
    }
    return () => setPlato(null)
  }, [params.plato])

  const backLink = () => {
    if (params.idRestaurant) return `/restaurantes/${params.idRestaurant}/platos`
    else return `/platos`
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    const getPlateName = (plate) => {
      return lng === 'en' ? (plate?.translations && plate?.translations[0] ? plate?.translations[0].name : plate.name) : plate.name
    }

    return (
      <div className="flex-between" style={{ position: 'relative' }}>
        <div className="flex-center">
          <Link to={backLink()}>
            <IconButton
              sx={{
                ml: '-14px',
                [theme.breakpoints.down('md')]: {
                  paddingRight: 0,
                  padding: 0,
                  ml: 0,
                  display: 'none'
                }
              }}
            >
              <ChevronLeftIcon sx={{ color: grey[500] }} />
            </IconButton>
          </Link>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: '-2px',
              [theme.breakpoints.up('md')]: {
                display: 'none'
              }
            }}
          >
            <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.goBack()} />
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              textTransform: 'capitalize',
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            }}
          >
            {params.idRestaurant && plato?.restaurant ? plato.restaurant.name : labels[lng].plates}
          </Typography>
        </div>
        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
          className="flex-center"
        >
          <Typography variant="subtitle1" component="h1">
            {plato ? getPlateName(plato) : <Skeleton sx={{ width: 200 }} animation="wave" />}
          </Typography>
          <IconButton onClick={() => setOpenDialog(true)}>
            <Selector />
          </IconButton>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
          className="flex-center"
        >
          <Typography lineHeight={'20px'} variant="h3" component="h1" position={'relative'} left="0px">
            {menuNav === 'resumen' ? labels[lng].plateSummary : menuNav === 'estadisticas' ? 'Estadísticas del plato' : 'Valoraciones'}
          </Typography>
        </Box>
        {params.idRestaurant ? (
          <Button size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModal(true)}>
            {labels[lng].editPlate}
          </Button>
        ) : (
          <div />
        )}
      </div>
    )
  }
  useEffect(() => {
    showHeader(Header)
  }, [plato])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].plates, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data._id, deletePlate)
          .then(() => {
            showSnackbar('success', labels[lng].plateDeleted)
            history.push(`/restaurantes/${params.idRestaurant}/platos`)
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  if (error404) return <NotFound link={`/platos`} />

  return (
    <>
      <Box sx={{ position: 'relative' }} />
      <StickyMenuStack
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }}
      >
        <div>
          {MENU_PLATOS.map((elm: any) => (
            <OptionButton
              startIcon={elm.Icon ? <elm.Icon /> : null}
              key={elm.value}
              sx={{
                backgroundColor: (theme) => (menuNav === elm.value ? theme.palette.grey[200] : '#fff'),
                color: menuNav === elm.value ? '#374151' : 'inherit'
              }}
              disableElevation
              variant="contained"
              onClick={() => navigationLinking(elm.value)}
            >
              {elm.text}
            </OptionButton>
          ))}
        </div>
        {/* <ButtonSelector height="60px" valueSelected={menuNav} setValue={navigationLinking} options={MENU_PLATOS} /> */}
        {menuNav === 'estadisticas' && plato && <StatiticsSelect options={statiticsOptionsSelect} value={type} setter={setType} />}
      </StickyMenuStack>
      {loading && <LinearProgress />}
      {menuNav === 'resumen' && plato && (
        <Resumen
          onClickSeeMore={() => navigationLinking('estadisticas')}
          onClickSeeMoreRatings={() => navigationLinking('valoraciones')}
          plate={plato}
        />
      )}
      {menuNav === 'estadisticas' && plato && (
        <PlateStatitics
          plato={plato}
          type={type}
          select={plato ? <StatiticsSelect options={statiticsOptionsSelect} value={type} setter={setType} /> : null}
          options={statiticsOptionsSelect}
          setter={setType}
        />
      )}
      {menuNav === 'valoraciones' && plato && <Valoraciones platoId={plato._id} />}
      {openModal && (
        <PlatosAddEdit
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          dataToEdit={plato}
          onDelete={onDelete}
          fetchData={fetchPlato}
        />
      )}

      <SearchDialog
        selected={[plato?._id]}
        onClose={handleClose}
        open={openDialog}
        optionsData={options}
        setter={changePlato}
        text="plato"
      />
    </>
  )
}
