import React from 'react'
import { Chip, Grid, Typography, TextField, Box } from '@mui/material'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import { ChipAdd, ChipElement, ChipGrid, GridFlexBetween, MainBox } from './form.styles'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { es } from 'date-fns/locale'
import { labels } from 'src/labels/main_labels'
import { useTranslation } from 'react-i18next'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

import { dateTimeConvertLng } from 'src/utils/time-helpers'
import { theme } from 'src/styles/theme'
import { getLabel } from '../Modals/Dialog.utils'
interface TextInputProps {
  ariaLabel?: string
  // handleChange?: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleChange?: (any: any) => void
  data?: string[]
  name: string
  id?: string
  text: string
  placeholder: string
  value: string | number
  multiline?: boolean
  type?: string
  // Badge error into text input
  errorBadge?: string
  required?: boolean
  price?: boolean
  percentage?: boolean
  disabled?: boolean
  noTopBorder?: boolean
  noBottomBorder?: boolean
  min?: number
  max?: number
  byDefaultText?: boolean
  step?: string
  valid?: string
  onClick?: () => void
  onChipDelete?: (name) => void
}

export default function TextInput({
  ariaLabel,
  name,
  data,
  id,
  text,
  placeholder,
  handleChange,
  value,
  multiline,
  type = 'text',
  errorBadge,
  price,
  percentage,
  disabled,
  noTopBorder,
  noBottomBorder,
  byDefaultText,
  step = '1',
  valid,
  onClick,
  onChipDelete,
  ...props
}: TextInputProps & InputBaseProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  return (
    <MainBox
      sx={{
        borderTop: noTopBorder ? 'none' : '',
        borderBottom: noBottomBorder ? 'none' : '',
        [theme.breakpoints.down('md')]: {
          padding: '16px'
        }
      }}
    >
      <GridFlexBetween container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="h6">
            {text}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ position: 'relative' }}>
          {type === 'date' && (
            <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={value}
                disabled={disabled || false}
                onChange={(fecha: any) => {
                  if (fecha && handleChange && fecha instanceof Date) {
                    handleChange({ target: { name: 'expiration', value: fecha } })
                  }
                }}
                showToolbar
                renderInput={(params: any) => {
                  params.inputProps = {
                    ...params.inputProps,
                    value: value && dateTimeConvertLng(value, lng, true),
                    placeholder: placeholder
                  }
                  const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker

                  params.InputProps = { ...params.InputProps }
                  return (
                    <TextField
                      sx={{
                        width: '100%',
                        '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                        '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                        input: { fontFamily: 'Inter', fontSize: 14 },
                        label: { fontFamily: 'Inter', fontSize: 14 }
                      }}
                      variant="standard"
                      {...params}
                      onClick={openClick}
                    />
                  )
                }}
              />
            </LocalizationProvider>
          )}

          {type === 'number' && (
            <InputBase
              sx={{ fontFamily: 'Inter' }}
              value={value}
              onChange={handleChange ? (e) => handleChange(e) : undefined}
              name={name}
              id={id || name}
              fullWidth
              placeholder={placeholder}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 200, 'aria-label': ariaLabel || text, step }}
              type="number"
              disabled={disabled}
              endAdornment={
                errorBadge ? (
                  <Chip
                    sx={{ height: 20, backgroundColor: '#FEE2E2', color: '#991B1B', fontSize: '12px', lineHeight: '16px', fontWeight: 500 }}
                    label={errorBadge}
                  />
                ) : (
                  valid && (
                    <Chip
                      sx={{
                        height: 20,
                        backgroundColor: '#F3F4F6',
                        color: '#1F2937',
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontWeight: 500
                      }}
                      label={valid}
                    />
                  )
                )
              }
              {...props}
            />
          )}

          {type === 'range' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
              <InputBase
                sx={{ fontFamily: 'Inter' }}
                value={type === 'range' ? value[0] : value}
                onChange={handleChange ? (e) => handleChange(e) : undefined}
                name={`${name}-min`}
                id={id || name}
                placeholder={'0'}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 200, 'aria-label': ariaLabel || text, step }}
                type="number"
                disabled={disabled}
                endAdornment={
                  errorBadge ? (
                    <Chip
                      sx={{
                        height: 20,
                        backgroundColor: '#FEE2E2',
                        color: '#991B1B',
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontWeight: 500
                      }}
                      label={errorBadge}
                    />
                  ) : (
                    valid && (
                      <>
                        <Chip
                          sx={{
                            marginLeft: '18px',
                            height: 20,
                            backgroundColor: '#F3F4F6',
                            color: '#1F2937',
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontWeight: 500
                          }}
                          label={valid}
                        />
                      </>
                    )
                  )
                }
                {...props}
              />

              <Typography sx={{ width: '80px', textAlign: 'center' }}>a</Typography>
              <InputBase
                sx={{ fontFamily: 'Inter' }}
                value={type === 'range' ? value[1] : value}
                onChange={handleChange ? (e) => handleChange(e) : undefined}
                name={`${name}-max`}
                id={id || name}
                // fullWidth
                placeholder={'0'}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 200, 'aria-label': ariaLabel || text, step }}
                type="number"
                disabled={disabled}
                endAdornment={
                  errorBadge ? (
                    <Chip
                      sx={{
                        marginLeft: '18px',
                        height: 20,
                        backgroundColor: '#FEE2E2',
                        color: '#991B1B',
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontWeight: 500
                      }}
                      label={errorBadge}
                    />
                  ) : (
                    valid && (
                      <>
                        <Chip
                          sx={{
                            height: 20,
                            backgroundColor: '#F3F4F6',
                            color: '#1F2937',
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontWeight: 500
                          }}
                          label={valid}
                        />
                      </>
                    )
                  )
                }
                {...props}
              />
            </Box>
          )}

          {type === 'enterprises' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                flexDirection: 'row'
              }}
            >
              <InputBase
                sx={{ fontFamily: 'Inter' }}
                value={value}
                onChange={handleChange ? (e) => handleChange(e) : undefined}
                name={name}
                id={id}
                type="text"
                placeholder={placeholder}
              />
              <ChipGrid>
                {data &&
                  data.map((elm: any, idx: number) => (
                    <ChipElement key={idx}>
                      <Chip
                        size="small"
                        label={getLabel(elm)}
                        variant="outlined"
                        onDelete={() => {
                          if (onChipDelete) onChipDelete(getLabel(elm))
                        }}
                      />
                    </ChipElement>
                  ))}
              </ChipGrid>
              <ChipAdd
                onClick={onClick}
                sx={{ cursor: 'pointer' }}
                icon={<AddOutlinedIcon />}
                size="small"
                label={labels[lng].add}
                variant="filled"
                color="primary"
              />
            </Box>
          )}

          {type !== 'number' && type !== 'date' && type !== 'range' && type !== 'enterprises' && (
            <InputBase
              sx={{ color: type === 'date' ? '#9CA3AF' : 'inherit', fontFamily: 'Inter' }}
              value={value}
              onChange={handleChange ? (e) => handleChange(e) : undefined}
              name={name}
              id={id || name}
              placeholder={placeholder}
              inputProps={
                type === 'number'
                  ? { inputMode: 'numeric', pattern: '[0-9]*', min: 0, 'aria-label': ariaLabel || text }
                  : { 'aria-label': ariaLabel || text }
              }
              fullWidth
              multiline={multiline}
              type={type}
              disabled={disabled}
              endAdornment={
                errorBadge ? (
                  <Chip
                    sx={{
                      height: 20,
                      backgroundColor: '#FEE2E2',
                      color: '#991B1B',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: 500
                    }}
                    label={errorBadge}
                  />
                ) : (
                  valid && (
                    <Chip
                      sx={{
                        height: 20,
                        backgroundColor: '#F3F4F6',
                        color: '#1F2937',
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontWeight: 500
                      }}
                      label={valid}
                    />
                  )
                )
              }
              {...props}
            />
          )}

          {price && value && (
            <Typography
              sx={{
                color: disabled ? '#6B7280' : '#111827',
                position: 'absolute',
                top: 20,
                left: `${value.toString().length * 10 + 14}px`
              }}
            >
              € <span style={{ color: '#6B7280' }}>{byDefaultText && `(${labels[lng].byDefault})`}</span>
            </Typography>
          )}
          {percentage && value && (
            <Typography
              sx={{
                color: disabled ? '#6B7280' : '#111827',
                position: 'absolute',
                top: 20,
                left: `${value.toString().length * 10 + 14}px`
              }}
            >
              % <span style={{ color: '#6B7280' }}>{byDefaultText && `(${labels[lng].byDefault})`}</span>
            </Typography>
          )}
          {byDefaultText && !price && (
            <Typography sx={{ position: 'absolute', top: 20, left: `${value.toString().length * 10 + 15}px` }} variant="body1">
              ({labels[lng].byDefault})
            </Typography>
          )}
        </Grid>
      </GridFlexBetween>
    </MainBox>
  )
}
