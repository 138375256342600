import React, { useContext, ChangeEvent, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { CardSummaryGrid } from '../Cards/cards.styles'
import CardWithSwitchs from '../Cards/CardWithSwitchs'
import SwitchSmall from '../Form/SwitchSmall'
import { switchNotificationsTypes, switchPrivacityTypes } from 'src/interfaces/app.types'
import { ReactComponent as Privacidad } from 'src/assets/icons/general/Privacidad.svg'
import { ReactComponent as Notificaciones } from 'src/assets/icons/general/Notificaciones.svg'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import { UpdatePassword } from 'src/pages/RestaurantPlatform/Informacion/UpdatePassword'
import { useTranslation } from 'react-i18next'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { theme } from 'src/styles/theme'

interface NotificationsPrivacityCardsProps {
  handleSwitch: (e: ChangeEvent<HTMLInputElement>, name: string, type: string) => void
  switchNotifications: switchNotificationsTypes
  switchPrivacity: switchPrivacityTypes
  loading?: boolean
}

export default function NotificationsPrivacityCards({
  handleSwitch,
  switchNotifications,
  switchPrivacity
}: NotificationsPrivacityCardsProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { restaurant } = useContext(RestaurantContext)
  const [openModal, setOpenModal] = useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <CardSummaryGrid item md={6} xs={12}>
          <CardWithSwitchs Icon={Notificaciones} title={labels[lng].notifications}>
            <SwitchSmall
              disabled={userData.role === 'admin'}
              handleChange={(e) => handleSwitch(e, 'dish_of_the_day_reminder', 'notification')}
              ariaLabel={labels[lng].planningDay}
              checked={switchNotifications.dish_of_the_day_reminder}
              text={labels[lng].planningDay}
            />
            <SwitchSmall
              disabled={userData.role === 'admin'}
              handleChange={(e) => handleSwitch(e, 'orders_and_cancellations', 'notification')}
              ariaLabel={labels[lng].newOrdersCancelations}
              checked={switchNotifications.orders_and_cancellations}
              text={labels[lng].newOrdersCancelations}
            />
            <SwitchSmall
              disabled={userData.role === 'admin'}
              handleChange={(e) => handleSwitch(e, 'order_picking', 'notification')}
              ariaLabel={labels[lng].pickupOrders}
              checked={switchNotifications.order_picking}
              text={labels[lng].pickupOrders}
            />
            <SwitchSmall
              disabled={userData.role === 'admin'}
              handleChange={(e) => handleSwitch(e, 'news', 'notification')}
              ariaLabel={labels[lng].fudclubNews}
              checked={switchNotifications.news}
              text={labels[lng].fudclubNews}
            />
          </CardWithSwitchs>
        </CardSummaryGrid>
        <CardSummaryGrid item md={6} xs={12}>
          <CardWithSwitchs pass Icon={Privacidad} title={lng === 'es' ? 'Privacidad' : labels[lng]?.permissions}>
            <SwitchSmall
              handleChange={(e) => handleSwitch(e, 'new_orders_and_cancellations', 'privacity')}
              ariaLabel={labels[lng].newOrdersCancelationsEmails}
              checked={switchPrivacity.new_orders_and_cancellations}
              text={labels[lng].newOrdersCancelationsEmails}
            />
            <SwitchSmall
              handleChange={(e) => handleSwitch(e, 'special_offers_and_promotions', 'privacity')}
              ariaLabel={labels[lng].offersEmail}
              checked={switchPrivacity.special_offers_and_promotions}
              text={labels[lng].offersEmail}
            />
            {userData.role !== 'admin' && restaurant?._id === userData._id && (
              <Button
                sx={{
                  position: 'absolute',
                  left: '20px',
                  bottom: '20px',
                  [theme.breakpoints.down('md')]: {
                    left: '16px',
                    bottom: '16px'
                  }
                }}
                variant="text"
                onClick={() => setOpenModal(true)}
              >
                {labels[lng].changePassword}
              </Button>
            )}
          </CardWithSwitchs>
        </CardSummaryGrid>
      </Grid>
      {openModal && <UpdatePassword open={openModal} handleClose={closeModal} userData={userData} lng={lng} />}
    </>
  )
}
