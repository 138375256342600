/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axiosInstance'

export const foodStylesExcelService = async () => {
  const res = await axiosInstance.get('/excel/food_styles')
  return res.data
}

export const foodStylesSousinesExcelService = async () => {
  const res = await axiosInstance.get('/excel/food_styles_cousines')
  return res.data
}

// TODO - Add cards to excels
export const usersExcelService = async () => {
  const res = await axiosInstance.get('/excel/users')
  return res.data
}

export const restaurantsExcelService = async () => {
  const res = await axiosInstance.get('/excel/restaurants')
  return res.data
}

export const platesExcelService = async () => {
  const res = await axiosInstance.get('/excel/plates')
  return res.data
}

export const platesFoodStylesExcelService = async () => {
  const res = await axiosInstance.get('/excel/plates_food_styles')
  return res.data
}

export const bonosExcelService = async () => {
  const res = await axiosInstance.get('/excel/bonos')
  return res.data
}

export const bonosCompanyExcelService = async () => {
  const res = await axiosInstance.get('/excel/bonos_company')
  return res.data
}

export const bonoPayExcelService = async () => {
  const res = await axiosInstance.get('/excel/bono_pay')
  return res.data
}

export const emailsExcelService = async () => {
  const res = await axiosInstance.get('/excel/user/emails')
  return res.data
}

export const phonesExcelService = async () => {
  const res = await axiosInstance.get('/excel/user/phones')
  return res.data
}

export const giftsPlatesExcelService = async () => {
  const res = await axiosInstance.get('/excel/gifts_plates')
  return res.data
}

export const expiredPlatesExcelService = async () => {
  const res = await axiosInstance.get('/excel/expired_plates')
  return res.data
}

export const bookingExcelService = async () => {
  const res = await axiosInstance.get('/excel/bookings')
  return res.data
}

export const bookingCanceledExcelService = async () => {
  const res = await axiosInstance.get('/excel/bookings/canceled')
  return res.data
}

export const bookingReviewExcelService = async () => {
  const res = await axiosInstance.get('/excel/bookings/review')
  return res.data
}

export const restaurantPaymentExcelService = async () => {
  const res = await axiosInstance.get('/excel/restaurant_payment')
  return res.data
}

export const restaurantPlanningExcelService = async () => {
  const res = await axiosInstance.get('/excel/plannings')
  return res.data
}

export const plateParamenterPPExcelService = async () => {
  const res = await axiosInstance.get('/excel/parameters/pp')
  return res.data
}

export const restaurantParamenterEMHIRRExcelService = async () => {
  const res = await axiosInstance.get('/excel/parameters/emhirr')
  return res.data
}

export const restaurantParamenterFRRExcelService = async () => {
  const res = await axiosInstance.get('/excel/parameters/frr')
  return res.data
}

export const plateParamenterRFCExcelService = async () => {
  const res = await axiosInstance.get('/excel/parameters/rfc')
  return res.data
}

export const cardsExcelService = async () => {
  const res = await axiosInstance.get('/excel/cards')
  return res.data
}

