import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { CardSummaryBigContainer } from './cards.styles'
import { theme } from 'src/styles/theme'

interface CardWithSwitchsProps {
  Icon: FC
  title: string
  children: React.ReactNode
  pass?: boolean
}

export default function CardWithSwitchs({ title, Icon, children, pass = false }: CardWithSwitchsProps): JSX.Element {
  return (
    <CardSummaryBigContainer
      sx={{
        px: 1,
        pt: 3,
        justifyContent: 'flex-start',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          pb: pass ? '64px' : theme.spacing(4)
        }
      }}
    >
      <Icon />
      <Typography sx={{ my: 0.5 }} variant="subtitle2" color="grey.900" component="strong">
        {title}
      </Typography>
      {children}
    </CardSummaryBigContainer>
  )
}
