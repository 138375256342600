import React, { useContext, useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2'
import { HeaderContext } from 'src/context/header/headerContext'
import Button from '@mui/material/Button'
import SearchInput from 'src/components/SearchInput'
import GruposAddEdit from './GruposAddEdit'

import TableApi from 'src/components/Table/TableApi'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import { labels } from 'src/labels/main_labels'
import { Box } from '@mui/material'
import { activeGroup, deactiveGroup, deleteGroups, getGroups, toogleStatusGroup } from 'src/services/groups.services'
import { AuthContext } from 'src/context/auth/authContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { useHistory } from 'react-router-dom'
import { Order } from 'src/interfaces/pages.types'
import { useTranslation } from 'react-i18next'

export default function Grupos(): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const { userData } = useContext(AuthContext)
  const { showHeader, setExportData, setExportName } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const history = useHistory()

  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const wordSearch = useRef('')

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }
  // TABLE CONTROL
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const pageRef = useRef(0)

  const headCells: any[] = [
    {
      idField: 'name',
      align: 'left',
      disablePadding: true,
      label: labels[lng].group,
      order: true,
      fieldtype: 'name-avatar'
    },
    {
      idField: 'group',
      align: 'center',
      disablePadding: true,
      label: labels[lng].numberRestaurants,
      fieldtype: 'numberRestaurants'
    },
    {
      idField: 'createdAt',
      align: 'center',
      disablePadding: true,
      label: labels[lng].createdAt,
      order: true,
      fieldtype: 'date-time'
    }
  ]

  // EXCEL EXPORT
  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      const excelFormatted = rowsData?.map((group: any) => {
        return {
          Nombre: group.name,
          Restaurantes: group.group.join(';'),
          'Nº de Restaurantes': group.group.length || 0,
          'Fecha de Registro': dateTimeConvert(group.createdAt)
        }
      })
      setExportData(excelFormatted)
      setExportName('Grupos')
    }
    return () => setExportData(null)
  }, [rowsData])

  const fetchGroups = async () => {
    setLoadingTable(true)
    try {
      const resp = await getGroups(
        `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
        rowsPerPage,
        pageRef.current,
        wordSearch.current
      )
      setTotalCount(resp.total)
      setRowsData(resp.groups)
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    pageRef.current = newPage
    fetchGroups()
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageRef.current = 0
  }
  const handleSearch = async (word: string) => {
    if (!word) {
      fetchGroups()
      return
    }
    const resp = await getGroups(
      `${orderBy === 'location' ? 'address' : orderBy}=${order === 'desc' ? 'des' : 'asc'}`,
      rowsPerPage,
      0,
      word
    )
    pageRef.current = 0
    setTotalCount(resp.total)
    setRowsData(resp.groups)
  }

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <div className="flex-between ">
        <Box sx={{ width: { sm: 350 } }}>
          <SearchInput fullwidth placeholder={labels[lng].searchGroup} name="search" handleSubmit={handleSearch} clean />
        </Box>
        <Button sx={{ ml: 2 }} size="small" type="submit" color="info" variant="outlined" onClick={() => setOpenModal(true)}>
          {labels[lng].addGroup}
        </Button>
      </div>
    )
  }

  useEffect(() => {
    if (userData) fetchGroups()
  }, [rowsPerPage, order, orderBy])

  useEffect(() => {
    if (initHeader) showHeader(Header)
    // return () => setInitHeader(false)
  }, [initHeader])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels[lng].groups, lng)).then((result) => {
      if (result.isConfirmed) {
        deleteGroups(data)
          .then(() => {
            showSnackbar('success', labels[lng].groupDeleted)
            fetchGroups()
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = (data: Record<string, unknown>) => {
    setEditData(data)
    setOpenModal(true)
  }

  const onDeactivate = async (group: any) => {
    try {
      await toogleStatusGroup(group._id)
      showSnackbar('success', labels[lng].statusChanged)
      fetchGroups()
      closeModal()
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onDeactivateAll = async () => {
    try {
      await deactiveGroup(selected)
      showSnackbar('success', labels[lng].statusChanged)
      fetchGroups()
      setSelected([])
      closeModal()
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const onActivateAll = async () => {
    try {
      await activeGroup(selected)
      showSnackbar('success', labels[lng].statusChanged)
      fetchGroups()
      setSelected([])
      closeModal()
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  return (
    <>
      <TableApi
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rows={rowsData}
        headCells={headCells}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        loading={loadingTable}
        onRowClick={(row) => history.push(`/grupos/${row._id}`)}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
        page={pageRef.current}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        selected={selected}
        setSelected={setSelected}
        onDeactivate={onDeactivate}
        onDeactivateAll={onDeactivateAll}
        onActivateAll={onActivateAll}
      />
      {openModal && (
        <GruposAddEdit openModal={openModal} closeModal={closeModal} dataToEdit={editData} onDelete={onDelete} fetchData={fetchGroups} />
      )}
    </>
  )
}
