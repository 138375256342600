import React, { PropsWithChildren, useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import { Dialog, DialogContentText, DialogContent, DialogActions, Breakpoint, Box, FormHelperText } from '@mui/material'
import { ReactComponent as ErrorRed } from 'src/assets/icons/dialog-icons/ErrorRed.svg'
import { ReactComponent as SuccessGreen } from 'src/assets/icons/dialog-icons/SuccessGreen.svg'
import { ReactComponent as SuccessRed } from 'src/assets/icons/dialog-icons/SuccessRed.svg'
import { ModalTitle } from './modals.styles'
import { labels, labels as labelsT } from 'src/labels/main_labels'
import { Input } from 'src/pages/Welcome/welcome.styles'
import { validateEmail } from 'src/utils/validateForm'
import { saveAs } from 'file-saver'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { theme } from 'src/styles/theme'

interface Props {
  open: boolean
  handleClose: () => void
  handleAction?: () => void
  title: string
  text?: string
  size?: Breakpoint
  noIcon?: boolean
  noFooter?: boolean
  btnText?: string
  children?: React.ReactNode
  category?: string
  name?: string
  qrCode?: string
  centerTitle?: boolean
}

export const SimpleDialog = ({
  open,
  handleClose,
  title,
  text,
  size = 'xs',
  noIcon,
  btnText,
  noFooter,
  category,
  centerTitle,
  children
}: PropsWithChildren<Props>): JSX.Element => {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const modalTitleStyle = centerTitle ? { pb: 0, textAlign: 'center' } : { pb: 0 }
  return (
    <Dialog
      fullWidth
      maxWidth={size}
      open={open}
      sx={{
        [theme.breakpoints.down('md')]: {
          width: 'calc(100% - 32px)',
          mx: 'auto',
          '& .MuiPaper-root': {
            margin: 0,
            width: '100%'
          }
        }
      }}
    >
      <Box sx={{ mt: 3 }} className="flex-column-center ">
        {!noIcon && category === 'error' && <ErrorRed style={{ marginBottom: '20px' }} />}
        {!noIcon && category === 'successRed' && <SuccessRed style={{ marginBottom: '20px' }} />}
        {!noIcon && !['error', 'successRed'].includes(category || '') && <SuccessGreen style={{ marginBottom: '20px' }} />}
        {children && children}
        <ModalTitle sx={modalTitleStyle}>{title}</ModalTitle>
      </Box>
      <DialogContent
        sx={{
          pb: 0,
          pt: 1,
          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        }}
      >
        {text && <DialogContentText sx={{ textAlign: 'center' }}>{text}</DialogContentText>}
      </DialogContent>
      {!noFooter && (
        <DialogActions>
          <Button className="button-mobile" type="submit" fullWidth variant="contained" sx={{ m: 2 }} onClick={handleClose}>
            {btnText || labels[lng].accept}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export const QrDialog = ({ open, handleClose, title, text, size = 'xs', name, qrCode }: PropsWithChildren<Props>): JSX.Element => {
  const { i18n } = useTranslation()
  const lng: string = i18n.language

  const handleDownload = () => {
    if (qrCode) {
      const convertBase64ToFile = (base64String: any, fileName: any) => {
        const arr = base64String.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const uint8Array = new Uint8Array(n)
        while (n--) {
          uint8Array[n] = bstr.charCodeAt(n)
        }
        const file = new File([uint8Array], fileName, { type: mime })
        return file
      }

      const file = convertBase64ToFile(qrCode, name)
      saveAs(file, name)
    }
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 384,
          [theme.breakpoints.down('md')]: {
            padding: '24px 16px'
          }
        }
      }}
      fullWidth
      maxWidth={size}
      open={open}
    >
      <Box
        sx={{
          mt: '16px'
        }}
        className="flex-column-center "
      >
        {qrCode && <img src={qrCode} alt={`qrcode-${name}`} />}
        <ModalTitle
          sx={{
            pb: 0,
            pt: '24px',
            [theme.breakpoints.down('md')]: {
              boxShadow: 'none'
            }
          }}
        >
          {title}
        </ModalTitle>
      </Box>
      <DialogContent sx={{ pb: 0, pt: 1, px: 3 }}>
        {text && <DialogContentText sx={{ textAlign: 'center', color: '#6B7280' }}>{text}</DialogContentText>}
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Button
          className="button-mobile"
          color="info"
          fullWidth
          variant="outlined"
          sx={{
            m: '24px',
            mr: '12px',
            px: 0,
            [theme.breakpoints.down('md')]: {
              width: '50%',
              mx: 0,
              mb: 0
            }
          }}
          onClick={handleDownload}
        >
          {labels[lng].downloadQr}
        </Button>
        <Button
          className="button-mobile"
          fullWidth
          variant="contained"
          sx={{
            m: '24px',
            ml: '12px !important',
            px: 0,
            [theme.breakpoints.down('md')]: {
              width: '50%',
              mx: 0,
              mb: 0
            }
          }}
          onClick={handleClose}
        >
          {labels[lng].accept}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const QuestionDialog = ({
  open,
  handleClose,
  title,
  text,
  size = 'xs',
  noIcon,
  noFooter,
  btnText,
  handleAction
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Dialog fullWidth maxWidth={size} open={open}>
      <Box sx={{ mt: 3 }} className="flex-column-center ">
        {!noIcon && <SuccessGreen />}
        <ModalTitle sx={{ pb: 0, textAlign: 'center' }}>{title}</ModalTitle>
      </Box>
      {text && (
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText sx={{ textAlign: 'center' }}>{text}</DialogContentText>
        </DialogContent>
      )}
      {!noFooter && (
        <DialogActions>
          <Button type="submit" fullWidth variant="contained" sx={{ m: 2 }} onClick={handleAction}>
            {btnText || 'Aceptar'}
          </Button>
          <Button type="submit" color="info" fullWidth variant="outlined" sx={{ m: 2 }} onClick={handleClose}>
            {'Cancelar'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export const DeleteDialog = ({ open, handleClose, handleAction }: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <Box sx={{ mt: 3 }} className="flex-column-center ">
        <SuccessGreen />
        <ModalTitle sx={{ pb: 0, textAlign: 'center' }}>¿Estás seguro?</ModalTitle>
      </Box>

      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ textAlign: 'center' }}>No podrás recuperar el elemento borrado</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="info" fullWidth variant="outlined" sx={{ m: 2 }} onClick={handleClose}>
          {'Cancelar'}
        </Button>
        <Button type="submit" fullWidth variant="contained" sx={{ m: 2 }} onClick={handleAction}>
          {'Si'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface DialogRecoverProps {
  open: boolean
  handleClose: () => void
  handleAction: (email: string) => void
  loading: boolean
  errorModal: string | null
}

export const RecoverPasswordDialog = ({ open, handleClose, handleAction, loading, errorModal }: DialogRecoverProps): JSX.Element => {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const [state, setState] = useState('')
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setError(null)
  }, [open])

  useEffect(() => {
    setError(errorModal)
  }, [errorModal])

  const submit = () => {
    if (validateEmail(state)) {
      handleAction(state)
    } else {
      setError(labelsT[lng].emailError)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState(e.target.value)
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ textAlign: 'center', mb: 2, fontWeight: '500', fontSize: '18px', color: '#000' }}>
          {labelsT[lng].recoverPasswordTitle}
        </DialogContentText>
        <DialogContentText sx={{ textAlign: 'center', mb: 2 }}>{labelsT[lng].recoverPasswordText}</DialogContentText>
        <Input
          placeholder={labelsT[lng].email}
          required
          fullWidth
          name="email"
          type="email"
          id="email"
          autoComplete="recover-email"
          onChange={(e) => handleChange(e)}
        />
        {error ? (
          <FormHelperText sx={{ mt: '4px', mb: '16px' }} error id="form-login">
            {error}
          </FormHelperText>
        ) : (
          <Box sx={{ mt: 3 }} />
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          className="button-mobile"
          loading={loading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mx: 2, mb: 2 }}
          onClick={submit}
        >
          {labelsT[lng].recoverPassword}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
