import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import Cookies from 'universal-cookie'
import { io } from 'socket.io-client'

// COMPONENTS
import { labels } from 'src/labels/main_labels'
import PedidosHeader from 'src/components/PedidosComponents/PedidosHeader'
import PickUpOrders from 'src/components/PedidosComponents/PickUpOrders'
import OrdersCollected from 'src/components/PedidosComponents/OrdersCollected'
import { QrDialog, SimpleDialog } from 'src/components/Modals/Dialog'
import EmptyPage from 'src/components/EmptyPage'

// CONTEXT
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'
import { RestaurantContext } from 'src/context/restaurants/restaurantContext'
import { BookingContext } from 'src/context/booking/bookingContext'

import { getBookings } from 'src/services/booking.services'
import { HEADER_BAR_HEIGHT } from 'src/constants/const'

// TYPES
import { BookingApiTypes, BookingTypes, RestaurantTypes } from 'src/interfaces/pages.types'

// ICONS
import { ReactComponent as QRcode } from 'src/assets/icons/general/QRcode.svg'
import { ReactComponent as PedidosEmpty } from 'src/assets/icons/Empty/PedidosEmpty.svg'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getEMHIRR, getSubsMinutesFromDateFRrAddDays, subsMinutesFromDateFRr } from 'src/utils/time-helpers'
import { subHours } from 'date-fns'
import { theme } from 'src/styles/theme'
import { ReactComponent as Sun } from 'src/assets/icons/smalls/Sun.svg'

const cookies = new Cookies()

interface PedidosProps {
  restaurantInfo?: RestaurantTypes
}

export default function Pedidos({ restaurantInfo }: PedidosProps): JSX.Element {
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const history = useHistory()
  const { showHeader } = useContext(HeaderContext)
  const { userData } = useContext(AuthContext)
  const { restaurant } = useContext(RestaurantContext)
  const { setTotalBookings } = useContext(BookingContext)
  const [empty, setEmpty] = useState(true)
  const [loading, setLoading] = useState(true)
  const [openQrDialog, setOpenQrDialog] = useState(false)
  const [bookings, setBookings] = useState<BookingApiTypes | null>(null)
  const [EMHIRR, setEMHIRR] = useState<{ hour: number; minutes: number } | null>(null)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [textError, setTextError] = useState({ title: '', text: '' })

  const fetchData = async (noLoading?: boolean) => {
    !noLoading && setLoading(true)
    try {
      const restaurantSelect = restaurantInfo || restaurant
      const restaurantId: any = restaurantSelect?._id
      const bookingsInfo = await getBookings(restaurantId)
      setEMHIRR(restaurantSelect?.parameter?.EMHIRR)
      bookingsInfo && setEmpty(false)

      if (bookingsInfo && bookingsInfo.bookings?.waiting?.bookings) {
        const totalQuantity = bookingsInfo?.bookings?.waiting?.bookings?.reduce((acc: number, curr: BookingTypes) => {
          return acc + (curr.quantity || 0)
        }, 0)
        setTotalBookings(totalQuantity)
      }
      setBookings(bookingsInfo)
    } catch (error) {
      setEmpty(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const socket = io(process.env.REACT_APP_URLSOCKET || 'wss://fudclub-api.app.faable.com/', {
      extraHeaders: {
        Authorization: 'Bearer ' + cookies.get('fudClub')
      }
    })
    if (bookings && bookings.planning._id) {
      socket.on('connect', function () {
        console.info('socket connected')
      })

      socket.emit('join_planning', { planning: bookings.planning._id })

      socket.on('new_join', () => {
        console.info('new_join')
      })

      socket.on('NEW-BOOKING', () => {
        fetchData(true)
      })
      socket.on('CHANGE-STATUS', () => {
        fetchData(true)
      })
    }
    return () => {
      socket.disconnect()
      console.info('socket disconnected')
    }
  }, [bookings])

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language
    return (
      <Grid container>
        <Grid item xs={2} sm={4}></Grid>
        <Grid item xs={8} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', md: 'center' } }}>
          <Typography variant="h3" component="h1" flex={1} textAlign="center">
            {labels[lng].orders}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            sx={{
              backgroundColor: '#fff',
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            }}
            size="small"
            type="submit"
            color="info"
            variant="outlined"
            onClick={() => setOpenQrDialog(true)}
            startIcon={<QRcode />}
            disabled={!restaurant?.qrCode}
          >
            {labels[lng].seeQrCode}
          </Button>
          {restaurant?.qrCode ? (
            <Typography
              sx={{
                backgroundColor: '#fff',
                [theme.breakpoints.up('md')]: {
                  display: 'none'
                }
              }}
              variant="h3"
              fontSize="14px"
              lineHeight={'24px'}
              color="#374151"
              onClick={() => setOpenQrDialog(true)}
            >
              {labels[lng].seeQrCodeSmall}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    )
  }
  useEffect(() => {
    if (userData && userData.role === 'restaurant') showHeader(Header)
  }, [])

  const emptyPageForEMHIRR = () => {
    const now = new Date()
    const restaurantSelect = restaurantInfo || restaurant
    const FRR = restaurantSelect?.parameter?.FRR
    if (bookings?.planning && EMHIRR) {
      const planningDate = subHours(new Date(bookings.planning.date as string), Math.abs(new Date().getTimezoneOffset() / 60))
      return (
        now.getDate() === planningDate.getDate() &&
        now <= subHours(getEMHIRR(EMHIRR, FRR, planningDate), Math.abs(new Date().getTimezoneOffset() / 60))
      )
    }
    return (
      restaurantSelect?.parameter?.EMHIRR &&
      now < subHours(getEMHIRR(restaurantSelect?.parameter?.EMHIRR, FRR, now), Math.abs(new Date().getTimezoneOffset() / 60))
    )
  }

  const availableTitle = (today, tomorrow) => {
    const restaurantSelect = restaurantInfo || restaurant
    const FRR = restaurantSelect?.parameter?.FRR
    const EMHIRR = restaurantSelect?.parameter?.EMHIRR
    if (EMHIRR && FRR) {
      const date = new Date()
      const EMHIRRi = subHours(getEMHIRR(EMHIRR, FRR, date), Math.abs(new Date().getTimezoneOffset() / 60))
      if (
        EMHIRRi.getDate() === date.getDate() ||
        new Date(date.getFullYear(), date.getMonth(), date.getDate(), EMHIRR.hour, EMHIRR.minutes) > date
      ) {
        return today
      }
      return tomorrow
    }
    return ''
  }

  const getPlateName = (plate) => {
    return lng === 'en' ? (plate?.translations && plate?.translations[0] ? plate?.translations[0].name : plate.name) : plate.name
  }

  let forPickUp = labels[lng].pickUpOrdersText1
  let day = 0
  if (EMHIRR && bookings?.planning) {
    const now = new Date()
    const planningDate = subHours(new Date(bookings?.planning?.date as string), Math.abs(new Date().getTimezoneOffset() / 60))
    if (now < planningDate) {
      forPickUp = labels[lng].pickUpOrdersText2
      day = 1
    }
  }

  if (loading) return <LinearProgress />

  const totalUses =
    (bookings?.planning?.uses || 0) +
    (bookings?.bookings?.finalized?.bookings.reduce((total, booking) => {
      if (booking.status === 'HARD-CANCELED') {
        total += booking.quantity || 0
      }
      return total
    }, 0) || 0)

  const conditionTodayOrTomorrow = day
    ? new Date() <
      getSubsMinutesFromDateFRrAddDays(
        bookings?.planning.parameter?.FRr,
        restaurantInfo ? restaurantInfo.parameter?.FRR : restaurant?.parameter?.FRR,
        day
      )
    : new Date() >
      getSubsMinutesFromDateFRrAddDays(
        bookings?.planning.parameter?.FRr,
        restaurantInfo ? restaurantInfo.parameter?.FRR : restaurant?.parameter?.FRR,
        day
      )
  return (
    <>
      {emptyPageForEMHIRR() ? (
        <EmptyPage
          small
          title={availableTitle(labels[lng].emptyOrderTitle, labels[lng].emptyOrderTitle2)}
          text={labels[lng].emptyOrderText}
          Icon={PedidosEmpty}
          button={labels[lng].ordersEarly}
        />
      ) : empty ? (
        <EmptyPage
          small
          title={availableTitle(labels[lng].noDishesAvailableTodayTitle, labels[lng].noDishesAvailableTomorrowTitle)}
          text={labels[lng].noDishesAvailableDescription}
          Icon={PedidosEmpty}
        >
          <Button
            sx={{ backgroundColor: '#fff', mt: '12px', textTransform: 'inherit' }}
            size="small"
            color="info"
            variant="outlined"
            onClick={() => history.push('/planificar')}
          >
            {labels[lng].toPlan}
          </Button>
        </EmptyPage>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: `calc(100vh - ${HEADER_BAR_HEIGHT})` }}>
          <PedidosHeader
            parameter={bookings && bookings?.planning?.parameter}
            EMHIRR={EMHIRR}
            RFC={bookings?.planning?.parameter?.RFC?.value}
            name={getPlateName(bookings?.plate)}
            photo={bookings?.plate?.images[0]}
            quantity={bookings?.planning?.quantity}
            uses={totalUses}
            planning={bookings?.planning}
          />
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4,
              background: '#F9FAFB',
              borderRadius: '8px',
              p: '12px 16px',
              mx: '16px',
              '& svg': {
                flexShrink: 0,
                width: '22px',
                height: '22px'
              },
              [theme.breakpoints.up('md')]: {
                display: 'none'
              }
            }}
          >
            <Sun />
            {conditionTodayOrTomorrow ? (
              <Typography sx={{ textAlign: 'left', ml: 1 }} variant="subtitle2" component="h6">
                {labels[lng].cantLongerReceiveOrdersForToday}
              </Typography>
            ) : (
              <Typography sx={{ textAlign: 'left', ml: 1 }} variant="subtitle2" component="h6">
                {forPickUp}{' '}
                {bookings?.planning &&
                  subsMinutesFromDateFRr(
                    bookings?.planning.parameter?.FRr,
                    restaurantInfo ? restaurantInfo.parameter?.FRR : restaurant?.parameter?.FRR
                  )}
              </Typography>
            )}
          </Stack>
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              flex: 1,
              [theme.breakpoints.down('md')]: {
                mt: 0
              }
            }}
          >
            <Grid item xs={12} md={8}>
              <PickUpOrders
                EMHIRR={EMHIRR}
                bookings={bookings?.bookings?.waiting?.bookings}
                planning={bookings?.planning}
                plateUses={bookings?.bookings?.waiting?.platesUses}
                restaurantInfo={restaurantInfo || restaurant}
                setOpenErrorDialog={setOpenErrorDialog}
                setTextError={setTextError}
                fetchData={fetchData}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                [theme.breakpoints.down('md')]: {
                  mt: '18px !important'
                }
              }}
            >
              <OrdersCollected bookings={bookings?.bookings?.finalized?.bookings} plateUses={bookings?.bookings?.finalized?.platesUses} />
            </Grid>
          </Grid>
        </Box>
      )}
      <QrDialog
        open={openQrDialog}
        handleClose={() => setOpenQrDialog(false)}
        title={labels[lng].qrDialogTitle}
        text={labels[lng].qrDialogText}
        name={restaurant?.name}
        qrCode={restaurant?.qrCode}
      />
      <SimpleDialog
        noIcon
        centerTitle
        category="successRed"
        open={openErrorDialog}
        handleClose={() => setOpenErrorDialog(false)}
        title={textError.title}
        text={textError.text}
      />
    </>
  )
}
