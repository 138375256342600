import React, { FC } from 'react'

import { Stack } from '@mui/material'
import { OptionButton } from 'src/components/Form/buttonSelector.styles'
import { IconColor } from '../Layout/layout.styles'

interface FiltersFooterProps {
  setFilter: React.Dispatch<React.SetStateAction<string>>
  filter: string
  options: Array<{ value: string; text: string; Icon: FC }>
}

export default function FiltersFooter({ setFilter, filter, options }: FiltersFooterProps): JSX.Element {
  return (
    <Stack direction="row" sx={{ p: 1 }} spacing={1} alignItems="center">
      {options.map((elm: any) => {
        const active = filter === elm.value
        return (
          <OptionButton
            startIcon={
              elm.Icon ? (
                <IconColor style={{ height: '20px', lineHeight: 1 }} className={active ? 'stroke' : ''}>
                  {' '}
                  <elm.Icon />
                </IconColor>
              ) : null
            }
            key={elm.value}
            sx={{ mr: 0, backgroundColor: (theme) => (active ? theme.palette.grey[200] : '#fff') }}
            disableElevation
            variant="contained"
            style={{ color: active ? '#374151' : '#6B7280', alignItems: 'center' }}
            onClick={() => setFilter(elm.value)}
          >
            {elm.text}
          </OptionButton>
        )
      })}
    </Stack>
  )
}
