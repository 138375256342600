import React from 'react'
import { CardSummaryBigContainer } from './cards.styles'
import SimpleMap from '../Maps/SimpleMap'
import { grey } from '@mui/material/colors'
import { theme } from 'src/styles/theme'

interface CardMapProps {
  location: any
  showStreet?: boolean
}

function CardMap({ location, showStreet }: CardMapProps): JSX.Element {
  return (
    <>
      <CardSummaryBigContainer
        sx={{
          p: 0,
          '& >div': { width: '100%' },
          '& .map': { margin: '0 !important', width: '100% !important', height: '142px !important' },
          [theme.breakpoints.down('md')]: {
            height: '100px'
          }
        }}
      >
        <SimpleMap address={location} type={'simple'} />
      </CardSummaryBigContainer>
      {showStreet && (
        <div style={{ width: '95%', margin: 'auto', marginTop: 10, marginBottom: 10, overflow: 'hidden', color: grey[500] }}>
          <span style={{ fontSize: 14 }}>{location && location && location.street + ' ' + location.number + ',' + location.cp}</span>
        </div>
      )}
    </>
  )
}

export default React.memo(CardMap)
