import { Box, Divider, ListItemText, MenuItem, MenuList, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderContext } from 'src/context/header/headerContext'
import { theme } from 'src/styles/theme'
import { labels } from 'src/labels/main_labels'
import { ContainerFud } from 'src/styles/general-styles'
import { useHistory } from 'react-router-dom'
import { ChevronLeft } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import { updateLanguage } from 'src/services/restaurants.services'

export default function Idioma(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const { i18n } = useTranslation()
  const lng: string = i18n.language
  const history = useHistory()

  const Header = () => {
    const { i18n } = useTranslation()
    const lng: string = i18n.language

    return (
      <div className="flex-center" style={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: '-2px',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <ChevronLeft style={{ color: '#9CA3AF', fill: '#9CA3AF' }} onClick={() => history.goBack()} />
        </Box>
        <Typography
          variant="h3"
          component="h1"
          textAlign={'center'}
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          {labels[lng].language}
        </Typography>
      </div>
    )
  }
  useEffect(() => {
    showHeader(Header)
    window.scrollTo(0, 0)
  }, [])

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
    updateLanguage(value).catch((err) => {
      console.error('An error occurred while updating language. ', err)
    })
  }

  return (
    <ContainerFud
      sx={{
        px: '0'
      }}
    >
      <MenuList sx={{ p: 0, '& .MuiListItemText-root ': { '& .MuiTypography-root': { color: '#374151' } } }}>
        <Divider sx={{ mb: 1 }} />
        <MenuItem
          onClick={() => {
            changeLanguage('es')
          }}
        >
          <ListItemText sx={{ ml: '14px' }} primary={labels[lng].spanish} />
          {i18n.language === 'es' && <CheckIcon color="primary" />}
        </MenuItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem
          onClick={() => {
            changeLanguage('en')
          }}
        >
          <ListItemText sx={{ ml: '14px' }} primary={labels[lng].english} />
          {i18n.language === 'en' && <CheckIcon color="primary" />}
        </MenuItem>
        <Divider />
      </MenuList>
    </ContainerFud>
  )
}
